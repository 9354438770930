import { ApplicationRoutes } from '@src/app/router/routes';
import { prepareModuleRoutes } from '@src/routing';

export const testRunsRoutes = prepareModuleRoutes<'testRuns' | 'testRun'>(
	{
		testRuns: {
			path: '/',
		},
		testRun: {
			path: '/:id',
		},
	},
	ApplicationRoutes.testRuns.absolutePath
);
