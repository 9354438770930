import { useCopyToClipboard } from '@src/common/hooks/useCopyToClipboard';
import { ApplicationForInternal } from '@src/modules/application/types';

export const useApplicationsTableRow = ({
	application,
}: {
	application: ApplicationForInternal;
}) => {
	const { handleCopy, copyStatus } = useCopyToClipboard(application.apiKey);

	return {
		handleCopy,
		copyStatus,
	};
};
