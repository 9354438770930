import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useUpdateFileWithTagModalStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			padding: theme.spacing(5),
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(3),
		});

		const row = css({
			display: 'grid',
			gridTemplateColumns: '1fr auto',
			gap: '10px',
			alignItems: 'center',
		});

		const detachIconButton = css({ marginBottom: theme.spacing(3) });

		const buttonsContainer = css({
			display: 'flex',
			justifyContent: 'right',
			gap: '20px',
		});

		return { row, section, detachIconButton, buttonsContainer };
	}, [theme]);
};
