import {
	useDeleteUserFromAppMutation,
	useUpdateAppUserRoleMutation,
} from '@src/modules/settings/api';
import { useCallback, useMemo } from 'react';
import { isRoleGreaterThan } from '@src/modules/authentication/service';
import { capitalize } from '@mui/material';
import { InvitationStatus } from '@src/modules/settings/api/types';
import { ApplicationUserRoles } from '@src/modules/application/service';

export const useMemberTableRow = ({
	id,
	invitationStatus,
	role,
	isAuthenticatedUserAllowedToUpdate,
}: {
	isAuthenticatedUserAllowedToUpdate: boolean;
	role: ApplicationUserRoles;
	invitationStatus: InvitationStatus;
	id: string;
}) => {
	const [deleteUserFromApp, { isLoading: isDeletingLoading }] =
		useDeleteUserFromAppMutation();
	const [updateUserRoleMutation, { isLoading: isRoleUpdateLoading }] =
		useUpdateAppUserRoleMutation();

	const invitationStatusFormatted = useMemo(() => {
		return capitalize(invitationStatus);
	}, [invitationStatus]);

	const roleFormatted = useMemo(() => {
		return capitalize(role);
	}, [role]);

	const isUserEditable = useMemo(() => {
		return (
			!isRoleGreaterThan(role, ApplicationUserRoles.Member) &&
			isAuthenticatedUserAllowedToUpdate
		);
	}, [role, isAuthenticatedUserAllowedToUpdate]);

	const handleEditMembersRole = useCallback(
		(e) => {
			const role = e.target.value as ApplicationUserRoles;

			updateUserRoleMutation({ role, id });
		},
		[updateUserRoleMutation, id]
	);

	const handleDeleteUserFromApp = useCallback(() => {
		deleteUserFromApp(id);
	}, [id, deleteUserFromApp]);

	return {
		handleDeleteUserFromApp,
		isSelectDisabled: isRoleUpdateLoading || isDeletingLoading,
		isDeletingLoading,
		handleEditMembersRole,
		invitationStatusFormatted,
		roleFormatted,
		isUserEditable,
	};
};
