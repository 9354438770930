import { AppState } from '@src/store/types';

export const selectAppName = (state: AppState) =>
	state.volatile.applicationsInfo.activeApplication?.name;

export const selectActiveApplication = (state: AppState) =>
	state.volatile.applicationsInfo?.activeApplication;

export const selectLayoutOptions = (state: AppState) =>
	state.volatile.layoutOptions;

export const selectIsAuthenticated = (state: AppState) =>
	state.volatile.authenticationInfo?.authenticated;

export const selectIsLoggingIn = (state: AppState) =>
	state.volatile.authenticationInfo?.isLoggingIn;

export const selectAuthenticationInfo = (state: AppState) =>
	state.volatile.authenticationInfo;

export const selectApplicationsInfo = (state: AppState) =>
	state.volatile.applicationsInfo;

export const selectRedirectToAfterAuthentication = (state: AppState) =>
	state.volatile.redirectToAfterAuthentication;
