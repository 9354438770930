import {
	// isRejectedWithValue,
	Middleware,
	MiddlewareAPI,
} from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';

const apiMiddleware: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		// Example:
		// if (isRejectedWithValue(action)) {
		// 	const method = action?.meta?.baseQueryMeta?.request?.method;
		// 	if (method && method !== 'GET') {
		// 		toast.error('An error occurred, please try again later');
		// 	}
		// }
		return next(action);
	};

export default apiMiddleware;
