import { SvgIconComponent } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PersonIcon from '@mui/icons-material/Person';
import { UserStoryOrigin } from '../../types';

export const StoryItemIconByOrigin: Record<UserStoryOrigin, SvgIconComponent> =
	{
		[UserStoryOrigin.USER]: PersonIcon,
		[UserStoryOrigin.D2S]: AutoAwesomeIcon,
	};
