import { Box, Button, Chip, Typography } from '@mui/material';
import { useTestGenerationLabelingHeaderStyles } from './styles';
import { TestGenerationLogStep } from '@src/modules/test-generation-labeling/types';
import { Link } from 'react-router-dom';
import { useTestGenerationLabelingHeader } from './useTestGenerationLabelingHeader';

export const TestGenerationLabelingHeader = ({
	testGenerationLogStep,
	activeStoredStep,
	prevHref,
	nextHref,
}: {
	testGenerationLogStep?: TestGenerationLogStep;
	activeStoredStep?: TestGenerationLogStep;
	prevHref: string | null;
	nextHref: string | null;
}) => {
	const styles = useTestGenerationLabelingHeaderStyles();
	const { subTitle, title, date, labeledAtMessage } =
		useTestGenerationLabelingHeader({
			testGenerationLogStep,
			activeStoredStep,
		});

	//TODO: add empty state
	if (!testGenerationLogStep) {
		return <div>Loading...</div>;
	}

	return (
		<Box css={styles.container}>
			{/*Left part*/}

			<Box css={styles.column}>
				<Box css={styles.alignedRow}>
					<Typography variant="h5">{title}</Typography>
					<If condition={!!labeledAtMessage}>
						<Chip label={labeledAtMessage} color="success" />
					</If>
				</Box>
				<Typography variant="caption">{subTitle}</Typography>
			</Box>

			{/*Right part*/}
			<Box css={styles.row}>
				<Box css={styles.columnEnd}>
					<Link to={prevHref}>
						<Button variant="outlined" disabled={!prevHref} css={styles.button}>
							Previous
						</Button>
					</Link>
				</Box>

				<Box css={styles.column}>
					<Typography variant="caption">Generated on: {date}</Typography>

					<Link to={nextHref}>
						<Button
							variant="contained"
							disabled={!nextHref}
							css={styles.button}
						>
							Next
						</Button>
					</Link>
				</Box>
			</Box>
		</Box>
	);
};
