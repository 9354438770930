import { useForm } from 'react-hook-form';
import { useAddUserToAppMutation } from '@src/modules/settings/api';
import { useCallback } from 'react';
import { useYupValidationResolver } from '@src/common/hooks/useYupValidationResolver';
import { validationSchema } from '@src/modules/settings/components/ProjectSettingsForm/validation';
import { ApplicationUserRoles } from '@src/modules/application/service';

export const useAddNewUserForm = () => {
	const [addUserToApp, { isLoading: isAddNewUserLoading }] =
		useAddUserToAppMutation();

	const resolver = useYupValidationResolver(validationSchema);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		defaultValues: {
			email: '',
			role: ApplicationUserRoles.Member,
		},
		resolver,
	});

	// submit form to add new member
	const handleAddUserToApp = useCallback(() => {
		return handleSubmit(
			(formValues: { email: string; role: ApplicationUserRoles }) => {
				reset();
				return addUserToApp(formValues);
			}
		)();
	}, [handleSubmit, reset, addUserToApp]);

	return {
		register,
		handleAddUserToApp,
		errors,
		isAddNewUserLoading,
	};
};
