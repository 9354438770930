import { useUpdateUseCollectionNameInTestPathMutation } from '@src/modules/settings/api';
import { useCallback } from 'react';
import { useActiveApplication } from '@src/common/state/volatile/hooks';
import { ApplicationUserRoles } from '@src/modules/application/service';

export const useChangeUseCollectionNameInTestPathCheckbox = () => {
	const activeApplication = useActiveApplication();

	const isCurrentUserAdmin =
		activeApplication.role === ApplicationUserRoles.Admin;

	const [updateUseCollectionNameInTestPath, { isLoading }] =
		useUpdateUseCollectionNameInTestPathMutation();

	const onChangeUseCollectionNameInTestPath = useCallback(
		async (e, value: boolean) => {
			updateUseCollectionNameInTestPath({ useCollectionNameInTestPath: value });
		},
		[updateUseCollectionNameInTestPath]
	);

	return { onChangeUseCollectionNameInTestPath, isLoading, isCurrentUserAdmin };
};
