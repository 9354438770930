import { Route } from 'react-router-dom';
import AppRoute from '@src/routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { userStoriesRoutes } from './routes';
import { RedirectToFlow } from '../pages/RedirectToFlow/RedirectToFlow';

export function UserStoriesRouter() {
	return (
		<AppRouter>
			<Route
				path={userStoriesRoutes.flows.path}
				element={<AppRoute component={RedirectToFlow} />}
			/>
		</AppRouter>
	);
}
