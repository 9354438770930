import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import {
	CollectionsListDropdown,
	INBOX_COLLECTION_ID,
	UserStoriesCollection,
} from '@src/modules/collections/service';
import { useChangeCollectionModal } from './hooks';
import { useChangeCollectionModalStyles } from '@src/modules/user-stories/components/StoriesList/ChangeCollectionModal/styles';
import { UserStory } from '@src/modules/user-stories/types';

export const ChangeCollectionModal = ({
	onClose,
	onAddNewCollection,
	story,
	collections,
}: {
	onClose: () => void;
	onAddNewCollection: () => void;
	story: UserStory;
	collections: UserStoriesCollection[];
}) => {
	const { handleChangeCollection, filteredCollections } =
		useChangeCollectionModal({
			story,
			onClose,
			collections,
		});

	const styles = useChangeCollectionModalStyles();

	return (
		<Dialog fullWidth open={!!story} onClose={onClose} maxWidth="md">
			<DialogContent css={styles.section}>
				<Typography variant="subtitle1">
					Before we continue, please set a collection for "{story?.title}".
				</Typography>

				<Box css={styles.selectionContainer}>
					<Typography variant="caption" gutterBottom>
						Your collections:
					</Typography>

					<CollectionsListDropdown
						//styles={styles.dropdown}
						name="collectionId"
						storyId={story?.id}
						handleChangeCollection={handleChangeCollection}
						onCreateNewCollection={onAddNewCollection}
						collections={filteredCollections}
						value={INBOX_COLLECTION_ID}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
