import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

const useLoadingCircularProgressStyles = ({ stackStyles }) => {
	const theme = useTheme();

	return useMemo(() => {
		const circularProgress = css({
			color: 'inherit',
			marginRight: theme.spacing(1),
		});

		const stack = css(stackStyles, {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
		});

		return {
			circularProgress,
			stack,
		};
	}, [theme, stackStyles]);
};

export default useLoadingCircularProgressStyles;
