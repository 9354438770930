import { MainRouter } from './router/MainRouter';
import { useApp, useAppWithContextProviders } from './hooks';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Toast from './components/Toast';
import { LoadingPage } from '@src/app/pages/LoadingPage';
import { SerializedStyles } from '@emotion/react';
//import { BrowserRouter as Router } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';

function App() {
	const { theme, isLoading } = useApp();

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Toast />
			<Choose>
				<When condition={isLoading}>
					<LoadingPage />
				</When>
				<Otherwise>
					{/* <FeatureFlagsProvider> */}
					<AppWithContextProviders />
					{/* </FeatureFlagsProvider> */}
				</Otherwise>
			</Choose>
		</ThemeProvider>
	);
}

function AppWithContextProviders() {
	useAppWithContextProviders();
	return (
		<Router>
			<MainRouter />
		</Router>
	);
}

declare module 'react' {
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		/* eslint-disable-next-line  @typescript-eslint/no-explicit-any  */
		css?: SerializedStyles | any[];
	}
}

export default App;
