import React from 'react';
import { Autocomplete, Box, Chip, IconButton, useTheme } from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import { useTagsAndFavoriteStyles } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/components/TagTestGenerationStep/styles';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { useTagsAndFavorite } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/components/TagTestGenerationStep/hooks';
import { TestGenerationLogStep } from '@src/modules/test-generation-labeling/types';

export const TagTestGenerationStep = ({
	activeStoredStep,
}: {
	activeStoredStep: TestGenerationLogStep;
}) => {
	const { handleTagsChange, tagsOptions, tags, onFavoriteClick, favorite } =
		useTagsAndFavorite({
			activeStoredStep,
		});

	const styles = useTagsAndFavoriteStyles();

	return (
		<Box css={styles.row}>
			<Autocomplete
				multiple
				defaultValue={[]}
				limitTags={2}
				options={tagsOptions}
				freeSolo
				fullWidth
				value={tags}
				onChange={handleTagsChange}
				renderTags={(value: readonly string[], getTagProps) =>
					value.map((option: string, index: number) => (
						<Chip
							variant="outlined"
							label={option}
							{...getTagProps({ index })}
						/>
					))
				}
				renderInput={(params) => (
					<FormTextField {...params} name="tags" placeholder="Tags" />
				)}
			/>

			<FavoriteIconButton onClick={onFavoriteClick} favorite={favorite} />
		</Box>
	);
};

const FavoriteIconButton = ({ favorite, onClick }) => {
	const theme = useTheme();

	return (
		<IconButton size="small" onClick={onClick}>
			<Choose>
				<When condition={favorite}>
					<StarTwoToneIcon fontSize="large" htmlColor={theme.colors.yellow} />
				</When>

				<Otherwise>
					<StarOutlineOutlinedIcon
						fontSize="large"
						htmlColor={theme.colors.borderColor.main}
					/>
				</Otherwise>
			</Choose>
		</IconButton>
	);
};
