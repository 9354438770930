import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LabelingResult } from '@src/modules/test-generation-labeling/api/types';

export const useLabelingFeedback = ({
	onReset,
	onOpenEditJson,
	onSubmit,
}: {
	onReset: () => void;
	onOpenEditJson: () => void;
	onSubmit: (result: LabelingResult) => void;
}) => {
	const [isActionIncorrect, setIsActionIncorrect] = useState(false);

	const stepId = useParams<'stepId'>();

	const onBack = useCallback(() => {
		setIsActionIncorrect(false);
		onReset();
	}, [onReset]);

	const handleIsNotCorrectAction = useCallback(() => {
		setIsActionIncorrect(true);
		requestAnimationFrame(onOpenEditJson);
	}, [onOpenEditJson]);

	const handleSubmitJsonEdit = useCallback(() => {
		onSubmit(LabelingResult.Incorrect);
	}, [onSubmit]);

	const handleNotSure = useCallback(() => {
		onSubmit(LabelingResult.NotSure);
	}, [onSubmit]);

	const handleApproveLogStep = useCallback(() => {
		onSubmit(LabelingResult.Correct);
	}, [onSubmit]);

	useEffect(() => {
		setIsActionIncorrect(false);
	}, [stepId]);

	return {
		handleApproveLogStep,
		handleSubmitJsonEdit,
		isActionIncorrect,
		handleIsNotCorrectAction,
		handleNotSure,
		onBack,
	};
};
