import { css } from '@emotion/react';
import { StackOwnProps, useTheme } from '@mui/material';
import { useErrorEnabledTextFieldStyle } from '@src/common/styles/useErrorEnabledTextFieldStyle';
import { useMemo } from 'react';

const useStyles = () => {
	const { FormHelperTextPropsSX, errorEnabledField } =
		useErrorEnabledTextFieldStyle();

	const theme = useTheme();

	const componentsCss = useMemo(() => {
		const container = css({
			padding: 10,
			width: 800,
		});

		const centeredContainer = css({
			display: 'flex',
			justifyContent: 'space-between',
			gap: 40,
		});

		const title = css({
			marginBottom: 40,
		});

		const dropdownButton = css({
			padding: 0,
			'&:hover': {
				backgroundColor: 'initial',
			},
		});

		const status = css({
			display: 'flex',
			justifyContent: 'flex-end',
			marginBottom: 10,
		});

		const checkBoxTitle = css`
			width: fit-content;
			&.MuiFormControlLabel-root {
				margin: 0;
				.MuiFormControlLabel-label {
					font-size: 14px;
				}
			}
		`;

		const form = css`
			display: flex;
			flex-flow: column;
			margin: auto;
			min-width: 500px;
			gap: ${theme.spacing(3)};
		`;

		return {
			checkBoxTitle,
			centeredContainer,
			container,
			title,
			status,
			dropdownButton,
			form,
		};
	}, [theme]);

	const constants = useMemo(() => {
		return {
			formSpacing: 4,
			formActionsDirection: 'row-reverse' as StackOwnProps['direction'],
			formActionsSpacing: 2,
		};
	}, []);

	return {
		FormHelperTextPropsSX,
		errorEnabledField,
		...componentsCss,
		...constants,
	};
};

export const useStoryFormDialogStyles = () => {
	return useMemo(() => {
		const dialogContent = css({
			display: 'flex',
		});

		return {
			dialogContent,
		};
	}, []);
};

export default useStyles;
