//add types
export const settingsApplicationMockHandlers = [
	/*http.get(
		`${APIBaseURL}/application/:id/users`,
		async ({ request, params, cookies }) => {
			const currentAppUsersData = await getCurrentAppUsersData(request);

			return HttpResponse.json(currentAppUsersData);
		}
	),

	http.get(
		`${APIBaseURL}/user/applications`,
		async ({ request, params, cookies }) => {
			return HttpResponse.json(applications);
		}
	),

	http.post<{}, { role: ApplicationUserRoles; email: string }>(
		`${APIBaseURL}/settings/app-users`,
		async ({ request, params, cookies }) => {
			const body = await request.json();

			const newUser = generateAppUser(body);

			await delay(2000);

			const currentAppUsersData = await getCurrentAppUsersData(request);

			currentAppUsersData.push(newUser);

			return HttpResponse.json(currentAppUsersData);
		}
	),

	http.patch<{ id: string }, { role: ApplicationUserRoles }>(
		`${APIBaseURL}/settings/app-users/:id`,
		async ({ request, params, cookies }) => {
			const { role } = await request.json();

			await delay(2000);

			const currentAppUsersData = await getCurrentAppUsersData(request);

			const userToUpdateRole = currentAppUsersData.find(
				({ id }) => id === params.id
			);

			if (!userToUpdateRole) {
				HttpResponse.json({ error: 'No user found' }, { status: 404 });
			}

			userToUpdateRole.role = role;

			return HttpResponse.json(currentAppUsersData);
		}
	),

	http.delete<{ id: string }>(
		`${APIBaseURL}/settings/app-users/:id`,
		async ({ request, params, cookies }) => {
			const currentAppUsersData = await getCurrentAppUsersData(request);

			const userToDeleteIndex = currentAppUsersData.findIndex(
				({ id }) => id === params.id
			);

			await delay(2000);

			if (userToDeleteIndex === -1) {
				HttpResponse.json({ error: 'No user found' }, { status: 404 });
			}

			currentAppUsersData.splice(userToDeleteIndex, 1);

			return HttpResponse.json(currentAppUsersData);
		}
	),*/
];
