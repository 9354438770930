import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useSessionReplayerStyles = () => {
	return useMemo(() => {
		const loadingContainer = css({
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			height: '100%',
		});

		return {
			loadingContainer,
		};
	}, []);
};
