import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useTagsAndFavoriteStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const row = css({
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		});

		return {
			row,
		};
	}, [theme]);
};
