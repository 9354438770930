import { useMemo } from 'react';

export const useIsDefaultEnvironmentCheckbox = ({
	activeEnvironmentSettings,
	environments = [],
	isCreatingNewEnvSettings = false,
}) => {
	return useMemo(() => {
		const isEnvironmentDefault = !!activeEnvironmentSettings?.isDefault;
		const shouldDisplay = environments.length > 1 && !isCreatingNewEnvSettings;

		return {
			isEnvironmentDefault,
			shouldDisplay,
		};
	}, [activeEnvironmentSettings, environments, isCreatingNewEnvSettings]);
};
