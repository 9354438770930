import { List } from '@mui/material';
import { isNotEmptyArray } from '@src/common/helpers/arrays';
import { SessionRecording } from '@src/modules/session-recordings/types';
import { SessionRecordingsListItem } from '@src/modules/session-recordings/components/SessionRecordingsListItem/SessionRecordingsListItem';
import { useSessionRecordingsListStyles } from '@src/modules/session-recordings/components/SessionRecordingsList/styles';
import { SessionRecordingsListListSkeleton } from '@src/modules/session-recordings/components/SessionRecordingsList/SessionRecordingsListSkeleton';
import { Link } from 'react-router-dom';
import { sessionRecordingsRoutes } from '@src/modules/session-recordings/routes/routes';

export function SessionRecordingsList({
	sessionRecordings,
	activeSessionRecordingId,
}: {
	sessionRecordings: SessionRecording[];
	activeSessionRecordingId: string;
}) {
	const styles = useSessionRecordingsListStyles();
	return (
		<Choose>
			<When condition={isNotEmptyArray(sessionRecordings)}>
				<List css={styles.list}>
					{sessionRecordings.map((recording) => (
						<Link
							to={sessionRecordingsRoutes.sessionRecording.make({
								recordingId: recording.id,
							})}
							style={{ color: 'inherit' }}
							key={recording.id}
						>
							<SessionRecordingsListItem
								activeSessionRecordingId={activeSessionRecordingId}
								sessionRecording={recording}
							/>
						</Link>
					))}
				</List>
			</When>

			<Otherwise>
				<SessionRecordingsListListSkeleton />
			</Otherwise>
		</Choose>
	);
}
