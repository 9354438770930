/* eslint-disable no-extend-native */

// type ArrayPredicate<T> = (
// 	element: T,
// 	index: number,
// 	array: Array<T>
// ) => boolean;

type AsyncArrayPredicate<T> = (
	element: T,
	index: number,
	array: Array<T>
) => Promise<boolean>;

declare global {
	interface Array<T> {
		asyncFilter(predicate: AsyncArrayPredicate<T>): Promise<T[]>;
		asyncFind(predicate: AsyncArrayPredicate<T>): Promise<T>;
		last(): T;
	}
}

Array.prototype.asyncFilter = async function <T>(
	predicate: AsyncArrayPredicate<T>
): Promise<T[]> {
	const predicateResults = await Promise.all(this.map(predicate));
	return this.filter((item, index) => predicateResults[index]);
};

Array.prototype.asyncFind = async function <T>(
	predicate: AsyncArrayPredicate<T>
): Promise<T> {
	const predicateResults = await Promise.all(this.map(predicate));
	return this.find((item, index) => predicateResults[index]);
};

Array.prototype.last = function <T>(): T {
	return this[this.length - 1];
};

export {};
