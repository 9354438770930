import { TypographyOptions } from '@mui/material/styles/createTypography';
import { CSSProperties } from 'react';

const typographyVariants = {
	h1: {
		fontSize: '2.5rem',
		fontWeight: 600,
		lineHeight: 1.2,
	},
	h2: {
		fontSize: '2rem',
		fontWeight: 600,
		lineHeight: 1.2,
	},
	h3: {
		fontSize: '1.75rem',
		fontWeight: 600,
		lineHeight: 1.2,
	},
	h4: {
		fontSize: '1.5rem',
		fontWeight: 600,
		lineHeight: 1.2,
	},
	h5: {
		fontSize: '1.25rem',
		fontWeight: 600,
		lineHeight: 1.2,
	},
	h6: {
		fontSize: '1rem',
		fontWeight: 600,
		lineHeight: 1.2,
	},
};

type TypographyVariantsType = keyof typeof typographyVariants;

const Typography: TypographyOptions = {
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', // Bootstrap 5 system fonts

	...typographyVariants,
};

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides
		extends Record<TypographyVariantsType, true> {
		// here we can remove material-ui variants
		// body1: false;
		// body2: false;
		// button: false;
		// caption: false;
	}
}

declare module '@mui/material/styles' {
	interface TypographyVariants
		extends Record<TypographyVariantsType, CSSProperties> {}

	interface TypographyVariantsOptions
		extends Partial<Record<TypographyVariantsType, CSSProperties>> {}
}

export default Typography;
