import {
	CONFIRMATION_ALERTS_CONTENTS,
	OpenedConfirmationModalVariant,
} from '@src/modules/user-stories/components/StoryForm/constants';
import { RecursiveVoidCallbacks } from '@src/common/types/react';

type BaseProps = {
	onSuccess: () => void;
	onCancel?: () => void;
};

type Callbacks = BaseProps & RecursiveVoidCallbacks<BaseProps>;

export const generateConfirmationDialogContent = (
	variant: OpenedConfirmationModalVariant,
	callbacks: Callbacks
) => {
	if (variant === OpenedConfirmationModalVariant.DEFAULT) {
		return null;
	}

	const { title, buttons, description } = CONFIRMATION_ALERTS_CONTENTS[variant];

	const callbacksAsArray = Object.entries(callbacks);

	if (callbacksAsArray.length !== buttons.length) {
		throw new Error('Invalid callbacks amount');
	}

	const buttonsContent = callbacksAsArray.map(([key, value], index) => {
		return {
			callback: value,
			...buttons.at(index),
		};
	});

	return {
		title,
		description,
		buttons: buttonsContent,
		onClose: callbacks.onSuccess,
	};
};
