import { toast } from 'react-toastify';

export const TraceDownloadSuccessToastId = 'trace-download-success';

export function TraceDownloadSuccessToastContent() {
	return (
		<>
			<div>Trace file is downloading.</div>
			<div>
				Open{' '}
				<a
					href="https://trace.playwright.dev"
					target="_blank"
					rel="noreferrer"
					onClick={() => toast.dismiss(TraceDownloadSuccessToastId)}
				>
					https://trace.playwright.dev
				</a>{' '}
				and load the file to view the trace.
			</div>
		</>
	);
}
