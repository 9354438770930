import { TextField, TextFieldProps } from '@mui/material';
import { TextFieldLabelWithInfo } from './TextFieldLabelWithInfo';
import { FieldErrors, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { useErrorEnabledTextFieldStyle } from '../styles/useErrorEnabledTextFieldStyle';
import { SerializedStyles } from '@emotion/react';

export type AppTextFieldProps = TextFieldProps & {
	styles?: SerializedStyles;
	required?: boolean;
	info?: string;
	/* eslint-disable-next-line  @typescript-eslint/no-explicit-any  */
	register?: UseFormRegister<any>;
	registerOptions?: RegisterOptions;
	errors?: FieldErrors;
	name: string;
	hasErrorMessage?: boolean;
};

export const FormTextField = (props: AppTextFieldProps) => {
	const {
		styles,
		errors,
		register,
		registerOptions,
		required,
		info,
		label,
		name,
		hasErrorMessage,
		children,
		...rest
	} = props;
	const { FormHelperTextPropsSX, InputPropsSX, errorEnabledField } =
		useErrorEnabledTextFieldStyle(!!hasErrorMessage);

	return (
		<TextField
			{...rest}
			css={[errorEnabledField, styles]}
			FormHelperTextProps={{
				...rest.FormHelperTextProps,
				sx: {
					...FormHelperTextPropsSX,
					...rest.FormHelperTextProps?.sx,
				},
			}}
			InputLabelProps={{
				shrink: true,
				...rest.InputLabelProps,
			}}
			InputProps={{
				...rest.InputProps,
				sx: { ...InputPropsSX, ...rest.InputProps?.sx },
			}}
			label={
				<If condition={!!label}>
					<TextFieldLabelWithInfo
						title={label}
						info={info}
						required={!!required}
					/>
				</If>
			}
			error={!!errors?.[name]}
			{...register?.(name, registerOptions)}
			helperText={
				hasErrorMessage ? (errors?.[name]?.message as string) : props.helperText
			}
		>
			{children}
		</TextField>
	);
};
