import {
	Box,
	Input,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { useQueuedTask, useTestGenerationLabelingQueue } from './hooks';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { LoadingButton } from '@mui/lab';
import { TestGenerationLabelingTask } from '../../types';
import { testGenerationLabelingRoutes } from '../../service';
import { Link } from 'react-router-dom';
import { DeleteOutline } from '@mui/icons-material';

export function TestGenerationLabelingQueue() {
	const {
		isQueueLoading,
		queue,
		onNewURLChange,
		addToQueue,
		isAdding,
		deleteFromQueue,
		isDeleting,
	} = useTestGenerationLabelingQueue();
	return (
		<Box p={4}>
			<Choose>
				<When condition={isQueueLoading}>
					<LoadingCircularProgress>Loading...</LoadingCircularProgress>
				</When>
				<Otherwise>
					<Typography variant="h1">Test Generation Labeling Queue</Typography>
					<Link to={testGenerationLabelingRoutes.labeler.make()}>
						Go to Labeler
					</Link>
					<Box pt={4}>
						<Input
							placeholder="Enter log file gs URI"
							onChange={onNewURLChange}
						/>
						<LoadingButton
							variant="contained"
							sx={{ marginLeft: 2 }}
							loading={isAdding}
							onClick={addToQueue}
						>
							Add to queue
						</LoadingButton>
					</Box>
					<Box pt={4}>
						<List>
							{queue?.map((task, index) => (
								<QueuedTask
									task={task}
									key={index}
									deleteFromQueue={deleteFromQueue}
									isDeleting={isDeleting}
								/>
							))}
						</List>
					</Box>
				</Otherwise>
			</Choose>
		</Box>
	);
}

function QueuedTask({
	task,
	deleteFromQueue,
	isDeleting,
}: {
	task: TestGenerationLabelingTask;
	deleteFromQueue: (id: string) => void;
	isDeleting: boolean;
}) {
	const { handleDeleteFromQueue, testGenerationLink } = useQueuedTask({
		deleteFromQueue,
		task,
	});

	return (
		<ListItem
			disablePadding
			divider
			secondaryAction={
				<LoadingButton loading={isDeleting} onClick={handleDeleteFromQueue}>
					<DeleteOutline />
				</LoadingButton>
			}
		>
			<Link to={testGenerationLink}>
				<ListItemText
					primary={task.url}
					primaryTypographyProps={{
						variant: 'h6',
						component: 'div',
					}}
					secondary={
						<Box>
							<Typography component="div">{task.status}</Typography>
							<If condition={task.hasOwnProperty('stepsCount')}>
								<Typography variant="caption" component="div">
									steps count: {task.stepsCount}
								</Typography>
								<Typography variant="caption" component="div">
									number of labeled steps: {task.labeledStepsCount}
								</Typography>
							</If>
						</Box>
					}
					secondaryTypographyProps={{ component: 'div' }}
				/>
			</Link>
		</ListItem>
	);
}
