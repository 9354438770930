import { useGetSettingsStatusQuery } from '@src/modules/settings/api';
import {
	useActiveApplication,
	useIsAuthenticated,
	useIsLoggingIn,
} from '@src/common/state/volatile/hooks';
import { useApplicationSettingsQueryState } from '@src/modules/application/service';

//const pollingInterval = process.env.REACT_APP_ENV === 'local' ? 30_000 : 5000;

export const useGetSettingsStatus = () => {
	const isAuthenticated = useIsAuthenticated();
	const activeApplication = useActiveApplication();
	const isLoggingIn = useIsLoggingIn();

	const { isLoading: isSettingStatus } = useGetSettingsStatusQuery(null, {
		pollingInterval: 5000,
		skip: !isAuthenticated || !activeApplication || isLoggingIn,
	});

	const { data: applicationSettings, isLoading: isApplicationSettingsLoading } =
		useApplicationSettingsQueryState();

	return {
		settingsStatus: applicationSettings?.settingsStatus,
		isLoading: isApplicationSettingsLoading || isSettingStatus,
	};
};
