import {
	AppTextFieldProps,
	FormTextField,
} from '@src/common/components/FormTextField';
import { useStepsTextAreaFormField } from '@src/modules/user-stories/components/StoryForm/components/StepsTextAreaFormField/hooks';

export const StepsTextAreaFormField = ({ ...props }: AppTextFieldProps) => {
	const { minRows, maxRows } = useStepsTextAreaFormField();

	return <FormTextField minRows={minRows} maxRows={maxRows} {...props} />;
};
