import { useSelector } from 'react-redux';
import {
	selectAppId,
	selectAuthCode,
	selectDoNotShowModalToOpenPrReviewInSeparateWindow,
} from './selectors';

export const useAuthCode = () => useSelector(selectAuthCode);
export const usePersistentAppId = () => useSelector(selectAppId);
export const useIsUserLoggedIn = () => !!useSelector(selectAuthCode);
export const useDoNotShowModalToOpenPrReviewInSeparateWindow = () =>
	!!useSelector(selectDoNotShowModalToOpenPrReviewInSeparateWindow);
