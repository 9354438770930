import {
	STORY_FORM_STEPS_TEXTAREA_DEFAULT_MAX_ROWS,
	STORY_FORM_STEPS_TEXTAREA_MIN_ROWS,
} from '@src/modules/user-stories/components/StoryForm/components/StepsTextAreaFormField/constants';

export const getTextAreaRowsByWindowHeight = (windowHeight?: number) => {
	if (!windowHeight) {
		return {
			minRows: STORY_FORM_STEPS_TEXTAREA_MIN_ROWS,
			maxRows: STORY_FORM_STEPS_TEXTAREA_DEFAULT_MAX_ROWS,
		};
	}

	let inputHeightCoefficient = 0;
	let isMaxRowsSameAsMinRows = false;

	//hardcoded breakpoints manually tested
	if (windowHeight < 800) {
		inputHeightCoefficient = 1;
	} else if (windowHeight < 1000) {
		inputHeightCoefficient = 1.4;
	} else if (windowHeight < 1200) {
		inputHeightCoefficient = 2.4;
	} else if (windowHeight < 1500) {
		inputHeightCoefficient = 3.4;
		isMaxRowsSameAsMinRows = true;
	} else {
		inputHeightCoefficient = 4;
		isMaxRowsSameAsMinRows = true;
	}

	const resultMinRows =
		STORY_FORM_STEPS_TEXTAREA_MIN_ROWS * inputHeightCoefficient;

	//set max rows to 20 if the min rows is less than 20, otherwise maxRows===minRows
	return {
		minRows: resultMinRows,
		maxRows: isMaxRowsSameAsMinRows
			? resultMinRows
			: STORY_FORM_STEPS_TEXTAREA_DEFAULT_MAX_ROWS,
	};
};
