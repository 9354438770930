import { useTheme } from '@mui/material';
import { useWindowDimensions } from '@src/common/hooks/useWindowDimensions';
import { useMemo } from 'react';

export const useIsMobile = () => {
	const theme = useTheme();
	const { width } = useWindowDimensions();

	return useMemo(
		() => (width ?? 0) < theme.breakpoints.values.md,
		[theme, width]
	);
};
