//If editing anything here make sure use updated the json editor schema in
//src/modules/test-generation-labeling/components/LabelingFeedback/constants.ts
export type APINextActionResponse = {
	thought: string;
	action?: APINextAction;
	actionHiddenOverride?: APINextAction & { selector?: string };
	reason?: string;
	insight?: string;
	plan?: string;
	mistake?: string;
	reference?: number;
	usedInsight?: string;
};

export type APINextAction = {
	type: APINextActionType;
	checksumid?: string;
	value?: string;
	failed?: boolean;
	position?: { x: number; y: number };
};

export type APINextActionType =
	| 'click'
	| 'fill'
	| 'hover'
	| 'reset'
	| 'keystroke'
	| 'navigate'
	| 'save'
	| 'fail'
	| 'selectoption'
	| 'finish'
	| 'dismiss_modal';

export type APIActionHistory = {
	thought: string;
	action: {
		type: string;
		value: string;
	};
};

//If editing anything here make sure use updated the json editor schema in
//src/modules/test-generation-labeling/components/LabelingFeedback/constants.ts
export type AutogptLLMResponse = {
	thought: string;
	thoughts: {
		// describe how the current html is different then the previous one. If html #1, describe the html
		html_changes: string;
		// Was the last step you took not helpful in reaching the test goal? Start with Yes / No + details
		previous_step_mistake: string;
		// Thoughts
		text: string;
		reasoning: string;
		// Short markdown-style bullet list that conveys the long-term plan
		plan: string;
		// Constructive self-criticism
		criticism: string;
		// Summary of thoughts to say to the user
		speak: string;
	};
	command: {
		name: 'action' | 'save' | 'dismiss_modal' | 'finish';
		args: {
			type?: APINextActionType;
			fill_value?: string;
			checksumid?: string;
			description?: string;
			key?: string;
			value?: string;
			full_test_plan?: string;
		};
	};
};

export type APITestGenerationLabelingTask = {
	id: string;
	url: string;
	testUuid: string;
	appName: string;
	runId: string;
	testId: string;
	timestamp: number;
	completedSuccessfully: boolean;
	expectedGoals: string[];
	hitGoals: string[];
	goalsScore: number;

	status: 'pending' | 'in_progress' | 'completed';
	currentUnlabelledStepIndex: number;
	stepsCount: number;
	labeledStepsCount: number;
};

export type APITestGenerationLog = {
	testGenerationLabelingTask: APITestGenerationLabelingTask;
	testGenerationStepLabelsFromJSON: APITestGenerationLogStep[];
};

export type APITestGenerationLogStep = {
	index: number;
	goal: string;
	steps: string;
	promptTemplate: string;
	systemMessageTemplate: string;
	appName: string;
	runId: string;
	testId: string;
	testStartTimestamp: number;
	rawReducedHTMLCapturedAt: number;
	messageHistory: unknown[];
	flashingHTML: unknown[];
	actionHistory: APIActionHistory[];
	rawReducedHTML: string;
	reducedHTML: string;
	screenshot: { base64Image: string };
	checksumIdTaggedScreenshot: { base64Image: string };
	systemMessage: string;
	rawLLMResponse: string;
	rawLLMResponseJSON: AutogptLLMResponse;
	sanitizedLLMResponse: APINextActionResponse;
	labeledAt?: number;
	status?: 'unlabeled' | 'in_progress' | 'labeled';

	favorite?: boolean;
	tags?: string[];
};

export enum LabelingResult {
	Correct = 'correct',
	Incorrect = 'incorrect',
	NotSure = 'not-sure',
}

export type LabelingResponseKey = 'sanitizedLLMResponse' | 'rawLLMResponseJSON';
