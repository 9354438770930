import { useGetTestRunsQuery } from '@src/modules/test-runs/api';
import { useFilterTestRuns } from '@src/modules/test-runs/pages/test-runs/hooks/useFilterTestRuns';
import { useGetSettingsStatus } from '@src/modules/settings/service';

export const useTestRuns = () => {
	const {
		data: testRuns,
		isSuccess: isTestRunsLoadingSuccess,
		isLoading: isTestRunsLoading,
	} = useGetTestRunsQuery();
	const { settingsStatus, isLoading: isSettingsStatusLoading } =
		useGetSettingsStatus();

	const { filterAutocompleteInputsData, filteredTestRuns } = useFilterTestRuns({
		testRuns,
	});

	return {
		testRuns: filteredTestRuns,
		isLoading: isTestRunsLoading && isSettingsStatusLoading,
		filterAutocompleteInputsData,
		isTestRunsLoadingSuccess,
		setupIncomplete: !(
			settingsStatus?.hasValidEnvironmentSettings &&
			settingsStatus?.isGitIntegrated
		),
	};
};
