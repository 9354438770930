import { Dispatch, SetStateAction } from 'react';
import {
	Box,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	Autocomplete,
	Switch,
} from '@mui/material';
import useStyles from './styles';
import { useStoryForm } from './hooks';
import { UserStory } from '../../types';
import { StoryStatusBar } from '../StoryStatusBar/StoryStatusBar';
import { ActionsBar } from '../StoryFormActionsBar/StoryFormActionsBar';
import { ConfirmationDialog } from '@src/common/components/ConfirmationDialog/ConfirmationDialog';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FormTextField } from '@src/common/components/FormTextField';
import { Controller } from 'react-hook-form';
import {
	CollectionsListDropdown,
	UserStoriesCollection,
} from '@src/modules/collections/service';
import { StepsTextAreaFormField } from '@src/modules/user-stories/components/StoryForm/components/StepsTextAreaFormField/StepsTextAreaFormField';
import { StoryEnvironmentUserSelection } from '@src/modules/user-stories/components/StoryForm/components/StoryEnvironmentUserSelection/StoryEnvironmentUserSelection';
import { FilesViewAndUpload } from './components/FilesViewAndUpload/FilesViewAndUpload';

const IS_PASSWORD_INPUT_CONCEALED = false;

export type StoryFormProps = {
	story: UserStory;
	onCreateSuccess?: (story: UserStory) => void;
	setCloseStoryModal?: Dispatch<SetStateAction<() => void>>;
	handleCloseStory: () => void;
	onAddNewCollection: () => void;
	collections: UserStoriesCollection[];
};

export function StoryForm(props: StoryFormProps) {
	const styles = useStyles();

	const {
		register,
		onSubmit,
		errors,
		toggleShowUserFields,
		toggleShowPassword,
		showPassword,
		showUserFields,
		isGenerationRunning,
		isNewStoryCreation,
		isSubmitLoading,
		isDirty,
		isTriggeringGeneration,
		isAborting,
		handleAbortStoryTestGeneration,
		handleDeleteStory,
		confirmationAlertDialogContent,
		handleGenerateTest,
		pageStartURLs,
		isSettingsStatusLoading,
		handleOpenAddNewPageFormModal,
		control,
		isFormFieldsDisabled,
		formRef,
		isStoryFormValid,
		collection,
		settingsStatus,
		environmentsSettings,
		shouldDisplayEnvironments,
		userStoriesFilesWatcher,
		triggerFieldValidation,
		submitCount,
	} = useStoryForm(props);

	return (
		<Box css={styles.container} ref={formRef}>
			<If condition={isNewStoryCreation}>
				<Box css={styles.title}>
					<Typography variant="h4">Generate a new test flow</Typography>
				</Box>
			</If>

			<If condition={!isNewStoryCreation}>
				<Box css={styles.status}>
					<StoryStatusBar
						settingsStatus={settingsStatus}
						story={props.story}
						onlyActiveAction={false}
					/>
				</Box>
			</If>

			<form
				className="story-form"
				css={styles.form}
				onSubmit={onSubmit}
				noValidate
			>
				<FormTextField
					label="Goal"
					placeholder='Define what the test should achieve, for example: "Add a new task to the list".'
					register={register}
					errors={errors}
					name="title"
					multiline
					rows={2}
					autoFocus={isNewStoryCreation}
					disabled={isFormFieldsDisabled}
				/>

				<StepsTextAreaFormField
					label="Steps"
					placeholder={`Add the necessary steps, instructions and/or flow highlights to achieve the goal.\nFor example:\n - Click on New Task Button\n - Fill out the title\n - Click on due date and set for tomorrow\n - Fill all other required fields and submit\n - Assert that task added to the task board`}
					register={register}
					errors={errors}
					name="steps"
					multiline
					fullWidth
					disabled={isFormFieldsDisabled}
				/>

				<FilesViewAndUpload
					control={control}
					userStoriesFilesWatcher={userStoriesFilesWatcher}
					activeUserStoryId={props.story.id}
					triggerFieldValidation={triggerFieldValidation}
					errors={errors}
					register={register}
					submitCount={submitCount}
				/>

				<Controller
					name="collectionId"
					control={control}
					// destruct ref object from field not to pass it as param to avoid errors in console
					render={({ field: { onChange, value, ref, ...field } }) => (
						<CollectionsListDropdown
							onCreateNewCollection={handleOpenAddNewPageFormModal}
							collections={props.collections}
							value={value}
							register={register}
							name="collectionId"
							disabled={isFormFieldsDisabled}
							isFormField
							{...field}
						/>
					)}
				/>

				<If condition={shouldDisplayEnvironments}>
					<StoryEnvironmentUserSelection
						disabled={isFormFieldsDisabled}
						errors={errors}
						control={control}
						register={register}
						environmentsSettings={environmentsSettings}
					/>
				</If>

				<Controller
					name="startURL"
					control={control}
					render={({ field }) => (
						<Autocomplete
							value={field.value}
							freeSolo
							options={pageStartURLs}
							onChange={(e, newValue) => {
								field.onChange(newValue);
							}}
							disabled={isFormFieldsDisabled}
							renderInput={(params) => (
								<FormTextField
									{...params}
									register={register}
									name="startURL"
									label="Start URL"
									placeholder='The URL to navigate to before starting the test, for example: "http://todo.com/tasks/mL174D82X".'
									info="The URL or path to navigate to before starting the test. Relative value starting with '/' or full URL are allowed."
									errors={errors}
									required={!collection?.defaultStartURL}
									hasErrorMessage
								/>
							)}
						/>
					)}
				/>

				<If condition={false}>
					<FormControlLabel
						disabled={isFormFieldsDisabled}
						css={styles.checkBoxTitle}
						control={
							<Switch
								checked={showUserFields}
								onChange={toggleShowUserFields}
							/>
						}
						label="Override default testing user credentials"
					/>
				</If>

				<If condition={showUserFields}>
					<Box css={styles.centeredContainer}>
						<TextField
							css={styles.errorEnabledField}
							label="Username"
							placeholder="Username or email address"
							InputLabelProps={{ shrink: true }}
							error={!!errors?.username}
							{...register('username')}
							multiline
							fullWidth
							helperText={errors?.username?.message}
							FormHelperTextProps={{
								sx: styles.FormHelperTextPropsSX,
							}}
							disabled={isFormFieldsDisabled}
						/>

						<TextField
							css={styles.errorEnabledField}
							type={
								showPassword || IS_PASSWORD_INPUT_CONCEALED
									? 'text'
									: 'password'
							}
							label="Password"
							placeholder=""
							InputLabelProps={{ shrink: true }}
							fullWidth
							error={!!errors?.password}
							disabled={isFormFieldsDisabled}
							{...register('password')}
							helperText={errors?.password?.message}
							FormHelperTextProps={{
								sx: styles.FormHelperTextPropsSX,
							}}
							InputProps={{
								endAdornment: (
									<If condition={IS_PASSWORD_INPUT_CONCEALED}>
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={toggleShowPassword}
												edge="end"
											>
												<>
													<If condition={showPassword}>
														<VisibilityOff />
													</If>

													<If condition={!showPassword}>
														<Visibility />
													</If>
												</>
											</IconButton>
										</InputAdornment>
									</If>
								),
							}}
						/>
					</Box>
				</If>

				<ActionsBar
					onDelete={handleDeleteStory}
					story={props.story}
					styles={styles}
					isDirty={isDirty}
					isSubmitLoading={isSubmitLoading}
					isGenerationRunning={isGenerationRunning}
					isTriggeringGeneration={isTriggeringGeneration}
					onGenerate={handleGenerateTest}
					onAbort={handleAbortStoryTestGeneration}
					isAborting={isAborting}
					isSettingsStatusLoading={isSettingsStatusLoading}
					isFormFieldsDisabled={isFormFieldsDisabled}
					isStoryFormValid={isStoryFormValid}
				/>
			</form>

			<If condition={!!confirmationAlertDialogContent}>
				<ConfirmationDialog {...confirmationAlertDialogContent} />
			</If>
		</Box>
	);
}
