import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useTestGenerationLabelingHeaderStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			padding: `${theme.spacing(1)}`,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		});

		const column = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(1),
		});

		const row = css({
			display: 'flex',
			gap: theme.spacing(1),
		});

		const alignedRow = css(row, {
			alignItems: 'center',
		});

		const columnEnd = css(column, { justifyContent: 'end' });

		const button = css({ margin: 0, width: '100%' });

		return { container, column, columnEnd, row, button, alignedRow };
	}, [theme]);
};
