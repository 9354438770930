import { useEffect, useRef, useState } from 'react';
import { UserStory } from '@src/modules/user-stories/service';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/constants';
import { useInboxCollectionAndStories } from '@src/modules/collections/hooks/useInboxCollectionAndStories/useInboxCollectionAndStories';
import { useReadInboxUserStories } from './useReadInboxStories';
import { InboxCollectionAndStoriesBadgesData } from './types';
import {
	generateUserStoriesBadgesInfoAndInboxCollectionStatistics,
	getActualUserStories,
	makeDefaultBadgesState,
} from '@src/modules/collections/hooks/useInboxCollectionAndStories/helpers';

export const useInboxCollectionAndStoriesBadgesData = ({
	activeCollectionId,
}) => {
	const { inboxUserStories } = useInboxCollectionAndStories();

	// read inbox with interval 3 sec
	useReadInboxUserStories({
		inboxUserStories,
		activeCollectionId,
	});

	// prev user stories holder
	const actualUserStoriesRef = useRef<UserStory[]>([]);

	const [
		inboxCollectionAndStoriesBadgesData,
		setInboxCollectionAndStoriesBadgesData,
	] = useState<InboxCollectionAndStoriesBadgesData>(makeDefaultBadgesState());

	useEffect(() => {
		if (!inboxUserStories) {
			return;
		}

		if (!actualUserStoriesRef.current.length) {
			actualUserStoriesRef.current = inboxUserStories;
		}

		// once the active collection id Inbox do not update the last active field
		// for each user story
		const userStories = getActualUserStories(
			actualUserStoriesRef.current,
			inboxUserStories,
			activeCollectionId === INBOX_COLLECTION_ID
		);

		// save previous inbox user stories list
		actualUserStoriesRef.current = userStories;

		// generate statistics for inbox collection and inbox stories
		setInboxCollectionAndStoriesBadgesData(
			generateUserStoriesBadgesInfoAndInboxCollectionStatistics(userStories)
		);
	}, [inboxUserStories, activeCollectionId]);

	return inboxCollectionAndStoriesBadgesData;
};
