import axios from 'axios';

//range step
const RANGE = 400_000;

const MIN_TIME_DIFFERENCE = 60_000;

interface IEvent {
	timestamp: number;
	data: Record<string, unknown>;
	type: number;
}

type SetEventsFunctionType = (events: IEvent[]) => void;

export class RrwebJSONStreamParser {
	private buffer: string;
	private bytesRange: number;
	private lastEventTimestamp: number;
	private currentTimestamp: number;
	private intermediateEventsHolder: IEvent[];
	private recordingUrl: string;
	private isLastRequest: boolean;
	private isGeneratingEvents: boolean;
	private isClosed: boolean;
	private isInitialEventsSent: boolean;
	private setEvents: SetEventsFunctionType;

	constructor({
		recordingUrl,
		setEvents,
	}: {
		recordingUrl: string;
		setEvents: SetEventsFunctionType;
	}) {
		this.buffer = '';
		this.bytesRange = 0;
		this.lastEventTimestamp = 0;
		this.isLastRequest = false;
		this.isInitialEventsSent = false;
		this.isGeneratingEvents = false;
		this.isClosed = false;
		this.intermediateEventsHolder = [];
		this.currentTimestamp = 0;
		this.setEvents = setEvents;
		this.recordingUrl = recordingUrl;
	}

	//public function to update events state
	public async updateProgress(currentTimeStamp: number) {
		this.currentTimestamp = currentTimeStamp;

		if (
			this.lastEventTimestamp - currentTimeStamp > MIN_TIME_DIFFERENCE ||
			this.isGeneratingEvents
		) {
			return;
		}

		this.generateEvents();
	}

	private async generateEvents(): Promise<IEvent[]> {
		//if the session recording was changed during this function processing
		if (this.isClosed) {
			return;
		}

		const eventsBytes = await this.handleFetchEventsBytes();

		//all recursively parsed events
		this.intermediateEventsHolder = [
			...this.intermediateEventsHolder,
			...this.parse(eventsBytes),
		];

		this.isGeneratingEvents = true;

		if (this.intermediateEventsHolder.length) {
			this.lastEventTimestamp = this.intermediateEventsHolder.last().timestamp;
		}

		//generateEvents should return at least 2 events to successfully initiate
		//rrweb replayer
		const isGeneratingInitialEvents =
			this.intermediateEventsHolder.length < 2 && !this.isInitialEventsSent;

		//if there is no parsed events -> recursively generate more
		if (isGeneratingInitialEvents || !this.intermediateEventsHolder.length) {
			return await this.generateEvents();
		}

		//set events to the react component local state
		this.setEvents?.(this.intermediateEventsHolder);
		this.intermediateEventsHolder = [];

		if (!this.isInitialEventsSent) {
			this.isInitialEventsSent = true;
		}

		if (this.isLastRequest) {
			this.isGeneratingEvents = false;
			return;
		}

		//if the time difference of current event timestamp and the last event timestamp
		//is less than MIN_TIME_DIFFERENCE generate new events
		const isInsufficientBuffer =
			this.lastEventTimestamp - this.currentTimestamp < MIN_TIME_DIFFERENCE;

		if (isInsufficientBuffer) {
			return await this.generateEvents();
		}

		this.isGeneratingEvents = false;
	}

	//function to get the bytes string
	async fetchEventsBytes(): Promise<string> {
		const response = await axios(this.recordingUrl, {
			responseType: 'text',
			headers: {
				Range: `bytes=${this.getBytesRange()}`,
			},
		});

		return response.data;
	}

	//fetch out of range handler
	async handleFetchEventsBytes(): Promise<string> {
		try {
			return await this.fetchEventsBytes();
		} catch {
			this.isLastRequest = true;
			return await this.fetchEventsBytes();
		}
	}

	//function to parse the bytes string to the actual js object
	private parse(bytesString: string): IEvent[] {
		//array of all parsed objects
		const parsedItems: IEvent[] = [];

		const jsonString = this.formatFirstResponseData(bytesString);

		this.buffer += jsonString;

		//levels of the object boundaries "{" and "}"
		let objStart = -1;
		let isInString = false;
		let braceLevel = 0;

		for (let i = 0; i < this.buffer.length; i++) {
			const char = this.buffer[i];

			//detects if we are currently in a "" to prevent count the object layer {
			if (char === '"' && (i === 0 || this.buffer[i - 1] !== '\\')) {
				isInString = !isInString;
			}

			if (!isInString) {
				//stack functionality to detect when the object ends
				if (char === '{') {
					braceLevel++;

					if (braceLevel === 1) {
						objStart = i;
					}
				} else if (char === '}') {
					braceLevel--;

					//parse buffer when the end of the object is found
					if (braceLevel === 0 && objStart !== -1) {
						const objStr = this.buffer.substring(objStart, i + 1);

						try {
							parsedItems.push(JSON.parse(objStr));
						} catch (error) {
							console.error('Error parsing JSON:', error);
						}
						objStart = -1;
					}
				}
			}
		}

		//increases range if there are parsed objects and modifies bytes range
		//to start from the firs byte of unparsed object
		if (!!parsedItems.length && objStart !== -1) {
			let difference = this.getStringByteLength(
				this.buffer.substring(objStart)
			);
			this.increaseRange(difference);
			this.buffer = '';
		} else {
			this.increaseRange();
		}

		return parsedItems;
	}

	//returns the actual bytes range for the request
	//if last request returns only the start point of the range
	private getBytesRange(): string {
		if (this.isLastRequest) {
			return `${this.bytesRange}`;
		}

		return `${this.bytesRange + 1}-${this.bytesRange + RANGE}`;
	}

	//increases range by the bytes length of the rest partial unparsed object
	private increaseRange(difference = 0): void {
		this.bytesRange = this.bytesRange - difference + RANGE;
	}

	//get string converted in bytes length.
	private getStringByteLength(str: string): number {
		const encoder = new TextEncoder();
		const encoded = encoder.encode(str);
		return encoded.length;
	}

	//function to remove first "[" from buffer for the first events request.
	// To start parsing objects instead of array.
	// Start buffer example: {"18b447b5441ff-04945b560ec26-1a525634-13c680-18b447b54423e85": [{"type": 4,
	private formatFirstResponseData(bytesString: string): string {
		if (this.bytesRange === 0 && bytesString) {
			return bytesString.substring(bytesString.indexOf('[') + 1);
		}
		return bytesString;
	}

	//intercept currently working generateEvents function to avoid setting
	//previously uploaded events
	public close() {
		this.setEvents = null;
		this.isClosed = true;
		this.intermediateEventsHolder = [];
	}
}

export default RrwebJSONStreamParser;
