import { useCallback, useMemo } from 'react';
import { UserStory, UserStoryStatus } from '@src/modules/user-stories/types';
import { StoryItemIconByOrigin } from './constants';
import {
	INBOX_COLLECTION_ID,
	ALL_FLOWS_COLLECTION_ID,
} from '@src/modules/collections/service';
import { useLocation, useNavigate } from 'react-router-dom';
import { userStoriesExplorerRoutes } from '@src/modules/user-stories-exploration/service';
import {
	getChipColor,
	getOpenStoryFormCTAText,
	getStoryBadgeTextContent,
} from '@src/modules/user-stories/components/StoryListItem/helpers';

export const useStoryListItem = ({
	story,
	activeCollectionId,
	onXClick,
	handleOpenPrInNewWindowModal,
}: {
	story: UserStory;
	activeCollectionId: string;
	onXClick: (e, story: UserStory) => void;
	handleOpenPrInNewWindowModal: (e, story: UserStory) => void;
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { isInboxActive, shouldDisplayCollectionName } = useMemo(() => {
		return {
			isInboxActive: activeCollectionId === INBOX_COLLECTION_ID,
			shouldDisplayCollectionName:
				[ALL_FLOWS_COLLECTION_ID, INBOX_COLLECTION_ID].includes(
					activeCollectionId
				) && !!story.collectionName,
		};
	}, [activeCollectionId, story]);

	const { userStoryChipColor, Icon, badgeText } = useMemo(() => {
		return {
			badgeText: getStoryBadgeTextContent(story),
			userStoryChipColor: getChipColor(story),
			Icon: StoryItemIconByOrigin[story.origin],
		};
	}, [story]);

	const handleOpenStoryForm = useCallback(() => {
		navigate(
			userStoriesExplorerRoutes.flow.make(
				{
					collectionId: activeCollectionId,
					storyId: story.id,
				},
				location.search.toString()
			)
		);
	}, [navigate, location.search, activeCollectionId, story]);

	const handleClearFromInbox = useCallback(
		(e) => {
			onXClick(e, story);
		},
		[onXClick, story]
	);

	const { buttonContent, onButtonClick } = useStoryListItemButton({
		story,
		handleOpenPrInNewWindowModal,
		handleOpenStoryForm,
	});

	return {
		shouldDisplayCollectionName,
		isInboxActive,
		handleOpenStoryForm,
		userStoryChipColor,
		handleClearFromInbox,
		Icon,
		buttonContent,
		onButtonClick,
		badgeText,
	};
};

const useStoryListItemButton = ({
	story,
	handleOpenPrInNewWindowModal,
	handleOpenStoryForm,
}: {
	story: UserStory;
	handleOpenPrInNewWindowModal: (e, story: UserStory) => void;
	handleOpenStoryForm: () => void;
}) => {
	const onOpenPrInNewWindow = useCallback(
		(e) => {
			handleOpenPrInNewWindowModal(e, story);
		},
		[story, handleOpenPrInNewWindowModal]
	);

	return useMemo(() => {
		if (story.status === UserStoryStatus.Completed) {
			return {
				buttonContent: 'Review and Merge',
				onButtonClick: onOpenPrInNewWindow,
			};
		}

		return {
			buttonContent: getOpenStoryFormCTAText(story),
			onButtonClick: handleOpenStoryForm,
		};
	}, [story, onOpenPrInNewWindow, handleOpenStoryForm]);
};
