import Config from './config';

export default function postRender() {
	setupEnvConfiguration();
}

function setupEnvConfiguration() {
	// Dear appreciated customer, you can try settings these manually in the console and it might actually work.
	if (Config.env === 'local') {
		window._checksum_allow_incomplete_settings_generation = true;
		window._checksum_allow_tg_abort_when_verifying = true;
	}
}
