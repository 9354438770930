import {
	APICreateUserStoryArgs,
	APIUserStory,
} from '@src/modules/user-stories/api/types';
import { userStoriesMock } from '@src/modules/user-stories/api/mock/data';
import { ApplicationEnvironmentUser } from '@src/modules/settings/service';

export const transformCreateMockUserStoryArgsToAPI = ({
	startURL,
	...userStoryArgs
}: APICreateUserStoryArgs): APIUserStory => {
	return {
		...userStoryArgs,
		userStoryEngagements: [],
		userStoryFiles: [],
		environmentUser: {} as ApplicationEnvironmentUser,
		startURL: startURL ?? '',
		collection: undefined,
		id: userStoriesMock.last().id + 1,
		origin: 'user',
		payload: {},
		testGenerations: [],
		createdAt: new Date().toISOString(),
		updatedAt: new Date().toISOString(),
	};
};
