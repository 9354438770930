import ReactDOM from 'react-dom';
import App from './app/App';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import 'style';
import './common/helpers/prototype-extensions';
import './api/mock/mock';
import Config from '@src/config';
import { initializeServices } from './services';
import postRender from './post-render';

console.log('ChecksumAI', Config.env, Config.git.versionTag);
initializeServices();

(async () => {
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>,
		document.getElementById('root'),
		postRender
	);
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//https://www.robinwieruch.de/react-folder-structure/
