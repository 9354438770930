import { Box, Button, Typography } from '@mui/material';
import { useTestGenerationLabelingStyles } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/styles';
import { useTestGenerationLabeling } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/useTestGenerationLabeling';
import { TestGenerationLabelingHeader } from '@src/modules/test-generation-labeling/components/TestGenerationLabelingHeader/TestGenerationLabelingHeader';
import { LabelingFeedback } from '@src/modules/test-generation-labeling/components/LabelingFeedback/LabelingFeedback';
import Grid from '@mui/material/Unstable_Grid2';
import { ActionDescription } from '@src/modules/test-generation-labeling/components/ActionDescription/ActionDescription';
import { ScreenshotsSelect } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/components/ScreenshotsSelect/ScreenshotsSelect';
import { isNotEmptyArray } from '@src/common/helpers/arrays';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { TagTestGenerationStep } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/components/TagTestGenerationStep/TagTestGenerationStep';

export const TestGenerationLabeling = () => {
	const {
		activeTestGenerationLogStep,
		activeStoredStep,
		screenshot,
		onSelectScreenshot,
		activeScreenshotOption,
		nextHref,
		prevHref,
		onChange,
		onReset,
		LLMResponse,
		feedbackBottomRef,
		scrollToJsonEditorBottom,
		handleUpdateTestGenerationLogStep,
		handleExpandScreenshot,
		editorKey,
		entries,
		isSubmitting,
	} = useTestGenerationLabeling();

	const styles = useTestGenerationLabelingStyles();

	return (
		<Choose>
			<When condition={!activeTestGenerationLogStep}>
				<Box css={styles.centeredContainer}>
					<LoadingCircularProgress>
						Loading labeling data
					</LoadingCircularProgress>
				</Box>
			</When>

			<When condition={isNotEmptyArray(entries)}>
				<Box component="section" css={styles.section}>
					<Box css={styles.container}>
						<TestGenerationLabelingHeader
							testGenerationLogStep={activeTestGenerationLogStep}
							activeStoredStep={activeStoredStep}
							nextHref={nextHref}
							prevHref={prevHref}
						/>

						<Grid container spacing={2} css={styles.grid}>
							<Grid xs={4}>
								<TagTestGenerationStep activeStoredStep={activeStoredStep} />
							</Grid>

							<Grid xs={8}>
								<Box css={styles.imageHeader}>
									<ScreenshotsSelect
										onSelectScreenshot={onSelectScreenshot}
										activeScreenshotOption={activeScreenshotOption}
									/>

									<Button onClick={handleExpandScreenshot}>
										Expand Screenshot
									</Button>
								</Box>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid xs={4}>
								<If condition={!!activeTestGenerationLogStep}>
									<ActionDescription
										editorKey={editorKey}
										activeTestGenerationLogStep={activeTestGenerationLogStep}
										activeStoredStep={activeStoredStep}
									/>
								</If>
							</Grid>

							<Grid xs={8}>
								<Box css={styles.imageContainer}>
									<img src={screenshot} css={styles.image} alt="screenshot" />
								</Box>
							</Grid>
						</Grid>

						<LabelingFeedback
							isSubmitting={isSubmitting}
							editorKey={editorKey}
							onOpenEditJson={scrollToJsonEditorBottom}
							onReset={onReset}
							onJsonChange={onChange}
							jsonValue={LLMResponse}
							onSubmit={handleUpdateTestGenerationLogStep}
						/>

						<div ref={feedbackBottomRef} />
					</Box>
				</Box>
			</When>

			<Otherwise>
				<Box css={styles.centeredContainer}>
					<Typography variant="h4">No data for labeling</Typography>
				</Box>
			</Otherwise>
		</Choose>
	);
};
