// import { darken, lighten } from '@mui/material';
// import colors from './colors';
import { Palette } from '@mui/material/styles/createPalette';

const palette: Partial<Palette> = {
	// primary: {
	// 	main: colors.lightTheme.primary,
	// 	dark: darken(colors.lightTheme.primary, 0.1),
	// 	light: lighten(colors.lightTheme.primary, 0.1),
	// 	contrastText: colors.lightTheme.contrast,
	// },
};

export default palette;
