import { ModuleRedirects } from '@src/routing/types';
import { prepareModuleRoutes } from '@src/routing';
import { DefaultAppBarMode } from '@src/layout/DefaultAppBar/types';

type RouteNames =
	| 'authenticate'
	| 'userStories'
	| 'userStoriesExplorer'
	| 'testRuns'
	| 'settings'
	| 'sessionRecordings'
	| 'internal'
	| 'traceViewer';

export const ApplicationRoutes = prepareModuleRoutes<RouteNames>(
	{
		authenticate: {
			path: '/authenticate',
			isRouter: true,
			layoutOptions: {
				hideSettingsNotCompletedBanner: true,
				appBarOptions: { hideDefaultMenu: true },
				hideSideNav: true,
			},
		},
		userStories: {
			path: '/flows',
			isRouter: true,
		},
		userStoriesExplorer: {
			path: '/collections',
			isRouter: true,
		},
		testRuns: {
			path: '/test-runs',
			isRouter: true,
		},
		settings: {
			path: '/settings',
			isRouter: true,
			layoutOptions: {
				hideSettingsNotCompletedBanner: true,
			},
		},
		sessionRecordings: {
			path: '/session-recordings',
			isRouter: true,
		},
		traceViewer: {
			path: '/trace-viewer',
			layoutOptions: {
				hideSettingsNotCompletedBanner: true,
				appBarOptions: {
					hideDefaultMenu: true,
					mode: DefaultAppBarMode.TraceViewer,
				},
				hideSideNav: true,
				containerDisableGutters: true,
				containerMaxWidth: false,
			},
		},
		internal: {
			path: '/internal',
			isRouter: true,
		},
	},
	''
);

type InternalRouteNames = 'testGenerationLabeling';

export const InternalApplicationRoutes =
	prepareModuleRoutes<InternalRouteNames>(
		{
			testGenerationLabeling: {
				path: '/test-generation-labeling',
				isRouter: true,
			},
		},
		ApplicationRoutes.internal.absolutePath
	);

export const ApplicationRedirects: ModuleRedirects = {};
