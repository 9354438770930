import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function Toast() {
	return (
		<ToastContainer
			position="top-center"
			autoClose={7_000}
			hideProgressBar
			limit={1}
			transition={Slide}
		/>
	);
}

export default Toast;
