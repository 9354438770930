export enum LogComponents {
	App = 'App',
	Routing = 'Routing',
	Analytics = 'Analytics',
	CRM = 'CRM',
}

export enum LogVerbosity {
	Trace,
	Debug,
	Info,
	Warn,
	Error,
	Critical,
	Off,
}

export type LoggerOptions = {
	subComponent?: string;
	remoteVerbosity?: LogVerbosity;
	localVerbosity?: LogVerbosity;
	mute?: boolean;
};

export interface LoggerSessionData {
	appName: string;
}

export type LoggerParams = {
	err?: Error;
	functionName?: string;
};
