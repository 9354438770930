import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useApplicationFormModalStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			padding: theme.spacing(5),
		});

		return {
			container,
		};
	}, [theme]);
};
