import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { SetupSdkCommandsCodeBlock } from '@src/modules/settings/service';
import { useSessionRecordingsEmptyStateStyles } from './styles';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';

export const SessionRecordingsEmptyState = ({
	sdkKey,
}: {
	sdkKey?: string;
}) => {
	const styles = useSessionRecordingsEmptyStateStyles();

	return (
		<Box css={styles.container}>
			<Box css={styles.loadingBlock}>
				<LoadingCircularProgress circularProgressSize={25}>
					<Typography variant="h2">Waiting for events...</Typography>
				</LoadingCircularProgress>
			</Box>

			<Box css={styles.codeBlockContainer}>
				<Typography component="p" gutterBottom>
					It may take a few minutes for events to appear.
				</Typography>

				<Typography component="p" gutterBottom>
					If you don't see any events, make sure you have the SDK installed
					correctly following the steps below.
				</Typography>

				<SetupSdkCommandsCodeBlock sdkKey={sdkKey} />
			</Box>

			<Typography variant="subtitle2" css={styles.subtitle}>
				Our team is happy to assist with the integration. For support or to
				schedule a meeting, reach out to{' '}
				<Link href="mailto:support@checksum.ai" css={styles.link}>
					support@checksum.ai
				</Link>
				.
			</Typography>
		</Box>
	);
};
