import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useTestRunsPageStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			width: '100%',
			padding: `${theme.spacing(4)} 0`,
		});

		const listContainer = css({
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		});

		const list = css({
			height: '100%',
			overflow: `auto`,
			paddingTop: '10px',
			paddingRight: '10px',
		});

		return {
			section,
			list,
			listContainer,
		};
	}, [theme]);
};
