import { List, ListItem, ListItemButton } from '@mui/material';
import { StoryListItemSkeleton } from '../StoryListItem/StoryListItemSkeleton';
import { SerializedStyles } from '@emotion/react';

import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export function StoriesListSkeleton({
	count = 3,
	listStyle,
}: {
	count?: number;
	listStyle: SerializedStyles[];
}) {
	const styles = useStoriesListSkeletonStyles();

	return (
		<List css={listStyle}>
			{Array.from({ length: count }).map((_, index) => (
				<ListItem key={index} css={styles.listItem} disablePadding>
					<ListItemButton css={styles.listItemButton}>
						<StoryListItemSkeleton />
					</ListItemButton>
				</ListItem>
			))}
		</List>
	);
}

export const useStoriesListSkeletonStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const listItem = css({
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(1),
		});

		const listItemButton = css({
			padding: theme.spacing(2),
		});

		return {
			listItem,
			listItemButton,
		};
	}, [theme]);
};
