import {
	Route,
	Navigate,
	useParams,
	generatePath,
	useSearchParams,
} from 'react-router-dom';
import { ModuleRedirects } from '../../types';

export const NavigateWithParams = ({ to, ...rest }) => {
	const params = useParams();
	const [queryParams] = useSearchParams();
	const qp = queryParams.toString();
	let generatedTo: string;
	try {
		generatedTo = generatePath(to, params);
	} catch (e) {
		return <Navigate to="/not-found" />;
	}
	return <Navigate to={`${generatedTo}${qp ? `?${qp}` : ''}`} {...rest} />;
};

export const AppRedirect = ({
	base,
	from,
	to,
	state,
}: {
	from: string;
	to: string;
	base?: string;
	state?: object;
}) => {
	// return <Redirect exact from={base ? base + from : from} to={to} />;
	return (
		<Route
			key={`${base ?? ''}_${from}_${to}`}
			path={base ? base + from : from}
			element={<NavigateWithParams replace to={to} state={state} />}
		/>
	);
};

export const AppRedirects = ({
	base,
	redirects,
}: {
	base?: string;
	redirects: ModuleRedirects;
}) => {
	return Object.values(redirects).map((redirect, index) =>
		AppRedirect({
			from: redirect.from,
			to: redirect.to,
			base,
			state: redirect.state,
		})
	);
};
