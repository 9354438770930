import AnalyticsProviderInterface from './AnalyticsProviderInterface';
import Config from '@src/config';

declare global {
	interface Window {
		checksumai: {
			init: (key: string, options?: unknown) => void;
		};
	}
}

class ChecksumAnalyticsProvider implements AnalyticsProviderInterface {
	private active = false;
	private providerName = 'checksum';

	public get name() {
		return this.providerName;
	}

	init() {
		if (!Config.analytics.checksum.key) {
			return;
		}
		const onload = () => {
			this.checksumai.init(Config.analytics.checksum.key, {
				networkRecordingMode: 'regular',
			});
			this.active = true;
		};
		const loadScript = () => {
			// Create an async script element based on your key.
			let script = document.createElement('script');
			script.type = 'text/javascript';
			// script.async = true;
			script.src = 'https://cdn.checksum.ai/checksum-sdk-min-0.2.js';
			script.onload = onload;
			// Insert before first script on page.
			let first = document.getElementsByTagName('script')[0];
			first.parentNode.insertBefore(script, first);
		};
		loadScript();
	}

	isActive() {
		return this.active;
	}

	identify(userId: string, properties?: unknown) {}

	alias(userId: string, formerId?: string) {}

	reset() {}

	track(event: string, payload?: object) {}

	trackPageView(page: string) {}

	private get checksumai() {
		return window.checksumai;
	}
}

export default ChecksumAnalyticsProvider;
