import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Notes, TextSnippet, Dvr, Folder } from '@mui/icons-material';
import { UserStory, UserStoryStatus } from '../../types';
import { GeneratingCircularProgress } from '../GeneratingCircularProgress';
import { useStoryStatusBar } from '../StoryStatusBar/hooks';
import { useStoryStatusBarStyles } from '@src/modules/user-stories/components/StoryStatusBar/styles';
import { TestGenerationStatus } from '@src/modules/test-generations/service';
import { GitIcon } from '@src/common/components/GitIcon/GitIcon';
import { SettingsStatus } from '@src/modules/settings/service';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ALLOW_TRACE = true;

export function StoryStatusBar({
	story,
	onlyActiveAction = true,
	settingsStatus,
}: {
	story: UserStory;
	onlyActiveAction?: boolean;
	settingsStatus: SettingsStatus;
}) {
	const {
		storyLastActivityNote,
		hasPR,
		openTrace,
		openPR,
		shouldDisplayAuthorizeButton,
		shouldDisplayTestGenerationFailedForInternalUser,
		shouldDisplayPRGenerateButton,
		isGeneratePRLoading,
		handleAuthorizePfFailedTestGenerations,
		handleAuthorizeTestGenerations,
		isAuthorizeTestGenerationLoading,
		shouldDisplayTrace,
		iterationLogURL,
		agentLogURL,
		testArtifactsURL,
		internalTestId,
	} = useStoryStatusBar(story);

	const styles = useStoryStatusBarStyles();

	return (
		<Stack css={styles.stack}>
			<Choose>
				<When
					condition={[
						UserStoryStatus.Queued,
						UserStoryStatus.Running,
						UserStoryStatus.Verifying,
					].includes(story.status)}
				>
					<GeneratingCircularProgress story={story} />
				</When>

				<Otherwise>
					<If condition={onlyActiveAction}>
						<Typography variant="body2" css={styles.storyTitle}>
							{storyLastActivityNote}
						</Typography>
					</If>
				</Otherwise>
			</Choose>

			<If condition={!!internalTestId}>
				<Typography variant="subtitle2" css={styles.authorizeButton}>
					{internalTestId}
				</Typography>
			</If>

			<If
				condition={
					[
						TestGenerationStatus.Completed,
						TestGenerationStatus.PRMerged,
						TestGenerationStatus.PRClosed,
					].includes(story.recentGeneration?.status) && hasPR
				}
			>
				<Tooltip title="View pull request">
					<IconButton size="medium" css={styles.iconButton} onClick={openPR}>
						<GitIcon settingsStatus={settingsStatus} />
					</IconButton>
				</Tooltip>
			</If>

			<If condition={ALLOW_TRACE && shouldDisplayTrace}>
				<Tooltip title="View trace">
					<IconButton size="medium" css={styles.iconButton} onClick={openTrace}>
						<Dvr />
					</IconButton>
				</Tooltip>
			</If>

			<If condition={shouldDisplayTestGenerationFailedForInternalUser}>
				<Tooltip title="Test flow is failed before authorization">
					<IconButton color="error" size="medium">
						<ErrorOutlineIcon />
					</IconButton>
				</Tooltip>
			</If>

			<If condition={shouldDisplayAuthorizeButton}>
				<LoadingButton
					size="small"
					variant="outlined"
					css={styles.authorizeButton}
					loading={isAuthorizeTestGenerationLoading}
					onClick={handleAuthorizeTestGenerations}
				>
					authorize
				</LoadingButton>
			</If>

			<If condition={shouldDisplayPRGenerateButton}>
				<LoadingButton
					size="small"
					variant="outlined"
					css={styles.authorizeButton}
					loading={isGeneratePRLoading}
					onClick={handleAuthorizePfFailedTestGenerations}
				>
					Generate PR
				</LoadingButton>
			</If>

			<If condition={!!testArtifactsURL}>
				<Tooltip title="Test generation artifact bucket">
					<IconButton
						size="medium"
						css={styles.iconButton}
						href={testArtifactsURL}
						target="_blank"
					>
						<Folder />
					</IconButton>
				</Tooltip>
			</If>

			<If condition={!!agentLogURL}>
				<Tooltip title="Test generation agent log">
					<IconButton
						size="medium"
						css={styles.iconButton}
						href={agentLogURL}
						target="_blank"
					>
						<TextSnippet />
					</IconButton>
				</Tooltip>
			</If>

			<If condition={!!iterationLogURL}>
				<Tooltip title="Test generation iteration log. If not found, increase one day of first date in URL.">
					<IconButton
						size="medium"
						css={styles.iconButton}
						href={iterationLogURL}
						target="_blank"
					>
						<Notes />
					</IconButton>
				</Tooltip>
			</If>
		</Stack>
	);
}
