// CustomTooltip.tsx
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';

// Define your custom props that extend the existing TooltipProps

const TooltipNonInteractive: React.FC<TooltipProps> = ({ children, ...props }) => {
	return (
		<Tooltip
			PopperProps={{
				modifiers: [
					{
						name: 'preventPointerEvents',
						enabled: true,
						phase: 'beforeMain',
						fn: ({ state }) => {
							state.elements.popper.style.pointerEvents = 'none';
						},
					},
				],
			}}
			leaveDelay={0}
			placement="top-start"
			{...props}
		>
			{children}
		</Tooltip>
	);
};

export default TooltipNonInteractive;
