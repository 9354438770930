import { IconButton, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useMenuIconButton } from '@src/modules/collections/components/CollectionListItem/hooks/useMenuIconButton';
import { StyledMenu } from '@src/common/components/StyledMenu/StyledMenu';

export const MenuIconButton = ({
	onEdit,
	onDelete,
}: {
	onEdit: () => void;
	onDelete: () => void;
}) => {
	const {
		open,
		anchorElement,
		handleClose,
		handleClick,
		handleEdit,
		handleDelete,
	} = useMenuIconButton({ onEdit, onDelete });

	return (
		<>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>

			<StyledMenu anchorEl={anchorElement} open={open} onClose={handleClose}>
				<MenuItem onClick={handleEdit} disableRipple>
					<EditIcon />
					Edit
				</MenuItem>

				<MenuItem onClick={handleDelete} disableRipple>
					<DeleteIcon />
					Delete
				</MenuItem>
			</StyledMenu>
		</>
	);
};
