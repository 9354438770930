import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useProjectSettingsStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css`
			display: flex;
			flex-direction: column;
			gap: ${theme.spacing(3)};
			margin-bottom: ${theme.spacing(3)};
		`;

		const section = css(container, {
			maxWidth: 1000,
		});

		const tableContainer = css({
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(0.5),
		});

		const checkboxLabel = css({
			width: 'fit-content',
		});

		return { container, section, tableContainer, checkboxLabel };
	}, [theme]);
};
