import { useCallback, useMemo, useRef } from 'react';
import { getFormValues } from './helpers/collectionFormHelpers';
import { useCollectionFormConfirmationModalContent } from '@src/modules/collections/hooks/useCollectionFormConfirmationModalContent';
import { useCollectionFormHandlers } from '@src/modules/collections/hooks/useCollectionFormHandlers';
import { OpenedConfirmationModalVariant } from '@src/modules/collections/constants';
import { useCollectionForm } from '@src/modules/collections/hooks/useCollectionForm';
import { CollectionModalMode } from '@src/modules/user-stories-exploration/service';
import { UserStoriesCollection } from '@src/modules/collections/types';

export const useCollectionFormModal = ({
	onClose,
	collectionIdForUpdate,
	collections = [],
	openedCollectionModalMode,
	afterAddNewCollectionModalCloseCallback,
}: {
	onClose: () => void;
	collectionIdForUpdate: string;
	collections?: UserStoriesCollection[];
	openedCollectionModalMode: CollectionModalMode;
	afterAddNewCollectionModalCloseCallback: (id: string) => Promise<void>;
}) => {
	//the selected collection from the collection list
	const collectionToUpdate = useMemo(() => {
		if (openedCollectionModalMode === CollectionModalMode.Create) {
			return null;
		}

		return collections.find(({ id }) => id === collectionIdForUpdate) ?? null;
	}, [collections, collectionIdForUpdate, openedCollectionModalMode]);

	//ref for identifying which kind of Confirmation Alert Dialog should be shown
	const modalVariantRef = useRef<OpenedConfirmationModalVariant>(
		OpenedConfirmationModalVariant.DEFAULT
	);

	//delete collection button tooltip that collection can not be deleted if there are related tests
	const disabledDeleteTooltipMessage = useMemo(() => {
		if (!collectionToUpdate) {
			return null;
		}

		return collectionToUpdate.storiesStatistics.count === 0
			? null
			: 'Relocate or delete all flows before attempting to delete this collection';
	}, [collectionToUpdate]);

	const {
		handleSubmit,
		dirtyFields,
		registerEditCollectionField,
		isDirty,
		reset,
		errors,
	} = useCollectionForm({ collectionToUpdate });

	//close modal and reset all values to default
	const onCloseCollectionModal = useCallback(() => {
		reset(getFormValues());
		onClose();
	}, [reset, onClose]);

	//CRUD form operations handlers
	const { submitCollectionFormHandler, isSubmitLoading } =
		useCollectionFormHandlers({
			dirtyFields,
			collectionToUpdate,
			onCloseCollectionModal,
			isDirty,
			handleSubmit,
			modalVariantRef,
			afterAddNewCollectionModalCloseCallback,
		});

	//content for the confirmation modal
	const { handleCloseModal, confirmationAlertContent } =
		useCollectionFormConfirmationModalContent({
			modalVariantRef,
			onClose: onCloseCollectionModal,
			isDirty,
		});

	return {
		registerEditCollectionField,
		handleSubmitCollectionForm: submitCollectionFormHandler,
		errors,
		handleCloseModal,
		confirmationAlertContent,
		isSubmitLoading,
		disabledDeleteTooltipMessage,
		isNew: !collectionToUpdate,
		collectionToUpdate,
	};
};
