import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useSessionRecordingsPageStyles } from './styles';
import { useSessionRecordings } from '@src/modules/session-recordings/pages/hooks/useSessionRecordings';
import { SessionRecordingsList } from '../components/SessionRecordingsList/SessionsRecordingsList';
import { SessionReplayer } from '@src/modules/session-recordings/components/SessionReplayer/SessionReplayer';
import { SessionRecordingsEmptyState } from '@src/modules/session-recordings/components/SessionRecordingsEmptyState/SessionRecordingsEmptyState';

export const SessionRecordings = () => {
	const {
		sessionRecordings,
		activeSessionRecordingId,
		recordingUrl,
		sdkKey,
		isSessionRecordingsEmpty,
	} = useSessionRecordings();
	const styles = useSessionRecordingsPageStyles();

	return (
		<Box component="section" css={styles.section}>
			<Box css={styles.pageHeader.container}>
				<Typography variant="h1" gutterBottom>
					Session Recordings
				</Typography>
			</Box>

			<Choose>
				<When condition={isSessionRecordingsEmpty}>
					<SessionRecordingsEmptyState sdkKey={sdkKey} />
				</When>

				<Otherwise>
					<Grid container spacing={5}>
						<Grid xs={5} css={styles.listContainer}>
							<SessionRecordingsList
								activeSessionRecordingId={activeSessionRecordingId}
								sessionRecordings={sessionRecordings}
							/>
						</Grid>

						<Grid xs={7}>
							<If condition={!!recordingUrl}>
								<SessionReplayer recordingUrl={recordingUrl} />
							</If>

							<If condition={!recordingUrl}>
								<Box css={styles.replayerEmptyState.container}>
									<Typography
										variant="h3"
										css={styles.replayerEmptyState.title}
									>
										No recording selected
									</Typography>

									<Typography>
										Please select a session recording to view it
									</Typography>
								</Box>
							</If>
						</Grid>
					</Grid>
				</Otherwise>
			</Choose>
		</Box>
	);
};

export default SessionRecordings;
