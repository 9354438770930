import { createSlice } from '@reduxjs/toolkit';
import { SignalsSliceState } from './types';

const initialState: SignalsSliceState = {
	channel: undefined,
	user: undefined,
	participants: {},
	channelChat: [],
	privateChats: {},
};

export const slice = createSlice({
	name: 'signals',
	initialState,
	reducers: {
		resetSignalsState: (state) => {
			return initialState;
		},
		updateUserState: (state, action) => {
			state.user = action.payload;
		},

		updateChannelState: (state, action) => {
			state.channel = action.payload;
		},
		updateParticipantState: (state, action) => {
			state.participants[action.payload.uuid] = action.payload.state;
		},
		updateParticipantUploadState: (state, action) => {
			state.participants[action.payload.uuid] = action.payload.state;
		},
		markParticipantLeftChannel: (state, action) => {
			state.participants[action.payload.uuid] = {
				...state.participants[action.payload.uuid],
				isDisconnected: true,
			};
		},
		addChannelChatMessage: (state, action) => {
			state.channelChat.push(action.payload.message);
		},
		addPrivateChatMessage: (state, action) => {
			if (!state.privateChats[action.payload.otherUserUUID]) {
				state.privateChats[action.payload.otherUserUUID] = [];
			}
			state.privateChats[action.payload.otherUserUUID].push(
				action.payload.message
			);
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	resetSignalsState,
	updateUserState,
	updateParticipantState,
	updateChannelState,
	updateParticipantUploadState,
	addChannelChatMessage,
	addPrivateChatMessage,
	markParticipantLeftChannel,
} = slice.actions;

export default slice.reducer;
