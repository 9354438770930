import api from '@src/api';
import {
	APISelectGithubRepository,
	ApplicationUser,
	GitlabAppIntegrationAPIArgsInfo,
	GitlabAppIntegrationInfo,
	SettingsProfileCreateAPI,
	SettingsProfileUpdateAPI,
} from '@src/modules/settings/api/types';
import { transformAPISettingsStatus } from './transformers';
import {
	ApplicationEnvironmentUser,
	GithubAppIntegrationStatus,
	GitlabAppIntegrationStatus,
	SettingsStatus,
} from '../types';
import {
	APIApplicationSettings,
	applicationsApi,
	ApplicationSettings,
	ApplicationUserRoles,
	AvailableApplication,
} from '@src/modules/application/service';
import _ from 'lodash';
import { TagDescription } from '@reduxjs/toolkit/query';

export const settingsAPI = api
	.enhanceEndpoints({
		addTagTypes: ['ProjectSettings', 'ApplicationSettings'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getSettingsStatus: builder.query<SettingsStatus, void>({
				query: () => ({
					url: `settings/status`,
				}),
				onQueryStarted: async (
					arg,
					{ dispatch, queryFulfilled, getState, getCacheEntry }
				) => {
					const prevData =
						getState().api?.queries?.['getSettingsStatus(null)']?.data;

					// initiate getApplicationSettings on first getSettingsStatus call
					if (!prevData) {
						dispatch(
							applicationsApi.endpoints.getApplicationSettings.initiate()
						);

						return;
					}

					try {
						const data = await queryFulfilled;

						if (!!prevData && !_.isEqual(prevData, data.data)) {
							dispatch(
								api.util.invalidateTags([
									'ApplicationSettings',
									// eslint-disable-next-line  @typescript-eslint/no-explicit-any
								] as TagDescription<any>[])
							);
						}
					} catch (e) {}
				},
				transformResponse: transformAPISettingsStatus,
			}),

			// [createSettingsProfile]
			createSettingsProfile: builder.mutation<
				APIApplicationSettings,
				SettingsProfileCreateAPI
			>({
				query: (args) => {
					return {
						url: 'settings/environment/',
						method: 'POST',
						successToastMessage: 'Environment created successfully',
						body: args,
					};
				},
				invalidatesTags: ['ApplicationSettings'],
			}),

			// [getDefaultEnvironmentUsers]
			getEnvironmentUsers: builder.query<ApplicationEnvironmentUser[], void>({
				query: () => {
					return {
						url: 'settings/environment/users',
					};
				},
			}),

			// [getDefaultEnvironmentUsers]
			deleteEnvironmentUser: builder.mutation<boolean, { id: string }>({
				query: ({ id }) => {
					return {
						url: `settings/environment/users/${id}`,
						method: 'DELETE',
						successToastMessage: 'Environment user deleted successfully',
						errorToastMessage:
							'It is not possible to delete users that are affiliated with test flows. Please re-assign users to the test flows first.',
						toastOptions: { autoClose: false },
					};
				},
			}),

			// [getDefaultEnvironment]
			deleteEnvironment: builder.mutation<boolean, { id: string }>({
				query: ({ id }) => {
					return {
						url: `settings/environment/${id}`,
						method: 'DELETE',
						successToastMessage: 'Environment deleted successfully',
						errorToastMessage:
							'Can not delete environment. Users credentials are used in test flows.',
					};
				},
				// optimistic update for environment delete
				onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
					const patchResult = dispatch(
						applicationsApi.util.updateQueryData(
							'getApplicationSettings',
							undefined,
							(draft: ApplicationSettings) => {
								return {
									...draft,
									userStories: draft.environments.filter(
										({ id }) => id !== arg.id
									),
								};
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				},
				invalidatesTags: ['ApplicationSettings'],
			}),

			// [updateSettingsProfile]
			updateSettingsProfile: builder.mutation<void, SettingsProfileUpdateAPI>({
				query: ({ id, update }) => {
					return {
						url: `settings/environment/${id}/`,
						method: 'PATCH',
						successToastMessage: 'Environment updated successfully',
						body: update,
					};
				},
				invalidatesTags: ['ApplicationSettings'],
			}),

			// TODO: move this endpoint to applications module, same on BE
			// [updateSettingsProfile]
			updateApplicationHasValidApplication: builder.mutation<
				void,
				{ hasValidLoginFunction: boolean }
			>({
				query: (body) => {
					return {
						url: `settings/has-login-function`,
						method: 'PATCH',
						body,
						successToastMessage: 'Environment settings updated successfully',
					};
				},
				invalidatesTags: ['ApplicationSettings'],
			}),

			// [getGitlabAppInstallationInfo]
			createOrUpdateGitlabAppInfo: builder.mutation<
				GitlabAppIntegrationInfo,
				GitlabAppIntegrationAPIArgsInfo
			>({
				query: (body) => ({
					method: 'POST',
					url: `settings/gitlab-app-integration/`,
					body,
					errorToastMessage:
						"An error has occurred while validating and saving GitLab settings. This usually means we haven't been able to connect to GitLab using these credentials. Please check the values and try again.",
					toastOptions: { autoClose: false },
					successToastMessage: 'GitLab integration settings saved successfully',
				}),
				invalidatesTags: ['ApplicationSettings'],
			}),

			// [selectGithubRepository]
			selectGithubRepository: builder.mutation<void, APISelectGithubRepository>(
				{
					query: (body) => ({
						url: `settings/github-app-integration/select-repository`,
						method: 'POST',
						body,
					}),
					invalidatesTags: ['ApplicationSettings'],
				}
			),

			// [deleteGitIntegration]
			deleteGitIntegration: builder.mutation<void, void>({
				query: (body) => ({
					method: 'DELETE',
					url: `settings/git-integration`,
					body,
					successToastMessage: `Git application integration has been successfully deleted.`,
				}),
				invalidatesTags: ['ApplicationSettings'],
			}),

			// [deleteGitIntegration]
			updateUseCollectionNameInTestPath: builder.mutation<
				void,
				{ useCollectionNameInTestPath: boolean }
			>({
				query: (body) => ({
					method: 'PATCH',
					url: `settings/git-integration/collection-name-in-test-path`,
					body,
					successToastMessage: body.useCollectionNameInTestPath
						? `Test files will be stored in collection folders`
						: 'Test files will be no longer be stored in collection folders',
				}),
				// optimistic update for environment delete
				onQueryStarted: async (
					{ useCollectionNameInTestPath },
					{ dispatch, queryFulfilled }
				) => {
					const patchResult = dispatch(
						applicationsApi.util.updateQueryData(
							'getApplicationSettings',
							undefined,
							(draft: ApplicationSettings) => {
								let optimisticallyUpdatedGitIntegrationFieldName = null;

								if (
									draft.settingsStatus.gitlabAppIntegrationStatus !==
									GitlabAppIntegrationStatus.NOT_INSTALLED
								) {
									optimisticallyUpdatedGitIntegrationFieldName =
										'gitlabAppIntegrationInfo';
								}

								if (
									draft.settingsStatus.githubAppIntegrationStatus !==
									GithubAppIntegrationStatus.NOT_INSTALLED
								) {
									optimisticallyUpdatedGitIntegrationFieldName =
										'githubAppIntegrationInfo';
								}

								return {
									...draft,
									[optimisticallyUpdatedGitIntegrationFieldName]: {
										...draft[optimisticallyUpdatedGitIntegrationFieldName],
										useCollectionNameInTestPath,
									},
								};
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				},
				invalidatesTags: ['ApplicationSettings'],
			}),

			// [getProjectsSettings]
			getProjectSettings: builder.query<ApplicationUser[], void>({
				query: () => ({
					url: `applications/users`,
				}),
				providesTags: ['ProjectSettings'],
			}),

			// [addUserToApp]
			addUserToApp: builder.mutation<
				void,
				{ email: string; role: ApplicationUserRoles }
			>({
				query: (body) => ({
					method: 'POST',
					body,
					url: `applications/users`,
					makeErrorFromResponse: ({ data }: { data: { message: string } }) => {
						return data.message;
					},
				}),
				invalidatesTags: ['ProjectSettings'],
			}),

			// [getApplications]
			getApplications: builder.query<AvailableApplication[], void>({
				query: () => ({
					url: `user/applications`,
				}),
			}),

			// [updateAppUserRole]
			updateAppUserRole: builder.mutation<
				void,
				{ id: string; role: ApplicationUserRoles }
			>({
				query: ({ id, role }) => ({
					method: 'PATCH',
					body: { role },
					url: `applications/users/${id}`,
				}),
				invalidatesTags: ['ProjectSettings'],
			}),
			// [deleteUserFromApp]
			deleteUserFromApp: builder.mutation<void, string>({
				query: (id) => ({
					method: 'DELETE',
					url: `applications/users/${id}`,
				}),
				invalidatesTags: ['ProjectSettings'],
			}),
		}),
	});

export const {
	useCreateSettingsProfileMutation,
	useUpdateSettingsProfileMutation,
	useSelectGithubRepositoryMutation,
	useGetSettingsStatusQuery,
	useAddUserToAppMutation,
	useDeleteUserFromAppMutation,
	useGetProjectSettingsQuery,
	useUpdateAppUserRoleMutation,
	useCreateOrUpdateGitlabAppInfoMutation,
	useDeleteGitIntegrationMutation,
	useUpdateApplicationHasValidApplicationMutation,
	useGetEnvironmentUsersQuery,
	useDeleteEnvironmentUserMutation,
	useDeleteEnvironmentMutation,
	useUpdateUseCollectionNameInTestPathMutation,
} = settingsAPI;

export const useGetApplicationsQueryState =
	settingsAPI.endpoints.getApplications.useQueryState;
