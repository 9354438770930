import { CRMProviderInterface } from '@src/services/crm/providers/CRMProviderInterface';
import Logger from '@src/services/logger';
import { LogComponents } from '@src/services/logger/types';
import { UserDataForCRM } from '@src/services/crm/types';
import {
	CRM_PROVIDER_CLASSES,
	CRM_SINGLE_PROVIDER_FEATURES,
} from '@src/services/crm/constants';

const logger = new Logger(LogComponents.CRM, { mute: true });

class CRMManagerClass {
	private readonly providers: CRMProviderInterface[];

	constructor() {
		this.providers = CRM_PROVIDER_CLASSES.map(
			(ProviderClass) => new ProviderClass()
		);
	}

	init() {
		this.forEachProvider((provider) => provider.init(), false);
	}

	identify(userData: UserDataForCRM) {
		this.forEachProvider((provider) => provider.identify(userData));
	}

	show = () => {
		logger.info('show launcher');
		return this.getProvider(CRM_SINGLE_PROVIDER_FEATURES.widget).show();
	};

	hide = () => {
		logger.info('hide launcher');
		return this.getProvider(CRM_SINGLE_PROVIDER_FEATURES.widget).hide();
	};

	open = () => {
		logger.info('show');
		return this.getProvider(CRM_SINGLE_PROVIDER_FEATURES.widget).open();
	};

	close = () => {
		logger.info('hide');
		return this.getProvider(CRM_SINGLE_PROVIDER_FEATURES.widget).close();
	};

	showNewMessage = (message) => {
		return this.getProvider(CRM_SINGLE_PROVIDER_FEATURES.widget).showNewMessage(
			message
		);
	};

	trackEvent = (event) => {
		this.forEachProvider((provider) => provider.trackEvent(event));
	};

	reset = () => {
		this.forEachProvider((provider) => provider.reset());
	};

	// -------- [Private] -------- //

	private forEachProvider(
		cb: (provider: CRMProviderInterface) => unknown,
		checkActive = true
	) {
		const results: Record<string, unknown> = {};

		this.providers.forEach((provider, index) => {
			try {
				if (checkActive && !provider.isActive()) {
					return;
				}
				results[provider.name] = cb(provider);
			} catch (e) {
				logger.error(' forEachProvider error for provider at index', {
					index,
					e,
				});
			}
		});

		return results;
	}

	private getProvider<T extends CRMProviderInterface>(providerClass): T {
		const index = CRM_PROVIDER_CLASSES.indexOf(providerClass);
		return this.providers[index] as T;
	}
}

const CRM = new CRMManagerClass();
export { CRM };
