import { useMemo } from 'react';
import { createTheme } from '@mui/material/styles';

import MuiCssBaseline from './components/CssBaseline';
import Typography from './components/Typography';

import colors from './colors';
// import shadow from './shadow';
// import animations from './animations';
// import palette from './palette';

function useAppTheme() {
	return useMemo(
		() =>
			createTheme({
				// palette,
				typography: Typography,
				// shape: {
				// 	borderRadius: 8,
				// },
				components: {
					MuiCssBaseline,
					// MuiButton,
					// MuiChip,
					// MuiPaper,
					// MuiDialog,
					// MuiTextField,
					// MuiCollapse,
					// MuiToggleButtonGroup,
					// MuiToggleButton,
					// MuiMenu,
					// MuiMenuItem,
					// MuiSwitch,
					// MuiLink,
					// MuiSelect,
					// MuiDivider,
					// MuiAvatar,
					// MuiCheckbox,
					// MuiCircularProgress,
				},
				colors,
				// animations,
				// shadow,
			}),
		[]
	);
}

declare module '@mui/material/styles' {
	interface ThemeOptions {
		colors: typeof colors;
		// animations: typeof animations;
		// shadow: typeof shadow;
	}

	interface Theme {
		colors: typeof colors;
		// animations: typeof animations;
		// shadow: typeof shadow;
	}
}

export { useAppTheme };
