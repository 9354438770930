import { EventInterface } from './index';

export enum TestEventTypes {
	// test
	TestEvent1 = 'test-event-1',
	TestEvent2 = 'test-event-2',
	// ...
}

export interface TestEvent1 extends EventInterface {
	type: TestEventTypes.TestEvent1;
	payload: { test: number };
}

export interface TestEvent2 extends EventInterface {
	type: TestEventTypes.TestEvent2;
	payload: { test: string };
}

export type TestEvents = TestEvent1 | TestEvent2;
