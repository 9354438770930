import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useTestRunsHeaderStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			display: 'flex',
			gap: theme.spacing(2),
		});

		return {
			container,
		};
	}, [theme]);
};

export const useAutoCompleteFilterInputStyles = () => {
	return useMemo(() => {
		const autocompleteInput = css({
			minWidth: '200px',
		});

		return {
			autocompleteInput,
		};
	}, []);
};
