import {
	useGetStoredTestGenerationStepLabelsQuery,
	useGetTestGenerationLogQuery,
} from '@src/modules/test-generation-labeling/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TestGenerationLogStep } from '@src/modules/test-generation-labeling/types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { testGenerationLabelingRoutes } from '@src/modules/test-generation-labeling/routes/routes';
import { getTestGenerationLogStepByIndex } from './helpers';
import { useTestGenerationLabelingStepsNavigation } from './hooks/useTestGenerationLabelingStepsNavigation';
import { useScreenshots } from './hooks/useScreenshots';
import { useLLMResponseEdit } from './hooks/useLLMResponseEdit';
import { usePrevious } from 'react-use';
import { useUpdateTestGenerationLabelingStep } from './hooks/useUpdateTestGenerationLabelingStep';

export const useTestGenerationLabeling = () => {
	const { sessionId, stepId } = useParams<'sessionId' | 'stepId'>();
	const [queryParams] = useSearchParams();

	const shouldSkipRequestRef = useRef(false);

	const { data: testGenerationLog } = useGetTestGenerationLogQuery(
		{
			taskId: queryParams.get('taskId'),
			testUuid: sessionId,
		},
		{
			skip: shouldSkipRequestRef.current,
		}
	);

	const { data: storedSteps } = useGetStoredTestGenerationStepLabelsQuery({
		testUuid: sessionId,
	});

	const [activeTestGenerationLogStep, setActiveTestGenerationLogStep] =
		useState<TestGenerationLogStep | null>(null);

	const activeStoredStep = useMemo(() => {
		if (!activeTestGenerationLogStep || !storedSteps) {
			return;
		}
		return storedSteps.find(
			({ index }) => index === activeTestGenerationLogStep.index
		);
	}, [storedSteps, activeTestGenerationLogStep]);

	const entries = useMemo(
		() => testGenerationLog?.entries,
		[testGenerationLog]
	);

	const { prevHref, nextHref } = useTestGenerationLabelingStepsNavigation({
		entries,
		activeTestGenerationLogStep,
	});

	const {
		screenshot,
		onSelectScreenshot,
		handleExpandScreenshot,
		activeScreenshotOption,
	} = useScreenshots({
		activeTestGenerationLogStep,
	});

	const {
		LLMResponse,
		scrollToJsonEditorBottom,
		onReset,
		onChange,
		feedbackBottomRef,
		editorKey,
		updateEditorKey,
	} = useLLMResponseEdit({
		activeTestGenerationLogStep,
		activeStoredStep,
	});

	const navigate = useNavigate();

	const { handleUpdateTestGenerationLogStep, isSubmitting } =
		useUpdateTestGenerationLabelingStep({
			updateEditorKey,
			entries,
			LLMResponse,
			sessionId,
			stepId,
			activeTestGenerationLogStep,
			activeStoredStep,
		});

	//when no sessionId or stepId navigate to first not reviewed step
	useEffect(() => {
		if (!sessionId && !stepId && !!testGenerationLog) {
			navigate(
				testGenerationLabelingRoutes.step.make({
					sessionId: testGenerationLog.uuid,
					stepId: testGenerationLog.currentUnlabelledStepIndex.toString(),
				})
			);
			//prevent new requests once log is fetched
			shouldSkipRequestRef.current = true;
		}
	}, [
		queryParams,
		sessionId,
		stepId,
		navigate,
		activeTestGenerationLogStep,
		testGenerationLog,
	]);

	//set active step once stepId param is changed
	useEffect(() => {
		if (!!stepId && testGenerationLog) {
			const step = getTestGenerationLogStepByIndex(
				testGenerationLog.entries,
				stepId
			);
			setActiveTestGenerationLogStep(step);
		}
	}, [stepId, testGenerationLog]);

	const previousSessionId = usePrevious(sessionId);

	//allow new log request once sessionId changed
	useEffect(() => {
		if (!!previousSessionId && previousSessionId !== sessionId) {
			shouldSkipRequestRef.current = false;
		}
	}, [previousSessionId, sessionId]);

	return {
		handleUpdateTestGenerationLogStep,
		onChange,
		onReset,
		LLMResponse,
		nextHref,
		prevHref,
		activeTestGenerationLogStep,
		activeStoredStep,
		screenshot,
		onSelectScreenshot,
		activeScreenshotOption,
		feedbackBottomRef,
		scrollToJsonEditorBottom,
		handleExpandScreenshot,
		editorKey,
		entries,
		isSubmitting,
	};
};
