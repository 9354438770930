import { SerializedStyles } from '@emotion/react';
import { List } from '@mui/material';
import { isNotEmptyArray } from '@src/common/helpers/arrays';
import { TestSuiteRun } from '../../types';
import { useTestRunsListStyles } from '@src/modules/test-runs/components/TestRunList/styles';
import { TestRunListSkeleton } from '@src/modules/test-runs/components/TestRunList/TestRunListSkeleton';
import { TestRunListItem } from '@src/modules/test-runs/components/TestRunListItem/TestRunListItem';
import { Link } from 'react-router-dom';
import { testRunsRoutes } from '@src/modules/test-runs/routes/routes';

export function TestRunList({
	testRuns,
	listStyle,
	isTestRunsLoadingSuccess,
}: {
	testRuns: TestSuiteRun[];
	listStyle: SerializedStyles;
	isTestRunsLoadingSuccess: boolean;
}) {
	const styles = useTestRunsListStyles({ listStyle });

	return (
		<Choose>
			<When condition={isNotEmptyArray(testRuns)}>
				<List css={styles.list}>
					{testRuns.map((testRun, index) => (
						<Link
							key={testRun.id}
							to={testRunsRoutes.testRun.make({
								id: testRun.id,
							})}
							style={{ color: 'inherit' }}
						>
							<TestRunListItem key={testRun.id} testRun={testRun} />
						</Link>
					))}
				</List>
			</When>

			<When condition={isTestRunsLoadingSuccess}>
				No test runs matching these filters
			</When>

			<Otherwise>
				<TestRunListSkeleton count={4} />
			</Otherwise>
		</Choose>
	);
}
