import { delay, http, HttpResponse } from 'msw';
import { APIBaseURL } from '@src/api/constants';
import { storedSteps, tesGenerationLogData } from './tg-log-data';
import {
	APINextActionResponse,
	LabelingResult,
} from '@src/modules/test-generation-labeling/api/types';
import { tagsOptions, tgLabelingQueueData } from './data';
import { getStoredStepByStepId } from '@src/modules/test-generation-labeling/api/mock/helpers';

const BaseURL = `${APIBaseURL}/test-generation-labeling`;

export const tesGenerationLabelingMockHandlers = [
	http.get(`${BaseURL}/manager/next`, async ({ request, params, cookies }) => {
		await delay(2000);

		// Construct a URL instance out of the intercepted request.
		const url = new URL(request.url);

		const taskId = url.searchParams.get('taskId');
		const testUuid = url.searchParams.get('testUuid');

		//all logs should be a data constant with an array of the logs
		const allLogs = [tesGenerationLogData];

		let responseLogData = tesGenerationLogData;

		if (!!taskId || !!testUuid) {
			const activeLog = allLogs.find(
				({ testGenerationLabelingTask }) =>
					testGenerationLabelingTask.id === taskId ||
					testGenerationLabelingTask.testUuid === testUuid
			);

			if (activeLog) {
				responseLogData = activeLog;
			}
		}

		return HttpResponse.json(responseLogData);
	}),

	http.get(`${BaseURL}/manager/tags`, async ({ request, params, cookies }) => {
		await delay(2000);

		return HttpResponse.json(tagsOptions);
	}),

	http.get<{ testUuid: string }>(
		`${BaseURL}/tests/:testUuid/steps`,
		async ({ request, params, cookies }) => {
			await delay(2000);

			//all logs should be a data constant with an array of the logs
			const allLogs = [tesGenerationLogData];

			const activeLog = allLogs.find(
				({ testGenerationLabelingTask }) =>
					params.testUuid === testGenerationLabelingTask.testUuid
			);

			if (!activeLog) {
				return HttpResponse.json(
					{ error: 'invalid testUuid' },
					{ status: 404 }
				);
			}

			return HttpResponse.json(storedSteps);
		}
	),

	http.patch<
		{ sessionId: string; stepId: string },
		{
			labelingResult: LabelingResult;
			update: Partial<APINextActionResponse>;
		}
	>(
		`${BaseURL}/tests/:sessionId/steps/:stepId/label`,
		async ({ request, params, cookies }) => {
			const requestBody = await request.json();

			await delay(2000);

			let testGenerationLogStep = getStoredStepByStepId(params.stepId, true);

			//TODO: add a search for tesGenerationLogs

			//as any because the json file has not types
			Object.assign(testGenerationLogStep, requestBody.update);

			// testGenerationLogStep.completedSuccessfully = requestBody.isCorrect;

			return HttpResponse.json(testGenerationLogStep);
		}
	),

	http.patch<
		{ sessionId: string; stepId: string },
		{
			tags?: string[];
			favorite?: boolean;
		}
	>(
		`${BaseURL}/tests/:sessionId/steps/:stepId/tag`,
		async ({ request, params, cookies }) => {
			const requestBody = await request.json();

			const { favorite, tags } = requestBody;

			await delay(2000);

			const testGenerationLogStep = getStoredStepByStepId(params.stepId, true);

			if (favorite !== undefined) {
				testGenerationLogStep.favorite = favorite;
			}
			if (tags) {
				testGenerationLogStep.tags = tags;
			}

			return HttpResponse.json(testGenerationLogStep);
		}
	),

	http.post<
		{},
		{
			url: string;
		}
	>(`${BaseURL}/queue`, async ({ request, params, cookies }) => {
		const requestBody = await request.json();

		const { url } = requestBody;

		tgLabelingQueueData.push({
			url,
			status: 'pending',
			id: tesGenerationLogData.testGenerationLabelingTask.id,
		});

		await delay(2000);

		return HttpResponse.json(tgLabelingQueueData);
	}),

	http.delete<{ taskId: string }, boolean>(
		`${BaseURL}/queue/:taskId`,
		async ({ request, params, cookies }) => {
			const labelingTaskIndexToDelete = tgLabelingQueueData.findIndex(
				({ id }) => params.taskId === id
			);

			if (labelingTaskIndexToDelete) {
				return HttpResponse.json({ error: 'Not found' }, { status: 404 });
			}

			tgLabelingQueueData.splice(labelingTaskIndexToDelete, 1);

			await delay(2000);

			return HttpResponse.json({ success: true });
		}
	),

	http.get(`${BaseURL}/queue`, async ({ request, params, cookies }) => {
		return HttpResponse.json(tgLabelingQueueData);
	}),
];
