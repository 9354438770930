import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useStoryFormActionsBarStyles = () => {
	return useMemo(() => {
		const centeredContainer = css({
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		});

		const actionBarSection = css({
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'end',
		});

		return {
			centeredContainer,
			actionBarSection,
		};
	}, []);
};
