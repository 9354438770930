import { Route } from 'react-router-dom';
import { ApplicationRedirects, ApplicationRoutes } from './routes';
import { AppRedirects } from '@src/routing/components/AppRedirect/AppRedirect';
import AppRoute, {
	InternalAppRoute,
} from '../../routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { useMemo } from 'react';
import { AuthenticateRouter } from '@src/modules/authentication/service';
import { UserStoriesExplorationRouter } from '@src/modules/user-stories-exploration/service';
import { TestRunsRouter } from '@src/modules/test-runs/service';
import { SettingsRouter } from '@src/modules/settings/service';
import { SessionRecordingsRouter } from '@src/modules/session-recordings/service';
import { InternalLayoutHOC } from '@src/layout/InternalLayout';
import { DefaultPage } from '../pages/DefaultPage';
import { InternalRouter } from '@src/modules/internal/service';
import { UserStoriesRouter } from '@src/modules/user-stories/service';
import { TraceViewerPage } from '@src/modules/trace-viewer/service';

export function MainRouter() {
	return useMemo(
		() => (
			<AppRouter>
				<Route
					path={ApplicationRoutes.authenticate.routerPath}
					element={
						<AppRoute
							withLayout
							allowMobile
							authenticated={false}
							requireApplications={false}
							layoutOptions={ApplicationRoutes.authenticate.layoutOptions}
						>
							<AuthenticateRouter />
						</AppRoute>
					}
				/>
				<Route
					path={ApplicationRoutes.userStoriesExplorer.routerPath}
					element={
						<AppRoute withLayout component={UserStoriesExplorationRouter} />
					}
				/>
				<Route
					path={ApplicationRoutes.userStories.routerPath}
					element={<AppRoute withLayout component={UserStoriesRouter} />}
				/>
				<Route
					path={ApplicationRoutes.testRuns.routerPath}
					element={<AppRoute withLayout component={TestRunsRouter} />}
				/>
				<Route
					path={ApplicationRoutes.settings.routerPath}
					element={
						<AppRoute
							layoutOptions={ApplicationRoutes.settings.layoutOptions}
							withLayout
							component={SettingsRouter}
						/>
					}
				/>
				<Route
					path={ApplicationRoutes.sessionRecordings.routerPath}
					element={<AppRoute withLayout component={SessionRecordingsRouter} />}
				/>
				<Route
					path={ApplicationRoutes.traceViewer.path}
					element={
						<AppRoute
							withLayout
							authenticated={false}
							component={TraceViewerPage}
							layoutOptions={ApplicationRoutes.traceViewer.layoutOptions}
						/>
					}
				/>
				<Route
					path={ApplicationRoutes.internal.routerPath}
					element={
						<InternalAppRoute
							withLayout
							layoutHOC={InternalLayoutHOC}
							component={InternalRouter}
						/>
					}
				/>
				<Route path="/" element={<DefaultPage />} />
				{AppRedirects({ redirects: ApplicationRedirects })}
			</AppRouter>
		),
		[]
	);
}
