import { FC } from 'react';
import {
	AppTextFieldProps,
	FormTextField,
} from '@src/common/components/FormTextField';
import { MenuItem, Typography } from '@mui/material';
import { UserStoriesCollection } from '@src/modules/collections/types';
import { SerializedStyles } from '@emotion/react';
import { useCollectionsListDropdown } from '@src/modules/collections/components/CollectionsListDropdown/useCollectionsListDropdown';
import { UseFormRegister } from 'react-hook-form';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/constants';
import { useCollectionsListDropdownStyles } from '@src/modules/collections/components/CollectionsListDropdown/styles';

type Props = {
	collections: UserStoriesCollection[];
	value: string;
	disabled?: boolean;
	storyId?: string;
	isFormField?: boolean;
	onCreateNewCollection?: () => void;
	handleChangeCollection?: (collectionId: string) => void;
	/* eslint-disable-next-line  @typescript-eslint/no-explicit-any  */
	register?: UseFormRegister<any>;
	styles?: SerializedStyles;
} & AppTextFieldProps;

export const CollectionsListDropdown: FC<Props> = ({
	collections,
	value,
	disabled,
	name,
	styles: propStyles,
	onCreateNewCollection,
	handleChangeCollection,
	storyId,
	register,
	isFormField = false,
	...props
}) => {
	const { filteredCollections, inputSize, label, onChangeCollection } =
		useCollectionsListDropdown({
			isFormField,
			collections,
			handleChangeCollection,
		});

	const { getShouldHideMenuItemStyles, styles } =
		useCollectionsListDropdownStyles();

	return (
		<FormTextField
			select
			label={label}
			placeholder="Select collection"
			name={name}
			size={inputSize}
			value={value}
			onChange={onChangeCollection}
			InputLabelProps={{ shrink: isFormField }}
			inputProps={register?.(name)}
			disabled={disabled}
			styles={propStyles}
			{...props}
		>
			{/*Show Create a new collection only if such callback provided*/}
			<If condition={!!onCreateNewCollection}>
				<MenuItem
					css={styles.createNewCollectionMenuItem}
					onClick={onCreateNewCollection}
				>
					<Typography variant="button">+ Create a new collection</Typography>
				</MenuItem>
			</If>

			{/*show inbox option only in the inbox stories list*/}
			<If condition={value === INBOX_COLLECTION_ID}>
				<MenuItem
					disabled
					key={INBOX_COLLECTION_ID}
					value={INBOX_COLLECTION_ID}
				>
					Inbox
				</MenuItem>
			</If>

			{filteredCollections.map(({ name, id }) => (
				<MenuItem
					key={id}
					value={id}
					css={getShouldHideMenuItemStyles(id === value)}
				>
					{name}
				</MenuItem>
			))}
		</FormTextField>
	);
};
