import { useCallback, useMemo } from 'react';
import useToggle from '@src/common/hooks/useToggle';

export const useDynamicEnvironmentUserRow = ({
	onDeleteEnvironmentUser,
	onChangeEnvironmentUserIsDefault,
	index,
}) => {
	const { isOn: showPassword, toggle: toggleShowPassword } = useToggle();

	const passwordInputType = useMemo(() => {
		return showPassword ? 'text' : 'password';
	}, [showPassword]);

	const handleDeleteEnvironmentUser = useCallback(() => {
		onDeleteEnvironmentUser(index);
	}, [index, onDeleteEnvironmentUser]);

	const handleChangeIsDefault = useCallback(
		(e, checked) => {
			onChangeEnvironmentUserIsDefault(checked, index);
		},
		[index, onChangeEnvironmentUserIsDefault]
	);

	return {
		handleDeleteEnvironmentUser,
		handleChangeIsDefault,
		showPassword,
		toggleShowPassword,
		passwordInputType,
	};
};
