import { Dispatch, SetStateAction, useMemo } from 'react';

export const useConfirmationModal = ({
	handleStopDetecting,
	isConfirmationModalOpen,
	setIsConfirmationModalOpen,
}: {
	handleStopDetecting: () => void;
	isConfirmationModalOpen: boolean;
	setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	// buttons array config to build buttons in confimation modal
	const confirmationModalButtonsWithCallbacks = useMemo(
		() => [
			{
				callback: () => {
					handleStopDetecting();
					setIsConfirmationModalOpen(false);
				},
				text: 'Yes',
			},
			{
				callback: () => {
					setIsConfirmationModalOpen(false);
				},
				text: 'No',
			},
		],
		[handleStopDetecting, setIsConfirmationModalOpen]
	);

	return {
		onClose: () => setIsConfirmationModalOpen(false),
		buttons: confirmationModalButtonsWithCallbacks,
		open: isConfirmationModalOpen,
		title: 'Stop test flows auto-detecting',
		description: 'Do you want to stop automatic flow detection?',
	};
};
