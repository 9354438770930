import {
	AlertDialogButton,
	AlertDialogContent,
} from '@src/common/components/ConfirmationDialog/types';

export enum OpenedConfirmationModalVariant {
	UNSAVED_CHANGES = 'unsaved-changes',
	DELETE = 'delete',
	DEFAULT = 'default',
	ABORT = 'abort',
	SETTINGS_INCOMPLETE = 'settings-incomplete',
	NO_VALID_LOGIN_FUNCTION = 'no-valid-login-function',
}

export const CONFIRMATION_ALERTS_CONTENTS: Record<
	OpenedConfirmationModalVariant,
	Pick<AlertDialogContent, 'title' | 'description'> & {
		buttons: Omit<AlertDialogButton, 'callback'>[];
	}
> = {
	[OpenedConfirmationModalVariant.DEFAULT]: null,
	[OpenedConfirmationModalVariant.DELETE]: {
		title: 'Test flow will be deleted',
		description: 'Are you sure you want to delete this test flow?',
		buttons: [{ text: 'Return to test flow' }, { text: 'Yes, delete' }],
	},
	[OpenedConfirmationModalVariant.UNSAVED_CHANGES]: {
		title: 'Your changes are not saved',
		description:
			"You have made changes to the test flow that haven't been saved yet. If you leave now, your changes will be lost.",
		buttons: [
			{ text: 'Return to test flow' },
			{ text: 'Continue without saving' },
		],
	},
	[OpenedConfirmationModalVariant.ABORT]: {
		title: 'Test flow generation will be aborted',
		description:
			'Are you sure you want to abort test generation in progress for this test flow?',
		buttons: [{ text: 'Return to test flow' }, { text: 'Yes, abort' }],
	},
	[OpenedConfirmationModalVariant.SETTINGS_INCOMPLETE]: {
		title: 'Settings incomplete',
		description:
			'Before we generate tests, you must complete setting up the testing environment and the Git integration.',
		buttons: [{ text: 'Return to test flow' }, { text: 'Go to settings' }],
	},
	[OpenedConfirmationModalVariant.NO_VALID_LOGIN_FUNCTION]: {
		title: 'Project setup still in progress',
		description:
			"The project is still being setup, therefore tests cannot be generated yet. The process usually takes up to 24 hours, we'll notify you when it's done.",
		buttons: [{ text: 'OK' }],
	},
};
