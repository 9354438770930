import { TestGeneration } from '@src/modules/test-generations/service';
import { UserStoryEngagement } from '@src/modules/user-stories/api/types';
import { ApplicationEnvironmentUser } from '@src/modules/settings/service';

export enum UserStoryOrigin {
	D2S = 'd2s',
	USER = 'user',
}

export type UserStoryD2SPayload = {
	sessionId: string;
	startInteractionId: string;
	endInteractionId: string;
	actions: string[];
	intents: string[];
	model: string;
	batchId: string;
};

export enum UserStoryStatus {
	Idle = 'idle',
	Queued = 'queued',
	Running = 'running',
	Completed = 'completed',
	Failed = 'failed',
	Aborted = 'aborted',
	Verifying = 'verifying',
	Edited = 'edited',
	PRClosed = 'pr-closed',
	PRMerged = 'pr-merged',
}

export interface UserStory {
	id: string;
	title: string;
	steps: string;
	collectionId: string;
	collectionName: string;
	startURL?: string;
	defaultStartURL?: string;
	origin: UserStoryOrigin;
	payload: {
		d2s?: UserStoryD2SPayload;
	};
	createdAt: string;
	editedAt?: string;
	status: UserStoryStatus;
	environmentUser: ApplicationEnvironmentUser;
	recentActivityDate: string;
	completedRecentTestGenerationStoryStatus: UserStoryStatus | null;
	generations: TestGeneration[];
	recentGeneration?: TestGeneration;
	engagement: UserStoryEngagement | null;
	username?: string;
	password?: string;
	userStoryFiles: UserStoryFile[];
}

export interface UpdateUserStoryAPIArgs {
	update: Partial<
		Pick<
			UserStory,
			| 'title'
			| 'steps'
			| 'collectionId'
			| 'username'
			| 'password'
			| 'startURL'
			| 'userStoryFiles'
		>
	> /*& { shouldChangeCollection: boolean };*/;
	id: string;
}

export type CreateUserStoryAPIArgs = Pick<
	UserStory,
	'title' | 'steps' | 'username' | 'password' | 'startURL' | 'collectionId'
> & { userStoryFiles: { tag: string; fileId: string; id?: string }[] };

export interface GetUserStoriesAPIArgs {
	collectionId?: string;
}

export interface GenerateUserStoryAPIArgs {
	id: string;
}

export type CustomFile = {
	id: string;
	path: string;
	bucket: string;
	storage_type: 'gcp' | 'local';
	available: boolean;
	directory: boolean;
	name: string;
};

export type UserStoryFile = {
	id: string;
	tag: string;
	file: CustomFile;
};
