import { Box, ButtonOwnProps, Tooltip } from '@mui/material';
import { ConditionalWrapper } from '@src/common/components/ConditionalWrapper';
import { useAutoDetectTestFlowsButton } from './hooks';
import { AutoAwesome } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { SerializedStyles } from '@emotion/react';
import { useAutoDetectFlowsButtonStyles } from '@src/modules/collections/components/AutoDetectTestFlowsButton/styles';
import { AutoDetectTestFlowsPopUpForm } from '@src/modules/collections/components/AutoDetectTestFlowsButton/AutoDetectTestFlowsPopUpForm/AutoDetectTestFlowsPopUpForm';

export const AutoDetectTestFlowsButton = ({
	containerStyles,
	buttonStyles,
	variant = 'outlined',
}: {
	containerStyles?: SerializedStyles;
	buttonStyles?: SerializedStyles;
	variant?: ButtonOwnProps['variant'];
}) => {
	const {
		onAutoDetectFlows,
		isFlowAutoDetectionActivated,
		isLoading,
		shouldDisplayAutoDetectFlowButton,
		autoDetectFlowsDescription,
		anchor,
		handleClose,
		handleClick,
		buttonRef,
	} = useAutoDetectTestFlowsButton();

	const styles = useAutoDetectFlowsButtonStyles({ buttonStyles, isLoading });

	return (
		<If condition={shouldDisplayAutoDetectFlowButton}>
			<Box css={containerStyles}>
				<ConditionalWrapper
					condition={isFlowAutoDetectionActivated}
					wrapper={(children) => (
						<Tooltip title="Tests flow auto-detection is activated for this collection">
							{children}
						</Tooltip>
					)}
				>
					<span>
						<LoadingButton
							size="large"
							variant={variant}
							onClick={handleClick}
							startIcon={<AutoAwesome />}
							loading={isLoading}
							css={styles.button}
						>
							<Choose>
								<When condition={isFlowAutoDetectionActivated}>
									Auto-detecting test flows
								</When>

								<Otherwise>Auto-detect test flows</Otherwise>
							</Choose>
						</LoadingButton>
					</span>
				</ConditionalWrapper>

				<div ref={buttonRef} />

				<AutoDetectTestFlowsPopUpForm
					isLoading={isLoading}
					isFlowAutoDetectionActivated={isFlowAutoDetectionActivated}
					onAutoDetectFlows={onAutoDetectFlows}
					anchor={anchor}
					onClose={handleClose}
					autoDetectFlowsDescription={autoDetectFlowsDescription}
				/>
			</Box>
		</If>
	);
};
