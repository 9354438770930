import { Typography } from '@mui/material';

function MobilePage() {
	return (
		<section>
			<Typography>Continue on your computer</Typography>
			<Typography>
				Head to app.checksum.ai on your desktop or laptop to continue.
			</Typography>
		</section>
	);
}

export default MobilePage;
