import React, { useCallback, useMemo, useRef } from 'react';
import { useAutoDetectFlowsMutation } from '../../api';
import { useParams } from 'react-router-dom';
import { useCollection } from '@src/modules/collections/hooks/useCollection';
import {
	ALL_FLOWS_COLLECTION_ID,
	INBOX_COLLECTION_ID,
} from '@src/modules/collections/constants';

export const useAutoDetectTestFlowsButton = () => {
	const { collectionId } = useParams<'collectionId'>();

	const { collection, isFetching } = useCollection(collectionId);

	// anchor to place auto-detecting popup on screen properly
	const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

	// create ref for popup in case that Button is being rendered conditionally
	const buttonRef = useRef(null);

	const shouldDisplayAutoDetectFlowButton = useMemo(() => {
		return ![INBOX_COLLECTION_ID, ALL_FLOWS_COLLECTION_ID].includes(
			collectionId
		);
	}, [collectionId]);

	const [autoDetectFlows, { isLoading: isAutoDetectFlowsLoading }] =
		useAutoDetectFlowsMutation();

	// handler to change auto-detecting status active or not
	const onAutoDetectFlows = useCallback(
		async ({
			activated,
			description,
		}: {
			activated: boolean;
			description?: string;
		}) => {
			if (!collection?.id) {
				return;
			}

			await autoDetectFlows({ collectionId, activated, description });
		},
		[autoDetectFlows, collection, collectionId]
	);

	const handleClick = useCallback(() => {
		setAnchor(anchor ? null : buttonRef.current);
	}, [anchor]);

	const handleClose = useCallback(() => {
		setAnchor(null);
	}, []);

	return {
		shouldDisplayAutoDetectFlowButton,
		onAutoDetectFlows,
		isFlowAutoDetectionActivated: !!collection?.isFlowAutoDetectionActivated,
		autoDetectFlowsDescription: collection?.autoDetectFlowsDescription,
		isLoading: isFetching || isAutoDetectFlowsLoading,
		anchor,
		handleClose,
		handleClick,
		buttonRef,
	};
};
