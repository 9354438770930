import api from '@src/api';
import { UserStoriesCollection } from '../types';
import {
	transformAPIGetCollectionsResponse,
	transformAPIGetInboxDataResponse,
	transformCreateOrUpdateCollectionToAPI,
} from './transformers';
import {
	CreateCollectionAPIArgs,
	InboxDataResponse,
	ReadUserStoriesAPIArgs,
	UpdateCollectionAPIArgs,
} from '@src/modules/collections/api/types';

export const collectionsApi = api
	.enhanceEndpoints({
		addTagTypes: ['Collections', 'InboxData', 'UserStories'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getUserStoriesCollections: builder.query<UserStoriesCollection[], void>({
				query: () => ({
					url: `user-stories-collections`,
				}),
				providesTags: ['Collections'],
				transformResponse: transformAPIGetCollectionsResponse,
			}),

			getUserInboxCollectionAndStories: builder.query<InboxDataResponse, void>({
				query: () => ({
					url: `user-stories-inbox`,
				}),
				providesTags: ['InboxData'],
				transformResponse: transformAPIGetInboxDataResponse,
			}),

			//[createCollection]
			createUserStoriesCollection: builder.mutation<
				{ id: string },
				CreateCollectionAPIArgs
			>({
				query: (body) => {
					return {
						url: `user-stories-collections`,
						method: 'POST',
						body: transformCreateOrUpdateCollectionToAPI(body),
						successToastMessage: 'Collection has been created successfully',
						errorToastMessage: `Collection with name "${body.name}" already exists`,
					};
				},
				invalidatesTags: ['Collections'],
			}),

			//[readInboxUserStories]
			readInboxUserStories: builder.mutation<void, ReadUserStoriesAPIArgs>({
				query: (body) => {
					return {
						url: `user-stories-inbox/readAll`,
						method: 'POST',
						body,
					};
				},
			}),

			//[updateCollection]
			updateCollection: builder.mutation<void, UpdateCollectionAPIArgs>({
				query: ({ update, collectionId }) => {
					return {
						url: `user-stories-collections/${collectionId}`,
						method: 'PATCH',
						body: transformCreateOrUpdateCollectionToAPI(update),
						successToastMessage: 'Collection has been updated successfully',
						errorToastMessage: update.name
							? `Collection with name "${update.name}" already exists`
							: undefined,
					};
				},
				// [UserStories] to validate story form exactly after the collection update
				invalidatesTags: ['Collections', 'UserStories'],
			}),

			// [moveUserStoriesToAnotherCollection]
			moveUserStoriesToAnotherCollection: builder.mutation<
				void,
				{
					collectionId: string;
					moveToCollectionId: string;
				}
			>({
				query: ({ collectionId, ...update }) => {
					return {
						url: `user-stories-collections/${collectionId}/transferStories`,
						method: 'PATCH',
						body: update,
						successToastMessage: 'Flows have been moved successfully',
					};
				},
				invalidatesTags: ['Collections'],
			}),

			//[autoDetectFlows]
			autoDetectFlows: builder.mutation<
				void,
				{ collectionId: string; activated: boolean; description?: string }
			>({
				query: ({ collectionId, ...update }) => {
					return {
						url: `user-stories-collections/${collectionId}/autoDetectFlows`,
						method: 'PATCH',
						body: update,
						successToastMessage:
							'Auto-detecting test flows based on production usage. This might take a few days and require to have at least 100 complete sessions in the collection',
						toastOptions: { autoClose: false },
					};
				},
				invalidatesTags: ['Collections'],
			}),

			//[deleteCollection]
			deleteCollection: builder.mutation<void, string>({
				query: (collectionId) => {
					return {
						url: `user-stories-collections/${collectionId}`,
						method: 'DELETE',
						successToastMessage: 'Collection has been deleted successfully',
					};
				},
				invalidatesTags: ['Collections'],
				async onQueryStarted(collectionId, { dispatch, queryFulfilled }) {
					const patchResult = dispatch(
						collectionsApi.util.updateQueryData(
							'getUserStoriesCollections',
							undefined,
							(draft: UserStoriesCollection[]) => {
								return draft.filter(({ id }) => id !== collectionId);
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				},
			}),
		}),
	});

export const {
	useAutoDetectFlowsMutation,
	useGetUserStoriesCollectionsQuery,
	useCreateUserStoriesCollectionMutation,
	useUpdateCollectionMutation,
	useDeleteCollectionMutation,
	useMoveUserStoriesToAnotherCollectionMutation,
	useGetUserInboxCollectionAndStoriesQuery,
	useReadInboxUserStoriesMutation,
} = collectionsApi;
