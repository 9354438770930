import { Route } from 'react-router-dom';
import AppRoute from '@src/routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import SessionRecordings from '../pages/SessionRecordings';
import { sessionRecordingsRoutes } from '@src/modules/session-recordings/routes/routes';

export function SessionRecordingsRouter() {
	return (
		<AppRouter>
			<Route
				path={sessionRecordingsRoutes.sessionRecordings.path}
				element={<AppRoute component={SessionRecordings} />}
			/>
			<Route
				path={sessionRecordingsRoutes.sessionRecording.path}
				element={<AppRoute component={SessionRecordings} />}
			/>
		</AppRouter>
	);
}
