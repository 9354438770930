import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Stack, Tooltip } from '@mui/material';
import { ConditionalWrapper } from '@src/common/components/ConditionalWrapper';
import { FC } from 'react';
import { UserStory } from '../../types';
import useStyles from '../StoryForm/styles';
import { useStoryFormActionsBar } from '@src/modules/user-stories/components/StoryFormActionsBar/hooks';
import { useStoryFormActionsBarStyles } from '@src/modules/user-stories/components/StoryFormActionsBar/styles';
import { TestGenerationsActivityLog } from './components/TestGenerationsActivityLog';

type Props = {
	story: UserStory;
	styles: ReturnType<typeof useStyles>;
	isGenerationRunning: boolean;
	isStoryFormValid: boolean;
	isDirty: boolean;
	isSettingsStatusLoading: boolean;
	isSubmitLoading: boolean;
	isTriggeringGeneration: boolean;
	isFormFieldsDisabled: boolean;
	onGenerate: () => void;
	isAborting: boolean;
	onAbort: () => void;
	onDelete: () => void;
};

export const ActionsBar: FC<Props> = ({
	story,
	styles: propsStyles,
	isGenerationRunning,
	isDirty,
	isSubmitLoading,
	isSettingsStatusLoading,
	isTriggeringGeneration,
	onGenerate,
	isAborting,
	onAbort,
	onDelete,
	isFormFieldsDisabled,
	isStoryFormValid,
}) => {
	const { isSaveDisabled, isGenerateDisabled, forceAllowAborting } =
		useStoryFormActionsBar({
			storyStatus: story?.status,
			isDirty,
			isFormFieldsDisabled,
			isSettingsStatusLoading,
			isStoryFormValid,
		});

	const styles = useStoryFormActionsBarStyles();

	return (
		<Box css={styles.actionBarSection}>
			<TestGenerationsActivityLog story={story} />

			<Stack
				direction={propsStyles.formActionsDirection}
				spacing={propsStyles.formActionsSpacing}
			>
				<Choose>
					<When condition={isGenerationRunning || forceAllowAborting}>
						<LoadingButton loading={isAborting} onClick={onAbort}>
							Abort
						</LoadingButton>
					</When>

					<Otherwise>
						<LoadingButton
							loading={isSubmitLoading}
							type="submit"
							disabled={isSaveDisabled}
						>
							Save
						</LoadingButton>
					</Otherwise>
				</Choose>

				<If condition={!!story.id}>
					<LoadingButton disabled={isFormFieldsDisabled} onClick={onDelete}>
						Delete
					</LoadingButton>
				</If>

				<Choose>
					<When condition={isGenerationRunning}>
						<GeneratingButtonLoadingIndicator
							story={story}
							isGenerateLoading={isTriggeringGeneration}
						/>
					</When>

					<Otherwise>
						<If condition={!!story.id}>
							<ConditionalWrapper
								condition={isDirty}
								wrapper={(children) => (
									<Tooltip title="Please save the story first" placement="top">
										<span>{children}</span>
									</Tooltip>
								)}
							>
								<LoadingButton
									loading={isTriggeringGeneration}
									onClick={onGenerate}
									disabled={isGenerateDisabled}
								>
									Generate
								</LoadingButton>
							</ConditionalWrapper>
						</If>
					</Otherwise>
				</Choose>
			</Stack>
		</Box>
	);
};

function GeneratingButtonLoadingIndicator({
	story,
	isGenerateLoading,
}: {
	story: UserStory;
	isGenerateLoading: boolean;
}) {
	const styles = useStoryFormActionsBarStyles();

	return (
		<Box css={styles.centeredContainer}>
			<Choose>
				<When condition={isGenerateLoading}>
					<CircularProgress color="inherit" size={16} />
				</When>
				{/* <Otherwise>
					<GeneratingCircularProgress story={story} />
				</Otherwise> */}
			</Choose>
		</Box>
	);
}
