import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { grey } from '@mui/material/colors';

export const useLabelingFeedbackStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			padding: `${theme.spacing(2)}`,
			border: `1px solid ${grey[300]}`,
			borderRadius: theme.spacing(2),
			marginTop: theme.spacing(2),
		});

		const column = css({
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(1),
		});

		const centered = css({
			display: 'flex',
			justifyContent: 'center',
			gap: theme.spacing(1),
		});

		return {
			container,
			column,
			centered,
		};
	}, [theme]);
};
