import { APISessionRecording } from '@src/modules/session-recordings/api/types';

const date = new Date().toISOString();

export const sessionRecordingsList: APISessionRecording[] = [];

export const sessionRecordingsListWithEvents: APISessionRecording[] = [
	{
		id: '1',
		title: 'whizzimo 1',
		duration: 200000,
		date,
		url: 'https://url.com/link1',
	},
	{
		id: '2',
		title: 'whizzimo 2',
		duration: 300000,
		date,
		url: 'https://url.com/link2',
	},
	{
		id: '3',
		title: 'buzzstream 1',
		duration: 300000,
		date,
		url: 'https://url.com/link3',
	},
	{
		id: '4',
		title: 'buzzstream 2',
		duration: 300000,
		date,
		url: 'https://url.com/link4',
	},
];

export const sessionRecordingsURLs = [
	{
		id: '1',
		recordingUrl:
			'https://storage.googleapis.com/recordings_checksum/whizzimo/2023-10-18/18b447b543be16-0622fa86d037f9-1a525634-13c680-18b447b543c44/snapshots.json?GoogleAccessId=144666174320-compute%40developer.gserviceaccount.com&Expires=1704038950&Signature=YnmPBMoNV2LZDAAD6QMKB4xPTwUthOeM%2BGEGRzD5HbIJa0BPEKKV%2B1zub%2BS0faXyGKkX8Gb9VSRY1hOZ5LmJ%2FH6MBVib%2BHM9fpNMaCK0LNwBJhzj%2FBvYqqSEShd9DPitLKPX2MdhoeEdlrqPQn4cRBSVvkIO118IX1Mvub0zeNHcaWSDZfH5OSfEk2wdwCT335%2Ffzdy%2F5EeDrPB8qEsG2Uuxp2kMADa7yB5lmgJj4sTxktwcFe%2F5x%2FWY%2BsxXKoJRqTC7YO7fzuFrIg%2FeM5BzdM9eeJsFf4JvtW9OQE9U6Z87c7s5GdCJM4bUcOXsgJKvUTk%2BLI%2FmD1OexIcAmS48cw%3D%3D',
	},
	{
		id: '2',
		recordingUrl:
			'https://storage.googleapis.com/recordings_checksum/whizzimo/2023-08-02/0189b38b-ebb8-72ed-8017-7c2b5f0f7d7c/snapshots.json?GoogleAccessId=144666174320-compute%40developer.gserviceaccount.com&Expires=1704038709&Signature=mwxb3XfoV8lpsA85JV%2BHJSOezaQnOhWNZkdBEqfIrEbeR4M%2Fhu8spG0vAyDCisPMTG4S8Ne%2Bq1I2RwvfM8HMEjHDWWz83vXzNHQb5mzf6BvEB1R7ptz4pccNzADbjKxUyS8gnFv4y2IEhDZN1AO3CTILq63GI97c5po8hv7tm4LDA5eITc59Mu8WwWpQwTSHHCp1aVsu%2FwFDgh%2BMGZI3TjEHKzz%2BJB8i3zXHN0%2BgqSP9tUM869Kkumqfoshqk76WkWzAorliE5gDGBWTBvzfC9t43yXNMriaApwyhbMvg%2BDwSgVIzd7oW80vdODBnzfI3oy7hJg%2BeQaDvWDL2m71AA%3D%3D',
	},
	{
		id: '3',
		recordingUrl:
			'https://storage.googleapis.com/recordings_checksum/buzzstream/2023-10-25/018b6473-01d3-7237-9d4d-f32fb05a5805/snapshots.json?GoogleAccessId=144666174320-compute%40developer.gserviceaccount.com&Expires=1704039231&Signature=oGSHMIFCneZQjr38mpAtHoSxoq3cOHUYbPcf0krh%2Bq7Ib%2F8adcXMw4icUFZZMKTls2xC93k2NJfXyueoBxC3GQf1UMrQ3i5JF3vTKc%2B%2Bdz4LsTFf0UGLLorYRTz7S4IPVksdppXArzRxGcgPXtHaXyoK2Sj%2B3PMHRLhhYYIyXw37ExiZt%2BJOX0rtIt4wD5AJFuBYxL4bBdwUqkSU43xa2XK2jQ6TZTwB%2BGseL1rmV8hgb%2B%2BD1u9r9xOUphl1N4AmDp5zlCrnwfTIlOxczPp4dBBOrjRsViXPcwoeFD4RD%2FqnKJqf%2Bq0amdBPkTlheiI6CuBW7SMV0evO5nQRhar2DA%3D%3D',
	},
	{
		id: '4',
		reportUrl:
			'https://storage.googleapis.com/recordings_checksum/buzzstream/2023-11-25/018c03cc-2102-7030-9486-9a2dc7c19b29/snapshots.json?GoogleAccessId=144666174320-compute%40developer.gserviceaccount.com&Expires=1704039181&Signature=fEn8%2F6PrZmN0J%2BExIB%2Fe6PIWod%2BgFo0Uw8%2FmWjufRQ%2FnJ3OpnSVm8hyk%2B%2BKHjiilpCpOditP9I8RcTFnFA%2BzBHf%2F6Uz295zBNynwQyvFLsgH5R077PbEtyxb5RUKxpPYPk%2BPBrDVgkl1JS6q8KrNZamYaHBEzudrZQpoA%2BT4c6Opz0ysuq65tj9ilwT00KxJFm%2BzzDYqhm8Rlm4PJW%2B5ueN2T8277tqPAqyg09UVvtePQS5qmKZbXtXDJRqmuQmqwqAWiohsLTckTziv9gNZSlgFzlfAefLoqH%2FuLyi90QUDR07qBGIjOHYllTMK%2FXq4BTM87QGDeM6MgdebuSVWQw%3D%3D',
	},
];
