import {
	APIAuthenticationResult,
	APIValidateAppNameResponse,
	OauthLoginAPIArgs,
} from '@src/modules/authentication/api/types';
import { applications } from '@src/modules/authentication/api/mock/data';
import { InternalUserPermissions } from '@src/modules/authentication/types';

export const makeLoginAPIResponse = (
	body?: OauthLoginAPIArgs
): APIAuthenticationResult => ({
	code: 'authentication-code',
	user: {
		id: 'user1-id',
		email: 'superuser@email.com',
		internal: true,
		internalPermissions: [
			InternalUserPermissions.TestGenerationLabeling,
			InternalUserPermissions.TestGenerationLabelingReview,
			InternalUserPermissions.AuthorizeTestGenerations,
		],
	},
	applications,
});

export const makeValidateAppNameAPIResponse = ({
	code,
}: {
	code: string;
}): APIValidateAppNameResponse => {
	return {
		validAppId: applications.find(
			({ application }) => code === application.name
		).application.id,
		// TODO: add functionality to get access to internal user
		internalCode: null,
	};
};
