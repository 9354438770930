import { useEffect } from 'react';
import Logger from '@src/services/logger';
import { useAppName } from '@src/common/state/volatile/hooks';

export function useLogger() {
	// const { id } = useUserData();
	const appName = useAppName();

	useEffect(() => {
		Logger.initSessionData({ appName });
	}, [appName]);

	// useEffect(() => {
	// 	Logger.mergeSessionData({
	// 		user: {
	// 			id,
	// 		},
	// 	});
	// }, [id]);
}
