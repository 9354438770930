import React from 'react';
import { Box, Typography } from '@mui/material';
import { CodeBlock } from '@src/common/components/CodeBlock';
import { Link } from 'react-router-dom';
import { useSetupSdkCommandsCodeBlockStyles } from './styles';

export const SetupSdkCommandsCodeBlock = ({ sdkKey }: { sdkKey?: string }) => {
	const styles = useSetupSdkCommandsCodeBlockStyles();

	return (
		//TODO: move border styling to the styles hook when color is added to the palette*/
		<Box css={styles.container}>
			<Typography>
				<strong>Step 1</strong>
			</Typography>

			<Typography>
				Append this script tag to your HTML's {'<head>'} tag
			</Typography>

			<CodeBlock language="javascript">
				{
					'<script src="https://cdn.checksum.ai/checksum-sdk-min-0.2.js"></script>'
				}
			</CodeBlock>

			<Typography css={styles.stepTitle}>
				<strong>Step 2</strong>
			</Typography>

			<Typography>Initialize Checksum after the script is loaded</Typography>

			<CodeBlock language="javascript">
				{`window.checksumai?.init('${sdkKey ?? '<SDK_KEY>'}')`}
			</CodeBlock>

			<Typography variant="subtitle2" css={styles.stepSubTitle}>
				For detailed instructions and more options see{' '}
				<Link to="https://checksum.ai/docs/integrate-checksum-sdk/">our docs</Link>
			</Typography>
		</Box>
	);
};
