import { GetUserStoriesAPIArgs } from '../types';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/service';

export const buildGetUserStoriesQueryString = (args: GetUserStoriesAPIArgs) => {
	const { collectionId } = args;

	if (collectionId === INBOX_COLLECTION_ID) {
		return '';
	}

	const params = new URLSearchParams();
	if (collectionId) {
		params.append('collectionId', collectionId);
	}
	return params.toString();
};
