import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useAuthenticatePage } from './hooks';
import useAthenticatePageStyles from './styles';
import { Box, Button, Typography } from '@mui/material';
import {
	LoginSocialGithub,
	LoginSocialGoogle,
	LoginSocialMicrosoft,
} from 'reactjs-social-login';
import Config from '@src/config';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import GoogleIcon from '@mui/icons-material/Google';
import GitHubIcon from '@mui/icons-material/GitHub';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { AuthenticatePageLayoutContainer } from '../../components/AuthenticatePageLayoutContainer';

const REDIRECT_URI = window.location.href;
const microsoftRedirectUri = REDIRECT_URI.replace('#/', '');

export const AuthenticatePage = () => {
	const {
		register,
		onSubmit,
		errors,
		onLoginStart,
		isLoginLoading,
		onOauthResolve,
		onOauthReject,
		showOauthOnly,
	} = useAuthenticatePage();

	const styles = useAthenticatePageStyles();

	return (
		<AuthenticatePageLayoutContainer isLoading={isLoginLoading}>
			<Choose>
				<When condition={isLoginLoading}>
					<LoadingCircularProgress circularProgressSize={20}>
						Loading...
					</LoadingCircularProgress>
				</When>

				<Otherwise>
					<If condition={!showOauthOnly}>
						<Typography variant="h4" textAlign="center" gutterBottom>
							Sign in to Checksum AI
						</Typography>
					</If>

					<If condition={showOauthOnly}>
						<Typography variant="h4" css={styles.greetingText}>
							We are deprecating app codes, please sign in
						</Typography>
					</If>

					<Box css={styles.buttonsBlock}>
						<LoginSocialGoogle
							isOnlyGetToken
							client_id={Config.authentication.google.clientId}
							onResolve={onOauthResolve}
							onReject={onOauthReject}
							onLoginStart={onLoginStart}
							scope="profile email"
						>
							<Button
								size="large"
								variant="outlined"
								startIcon={<GoogleIcon />}
								fullWidth
							>
								Continue with Google
							</Button>
						</LoginSocialGoogle>

						<LoginSocialGithub
							isOnlyGetCode
							client_id={Config.authentication.github.clientId}
							client_secret={''}
							redirect_uri={REDIRECT_URI}
							onLoginStart={onLoginStart}
							onResolve={onOauthResolve}
							scope="user:email"
							onReject={onOauthReject}
						>
							<Button
								size="large"
								variant="outlined"
								startIcon={<GitHubIcon />}
								fullWidth
							>
								Continue with Github
							</Button>
						</LoginSocialGithub>

						<LoginSocialMicrosoft
							client_id={Config.authentication.microsoft.clientId}
							redirect_uri={microsoftRedirectUri}
							scope="openid user.read email"
							isOnlyGetToken
							onResolve={onOauthResolve}
							// ignore
							onReject={() => {}}
						>
							<Button
								size="large"
								variant="outlined"
								startIcon={<MicrosoftIcon />}
								fullWidth
							>
								Continue with Microsoft
							</Button>
						</LoginSocialMicrosoft>
					</Box>

					<If condition={!showOauthOnly}>
						<form
							className="authenticate-form"
							css={styles.form}
							onSubmit={onSubmit}
							noValidate
						>
							<Typography variant="caption" textAlign="center" gutterBottom>
								Or use your application code
							</Typography>

							<Box css={styles.authenticateWithCodeBlock}>
								<TextField
									placeholder="code"
									type="password"
									autoComplete="code"
									error={!!errors?.code}
									css={[styles.codeInput]}
									{...register('code', {
										required: true,
									})}
									FormHelperTextProps={{
										sx: styles.FormHelperTextPropsSX,
									}}
								/>

								<LoadingButton
									loading={isLoginLoading}
									// variant="primary"
									// color="light"
									type="submit"
								>
									Authenticate
								</LoadingButton>
							</Box>
						</form>
					</If>
				</Otherwise>
			</Choose>
		</AuthenticatePageLayoutContainer>
	);
};
