import { SettingsTabs } from '@src/modules/settings/pages/settings/types';

export const getTabs = (shouldDisplaySdk = true) => {
	// if sdk tab hidden set 0 value to second map item
	let value = shouldDisplaySdk ? 0 : -1;

	return {
		[SettingsTabs.SDK]: value++,
		[SettingsTabs.Environment]: value++,
		[SettingsTabs.GitIntegration]: value++,
		[SettingsTabs.ProjectSettings]: value++,
	};
};
