import {
	useGetLabelingStepTagsQuery,
	useTagTestGenerationLogStepMutation,
} from '@src/modules/test-generation-labeling/api';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TestGenerationLogStep } from '@src/modules/test-generation-labeling/types';

export const useTagsAndFavorite = ({
	activeStoredStep,
}: {
	activeStoredStep: TestGenerationLogStep;
}) => {
	const { sessionId, stepId } = useParams<'sessionId' | 'stepId'>();

	const { data: tagsOptions } = useGetLabelingStepTagsQuery();

	const [tagTestGenerationLogStep] = useTagTestGenerationLogStepMutation();

	// maintain state of tags, initialize from stored entry
	const [tags, setTags] = useState(activeStoredStep?.tags ?? []);
	// update on change
	useEffect(() => {
		setTags(activeStoredStep?.tags ?? []);
	}, [activeStoredStep?.tags]);

	// prepare set callback, set optimistically and call API
	const handleTagsChange = useCallback(
		(e, newTags: string[]) => {
			setTags(newTags);
			tagTestGenerationLogStep({ sessionId, stepId, tags: newTags });
		},
		[sessionId, stepId, tagTestGenerationLogStep]
	);

	// maintain state of favorite, initialize from stored entry
	const [favorite, setFavorite] = useState(activeStoredStep?.favorite ?? false);
	// update on change
	useEffect(() => {
		setFavorite(activeStoredStep?.favorite ?? false);
	}, [activeStoredStep?.favorite]);

	// prepare click callback, set optimistically and call API
	const onFavoriteClick = useCallback(() => {
		setFavorite((prevState) => {
			const newValue = !prevState;
			tagTestGenerationLogStep({ sessionId, stepId, favorite: newValue });
			return newValue;
		});
	}, [sessionId, stepId, tagTestGenerationLogStep]);

	return {
		tags,
		favorite,
		onFavoriteClick,
		tagsOptions,
		handleTagsChange,
	};
};
