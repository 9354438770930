import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useErrorEnabledTextFieldStyle } from '@src/common/styles/useErrorEnabledTextFieldStyle';
import { useMemo } from 'react';

export const useApplicationFormStyles = () => {
	const theme = useTheme();

	const styles = useMemo(() => {
		const formContent = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(8),
		});

		const greetingText = css({
			textAlign: 'center',
		});

		const form = css`
			display: flex;
			flex-flow: column;
			gap: ${theme.spacing(8)};
			margin: auto;
			min-width: 300px;
		`;

		return {
			form,
			greetingText,
			formContent,
		};
	}, [theme]);

	const { FormHelperTextPropsSX, errorEnabledField } =
		useErrorEnabledTextFieldStyle();

	return {
		FormHelperTextPropsSX,
		errorEnabledField,
		...styles,
	};
};
