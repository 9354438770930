import { css } from '@emotion/react';
import { useMemo } from 'react';

export const useMemberTableRowStyles = () => {
	return useMemo(() => {
		const tableRow = css({
			border: 0,
		});

		const tableCell = css({
			border: 0,
			alignContent: 'center',
		});

		const dropdown = css({
			minWidth: 120,
		});

		const roleViewOnlyBox = css({
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minHeight: 45,
		});

		return { tableRow, tableCell, dropdown, roleViewOnlyBox };
	}, []);
};
