import { SerializedStyles, css } from '@emotion/react';
import { useMemo } from 'react';

export const useTestRunsListStyles = ({
	listStyle,
}: {
	listStyle: SerializedStyles;
}) => {
	return useMemo(() => {
		const list = [
			css({
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
			}),
			listStyle,
		];

		return {
			list,
		};
	}, [listStyle]);
};
