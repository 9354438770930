/* eslint-disable  @typescript-eslint/no-explicit-any  */
export const arrayToMap = (
	array: any[],
	key: string,
	{
		valueTransformer = undefined,
		keyTransformer = undefined,
		multipleValues = false,
	}: {
		valueTransformer?: Function;
		multipleValues?: boolean;
		keyTransformer?: Function;
	} = {}
) => {
	return array.reduce((map, value) => {
		const newKey = keyTransformer ? keyTransformer(value[key]) : value[key];
		const newValue = valueTransformer ? valueTransformer(value) : value;
		if (!multipleValues) {
			map[newKey] = valueTransformer ? valueTransformer(value) : value;
		} else {
			if (!map[newKey]) {
				map[newKey] = [];
			}
			map[newKey].push(newValue);
		}
		return map;
	}, {});
};

export const ensureArray = <T>(variable: T | T[]): T[] => {
	if (!Array.isArray(variable)) {
		return [variable];
	}
	return variable;
};

export const isNotEmptyArray = (array: any[]): boolean => {
	return array && !!array.length;
};
