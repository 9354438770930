import {
	Dispatch,
	ForwardedRef,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { usePersistentAppId } from '@src/common/state/persistent/hooks';
import { usePrevious } from 'react-use';
import { useEnvironmentUsers } from '@src/modules/settings/components/ApplicationEnvironmentSettings/hooks/useEnvironmentUsers';
import { useActiveSettingsProfile } from '@src/modules/settings/components/ApplicationEnvironmentSettings/hooks/useActiveSettingsProfile';
import { initValues } from '@src/modules/settings/components/ApplicationEnvironmentSettings/constants';
import { useApplicationEnvironmentSettingsFormActions } from '@src/modules/settings/components/ApplicationEnvironmentSettings/hooks/useApplicationEnvironmentSettingsFormActions/useApplicationEnvironmentSettingsFormActions';

// here change values all time envName changes
export const useApplicationEnvironmentSettings = ({
	submitFormRef,
	setIsFormSubmitting = undefined,
}: {
	submitFormRef: ForwardedRef<unknown>;
	setIsFormSubmitting: Dispatch<SetStateAction<boolean>>;
}) => {
	const persistentApplicationId = usePersistentAppId();
	const prevAppId = usePrevious(persistentApplicationId);

	// manage the displayed settings profile
	const [activeEnvironmentId, setActiveEnvironmentId] = useState<string | null>(
		null
	);

	// get the settings profile according to the profile name
	const { activeEnvironmentSettings, isLoadingSettingsProfile, environments } =
		useActiveSettingsProfile({ activeEnvironmentId });

	const prevActiveEnvironmentId = usePrevious(activeEnvironmentId);

	// form management
	const {
		environmentUsersWatcher,
		onChangeEnvironment,
		onDeleteEnvironment,
		isDirty,
		errors,
		clearErrors,
		reset,
		isSaving,
		register,
		control,
		setValue,
		confirmationModalContent,
		isConfirmationModalOpened,
		onCreateNewEnvironment,
		submitForm,
	} = useApplicationEnvironmentSettingsFormActions({
		setNewEnvironmentId: setActiveEnvironmentId,
		submitFormRef,
		environments,
		activeEnvironmentSettings,
	});

	const {
		handleAddNewEnvUser,
		handleChangeIsEnvUserDefault,
		handleDeleteEnvironmentUser,
		fields,
		getIsUserDefault,
	} = useEnvironmentUsers({
		control,
		environmentUsersWatcher,
		setValue,
		environmentUsersFromResponse: activeEnvironmentSettings?.environmentUsers,
	});

	const isCreatingNewEnvSettings = !activeEnvironmentId;

	const cancelCreateNewEnvironment = useCallback(() => {
		if (activeEnvironmentId === '') {
			const prevEnvId = prevActiveEnvironmentId
				? prevActiveEnvironmentId
				: environments?.find(({ isDefault }) => isDefault)?.id;

			setActiveEnvironmentId(prevEnvId);
		}
	}, [prevActiveEnvironmentId, activeEnvironmentId, environments]);

	// if single environment or current environment is default hide delete button
	const shouldDisplayDeleteButton = useMemo(() => {
		return (
			!isCreatingNewEnvSettings &&
			environments.length > 1 &&
			!activeEnvironmentSettings?.isDefault
		);
	}, [isCreatingNewEnvSettings, environments, activeEnvironmentSettings]);

	// update the isFormSubmitting state when isSaving changes
	useEffect(() => {
		setIsFormSubmitting?.(isSaving);
	}, [setIsFormSubmitting, isSaving]);

	// if application changes, then reset form values
	useEffect(() => {
		if (!!prevAppId && prevAppId !== persistentApplicationId) {
			reset(initValues);
		}
	}, [prevAppId, persistentApplicationId, reset]);

	// if profileName is not set, set it to the profile that was loaded (default profile)
	useEffect(() => {
		if (!activeEnvironmentId && activeEnvironmentSettings) {
			setActiveEnvironmentId(activeEnvironmentSettings.id);
		}
	}, [activeEnvironmentSettings, activeEnvironmentId]);

	return {
		isDirty,
		register,
		control,
		setValue,
		clearErrors,
		isSingleUser: fields.length === 1,
		errors,
		isSaving,
		submitForm,
		isLoadingSettingsProfile,
		fields,
		getIsUserDefault,
		handleAddNewEnvUser,
		handleChangeIsEnvUserDefault,
		handleDeleteEnvironmentUser,
		activeEnvironmentId,
		environments,
		activeEnvironmentSettings,
		onChangeEnvironment,
		isCreatingNewEnvSettings,
		onCreateNewEnvironment,
		onDeleteEnvironment,
		shouldDisplayDeleteButton,
		cancelCreateNewEnvironment,
		confirmationModalContent,
		isConfirmationModalOpened,
	};
};
