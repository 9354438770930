import { http, HttpResponse, RequestHandler, delay } from 'msw';
import { APIBaseURL } from '@src/api/constants';
import {
	REPORT_URL_MOCK,
	getTestRunsMock,
} from '@src/modules/test-runs/api/mock/data';
import { APITestSuiteRun } from '@src/modules/test-runs/api/types';

const BaseURL = `${APIBaseURL}/test-runs`;

export const testRunsMockHandlers: RequestHandler[] = [
	http.get(BaseURL, async ({ request, params, cookies }) => {
		await delay(3000);

		return HttpResponse.json<APITestSuiteRun[]>(getTestRunsMock);
	}),
	http.get<{ id: string }, string>(
		`${BaseURL}/:id/report-url`,
		async ({ request, params, cookies }) => {
			const isValidTestRunResult = !!getTestRunsMock.find(
				({ id }) => id === params.id
			);

			if (!isValidTestRunResult) {
				//error
			}

			await delay(3000);

			return HttpResponse.json(REPORT_URL_MOCK);
		}
	),
];
