import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const centered = css`
			display: flex;
			justify-content: center;
			padding-bottom: ${theme.spacing(10)};
		`;

		const select = css`
			width: 400px;
		`;

		const lookForPRIconNote = css`
			margin-top: 15px;
		`;

		const githubIcon = css`
			vertical-align: sub;
		`;

		const column = css({
			display: 'flex',
			gap: '20px',
			flexDirection: 'column',
			margin: '40px 0',
		});

		return { centered, select, githubIcon, lookForPRIconNote, column };
	}, [theme]);
};
