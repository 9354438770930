import { ApplicationRoutes } from '@src/app/router/routes';
import { prepareModuleRoutes } from '@src/routing';

export const settingsRouter = prepareModuleRoutes<'settings' | 'wizard'>(
	{
		settings: {
			path: '/',
		},
		wizard: {
			path: '/wizard',
		},
	},
	ApplicationRoutes.settings.absolutePath
);
