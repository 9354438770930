import { css, useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useAutoDetectTestFlowsPopUpFormStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const popUpSection = css({
			background: 'white',
			borderRadius: theme.spacing(1),
			width: 600,
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2),
		});

		const buttonsContainer = css({
			display: 'flex',
			gap: theme.spacing(2),
			justifyContent: 'end',
		});

		const spaceBottom = css({
			marginBottom: theme.spacing(4),
		});

		const contentBetween = css({
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		});

		const circularProgress = css({
			// 0.35em same as gutter bottom of typography has
			marginBottom: '0.35em',
		});

		return {
			buttonsContainer,
			popUpSection,
			spaceBottom,
			contentBetween,
			circularProgress,
		};
	}, [theme]);
};
