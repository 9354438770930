import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useStoryStatusBarStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const stack = css({
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
			height: '20px',
		});

		const storyTitle = css({
			color: theme.colors.text.secondary,
		});

		const iconButton = css({
			color: theme.colors.text.primary,
		});

		const authorizeButton = css({
			marginLeft: theme.spacing(1),
		});

		return {
			stack,
			iconButton,
			storyTitle,
			authorizeButton,
		};
	}, [theme]);
};
