import ConsoleLogProvider from '@src/services/logger/providers/ConsoleLogProvider/ConsoleLogProvider';
// import CoralogixLogProvider from './providers/CoralogixLogProvider/CoralogixLogProvider';
import LogProviderInterface, {
	LogProviderOptions,
} from '@src/services/logger/providers/LogProviderInterface';

export const ActiveLocalLogProvider: new (
	component: string,
	options: LogProviderOptions
) => LogProviderInterface = ConsoleLogProvider;

export const ActiveRemoteLogProvider: new (
	component: string,
	options: LogProviderOptions
) => LogProviderInterface = undefined; // CoralogixLogProvider;
