import { UserStoriesCollection } from '@src/modules/collections/service';

//helpers for useCollections hook

export const getCollectionById = (
	collections: UserStoriesCollection[],
	id?: string
): UserStoriesCollection => {
	return id
		? collections?.find((collection) => collection.id === id)
		: undefined;
};

const getFirstCollection = (
	collections: UserStoriesCollection[]
): UserStoriesCollection | null => {
	return collections && collections.length > 0 ? collections[0] : null;
};

export const getInitialActiveCollectionId = (
	collections: UserStoriesCollection[],
	id?: string
): string => {
	return (getCollectionById(collections, id) ?? getFirstCollection(collections))
		?.id;
};
