import { useState, useCallback, ChangeEvent, useEffect } from 'react';

export const useSearchBar = (onChange) => {
	const [searchTerm, setSearchTerm] = useState('');
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value);
		},
		[setSearchTerm]
	);
	const handleClear = useCallback(() => {
		setSearchTerm('');
	}, []);
	useEffect(() => {
		onChange(searchTerm);
	}, [onChange, searchTerm]);
	return {
		searchTerm,
		handleChange,
		handleClear,
	};
};
