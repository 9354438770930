import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { UserStoryStatus } from '../../../types';
import { useUpdateOrCreateStory } from './useUpdateOrCreateStory';
import { FormValues } from '../types';
import { useGenerateUserStory } from './useGenerateUserStory';
import { useYupValidationResolver } from '@src/common/hooks/useYupValidationResolver';
import { storyFormValidationSchema } from '@src/modules/user-stories/components/StoryForm/validation';
import useToggle from '@src/common/hooks/useToggle';
import { useDeleteUserStoryMutation } from '@src/modules/user-stories/api';
import { useShowConfirmationAlertDialog } from '@src/modules/user-stories/components/StoryForm/hooks/useShowConfirmationAlertDialog';
import { useGetSettingsStatus } from '@src/modules/settings/service';
import { usePrevious } from 'react-use';
import { usePageSelectionWidth } from './usePageSelectionWidth';
import { getStoryFormInitialValues } from '@src/modules/user-stories/components/StoryForm/helpers/getStoryFormInitialValues';
import { useEnvironmentsSettings } from '@src/modules/user-stories/components/StoryForm/hooks/useEnvironmentsSettings';
import { StoryFormProps } from '@src/modules/user-stories/components/StoryForm/StoryForm';
export const useStoryForm = ({
	story,
	onCreateSuccess,
	setCloseStoryModal,
	handleCloseStory,
	onAddNewCollection,
	collections,
}: StoryFormProps) => {
	const resolver = useYupValidationResolver(storyFormValidationSchema);

	const { environmentsSettings } = useEnvironmentsSettings();

	// utilize react hook form
	const {
		register,
		handleSubmit,
		formState: {
			errors,
			isDirty,
			dirtyFields,
			isSubmitted,
			isSubmitSuccessful,
			isSubmitting: isSubmitLoading,
			isValid: isStoryFormValid,
			submitCount,
		},
		trigger: triggerFieldValidation,
		control,
		setValue,
		clearErrors,
		getValues,
		watch,
		reset,
		setError,
	} = useForm<FormValues>({
		values: getStoryFormInitialValues(story, environmentsSettings),
		reValidateMode: 'onChange',
		resolver,
	});

	//reset isDirty exact after submit successful
	useEffect(() => {
		if (isSubmitSuccessful && isSubmitted) {
			// reset makes all fields as undefined and after that reinitialize with new values happen
			reset(getValues(), {
				keepValues: true,
			});
		}
	}, [isSubmitted, isSubmitSuccessful, reset, getValues]);

	const { settingsStatus, isLoading: isSettingsStatusLoading } =
		useGetSettingsStatus();

	const {
		// isGenerationTriggered,
		isTriggeringGeneration,
		onGenerate,
		isAborting,
		onAbort,
		// isAborted,
	} = useGenerateUserStory({
		story,
	});

	// in case story has no id, we assume it's a new story creation
	const isNewStoryCreation = useMemo(() => !story.id, [story.id]);

	// prepare update and create functionality
	const {
		onSubmit,
		// hasError: hasSubmitError,
		//isLoading: isSubmitLoading,
	} = useUpdateOrCreateStory({
		story,
		isNewStoryCreation,
		onCreateSuccess,
		dirtyFields,
		handleSubmit,
	});

	const [deleteStory] = useDeleteUserStoryMutation();

	const onDelete = useCallback(() => {
		deleteStory(story.id);
	}, [story, deleteStory]);

	const {
		confirmationAlertDialogContent,
		handleDeleteStory,
		handleAbortStoryTestGeneration,
		handleGenerateTest,
	} = useShowConfirmationAlertDialog({
		handleCloseStory,
		setCloseStoryModal,
		isDirty,
		onDelete,
		onAbort,
		onGenerate,
		settingsStatus,
	});

	const originalStartURLRef = useRef<string>(story.startURL ?? '');

	const handleOpenAddNewPageFormModal = useCallback(() => {
		isAddNewPageModalWasOpenedRef.current = true;
		onAddNewCollection();
	}, [onAddNewCollection]);

	//toggles for showPassword as text and show user data checkbox
	const { isOn: showPassword, toggle: toggleShowPassword } = useToggle();
	const { isOn: showUserFields, toggle: toggleShowUserFields } = useToggle(
		!!getValues().username
	);

	const collectionFormValue = watch('collectionId');
	const userStoriesFilesWatcher = watch('userStoryFiles');

	// get the startURLs for the selected page in order to
	// populate the autocomplete field
	const pageStartURLs = useMemo(() => {
		return (
			collections
				?.find(({ id }) => id === collectionFormValue)
				?.storiesStatistics.startURLs.map((data) => data.url) ?? []
		);
	}, [collections, collectionFormValue]);

	//set newly added page as a value to the page autocomplete input
	const isAddNewPageModalWasOpenedRef = useRef(false);
	const previousPagesLength = usePrevious(collections?.length);

	useEffect(() => {
		if (!collections) {
			return;
		}

		//update page value if new page was added while the story modal was opened
		if (
			isAddNewPageModalWasOpenedRef.current &&
			collections.length - previousPagesLength === 1
		) {
			setValue('collectionId', collections.last().id);
			isAddNewPageModalWasOpenedRef.current = false;
		}
	}, [collections, previousPagesLength, setValue]);

	const isGenerationRunning = useMemo(() => {
		return [UserStoryStatus.Queued, UserStoryStatus.Running].includes(
			story.status
		);
	}, [story]);

	const isFormFieldsDisabled = useMemo(() => {
		return isGenerationRunning || story.status === UserStoryStatus.Verifying;
	}, [story, isGenerationRunning]);

	const { formRef, formWidth } = usePageSelectionWidth();

	const shouldDisplayEnvironments = useMemo(() => {
		return (
			environmentsSettings.length > 1 ||
			environmentsSettings.at(0)?.environmentUsers?.length > 1
		);
	}, [environmentsSettings]);

	const collection = useMemo(() => {
		return collections.find(({ id }) => id === story.collectionId);
	}, [collections, story?.collectionId]);

	return {
		setValue,
		control,
		register,
		onSubmit,
		errors,
		isSubmitLoading,
		isDirty,
		clearErrors,
		pageStartURLs,
		showPassword,
		showUserFields,
		toggleShowPassword,
		toggleShowUserFields,
		isNewStoryCreation,
		isTriggeringGeneration,
		isAborting,
		isGenerationRunning,
		handleGenerateTest,
		handleDeleteStory,
		handleAbortStoryTestGeneration,
		confirmationAlertDialogContent,
		isSettingsStatusLoading,
		originalStartURL: originalStartURLRef.current,
		handleOpenAddNewPageFormModal,
		isFormFieldsDisabled,
		formRef,
		formWidth,
		isStoryFormValid,
		collection,
		settingsStatus,
		environmentsSettings,
		shouldDisplayEnvironments,
		userStoriesFilesWatcher,
		triggerFieldValidation,
		submitCount,
		setError,
	};
};
