import { SerializedStyles, css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const usePageListStyles = ({
	listStyle,
}: {
	listStyle: SerializedStyles;
}) => {
	const theme = useTheme();
	return useMemo(() => {
		const list = [
			css({
				border: `1px solid ${theme.colors.borderColor.main}`,
			}),
			listStyle,
		];

		return {
			list,
		};
	}, [theme, listStyle]);
};
