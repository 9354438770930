import { useCollections } from './useCollections';
import { useStories } from './useStories';
import { useMobileExploreUserStoriesCollection } from '@src/modules/user-stories-exploration/pages/explore-user-stories/hooks/useMobileExploreUserStoriesCollection';
import { useGetEnvironmentUsersQuery } from '@src/modules/settings/service';
import { useShouldDisplay } from './useShouldDisplay';

export const useExploreUserStoriesPage = () => {
	// add the environment users data to query cache
	useGetEnvironmentUsersQuery();

	const {
		collections,
		activeCollectionId,
		setActiveSearchTerm: setCollectionSearchTerm,
		isCollectionModalOpened,
		collectionIdForUpdate,
		closeCollectionModal,
		openExistingCollectionModal,
		isErrorLoadingCollections,
		filteredCollections,
		openNewCollectionModal,
		isLoadingCollections,
		hasFilteredOutCollections,
		openedCollectionModalMode,
		afterAddNewCollectionModalCloseCallback,
		setAfterAddNewCollectionModalCloseCallback,
		inboxBadgesData,
		activeCollection,
		isAllFlowsCollectionActive,
		isInboxCollectionActive,
	} = useCollections();

	const {
		storiesForActiveCollection,
		isLoadingStories,
		isErrorLoadingStories,
		activeFilterValue,
		setActiveFilterValue: setStoryFilterValue,
		setActiveSearchTerm: setStorySearchTerm,
		hasFilteredOutStories,
		activeStory,
		handleCloseStory,
		handleCreateNewStory,
		handleNewStoryCreatedSuccessfully,
		isFetchingNewStories,
		storiesPaginationProps,
		filteredStories,
		filteredStoriesPerPage,
	} = useStories({
		activeCollection,
		userStoriesBadgesInfo: inboxBadgesData.userStoriesBadgesInfo,
	});

	const { isMobile, displayCollections, displayUserStories } =
		useMobileExploreUserStoriesCollection();

	const { shouldDisplayAutoDetectButton } = useShouldDisplay({
		filteredStories,
		isAllFlowsCollectionActive,
		isFetchingNewStories,
		isInboxCollectionActive,
	});

	return {
		loading: isLoadingCollections || isLoadingStories,
		error: isErrorLoadingCollections || isErrorLoadingStories,
		isFetchingNewStories,
		collections,
		activeCollectionId,
		storiesForActiveCollection,
		activeFilterValue,
		setStoryFilterValue,
		setStorySearchTerm,
		filteredStories,
		hasFilteredOutStories,
		activeStory,
		handleCloseStory,
		handleCreateNewStory,
		handleNewStoryCreatedSuccessfully,
		setCollectionSearchTerm,
		filteredCollections,
		hasFilteredOutCollections,
		isMobile,
		displayCollections,
		displayUserStories,
		isCollectionModalOpened,
		collectionIdForUpdate,
		openNewCollectionModal,
		closeCollectionModal,
		openExistingCollectionModal,
		openedCollectionModalMode,
		afterAddNewCollectionModalCloseCallback,
		setAfterAddNewCollectionModalCloseCallback,
		inboxBadgesData,
		storiesPaginationProps,
		isAllFlowsCollectionActive,
		filteredStoriesPerPage,
		shouldDisplayAutoDetectButton,
	};
};
