import {
	SECONDS_PER_MINUTE,
	MINUTES_PER_HOUR,
} from '@src/common/constants/time';
/**
 * Transforms date string to timestamp
 */
export const dateStringToTimestamp = (date: string) => new Date(date).getTime();

/**
 * Returns time from now, past or future, according to +/- of days, months, years parameters
 * @param days
 * @param months
 * @param years
 * @param timestamp
 */
export const getDateFromNow = (
	{ days = 0, months = 0, years = 0 } = {},
	timestamp = false
) => {
	const date = new Date();
	date.setFullYear(date.getFullYear() + years);
	date.setMonth(date.getMonth() + months);
	date.setDate(date.getDate() + days);
	if (timestamp) {
		return date.getTime();
	}
	return date;
};

export const formatMinutesAsHoursAndMinutes = (minutes) => {
	const h = Math.floor(minutes / MINUTES_PER_HOUR);
	const m = minutes % SECONDS_PER_MINUTE;
	return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
};

export const formatSecondsAsHoursMinutesAndSeconds = (
	seconds,
	needsHours = false
) => {
	seconds = Math.floor(seconds);
	let m = Math.floor(seconds / SECONDS_PER_MINUTE);
	const s = seconds % SECONDS_PER_MINUTE;
	let h = Math.floor(m / MINUTES_PER_HOUR);
	if (h > 0) {
		m = m % MINUTES_PER_HOUR;
	}
	let str = '';
	if (h > 0 || needsHours) {
		str += (h < 10 ? '0' + h : h) + ':';
	}
	str += (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);

	return str;
};

// converts HTML time input to seconds
export const formatTimeInputAsSeconds = (time) => {
	const splitTime = time.split(':');
	const hasHours = splitTime.length === 3;
	let totalSeconds = 0;

	if (hasHours) {
		totalSeconds =
			splitTime[0] * MINUTES_PER_HOUR * SECONDS_PER_MINUTE +
			splitTime[1] * SECONDS_PER_MINUTE +
			splitTime[2];
	} else {
		totalSeconds = splitTime[0] * SECONDS_PER_MINUTE + splitTime[1];
	}

	return +totalSeconds;
};

export const formatMsToFullTime = (time) => {
	const fixedNumber = parseFloat(time).toFixed(3);
	const timeWithoutMS = formatSecondsAsHoursMinutesAndSeconds(
		fixedNumber,
		true
	);
	const timeSplit = fixedNumber.split('.');

	return timeWithoutMS + '.' + timeSplit[1];
};

function formatDateAsLocaleStringWithoutSeconds(date: Date) {
	// Convert to locale string and remove seconds
	let dateString = date.toLocaleDateString(undefined, {
		// remove year unless not the current year
		year:
			date.getFullYear() !== new Date().getFullYear() ? 'numeric' : undefined,
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: undefined,
	});
	// Using a regular expression to remove the seconds part
	// dateString = dateString.replace(/:\d{2}(?=\s*([AP]M)?\b)/, '');

	return dateString;
}

// export const formatDateAsLocaleStringWithoutSeconds = (date: Date) => {
// 	const locale = date.toLocaleString();

// 	const [dateComma, time] = locale.split(' ');
// 	const [hours, minutes] = time.split(':');

// 	const [hoursFormatted, minutesFormatted, amPm] = formatHoursAndMinutesToAMPM(
// 		hours,
// 		minutes
// 	);

// 	return `${dateComma} ${hoursFormatted}:${minutesFormatted} ${amPm}`;
// };

// remove eslint-disable-next-line after function is in use
/* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
const formatHoursAndMinutesToAMPM = (hours: string, minutes: string) => {
	let hoursAsNumber = Number(hours);
	let minutesAsNumber = Number(minutes);

	let ampm = hoursAsNumber >= 12 ? 'PM' : 'AM';
	hoursAsNumber = hoursAsNumber % 12;

	const hoursResult = hoursAsNumber ? hoursAsNumber : 12; // the hour '0' should be '12'
	const minutesResult =
		minutesAsNumber < 10 ? '0' + minutesAsNumber : minutesAsNumber;

	return [hoursResult, minutesResult, ampm];
};

export const formatTimestampAsLocaleStringWithoutSeconds = (
	timestamp: string
) => {
	return formatDateAsLocaleStringWithoutSeconds(new Date(timestamp));
};

// converts ms to `[number] sec` or `[number] min`
export const formatMsToMinutes = (ms: number): string => {
	const seconds = Math.floor(ms / 1000);

	if (seconds < 60) {
		return `${seconds} sec`;
	}

	const minutes = Math.floor(seconds / 60);

	return `${parseFloat(minutes.toFixed(0))} min`;
};

// formats iso string date to yyyy-mm-dd hh:mm:s
export const formatIsoStringToDate = (isoString: string): string => {
	const date = new Date(isoString);
	return date.toISOString().replace('T', ' ').substring(0, 19);
};
