import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { generateTestGenerationStepsHrefs } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/helpers';
import { TestGenerationLogStep } from '@src/modules/test-generation-labeling/types';

export const useTestGenerationLabelingStepsNavigation = ({
	entries,
	activeTestGenerationLogStep,
}: {
	entries?: TestGenerationLogStep[];
	activeTestGenerationLogStep?: TestGenerationLogStep;
}) => {
	const { sessionId, stepId } = useParams<'sessionId' | 'stepId'>();

	//link hrefs to next or prev step
	const { nextHref, prevHref } = useMemo(() => {
		if (!sessionId || !entries || !activeTestGenerationLogStep) {
			return {
				nextHref: null,
				prevHref: null,
			};
		}

		return generateTestGenerationStepsHrefs(entries, sessionId, stepId);
	}, [sessionId, stepId, entries, activeTestGenerationLogStep]);

	return {
		nextHref,
		prevHref,
		activeTestGenerationLogStep,
	};
};
