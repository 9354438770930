import { SerializedStyles } from '@emotion/react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete'; // Import RefreshIcon
import RefreshIcon from '@mui/icons-material/Refresh'; // Import RefreshIcon
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import TooltipNonInteractive from '@src/common/components/TooltipNonInteractive';
import { useCopyToClipboard } from '@src/common/hooks/useCopyToClipboard';
import { useState } from 'react';
import { ManagedUser } from '../types';
import { useUsersTableRow } from './hooks';
import { useUsersTableRowStyles } from './styles';

export const UsersTableRow = ({
	user,
	tableRowStyles,
	cb,
}: {
	user: ManagedUser;
	tableRowStyles: SerializedStyles;
	cb: (message: string) => void;
}) => {
	const [managedUser, setUser] = useState<ManagedUser>(user);
	const { refreshUser } = useUsersTableRow({
		user: managedUser,
	});
	const { handleCopy, copyStatus } = useCopyToClipboard(managedUser.token);

	const handleCopyClick = async () => {
		cb(`User ${user.email} token was copied`);
		handleCopy();
	};
	const handleDelete = async () => {
		// await deleteUser({ email: user.email });
	};

	const handleRefresh = async () => {
		const user = await refreshUser({ email: managedUser.email }).unwrap();
		setUser(user);
		cb(`User ${user.email} was refreshed`);
	};
	const styles = useUsersTableRowStyles();

	return (
		<TableRow css={tableRowStyles}>
			<TableCell component="th" scope="row" css={styles.tableCell}>
				{managedUser.email}
			</TableCell>

			<TableCell component="th" scope="row" css={styles.tableCell}>
				<Typography
					sx={{
						wordWrap: 'break-word',
						':hover': {
							cursor: 'pointer',
						},
					}}
					onClick={handleCopyClick}
				>
					{managedUser.token.length > 50
						? `...${managedUser.token.slice(-50)}`
						: managedUser.token}
				</Typography>
			</TableCell>

			<TableCell
				component="th"
				scope="row"
				sx={{ userSelect: 'none' }}
				align="right"
				css={styles.tableCell}
			>
				<TooltipNonInteractive title={copyStatus || 'Copy Token'}>
					<IconButton
						onClick={handleCopyClick}
						size="small"
						className="copy-button"
					>
						<ContentCopyIcon fontSize="inherit" />
					</IconButton>
				</TooltipNonInteractive>

				<TooltipNonInteractive title="Invalidate & Reset Token">
					<IconButton
						onClick={handleRefresh}
						size="small"
						className="refresh-button"
					>
						<RefreshIcon fontSize="inherit" />
					</IconButton>
				</TooltipNonInteractive>
				<TooltipNonInteractive title="Delete user">
					<IconButton
						disabled
						onClick={handleDelete}
						size="small"
						className="delete-button"
					>
						<DeleteIcon fontSize="inherit" />
					</IconButton>
				</TooltipNonInteractive>
			</TableCell>
		</TableRow>
	);
};
