export enum OpenedConfirmationModalVariant {
	UNSAVED_CHANGES = 'unsaved-changes',
	DELETE = 'delete',
	DEFAULT = 'default',
}

export const CONFIRMATION_ALERTS_CONTENTS = {
	[OpenedConfirmationModalVariant.DEFAULT]: null,
	[OpenedConfirmationModalVariant.DELETE]: {
		title: 'This collection will be deleted',
		description: 'Are you sure you want to delete this collection?',
		buttons: [{ text: 'Return to collection' }, { text: 'Yes, delete' }],
	},
	[OpenedConfirmationModalVariant.UNSAVED_CHANGES]: {
		title: 'Your changes are not saved',
		description:
			"You have made changes to the collection that haven't been saved yet. If you leave now, your changes will be lost.",
		buttons: [
			{ text: 'Return to collection' },
			{ text: 'Continue without saving' },
		],
	},
};

export const INBOX_COLLECTION_ID = 'inbox';
export const ALL_FLOWS_COLLECTION_ID = 'all-flows';
