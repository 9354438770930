import { css } from '@emotion/react';
import { useMemo } from 'react';

export const useDefaultLayoutStyles = () => {
	return useMemo(() => {
		const root = css({ display: 'flex', height: '100%' });

		const main = css({
			paddingTop: 0,
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		});

		return {
			root,
			main,
		};
	}, []);
};
