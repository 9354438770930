import { useAutoDetectTestFlowsPopUpForm } from './hooks/useAutoDetectTestFlowsPopUpForm';
// import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ConfirmationDialog } from '@src/common/components/ConfirmationDialog/ConfirmationDialog';
import { LoadingButton } from '@mui/lab';
import { useAutoDetectTestFlowsPopUpFormStyles } from './styles';
import { StyledMenu } from '@src/common/components/StyledMenu/StyledMenu';

export type AutoDetectTestFlowsPopUpFormProps = {
	anchor: null | HTMLElement;
	onClose: () => void;
	onAutoDetectFlows: (body: {
		activated: boolean;
		description?: string;
	}) => void;
	isFlowAutoDetectionActivated: boolean;
	autoDetectFlowsDescription: string;
	isLoading: boolean;
};

export const AutoDetectTestFlowsPopUpForm = (
	props: AutoDetectTestFlowsPopUpFormProps
) => {
	const { anchor, isFlowAutoDetectionActivated, isLoading, onClose } = props;

	const {
		register,
		errors,
		onSubmit,
		isSaveButtonDisabled,
		onStopDetecting,
		confirmationModalContent,
		saveButtonText,
		open,
		handleBackdropClick,
		handleTextAreaKeyDown,
	} = useAutoDetectTestFlowsPopUpForm(props);

	const styles = useAutoDetectTestFlowsPopUpFormStyles();

	return (
		<>
			<StyledMenu
				anchorEl={anchor}
				open={open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<form onSubmit={onSubmit}>
					<ClickAwayListener onClickAway={handleBackdropClick}>
						<Box onClick={(e) => e.stopPropagation()} css={styles.popUpSection}>
							<Box css={styles.contentBetween}>
								<Typography variant="h4" gutterBottom>
									Flows auto-detection instructions
								</Typography>

								<If condition={isFlowAutoDetectionActivated}>
									<CircularProgress size={16} css={styles.circularProgress} />
								</If>
							</Box>

							<Typography
								variant="caption"
								css={styles.spaceBottom}
								gutterBottom
							>
								Write a few sentences about the flows you would like Checksum to
								detect. For example, specific selections, which
								features/sections should we focus on, happy paths, edge cases,
								how many flows, etc.
							</Typography>

							<FormTextField
								onKeyDown={handleTextAreaKeyDown}
								name="description"
								register={register}
								placeholder="I would like to detect flows that..."
								// label="Auto-detect flows description"
								multiline
								rows={3}
								maxRows={5}
								errors={errors}
								hasErrorMessage
								css={styles.spaceBottom}
							/>

							<Box css={styles.buttonsContainer}>
								<Button variant="text" onClick={onClose}>
									Cancel
								</Button>

								<If condition={isFlowAutoDetectionActivated}>
									<LoadingButton
										loading={isLoading}
										variant="text"
										onClick={onStopDetecting}
									>
										Stop
									</LoadingButton>
								</If>

								<LoadingButton
									variant="contained"
									type="submit"
									loading={isLoading}
									disabled={isSaveButtonDisabled}
								>
									{saveButtonText}
								</LoadingButton>
							</Box>
						</Box>
					</ClickAwayListener>
				</form>
			</StyledMenu>
			<ConfirmationDialog {...confirmationModalContent} />
		</>
	);
};
