import {
	UserStory,
	UserStoryOrigin,
	UserStoryStatus,
} from '@src/modules/user-stories/service';
import { InboxCollectionAndStoriesBadgesData } from '@src/modules/collections/hooks/useInboxCollectionAndStories/types';
import { MIN_DATE_ISO_STRING } from '@src/common/constants/time';

export const generateUserStoriesBadgesInfoAndInboxCollectionStatistics = (
	userStories: UserStory[]
) => {
	return userStories.reduce(
		(acc, story: UserStory) => {
			// get engagement statuses for added/identified, failed, completed stories

			const isStoryNewForInbox = getIsNewForInbox(story);

			if (story.completedRecentTestGenerationStoryStatus) {
				// set statistics for inbox stories
				acc.inboxCollectionInfo[
					story.completedRecentTestGenerationStoryStatus
				]++;
			}

			// set engagementStatus to for each story that have unseen engagement
			if (isStoryNewForInbox) {
				acc.userStoriesBadgesInfo[story.id] = true;
				acc.inboxCollectionInfo.withBadgeCount++;
			}
			acc.inboxCollectionInfo.count++;

			return acc;
		},
		// do not use the default state object in case of data duplication
		// reduce changes the default state object
		makeDefaultBadgesState()
	);
};

export const getActualUserStories = (
	prevStories: UserStory[],
	pollingStories: UserStory[],
	ignoreLastActiveAt = false
): UserStory[] => {
	if (!ignoreLastActiveAt) {
		return pollingStories;
	}

	// get all stories
	return pollingStories.map((actual) => {
		const prevStory = prevStories.find((prev) => prev.id === actual.id);

		return {
			...actual,
			engagement: {
				clearedAt: actual?.engagement?.clearedAt ?? null,
				lastSeenAt:
					prevStory?.engagement?.lastSeenAt ??
					actual?.engagement?.lastSeenAt ??
					MIN_DATE_ISO_STRING,
			},
		};
	});
};

export const makeDefaultBadgesState =
	(): InboxCollectionAndStoriesBadgesData => ({
		userStoriesBadgesInfo: {},
		inboxCollectionInfo: {
			count: 0,
			withBadgeCount: 0,
			[UserStoryStatus.Idle]: 0,
			[UserStoryStatus.Failed]: 0,
			[UserStoryStatus.Completed]: 0,
		},
	});

const getIsNewForInbox = (userStory: UserStory): boolean => {
	// if user story is added by user do not mark it with badge
	if (
		userStory.completedRecentTestGenerationStoryStatus ===
			UserStoryStatus.Idle &&
		userStory.origin === UserStoryOrigin.USER &&
		userStory.engagement?.lastSeenAt > userStory.createdAt
	) {
		return false;
	}

	// if story does not have engagement -> show last activity status
	if (!userStory?.engagement || !userStory.engagement?.lastSeenAt) {
		return true;
	}

	// do not show badge if user saw this user story already
	return userStory.engagement.lastSeenAt <= userStory.recentActivityDate;
};
