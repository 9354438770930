import { useGetAllApplicationsForInternalUserQuery } from '@src/modules/application/service';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

export const useAllApplicationsPage = () => {
	const { data: applications, isLoading } =
		useGetAllApplicationsForInternalUserQuery(undefined);

	const [searchTerm, setSearchTerm] = useState('');

	const filteredApplications = useMemo(() => {
		if (!applications) {
			return [];
		}

		return applications.filter(({ name }) => {
			return name.toLowerCase().includes(searchTerm.toLowerCase());
		});
	}, [applications, searchTerm]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value);
		},
		[setSearchTerm]
	);

	return {
		searchTerm,
		handleChange,
		applications: filteredApplications,
		isLoading,
	};
};
