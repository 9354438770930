import {
	Drawer,
	Toolbar,
	Box,
	List,
	ListItemButton,
	ListItemIcon,
	Tooltip,
} from '@mui/material';

import { Link } from 'react-router-dom';
import { DefaultSideNavigationMenuOptions } from '../DefaultLayout/types';
import { TopDrawerButtons, DrawerButtons } from './constants';
import useSideNavigationMenu from './hooks';
import { useDrawerButtonStyles, useSideNavigationMenuStyles } from './styles';

export function SideNavigationMenu({
	nop,
}: Partial<DefaultSideNavigationMenuOptions>) {
	const getIsSelectedNavItem = useSideNavigationMenu();
	const styles = useSideNavigationMenuStyles();

	return (
		<Drawer variant="permanent" css={styles.drawer}>
			<Toolbar variant="dense" /> {/* to offset the height of the AppBar */}
			<Box css={styles.listContainer}>
				<List css={styles.list}>
					{DrawerButtons.map(({ icon: Icon, text, path, hide }, index) => {
						return (
							<If condition={!hide}>
								<DrawerButton
									key={path}
									Icon={Icon}
									text={text}
									path={path}
									index={index}
									selected={getIsSelectedNavItem(path)}
									isFirstBottomButton={index === TopDrawerButtons.length}
								/>
							</If>
						);
					})}
				</List>
			</Box>
		</Drawer>
	);
}

function DrawerButton({
	Icon,
	text,
	path,
	index,
	selected,
	isFirstBottomButton,
}) {
	const styles = useDrawerButtonStyles({ isFirstBottomButton });
	return (
		<Link key={index} to={path} css={styles.link}>
			<Tooltip title={text} placement="right">
				<ListItemButton
					selected={selected}
					css={styles.listItemButton}
					// href={path}
					key={index}
				>
					<ListItemIcon css={styles.listItemIcon}>
						<Icon />
					</ListItemIcon>
				</ListItemButton>
			</Tooltip>
		</Link>
	);
}
