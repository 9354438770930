import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const InstalledWithEmptyRepositoriesScreen = ({
	installationURL,
	handleRedirect,
}: {
	installationURL: string;
	handleRedirect: () => void;
}) => {
	return (
		<>
			<Typography>
				You haven't authorized access to your project's repository.
			</Typography>

			<Typography>
				Please follow this link to complete the integration with Checksum's
				Github Application.
			</Typography>

			<br />

			<Typography>
				<Link onClick={handleRedirect} to={installationURL} target="_blank">
					{installationURL}
				</Link>
			</Typography>
		</>
	);
};
