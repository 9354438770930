import { TestGenerationStatus } from '@src/modules/test-generations/service';
import { UserStoryStatus } from '@src/modules/user-stories/service';

export const getCollectionFlowsInfo = (
	{ inboxCollectionInfo, collectionStoriesStatistics },
	isInboxActive = false
) => {
	if (isInboxActive) {
		return {
			subtitle: generateSubtitleForInbox(inboxCollectionInfo),
			countFormatted: formatCount(inboxCollectionInfo.count),
		};
	} else {
		return {
			subtitle: generateSubtitleForCollection(collectionStoriesStatistics),
			countFormatted: formatCount(collectionStoriesStatistics.count),
		};
	}
};

const formatCount = (count: number) => (count > 0 ? `(${count})` : '');

const getCompletedAndFailedCount = (
	statusCount: Record<TestGenerationStatus, number>
) => {
	return {
		completedCount:
			statusCount[TestGenerationStatus.Completed] +
			statusCount[TestGenerationStatus.PRMerged],
		failedCount: statusCount[TestGenerationStatus.Failed],
	};
};

const generateSubtitleForCollection = (collectionStatistics) => {
	const messageParts = [];

	const { completedCount, failedCount } = getCompletedAndFailedCount(
		collectionStatistics.statusCount
	);

	if (completedCount > 0) {
		messageParts.push(`${completedCount} generated`);
	}

	if (failedCount > 0) {
		messageParts.push(`${failedCount} failed`);
	}

	return messageParts.join(', ');
};

const generateSubtitleForInbox = (inboxStatistics) => {
	if (Object.values(inboxStatistics).every((value) => value === 0)) {
		return '';
	}

	const messageParts = [];

	if (inboxStatistics[UserStoryStatus.Idle] > 0) {
		messageParts.push(`generate (${inboxStatistics[UserStoryStatus.Idle]})`);
	}

	if (inboxStatistics[UserStoryStatus.Failed] > 0) {
		messageParts.push(`fix (${inboxStatistics[UserStoryStatus.Failed]})`);
	}

	if (inboxStatistics[UserStoryStatus.Completed] > 0) {
		messageParts.push(`review (${inboxStatistics[UserStoryStatus.Completed]})`);
	}

	return messageParts.join(', ');
};
