import { useApplicationForm } from './hooks';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useApplicationFormStyles } from './styles';
import { FormTextField } from '@src/common/components/FormTextField';
import { titleByModeMap } from './constants';
import { AddNewAppModalMode } from './types';

export const ApplicationForm = ({
	afterCreateApplicationCallback,
	mode = AddNewAppModalMode.AddApplication,
}: {
	afterCreateApplicationCallback: () => Promise<void>;
	mode: AddNewAppModalMode;
}) => {
	const { register, errors, isCreateLoading, onSubmit } = useApplicationForm({
		afterCreateApplicationCallback,
	});

	const styles = useApplicationFormStyles();

	return (
		<form css={styles.form} onSubmit={onSubmit}>
			<Typography variant="h4" css={styles.greetingText}>
				{titleByModeMap[mode]}
			</Typography>

			<Box css={styles.formContent}>
				<FormTextField
					name="appName"
					placeholder="Application name"
					errors={errors}
					register={register}
					info="A globally unique application identifier"
					label="Application Name"
					hasErrorMessage
				/>

				<LoadingButton
					loading={isCreateLoading}
					variant="contained"
					// color="light"
					type="submit"
				>
					Create
				</LoadingButton>
			</Box>
		</form>
	);
};
