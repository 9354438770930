import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistentSliceState } from './types';
import { AuthenticationResult } from '@src/modules/authentication/service';

const initialState: PersistentSliceState = {
	authCode: undefined,
	appId: undefined,
	userPreferences: {
		doNotShowModalToOpenPrReviewInSeparateWindow: false,
	},
};

export const slice = createSlice({
	name: 'persistent',
	initialState,
	reducers: {
		setPersistentAuthenticationData: (
			state,
			action: PayloadAction<AuthenticationResult>
		) => {
			const { authCode } = action.payload;
			state.authCode = authCode ?? state.authCode;
		},
		setPersistentActiveApplicationId: (
			state,
			action: PayloadAction<string>
		) => {
			state.appId = action.payload;
		},
		loggedOut: (state) => {
			state.authCode = undefined;
		},
		setDoNotShowModalToOpenPrReviewInSeparateWindow: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.userPreferences = {
				...state.userPreferences,
				doNotShowModalToOpenPrReviewInSeparateWindow: action.payload,
			};
		},
	},
});

export const {
	setPersistentAuthenticationData,
	setPersistentActiveApplicationId,
	loggedOut,
	setDoNotShowModalToOpenPrReviewInSeparateWindow,
} = slice.actions;

export default slice.reducer;
