import {
	storedSteps,
	tesGenerationLogData,
} from '@src/modules/test-generation-labeling/api/mock/tg-log-data';

export const getStoredStepByStepId = (stepId, shouldLabel = false) => {
	let testGenerationLogStep = storedSteps.find(
		({ index }) => index === Number(stepId)
	);

	if (!testGenerationLogStep) {
		const unlabeledStep =
			tesGenerationLogData.testGenerationStepLabelsFromJSON.find(
				({ index }) => index === Number(stepId)
			);

		if (unlabeledStep) {
			/* eslint-disable-next-line */
			testGenerationLogStep = { ...unlabeledStep } as any;
			storedSteps.push(testGenerationLogStep);

			if (shouldLabel) {
				unlabeledStep.status = 'labeled';

				testGenerationLogStep.status = 'labeled';
				testGenerationLogStep.labeledAt = Date.now();
			}
		}
	}

	return testGenerationLogStep;
};
