import api from '@src/api';
import { ManagedUser } from '../pages/types';

export const supportAdminAPI = api
	.enhanceEndpoints({
		addTagTypes: ['UserByEmail'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getAllUsers: builder.query<ManagedUser[], void>({
				query: () => ({
					url: `support/user/`,
				}),
				transformResponse: (response: { users: ManagedUser[] }) =>
					response.users,
			}),
			getUserByEmail: builder.query<string, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/${email}`,
				}),
			}),
			createSupportUser: builder.mutation<string, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/`,
					method: 'POST',
					body: { email },
				}),
			}),
			deleteSupportUser: builder.mutation<ManagedUser, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/${email}`,
					method: 'DELETE',
				}),
				transformResponse: (response: { user: ManagedUser }) => response.user,
			}),
			refreshUserSeed: builder.mutation<ManagedUser, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/${email}`,
					method: 'PUT',
					successToastMessage: 'User seed refreshed',
				}),
				transformResponse: (response: { user: ManagedUser }) => response.user,
			}),
		}),
	});

export const {
	useGetAllUsersQuery,
	useGetUserByEmailQuery,
	useCreateSupportUserMutation,
	useRefreshUserSeedMutation,
	useDeleteSupportUserMutation,
} = supportAdminAPI;
