import { useCallback, useState } from 'react';

export const useMenuIconButton = ({
	onEdit,
	onDelete,
}: {
	onEdit: () => void;
	onDelete: () => void;
}) => {
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

	//open menu handler
	const handleClick = useCallback(
		(event) => {
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement]
	);

	//close menu
	const handleClose = useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);

	const handleEdit = useCallback(() => {
		handleClose();
		onEdit();
	}, [onEdit, handleClose]);

	const handleDelete = useCallback(() => {
		handleClose();
		onDelete();
	}, [onDelete, handleClose]);

	return {
		anchorElement,
		open: !!anchorElement,
		handleClick,
		handleClose,
		handleEdit,
		handleDelete,
	};
};
