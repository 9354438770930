import { Box, List, ListItem } from '@mui/material';
import { useInternalDefaultPage } from './hooks';
import { InternalUserPermissions } from '@src/modules/authentication/service';
import { Link } from 'react-router-dom';
import { testGenerationLabelingRoutes } from '@src/modules/test-generation-labeling/service';
import { InternalApplicationRoutes } from '@src/modules/internal/routes/routes';

export const InternalDefaultPage = () => {
	const { permissions } = useInternalDefaultPage();
	return (
		<Box p={4}>
			[Internal] Follow the instructions you've received to proceed.
			<List>
				<If
					condition={permissions.includes(
						InternalUserPermissions.TestGenerationLabelingReview
					)}
				>
					<ListItem>
						<Link to={testGenerationLabelingRoutes.queue.make()}>
							Test Generation Labeling Queue
						</Link>
					</ListItem>
				</If>

				<If
					condition={permissions.includes(
						InternalUserPermissions.TestGenerationLabeling
					)}
				>
					<ListItem>
						<Link to={testGenerationLabelingRoutes.labeler.make()}>
							Test Generation Labeler
						</Link>
					</ListItem>
				</If>

				<ListItem>
					<Link to={InternalApplicationRoutes.applications.absolutePath}>
						All Applications
					</Link>
				</ListItem>
				<ListItem>
					<Link to={InternalApplicationRoutes.support.absolutePath}>
						Support Management
					</Link>
				</ListItem>
			</List>
		</Box>
	);
};
