import { http, HttpResponse, RequestHandler } from 'msw';
import { APIBaseURL } from '@src/api/constants';
import {
	makeLoginAPIResponse,
	makeValidateAppNameAPIResponse,
} from '@src/modules/authentication/api/mock/helpers';
import {
	APIAuthenticationResult,
	APIValidateAppNameResponse,
	OauthLoginAPIArgs,
} from '@src/modules/authentication/api/types';

export const authenticationMockHandlers: RequestHandler[] = [
	// authenticateOAuth
	http.post<undefined, OauthLoginAPIArgs>(
		`${APIBaseURL}/auth/oauthLogin`,
		async ({ request, params, cookies }) => {
			const requestBody = await request.json();

			return HttpResponse.json<APIAuthenticationResult>(
				makeLoginAPIResponse(requestBody)
			);
		}
	),

	// getSetupInfo
	http.get<undefined, undefined>(
		`${APIBaseURL}/user/setupInfo`,
		async ({ request, params, cookies }) => {
			return HttpResponse.json<APIAuthenticationResult>(makeLoginAPIResponse());
		}
	),

	//
	http.post<undefined, { code: string }>(
		`${APIBaseURL}/user/setupInfo`,
		async ({ request, params, cookies }) => {
			const requestBody = await request.json();

			return HttpResponse.json<APIValidateAppNameResponse>(
				makeValidateAppNameAPIResponse(requestBody)
			);
		}
	),

	// logout
	http.post(`${APIBaseURL}/logout`, async ({ request, params, cookies }) => {
		return HttpResponse.json();
	}),
];
