import { CRMProviderInterface } from '@src/services/crm/providers/CRMProviderInterface';
import CrispCRMProvider from './providers/CrispCRMProvider';
// import IntercomCRMProvider from '@src/services/crm/providers/IntercomCRMProvider';
// import ZendeskCRMProvider from '@src/services/crm/providers/ZendeskCRMProvider';

export const CRM_PROVIDER_CLASSES: (new () => CRMProviderInterface)[] = [
	//IntercomCRMProvider,
	//ZendeskCRMProvider,
	CrispCRMProvider,
];

// when multiple providers are available, this is the one that will be used for the widget
export const CRM_SINGLE_PROVIDER_FEATURES = {
	widget: CrispCRMProvider,
};
