import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { useStyles } from './styles';
import { useGithubAppSettings } from './hooks';
import { InstalledWithEmptyRepositoriesScreen } from '@src/modules/settings/components/GitSettings/components/InitialGithubAppScreen/InstalledWithEmptyRepositoriesScreen';
import { InitialScreen } from '../InitialGithubAppScreen/InitialScreen';
import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { GithubAppIntegrationInfo } from '@src/modules/settings/types';
import { ChangeUseCollectionNameInTestPathCheckbox } from '@src/modules/settings/components/GitSettings/components/ChangeUseCollectionNameInTestPathCheckbox/ChangeUseCollectionNameInTestPathCheckbox';

export function GithubAppSettings({
	githubAppIntegrationInfo,
	isLoadingInfo,
}: {
	githubAppIntegrationInfo: GithubAppIntegrationInfo;
	isLoadingInfo: boolean;
}) {
	const {
		installationURL,
		installed,
		isAllRepositoriesEmpty,
		repositories,
		onChangeSelect,
		selectedRepositoryName,
		hasSelectedRepository,
		handleRedirect,
		isSelectLoading,
		selectedRepositoryLink,
	} = useGithubAppSettings({
		githubAppIntegrationInfo,
		isLoadingInfo,
	});
	const styles = useStyles();

	return (
		<>
			<Choose>
				<When condition={isLoadingInfo || isSelectLoading}>
					<LoadingCircularProgress>
						Loading Github App Integration Info...
					</LoadingCircularProgress>
				</When>

				<When condition={!installed}>
					<InitialScreen
						isLoadingInfo={isLoadingInfo}
						installationURL={installationURL}
						styles={styles.centered}
						handleRedirect={handleRedirect}
					/>
				</When>

				<When condition={installed && isAllRepositoriesEmpty}>
					<InstalledWithEmptyRepositoriesScreen
						installationURL={installationURL}
						handleRedirect={handleRedirect}
					/>
				</When>

				<Otherwise>
					<If condition={!hasSelectedRepository}>
						<Box>
							<Typography>
								You've installed the Github application but haven't selected a
								repository.
							</Typography>
							<Typography>
								The connected repository will receive pull requests from
								Checksum when new tests are generated or when tests have been
								auto-healed.
							</Typography>
						</Box>
						<Typography>
							Please select a repository in order to complete the integration.
						</Typography>
					</If>

					<If condition={hasSelectedRepository}>
						<Typography component="div" gutterBottom>
							The connected repository{' '}
							<Typography variant="subtitle1" display="inline">
								<Link to={selectedRepositoryLink} target="_blank">
									{selectedRepositoryName}
								</Link>
							</Typography>{' '}
							will receive pull requests from Checksum when new tests are
							generated or when tests have been auto-healed.
							<If condition={false}>
								<Typography css={styles.lookForPRIconNote}>
									Look for the <GitHubIcon css={styles.githubIcon} /> icon on
									the generated test flows and on the test runs.
								</Typography>
							</If>
						</Typography>

						<If condition={repositories.length > 1}>
							<Typography>
								You can change the repository by selecting another one from the
								list below.
							</Typography>
						</If>
					</If>

					<Box css={styles.column}>
						<TextField
							select
							label={
								selectedRepositoryName
									? 'Connected Repository'
									: 'Select a repository'
							}
							onChange={onChangeSelect}
							value={selectedRepositoryName}
							css={styles.select}
						>
							{repositories.map(({ name }) => {
								return (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								);
							})}
						</TextField>

						<ChangeUseCollectionNameInTestPathCheckbox
							value={githubAppIntegrationInfo.useCollectionNameInTestPath}
						/>
					</Box>

					<Typography component="div">
						If you can't find the repository you are looking for, use the
						following link to authorize access to the right repository.
						<Typography variant="subtitle1">
							<Link to={installationURL} target="_blank">
								{installationURL}
							</Link>
						</Typography>
					</Typography>
				</Otherwise>
			</Choose>
		</>
	);
}
