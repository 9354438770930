import {
	APIAvailableApplication,
	ApplicationUserRoles,
} from '@src/modules/application/service';

export const applications: APIAvailableApplication[] = [
	{
		application: {
			id: '1',
			name: 'buzzstream',
		},
		role: ApplicationUserRoles.Member,
	},
	{
		application: {
			id: '2',
			name: 'medusa',
		},
		role: ApplicationUserRoles.Admin,
	},
];
