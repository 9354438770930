import { useMemo, useState } from 'react';
import { UserStoriesCollection } from '@src/modules/collections/service';

export const useCollectionsFilter = (collections: UserStoriesCollection[]) => {
	const [activeSearchTerm, setActiveSearchTerm] = useState<string>(undefined);

	const filteredCollections = useMemo(() => {
		if (!collections) {
			return [];
		}

		if (!activeSearchTerm) {
			return collections;
		}

		// split the search term into words and filter the collections by them
		// each word has to appear in the page name or in one of the url patterns
		const splitSearchTerms = activeSearchTerm
			.replaceAll('/', '')
			.toLowerCase()
			.split(' ');

		return collections.filter((collection) => {
			return splitSearchTerms.every((word) =>
				collection.name.toLowerCase().includes(word)
			);
		});
	}, [activeSearchTerm, collections]);

	const hasFilteredOutCollections =
		collections &&
		filteredCollections &&
		collections.length > filteredCollections.length;

	return {
		setActiveSearchTerm,
		filteredCollections,
		hasFilteredOutCollections,
	};
};
