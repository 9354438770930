import { Route } from 'react-router-dom';
import AppRoute from '@src/routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { ExploreUserStoriesPage } from '../pages/explore-user-stories/ExploreUserStoriesPage';
import { userStoriesExplorerRoutes } from './routes';

export function UserStoriesExplorationRouter() {
	return (
		<AppRouter>
			<Route
				path={userStoriesExplorerRoutes.userStories.path}
				element={<AppRoute component={ExploreUserStoriesPage} />}
			/>
			<Route
				path={userStoriesExplorerRoutes.userStoriesCollection.path}
				element={<AppRoute component={ExploreUserStoriesPage} />}
			/>
			<Route
				path={userStoriesExplorerRoutes.flow.path}
				element={<AppRoute component={ExploreUserStoriesPage} />}
			/>
		</AppRouter>
	);
}
