import { useCallback, useMemo } from 'react';
import { useIsMobile } from '@src/common/hooks/useIsMobile';
import { UserStoriesCollection } from '@src/modules/collections/types';
import {
	ALL_FLOWS_COLLECTION_ID,
	INBOX_COLLECTION_ID,
} from '@src/modules/collections/constants';
import { CollectionModalMode } from '@src/modules/user-stories-exploration/service';
import { useDeleteCollectionMutation } from '@src/modules/collections/api';
import { getCollectionFlowsInfo } from '@src/modules/collections/components/CollectionListItem/helpers';
import { InboxCollectionAndStoriesBadgesData } from '@src/modules/collections/service';

export const useCollectionListItem = ({
	collection,
	openCollectionModal,
	inboxCollectionInfo,
}: {
	collection: UserStoriesCollection;
	openCollectionModal: (
		collectionId: string,
		collectionModalMode: CollectionModalMode
	) => void;
	inboxCollectionInfo: InboxCollectionAndStoriesBadgesData['inboxCollectionInfo'];
}) => {
	const [deleteCollection] = useDeleteCollectionMutation();

	const { isInboxActive, isAllFlowsActive } = useMemo(() => {
		return {
			isInboxActive: collection.id === INBOX_COLLECTION_ID,
			isAllFlowsActive: collection.id === ALL_FLOWS_COLLECTION_ID,
		};
	}, [collection.id]);

	const isMobile = useIsMobile();
	const isInboxCollection = useMemo(
		() => collection.id === INBOX_COLLECTION_ID,
		[collection.id]
	);

	const { subtitle, countFormatted } = useMemo(() => {
		return getCollectionFlowsInfo(
			{
				inboxCollectionInfo,
				collectionStoriesStatistics: collection.storiesStatistics,
			},
			isInboxActive
		);
	}, [collection.storiesStatistics, inboxCollectionInfo, isInboxActive]);

	const inboxNewBadgeCount = useMemo(() => {
		if (isInboxActive) {
			return inboxCollectionInfo.withBadgeCount;
		}
	}, [isInboxActive, inboxCollectionInfo]);

	const handleEdit = useCallback(() => {
		openCollectionModal(collection.id, CollectionModalMode.Edit);
	}, [openCollectionModal, collection?.id]);

	const handleDelete = useCallback(() => {
		if (collection?.storiesStatistics?.count === 0) {
			deleteCollection(collection.id);
		} else {
			openCollectionModal(collection.id, CollectionModalMode.Delete);
		}
	}, [openCollectionModal, collection, deleteCollection]);

	return {
		subtitle,
		isMobile,
		handleEdit,
		isInboxCollection,
		handleDelete,
		inboxNewBadgeCount,
		isInboxActive,
		countFormatted,
		isAllFlowsActive,
	};
};
