import { Route } from 'react-router-dom';
import AppRoute from '@src/routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { testRunsRoutes } from './routes';
import { TestRuns } from '../pages/test-runs/TestRuns';
import { TestReport } from '../pages/test-report/TestReport';

export function TestRunsRouter() {
	return (
		<AppRouter>
			<Route
				path={testRunsRoutes.testRuns.path}
				element={<AppRoute component={TestRuns} />}
			/>
			<Route
				path={testRunsRoutes.testRun.path}
				element={<AppRoute component={TestReport} />}
			/>
		</AppRouter>
	);
}
