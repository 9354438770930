import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SettingsTabs } from './types';
import { useGetSettingsStatus } from '@src/modules/settings/hooks/useGetSettingsStatus';
import { settingsRouter } from '@src/modules/settings/routes/routes';
import { getTabs } from '@src/modules/settings/pages/settings/helpers';

const REDIRECT_TO_WIZARD_ON_SETTINGS_INCOMPLETE = false;

export const useSettingsPage = () => {
	const [queryParams] = useSearchParams();

	const [tabValue, setTabValue] = useState(
		convertQueryParamToTabValue(queryParams.get('tab')) ??
			getTabs()[SettingsTabs.SDK]
	);

	const [tabs, setTabs] = useState(getTabs());

	const a11yProps = useRef({});
	const makeA11yProps = useCallback((index: number) => {
		if (!a11yProps.current[index]) {
			a11yProps.current[index] = {
				id: `settings-tab-${index}`,
				'aria-controls': `settings-tabpanel-${index}`,
			};
		}
		return a11yProps.current[index];
	}, []);

	const { settingsStatus } = useGetSettingsStatus();

	const navigate = useNavigate();

	const shouldDisplaySDKTab = useMemo(() => {
		return !!settingsStatus?.sdkKey;
	}, [settingsStatus]);

	const handleTagChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		const tabs = getTabs(shouldDisplaySDKTab);

		setTabValue(
			tabs[shouldDisplaySDKTab ? SettingsTabs.SDK : SettingsTabs.Environment]
		);
		setTabs(tabs);
	}, [shouldDisplaySDKTab]);

	//redirect user to wizard page if there is any incomplete steps
	useEffect(() => {
		if (!settingsStatus) {
			return;
		}

		const allStepsCompleted =
			settingsStatus.sdkSuccessfullyIntegrated &&
			settingsStatus.isGitIntegrated &&
			settingsStatus.hasValidEnvironmentSettings;

		const tabQuery = queryParams.get('tab');

		if (REDIRECT_TO_WIZARD_ON_SETTINGS_INCOMPLETE && !allStepsCompleted) {
			navigate(
				{
					pathname: settingsRouter.wizard.absolutePath,
					search: tabQuery ? `?tab=${queryParams.get('tab')}` : null,
				},
				{ replace: true }
			);

			return;
		}
	}, [settingsStatus, navigate, queryParams]);

	return {
		tabValue,
		handleTagChange,
		makeA11yProps,
		settingsStatus,
		shouldDisplaySDKTab,
		tabs,
	};
};

const convertQueryParamToTabValue = (tab: string): number => {
	switch (tab) {
		case 'github':
		case 'gitlab':
		case 'git':
			return getTabs()[SettingsTabs.GitIntegration];
	}
};
