import { ApplicationRoutes } from '@src/app/router/routes';
import { prepareModuleRoutes } from '@src/routing';

export const authenticateRoutes = prepareModuleRoutes<
	'authenticate' | 'newApplication'
>(
	{
		authenticate: {
			path: '/',
		},
		newApplication: {
			path: '/new',
		},
	},
	ApplicationRoutes.authenticate.absolutePath
);
