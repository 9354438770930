import AnalyticsProviderInterface from '../AnalyticsProviderInterface';
import Cookies from 'js-cookie';
import { GoogleTagManagerEventsFilter } from '@src/services/analytics/providers/GoogleTagManagerAnalyticsProvider/constants';
import Config from '@src/config';
import Logger from '@src/services/logger';
import { LogComponents, LogVerbosity } from '@src/services/logger/types';

declare global {
	interface Window {
		// eslint-disable-next-line
		dataLayer: any;
	}
}

class GoogleTagManagerAnalyticsProvider implements AnalyticsProviderInterface {
	private active = false;
	private providerName = 'gtm';
	private logger = new Logger(LogComponents.Analytics, {
		subComponent: this.constructor.name,
		remoteVerbosity: LogVerbosity.Off,
		// mute: true,
	});

	public get name() {
		return this.providerName;
	}

	init() {
		if (!Config.analytics.gtm.id) {
			this.logger.warn('GTM id not found in config');
			return;
		}

		this.logger.info('initializing Google Tag Manager');

		const that = this;
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js',
			});
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l !== 'dataLayer' ? '&l=' + l : '';
			// @ts-ignore
			j.async = true;
			// @ts-ignore
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
			that.active = true;
		})(window, document, 'script', 'dataLayer', Config.analytics.gtm.id);
	}

	isActive() {
		return this.active;
	}

	identify(userId: string, properties?: unknown) {}

	alias(userId: string, formerId?: string) {}

	reset() {}

	track(event: string, payload?: object) {
		if (!GoogleTagManagerEventsFilter[event]) {
			return;
		}
		const eventDirectives = GoogleTagManagerEventsFilter[event];
		const eventName = eventDirectives.eventName ?? event;
		const eventAttributes = eventDirectives.makeAttributes
			? eventDirectives.makeAttributes(payload)
			: payload;

		this.pushToDataLayer(eventName, eventAttributes);
	}

	trackPageView(page: string) {}

	private pushToDataLayer(event, payload) {
		payload.fbq = Cookies.get('_fbp');
		this.dataLayer.push({ event, ...payload });
	}

	private get dataLayer() {
		return window.dataLayer;
	}
}

export default GoogleTagManagerAnalyticsProvider;
