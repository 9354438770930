import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const codeBlock = css`
			position: relative;
			background-color: ${theme.colors.codeBlock.dark};
			border-radius: 4px;
			padding: 8px 32px 8px 8px;
			overflow-x: auto;
			font-family: 'Fira Code', 'Fira Mono', monospace;
			font-size: 0.875rem;
			color: ${theme.colors.codeBlock.textColor};

			&:hover {
				.copy-button {
					opacity: 1;
				}
			}
		`;

		const tooltip = css`
	 	 position: absolute;
	 	 right: 8px;
	 	 top: 50%;
	 	 transform: translateY(-50%);
	 	 color: ${theme.colors.codeBlock.textColor};  
	 	 opacity: 0.5; 
			
			&:hover {
				opacity: 1;
			},
	`;

		return {
			codeBlock,
			tooltip,
		};
	}, [theme]);
};
