import {
	TestGeneration,
	TestGenerationStatus,
} from '@src/modules/test-generations/service';
import { UserStory, UserStoryOrigin, UserStoryStatus } from './types';
import { formatTimestampAsLocaleStringWithoutSeconds } from '@src/common/helpers/date';
import { ApplicationEnvironmentUser } from '@src/modules/settings/service';

export const getStoryCreationNoteParts = (story: UserStory) => {
	return [
		story.origin === UserStoryOrigin.D2S ? 'Identified' : 'Added',
		formatTimestampAsLocaleStringWithoutSeconds(story.createdAt),
	];
};

export const getStoryCreationNote = (story: UserStory) => {
	const [status, date] = getStoryCreationNoteParts(story);

	return `${status} on ${date}`;
};

export const getStoryLastActivityNote = (story: UserStory) => {
	if (story.status === UserStoryStatus.Edited) {
		return `Edited on ${formatTimestampAsLocaleStringWithoutSeconds(
			story.editedAt
		)}`;
	}

	if (!story.recentGeneration) {
		return getStoryCreationNote(story);
	}

	const timeString = formatTimestampAsLocaleStringWithoutSeconds(
		story.recentGeneration.completedAt ?? story.recentGeneration.updatedAt
	);

	const prClosedTimeString = formatTimestampAsLocaleStringWithoutSeconds(
		story.recentGeneration.prClosedAt
	);

	switch (story.recentGeneration.status) {
		case TestGenerationStatus.Queued:
			return `Generation queued on ${timeString}`;
		case TestGenerationStatus.Running:
			return `Generation started on ${timeString}`;
		case TestGenerationStatus.Completed:
			return `Generated on ${timeString}`;
		case TestGenerationStatus.Failed:
			return `Failed on ${timeString}`;
		case TestGenerationStatus.Aborted:
			return `Aborted on ${timeString}`;
		case TestGenerationStatus.PRMerged:
			return `Merged on ${prClosedTimeString}`;
		case TestGenerationStatus.PRClosed:
			return `Closed on ${prClosedTimeString}`;
	}
};

export const makeEmptyUserStory = (
	fields: Partial<UserStory> = {}
): UserStory => ({
	completedRecentTestGenerationStoryStatus: null,
	engagement: null,
	collectionName: null,
	userStoryFiles: [],
	recentActivityDate: new Date().toISOString(),
	id: '',
	title: '',
	steps: '',
	collectionId: '',
	origin: UserStoryOrigin.USER,
	payload: {},
	createdAt: '',
	status: UserStoryStatus.Idle,
	generations: [],
	environmentUser: {} as ApplicationEnvironmentUser,
	...fields,
});

export const getStoryLastActivityDate = (
	recentGeneration: TestGeneration,
	storyCreatedAt: string
): string => {
	return recentGeneration?.completedAt ?? storyCreatedAt;
};
