import { MenuItem, Select, Stack } from '@mui/material';
import { SearchBar } from '@src/common/components/SearchBar/SearchBar';
import { UserStoryStatus } from '@src/modules/user-stories/service';
import { ActiveFilterSetter, useStoriesHeader } from './hooks';
import { StoriesFilterAllValue } from './constants';
import { useStoriesHeaderStyles } from './styles';
import { SerializedStyles } from '@emotion/react';
import { TestGenerationStatus } from '@src/modules/test-generations/service';

export function StoriesHeader({
	activeFilterValue,
	setActiveFilterValue,
	containerStyles,
	setActiveSearchTerm,
}: {
	activeFilterValue: UserStoryStatus | null;
	setActiveFilterValue: ActiveFilterSetter;
	containerStyles?: SerializedStyles;
	setActiveSearchTerm: (searchTerm: string) => void;
}) {
	const styles = useStoriesHeaderStyles();
	const { onFilterChange } = useStoriesHeader({ setActiveFilterValue });
	return (
		<Stack direction="row" spacing={2} css={containerStyles}>
			<SearchBar
				placeholder="Search test flows..."
				fullWidth={true}
				onChange={setActiveSearchTerm}
			></SearchBar>
			<Select
				css={styles.filterSelect}
				value={activeFilterValue ?? StoriesFilterAllValue}
				onChange={onFilterChange}
			>
				<MenuItem value={StoriesFilterAllValue}>All Flows</MenuItem>
				<MenuItem value={TestGenerationStatus.Queued}>Queued</MenuItem>
				<MenuItem value={TestGenerationStatus.Running}>Running</MenuItem>
				<MenuItem value={TestGenerationStatus.Verifying}>Verifying</MenuItem>
				<MenuItem value={TestGenerationStatus.Completed}>Generated</MenuItem>
				<MenuItem value={TestGenerationStatus.PRMerged}>PR Merged</MenuItem>
				<MenuItem value={TestGenerationStatus.PRClosed}>PR Closed</MenuItem>
				<MenuItem value={TestGenerationStatus.Failed}>
					Failed to generate
				</MenuItem>
				<MenuItem value={UserStoryStatus.Idle}>Idle</MenuItem>
			</Select>
			{/* <Button
				variant="contained"
				size="medium"
				disableElevation
				css={styles.newStoryButton}
				onClick={onNewStory}
			>
				New Story
			</Button> */}
		</Stack>
	);
}
