import { useEffect, useRef } from 'react';
import { toast, TypeOptions } from 'react-toastify';

export const usePersistentToast = ({
	showCondition,
	message,
	type = 'info',
}: {
	showCondition: boolean;
	message: string;
	type?: TypeOptions;
}) => {
	const toastIdRef = useRef(null);

	useEffect(() => {
		if (showCondition) {
			if (toastIdRef.current === null) {
				// Show new toast
				toastIdRef.current = toast(message, {
					autoClose: false,
					closeOnClick: false,
					closeButton: false,
					draggable: false,
					type,
				});
			} else {
				// Update existing toast
				toast.update(toastIdRef.current, {
					render: message,
					type,
				});
			}
		} else if (toastIdRef.current !== null) {
			// Close toast when array is empty
			toast.dismiss(toastIdRef.current);
			toastIdRef.current = null;
		}
	}, [showCondition, message, type]);
};
