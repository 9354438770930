import { object, string } from 'yup';
import { FormValues } from '@src/modules/application/components/ApplicationForm/types';

export const validationSchema = object<FormValues>({
	appName: string()
		.trim()
		.required('Application name is required')
		.matches(
			/^[^-].*[^-]$/,
			'The application name cannot start or end with a dash'
		)
		.matches(
			/^(?!.*--).*$/,
			' The application name cannot contain consecutive dashes.'
		)
		.matches(
			/^[a-z0-9-]+$/,
			'The application name should be a lower-case, alphanumeric string, with dashes allowed anywhere except the first or last character.'
		)
		.max(40, 'The application name must be at most 40 characters long.'),
});
