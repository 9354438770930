/* eslint-disable */

import { combineReducers, Reducer } from 'redux';
import applyInterceptors from './interceptors';
import persistent from '../common/state/persistent/slice';
import volatile from '../common/state/volatile/slice';
import signals from '../services/signals/state/slice';
import uploads from '../services/files-uploading-manager/state/slice';

import api from '@src/api';

const applicationReducer: Reducer = combineReducers({
	persistent,
	volatile,
	signals,
	uploads,
	[api.reducerPath]: api.reducer,
});

export default applyInterceptors(applicationReducer);
