import { useReadInboxUserStoriesMutation } from '@src/modules/collections/api';
import { useEffect, useRef } from 'react';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/constants';

const INBOX_MARK_AS_READ_TIME_THRESHOLD = 3000; // 3 seconds

export const useReadInboxUserStories = ({
	inboxUserStories,
	activeCollectionId,
}) => {
	const [readStories] = useReadInboxUserStoriesMutation();

	const isInboxCurrentCollectionRef = useRef(
		activeCollectionId === INBOX_COLLECTION_ID
	);

	// read all user stories once user is longer than INBOX_MARK_AS_READ_TIME_THRESHOLD sec in inbox collection
	useEffect(() => {
		isInboxCurrentCollectionRef.current =
			activeCollectionId === INBOX_COLLECTION_ID;

		if (!!inboxUserStories) {
			const timeoutId = setTimeout(() => {
				if (!isInboxCurrentCollectionRef.current) {
					return;
				}

				readStories({ userStoriesIds: inboxUserStories.map(({ id }) => id) });
			}, INBOX_MARK_AS_READ_TIME_THRESHOLD);

			return () => clearTimeout(timeoutId);
		}
	}, [activeCollectionId, readStories, inboxUserStories]);
};
