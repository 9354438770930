import { SettingsStatus } from '@src/modules/settings/types';
import {
	GitAppMode,
	useGitSettings,
} from '@src/modules/settings/components/GitSettings/hooks';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { Box, Button, Typography } from '@mui/material';
import { GithubAppSettings } from '@src/modules/settings/components/GitSettings/components/GithubAppSettings/GithubAppSettings';
import { GitlabAppSettings } from '@src/modules/settings/components/GitSettings/components/GitlabAppSettings/GitlabAppSettings';
import { useGitSettingsStyles } from '@src/modules/settings/components/GitSettings/styles';
import { DeleteIntegrationConfirmationModal } from '@src/modules/settings/components/GitSettings/components/DeleteIntegrationConfirmationModal/DeleteIntegrationConfirmationModal';
import GitHubIcon from '@mui/icons-material/GitHub';
import { ReactComponent as GitLabIcon } from '@assets/icons/GitLab.svg';
import { ArrowBack } from '@mui/icons-material';

export const GitSettings = ({
	settingsStatus,
}: {
	settingsStatus: SettingsStatus;
}) => {
	const {
		handleChooseGitHub,
		handleChooseGitLab,
		isLoadingGithubAppIntegrationInfo,
		githubAppIntegrationInfo,
		deleteIntegrationModalOpenedMode,
		setDeleteIntegrationModalOpenedMode,
		modeToShow,
		onBack,
		onDelete,
		shouldDisplayButtonsHeader,
		shouldDisplayFooter,
	} = useGitSettings({ settingsStatus });

	const styles = useGitSettingsStyles();

	return (
		<>
			<If condition={shouldDisplayButtonsHeader}>
				<Box css={styles.buttonsNavHeader}>
					<If condition={!!onBack}>
						<Button onClick={onBack} startIcon={<ArrowBack />}>
							Back to Git providers
						</Button>
					</If>
				</Box>
			</If>

			<Choose>
				<When condition={modeToShow === GitAppMode.Loading}>
					<LoadingCircularProgress>Loading Git Info...</LoadingCircularProgress>
				</When>

				<When condition={modeToShow === GitAppMode.ChooseApp}>
					<Box css={styles.chooseGitProviderContainer}>
						<Typography>
							When a test is generated or auto-healed, Checksum will open a
							pull/merge request to your default branch.
						</Typography>
						<Typography gutterBottom>
							To integration with your Git repository, first choose your Git
							repository provider.
						</Typography>

						<Box css={styles.gitButtonsContainer}>
							<Button
								variant="contained"
								onClick={handleChooseGitHub}
								startIcon={<GitHubIcon />}
							>
								Github
							</Button>

							<Button
								variant="contained"
								onClick={handleChooseGitLab}
								startIcon={<GitLabIcon />}
								className="gitlab-button"
							>
								Gitlab
							</Button>
						</Box>
					</Box>
				</When>

				<When condition={modeToShow === GitAppMode.Github}>
					<GithubAppSettings
						githubAppIntegrationInfo={githubAppIntegrationInfo}
						isLoadingInfo={isLoadingGithubAppIntegrationInfo}
					/>
				</When>

				<When condition={modeToShow === GitAppMode.Gitlab}>
					<GitlabAppSettings />
				</When>
			</Choose>

			<If condition={shouldDisplayFooter}>
				<Box css={styles.footer}>
					<If condition={!!onDelete}>
						<Typography
							className="delete"
							variant="subtitle2"
							color="error"
							onClick={onDelete}
						>
							Remove Git integration
						</Typography>
					</If>
				</Box>
			</If>

			<DeleteIntegrationConfirmationModal
				deleteIntegrationModalOpenedMode={deleteIntegrationModalOpenedMode}
				setDeleteIntegrationModalOpenedMode={
					setDeleteIntegrationModalOpenedMode
				}
			/>
		</>
	);
};
