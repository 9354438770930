import { useCallback, useEffect, useMemo, useState } from 'react';
import useToggle from '@src/common/hooks/useToggle';

//@ts-ignore
import { JSONEditor } from 'jsoneditor-react';
import { moveObjectFieldToFirstPosition } from '@src/common/components/JsonViewer/helpers';

export const useJsonViewer = ({
	editable = false,
	value,
	expandValue,
	editorKey,
	disableExpandNodes,
}) => {
	const { isOn: isExpandModalOpened, toggle: toggleIsExpandModalOpened } =
		useToggle();

	const [rerenderEditorKey, setRerenderEditorKey] = useState(editorKey ?? 1);

	//show full object in modal
	const viewerValue = useMemo(() => {
		let resultValue = {};

		if (editable && !isExpandModalOpened) {
			resultValue = value;
		} else {
			resultValue = expandValue;
		}

		return moveObjectFieldToFirstPosition(resultValue, 'command');
	}, [isExpandModalOpened, value, expandValue, editable]);

	const mode = useMemo(() => {
		return editable ? 'tree' : 'view';
	}, [editable]);

	const handleToggleIsExpandModalOpened = useCallback(() => {
		//update local rerender key in case of modal opened
		setRerenderEditorKey((prevKey) => prevKey + 1);

		toggleIsExpandModalOpened();

		// editorKey is required here
		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	}, [editorKey, toggleIsExpandModalOpened]);

	//allow edit only values
	const onEditable = useCallback(() => {
		return { field: false, value: true };
	}, []);

	//expand all viewer fields after viewer is initialized
	const handleExpandAllViewerNodes = useCallback(
		(jsonViewer: JSONEditor | null) => {
			if (!jsonViewer || disableExpandNodes) {
				return;
			}

			jsonViewer.expandAll();
		},
		[disableExpandNodes]
	);

	//if the mode is editable then we can not rerender on change library does not support that
	useEffect(() => {
		if (editable) {
			return;
		}

		setRerenderEditorKey((prevKey) => prevKey + 1);
	}, [value, editable]);

	return {
		isExpandModalOpened,
		handleToggleIsExpandModalOpened,
		mode,
		onEditable,
		viewerValue,
		rerenderEditorKey,
		handleExpandAllViewerNodes,
	};
};
