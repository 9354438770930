import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { css, SerializedStyles } from '@emotion/react';

export const useInitialScreenStyles = (styles: SerializedStyles) => {
	const theme = useTheme();

	return useMemo(() => {
		const buttonContainer = [
			css({
				marginTop: theme.spacing(2),
			}),
			styles,
		];

		return {
			buttonContainer,
		};
	}, [styles, theme]);
};
