import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { useRedirectToFlow } from './hooks';
import { Box } from '@mui/material';
import { useRedirectToFlowStyles } from './styles';

export function RedirectToFlow() {
	useRedirectToFlow();
	const styles = useRedirectToFlowStyles();
	return (
		<Box css={styles.loadingContainer}>
			<LoadingCircularProgress circularProgressSize={20}>
				Loading...
			</LoadingCircularProgress>
		</Box>
	);
}
