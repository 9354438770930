import { useCallback, useMemo, useState } from 'react';
import { OpenedConfirmationModalVariant } from '@src/modules/settings/components/ApplicationEnvironmentSettings/constants';
import { generateConfirmationModalContents } from '../helpers';

export const useConfirmationModal = ({
	onContinueWithoutUnsavedNewChanges,
	onDeleteEnvironment,
	modalVariantRef,
	changeEnvironment,
	submitForm,
}) => {
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

	// state to store environment that was chosen from select dropdown
	// to select it inside confirmation dialog
	const [potentialActiveEnvironmentId, setPotentialActiveEnvironmentId] =
		useState<string | null>(null);

	// function to close confirmation modal
	const handleResetConfirmationModal = useCallback(() => {
		modalVariantRef.current = null;
		setIsConfirmationModalOpen(false);
	}, [setIsConfirmationModalOpen, modalVariantRef]);

	const openConfirmationModal = useCallback(
		(modalVariant: OpenedConfirmationModalVariant) => {
			modalVariantRef.current = modalVariant;
			setIsConfirmationModalOpen(true);
		},
		[setIsConfirmationModalOpen, modalVariantRef]
	);

	const onChangeEnvironment = useCallback(() => {
		// if user selected new environment then change it on click in confirmation dialog
		if (potentialActiveEnvironmentId) {
			changeEnvironment(potentialActiveEnvironmentId);
		}
	}, [potentialActiveEnvironmentId, changeEnvironment]);

	const {
		unsavedChangesConfirmationContent,
		deleteEnvironmentConfirmationContent,
		unsavedChangesSwitchBetweenEnvironmentsConfirmationContent,
		environmentNameOrEnvUserRoleChangedConfirmationContent,
	} = useConfirmationModalContent({
		handleResetConfirmationModal,
		isConfirmationModalOpen,
		onChangeEnvironment,
		onDeleteEnvironment,
		onContinueWithoutUnsavedNewChanges,
		submitForm,
	});

	/* ----- ACTIVE CONFIRMATION MODAL CONTENT ----- */

	// switch to return active modal state with its content and handlers (cancel/confirm)
	const currentConfirmationModalContent = useMemo(() => {
		switch (modalVariantRef.current) {
			case OpenedConfirmationModalVariant.DELETE_ENVIRONMENT: {
				return deleteEnvironmentConfirmationContent;
			}

			case OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_TO_NEW_ENVIRONMENT: {
				return unsavedChangesConfirmationContent;
			}

			case OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_ENVIRONMENT: {
				return unsavedChangesSwitchBetweenEnvironmentsConfirmationContent;
			}

			case OpenedConfirmationModalVariant.ENV_NAME_OR_USER_ROLE_CHANGED: {
				return environmentNameOrEnvUserRoleChangedConfirmationContent;
			}

			case OpenedConfirmationModalVariant.DEFAULT:
				return null;
		}
	}, [
		modalVariantRef,
		unsavedChangesSwitchBetweenEnvironmentsConfirmationContent,
		unsavedChangesConfirmationContent,
		deleteEnvironmentConfirmationContent,
		environmentNameOrEnvUserRoleChangedConfirmationContent,
	]);

	return {
		setPotentialActiveEnvironmentId,
		open: isConfirmationModalOpen,
		confirmationModalContent: currentConfirmationModalContent,
		openConfirmationModal,
	};
};

const useConfirmationModalContent = ({
	handleResetConfirmationModal,
	onContinueWithoutUnsavedNewChanges,
	onDeleteEnvironment,
	isConfirmationModalOpen,
	onChangeEnvironment,
	submitForm,
}) => {
	// reusable function to generate whole content for confirmation modal
	const makeConfirmationModalContent = useCallback(
		(variant: OpenedConfirmationModalVariant, cancelCallback: () => void) => {
			return generateConfirmationModalContents(variant, {
				onSuccess: handleResetConfirmationModal,
				onCancel: () => {
					handleResetConfirmationModal();
					cancelCallback();
				},
			});
		},
		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
		[isConfirmationModalOpen]
	);

	const unsavedChangesConfirmationContent = useMemo(() => {
		return makeConfirmationModalContent(
			OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_TO_NEW_ENVIRONMENT,
			onContinueWithoutUnsavedNewChanges
		);
	}, [makeConfirmationModalContent, onContinueWithoutUnsavedNewChanges]);

	const unsavedChangesSwitchBetweenEnvironmentsConfirmationContent =
		useMemo(() => {
			return makeConfirmationModalContent(
				OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_ENVIRONMENT,
				onChangeEnvironment
			);
		}, [makeConfirmationModalContent, onChangeEnvironment]);

	const deleteEnvironmentConfirmationContent = useMemo(() => {
		return makeConfirmationModalContent(
			OpenedConfirmationModalVariant.DELETE_ENVIRONMENT,
			onDeleteEnvironment
		);
	}, [makeConfirmationModalContent, onDeleteEnvironment]);

	const environmentNameOrEnvUserRoleChangedConfirmationContent = useMemo(() => {
		return makeConfirmationModalContent(
			OpenedConfirmationModalVariant.ENV_NAME_OR_USER_ROLE_CHANGED,
			submitForm
		);
	}, [makeConfirmationModalContent, submitForm]);

	return {
		unsavedChangesConfirmationContent,
		deleteEnvironmentConfirmationContent,
		unsavedChangesSwitchBetweenEnvironmentsConfirmationContent,
		environmentNameOrEnvUserRoleChangedConfirmationContent,
	};
};
