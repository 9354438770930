import {
	TestGenerationLogStep,
	TestGenerationStepLabelStatus,
} from '@src/modules/test-generation-labeling/types';
import { testGenerationLabelingRoutes } from '@src/modules/test-generation-labeling/routes/routes';

export const getTestGenerationLogStepByIndex = (
	entries: TestGenerationLogStep[],
	activeEntryIndex: string
): TestGenerationLogStep => {
	return entries.find(({ index }) => index.toString() === activeEntryIndex);
};

export const getFirstTestGenerationLogStep = (
	entries: TestGenerationLogStep[]
): TestGenerationLogStep => {
	return entries && entries.length > 0 ? entries[0] : null;
};

//get first noy viewed step
export const getInitialActiveTestGenerationLogStep = (
	entries: TestGenerationLogStep[]
) => {
	// if a step is already in progress, take it,
	// otherwise take the first unlabeled one
	// TODO: otherwise? message that session labeling complete and move to next?
	return (
		entries.find(
			({ status }) => status === TestGenerationStepLabelStatus.InProgress
		) ??
		entries.find(
			({ status }) => status === TestGenerationStepLabelStatus.Unlabeled
		) ??
		getFirstTestGenerationLogStep(entries)
	);
};

// export const getInitialActiveTestGenerationLogStepIndex = (
// 	entries: TestGenerationLogStep[]
// ) => {
// 	const activeTestGenerationLogStep =
// 		getInitialActiveTestGenerationLogStep(entries);

// 	return activeTestGenerationLogStep
// 		? activeTestGenerationLogStep.index.toString()
// 		: '0';
// };

//generate the href fot the next prev navigation
export const generateTestGenerationStepHref = (
	sessionId: string,
	stepId: string
) => {
	return testGenerationLabelingRoutes.step.make({
		sessionId,
		stepId,
	});
};

//entry has <index> field
//on base of index get next and previous steps links
export const generateTestGenerationStepsHrefs = (
	entries: TestGenerationLogStep[],
	sessionId: string,
	stepId: string
) => {
	const hrefs = {
		nextHref: null,
		prevHref: null,
	};

	const currentStepPosition = entries.findIndex(
		({ index }) => index.toString() === stepId
	);

	if (currentStepPosition === -1) {
		return hrefs;
	}

	//get if left/right elements in entries array exist
	const { rightElement, leftElement } = getIsStepNeighboursExist(
		entries,
		currentStepPosition
	);

	if (!!rightElement) {
		const nextStepIndex = rightElement.index.toString();

		hrefs.nextHref = generateTestGenerationStepHref(sessionId, nextStepIndex);
	}

	if (!!leftElement) {
		const prevStepIndex = leftElement.index.toString();

		hrefs.prevHref = generateTestGenerationStepHref(sessionId, prevStepIndex);
	}

	return hrefs;
};

const getIsStepNeighboursExist = (
	entries: TestGenerationLogStep[],
	currentPosition: number
) => {
	return {
		rightElement: entries[currentPosition + 1],
		leftElement: entries[currentPosition - 1],
	};
};

export const getNextStep = (
	entries: TestGenerationLogStep[],
	stepId: string
) => {
	const currentStepPosition = entries.findIndex(
		({ index }) => index.toString() === stepId
	);

	return entries.at(currentStepPosition + 1);
};
