/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetEnvVarOptions } from './types';

export const getEnvVar = <T = string>(
	key: string,
	fallback: T = undefined,
	options: GetEnvVarOptions = {}
) => {
	let envVar = runOptions<T>(key, process.env[key] ?? fallback, options) as any;
	if (
		envVar &&
		envVar[envVar.length - 1] === '/' &&
		!options.enforceTrailingSlash
	) {
		envVar = envVar.slice(0, -1);
	}
	return envVar;
};

export const getEnvVarOrFail = <T = string>(
	key: string,
	options: GetEnvVarOptions = {}
): T => {
	const value = getEnvVar<T>(key, undefined, options);
	if (!value) {
		throwConfigError(key, 'not provided');
	}
	return value;
};

const throwConfigError = (key: string, reason: string) => {
	throw Error(`${key} config error - ${reason}`);
};

const runOptions = <T>(key, value, options): T => {
	let processedValue = value;
	Object.keys(optionHandlers).forEach((option) => {
		if (options[option]) {
			processedValue = optionHandlers[option](key, processedValue);
		}
	});
	return processedValue;
};

// -------- [option handlers] -------- //

const parseCommaSeparatedArray = (key: string, value: string): any[] => {
	if (!value) {
		return [];
	}
	if (Array.isArray(value)) {
		return value;
	}
	return value.split(',').map((item) => item.trim());
};

const parseStringifiedJsonObject = (key: string, value: string): object => {
	if (!value) {
		return {};
	}
	if (typeof value !== 'string') {
		return value;
	}
	return JSON.parse(value);
};

const enforceTrailingSlash = (key: string, value: string): string => {
	if (!value.endsWith('/')) {
		throwConfigError(key, `requires trailing slash`);
	}
	return value;
};

const falsyValues = [
	'0',
	'false',
	'f',
	'null',
	'undefined',
	'NaN',
	'no',
	'n',
	'off',
	'disable',
	'disabled',
	0,
	false,
	null,
	undefined,
	NaN,
];

export const parseBoolean = (key: string, value: string): boolean => {
	return !falsyValues.includes(value);
};

export const parseNumber = (key: string, value: string): number => {
	const number = parseFloat(value); // to support both int and float
	if (isNaN(number)) {
		throwConfigError(key, `is not a number`);
	}
	return number;
};

// option handlers mapping between option in object to handler
const optionHandlers: Record<
	keyof GetEnvVarOptions,
	(key: string, value: string) => any
> = {
	commaSeparatedArray: parseCommaSeparatedArray,
	stringifiedJsonObject: parseStringifiedJsonObject,
	enforceTrailingSlash,
	boolean: parseBoolean,
	number: parseNumber,
};
