import { useCallback, useMemo, useState } from 'react';

export const useFilterTestRuns = ({ testRuns }) => {
	const [activeCommitHash, setActiveCommitHash] = useState<
		string | undefined
	>();
	const [activeBranch, setActiveBranch] = useState<string | undefined>();
	const [activeTag, setActiveTag] = useState<string | undefined>();

	//should add filters for tags and branch
	const filterOptions = useMemo(() => {
		if (!testRuns) {
			return {
				commitFilterOptions: [],
				tagsFilterOptions: [],
				branchFilterOptions: [],
			};
		}

		const commitFilterOptions = new Set([]);
		const tagsFilterOptions = new Set([]);
		const branchFilterOptions = new Set([]);

		testRuns.forEach((testRun) => {
			commitFilterOptions.add(testRun.commitHash);
			testRun.tags.forEach((tag) => tagsFilterOptions.add(tag));
			branchFilterOptions.add(testRun.branch);
		});

		return {
			commitFilterOptions: Array.from(commitFilterOptions),
			tagsFilterOptions: Array.from(tagsFilterOptions),
			branchFilterOptions: Array.from(branchFilterOptions),
		};
	}, [testRuns]);

	const filteredTestRuns = useMemo(() => {
		if (!testRuns) {
			return [];
		}

		return testRuns.filter((testRun) => {
			const tagMatch = !activeTag || testRun.tags.includes(activeTag);

			const commitMatch =
				!activeCommitHash || testRun.commitHash === activeCommitHash;

			const branchMatch = !activeBranch || testRun.branch === activeBranch;

			return tagMatch && commitMatch && branchMatch;
		});
	}, [activeCommitHash, testRuns, activeTag, activeBranch]);

	const handleSetActiveBranch = useCallback((e) => {
		setActiveBranch(e.target.outerText);
	}, []);

	const handleSetActiveCommitHash = useCallback((e) => {
		setActiveCommitHash(e.target.outerText);
	}, []);

	const handleSetActiveTag = useCallback((e) => {
		setActiveTag(e.target.outerText);
	}, []);

	const filterAutocompleteInputsData = useMemo(() => {
		return [
			{
				name: 'Commit',
				onChange: handleSetActiveCommitHash,
				options: filterOptions.commitFilterOptions,
				value: activeCommitHash,
			},
			{
				name: 'Branch',
				onChange: handleSetActiveBranch,
				options: filterOptions.branchFilterOptions,
				value: activeBranch,
			},
			{
				name: 'Tag',
				onChange: handleSetActiveTag,
				options: filterOptions.tagsFilterOptions,
				value: activeTag,
			},
		];
	}, [
		activeCommitHash,
		activeBranch,
		activeTag,
		filterOptions,
		handleSetActiveCommitHash,
		handleSetActiveBranch,
		handleSetActiveTag,
	]);

	return {
		filterAutocompleteInputsData,
		filteredTestRuns,
	};
};
