import { useProjectSettings } from '@src/app/hooks/useProjectSettings';
import {
	useGetProjectSettingsQuery,
	useUpdateApplicationHasValidApplicationMutation,
} from '@src/modules/settings/api';
import { useCallback, useMemo } from 'react';
import { isRoleGreaterThan } from '@src/modules/authentication/service';
import {
	useActiveApplication,
	useAuthenticationInfo,
} from '@src/common/state/volatile/hooks';
import { SettingsStatus } from '@src/modules/settings/types';
import { ApplicationUserRoles } from '@src/modules/application/service';

export const useProjectSettingsForm = ({
	settingsStatus,
}: {
	settingsStatus?: SettingsStatus;
}) => {
	const isUserInternal = !!useAuthenticationInfo()?.user?.internal;

	const [
		updateHasValidLoginFunction,
		{ isLoading: isHasValidLoginFunctionUpdateLoading },
	] = useUpdateApplicationHasValidApplicationMutation();

	const activeApplication = useActiveApplication();

	const handleChangeApplicationHasValidLoginFunction = useCallback(
		(e) => {
			const userResponse = confirm('Are you sure you want to change?');

			if (userResponse) {
				updateHasValidLoginFunction({
					hasValidLoginFunction: e.target.checked,
				});
			}
		},
		[updateHasValidLoginFunction]
	);

	const { data: users } = useGetProjectSettingsQuery();
	const { userRole } = useProjectSettings();

	//detect if authenticated user can edit application users
	const isAuthenticatedUserAllowedToUpdate = useMemo(() => {
		return isRoleGreaterThan(userRole, ApplicationUserRoles.Member);
	}, [userRole]);

	return {
		applicationUsers: users ?? [],
		isAuthenticatedUserAllowedToUpdate,
		activeApplicationName: activeApplication?.name ?? '',
		apiKey: settingsStatus?.cliKey ?? '',
		isHasValidLoginFunctionUpdateLoading,
		handleChangeApplicationHasValidLoginFunction,
		isUserInternal,
	};
};
