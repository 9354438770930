import { ApplicationRoutes } from '@src/app/router/routes';
import { prepareModuleRoutes } from '@src/routing';

export const userStoriesRoutes = prepareModuleRoutes<'flows'>(
	{
		flows: {
			path: '/:storyId',
		},
	},
	ApplicationRoutes.userStories.absolutePath
);
