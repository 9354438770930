import { UserStoriesCollection } from '../types';
import {
	APIGetCollectionsResponse,
	APICollection,
	APIInboxDataResponse,
	InboxDataResponse,
	CreateCollectionAPIArgs,
	UpdateCollectionAPIArgs,
} from './types';
import { sortBy } from 'lodash';
import {
	APITestGenerationStatus,
	TestGenerationStatus,
} from '@src/modules/test-generations/service';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/constants';
import { transformAPIUserStories } from '@src/modules/user-stories/service';

export const transformAPIGetCollectionsResponse = (
	apiGetCollectionsResponse: APIGetCollectionsResponse
): UserStoriesCollection[] => {
	const collections = apiGetCollectionsResponse.map(
		transformAPIUserStoriesCollection
	);

	return sortBy(collections, [
		(collection: UserStoriesCollection) => -collection.storiesStatistics.count,
		(collection: UserStoriesCollection) =>
			-collection.storiesStatistics.statusCount[TestGenerationStatus.Completed],
	]);
};

export const transformAPIGetInboxDataResponse = (
	apiGetInboxDataResponse: APIInboxDataResponse
): InboxDataResponse => {
	const inboxCollection: UserStoriesCollection = {
		id: INBOX_COLLECTION_ID,
		name: 'Inbox',
		autoDetectFlowsDescription: null,
		storiesStatistics: transformAPIStoriesStatistics(
			apiGetInboxDataResponse.inboxStatistics
		),
		isFlowAutoDetectionActivated: false,
	};

	const userStories = transformAPIUserStories(
		apiGetInboxDataResponse.userStories
	);

	return {
		userStories,
		inboxCollection,
	};
};

export const transformAPIUserStoriesCollection = (
	apiCollection: APICollection
): UserStoriesCollection => {
	return {
		...apiCollection,
		description: apiCollection.description ?? '',
		isFlowAutoDetectionActivated: apiCollection.autoDetectFlows,
		storiesStatistics: transformAPIStoriesStatistics(
			apiCollection.storiesStatistics
		),
	};
};

export const transformAPIStoriesStatistics = (
	storiesStatistics: APICollection['storiesStatistics']
): UserStoriesCollection['storiesStatistics'] => {
	return {
		...storiesStatistics,
		statusCount: transformAPIPageStatusCount(storiesStatistics),
		startURLs: Object.keys(storiesStatistics.startURLs)
			.map((url) => ({
				url,
				count: storiesStatistics.startURLs[url],
			}))
			.sort((a, b) => b.count - a.count),
	};
};

const transformAPIPageStatusCount = (
	storiesStatistics: APICollection['storiesStatistics']
): UserStoriesCollection['storiesStatistics']['statusCount'] => {
	return {
		[TestGenerationStatus.Aborted]: 0,
		[TestGenerationStatus.Edited]: 0,
		[TestGenerationStatus.PRMerged]:
			storiesStatistics.statusCount[APITestGenerationStatus.PRMerged],
		[TestGenerationStatus.PRClosed]:
			storiesStatistics.statusCount[APITestGenerationStatus.PRClosed],
		[TestGenerationStatus.Completed]: storiesStatistics.statusCount.completed,
		[TestGenerationStatus.Verifying]: storiesStatistics.statusCount.verifying,
		[TestGenerationStatus.Failed]: storiesStatistics.statusCount.failed,
		[TestGenerationStatus.Queued]: storiesStatistics.statusCount.queued,
		[TestGenerationStatus.Running]: storiesStatistics.statusCount.running,
	};
};

export const transformCreateOrUpdateCollectionToAPI = ({
	defaultStartURL,
	...args
}: CreateCollectionAPIArgs | UpdateCollectionAPIArgs['update']) => {
	return {
		...args,
		defaultStartURL: defaultStartURL === '' ? null : defaultStartURL,
	};
};
