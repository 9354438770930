import { Route } from 'react-router-dom';
import { InternalAppRoute } from '../../../routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { useMemo } from 'react';
import { InternalUserPermissions } from '@src/modules/authentication/service';
import { TestGenerationLabelingRouter } from '@src/modules/test-generation-labeling/service';
import { InternalApplicationRoutes } from './routes';
import { InternalDefaultPage } from '../pages/InternalDefaultPage';
import { AllApplicationsPage } from '@src/modules/application/service';
import { SupportPage } from '../pages/SupportPage';

export function InternalRouter() {
	return useMemo(
		() => (
			<AppRouter>
				<Route
					path={InternalApplicationRoutes.internal.path}
					element={<InternalAppRoute component={InternalDefaultPage} />}
				/>
				<Route
					path={InternalApplicationRoutes.applications.path}
					element={<InternalAppRoute component={AllApplicationsPage} />}
				/>
				<Route
					path={InternalApplicationRoutes.testGenerationLabeling.routerPath}
					element={
						<InternalAppRoute
							component={TestGenerationLabelingRouter}
							permissions={InternalUserPermissions.TestGenerationLabeling}
						/>
					}
				/>
				<Route
					path={InternalApplicationRoutes.support.path}
					element={<InternalAppRoute component={SupportPage} />}
				/>
			</AppRouter>
		),
		[]
	);
}
