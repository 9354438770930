import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { grey } from '@mui/material/colors';

export const useViewOnlyTextBlockStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const block = css({
			minHeight: '100px',
			maxHeight: '200px',
			//add border color from theme
			border: `1px solid ${grey[300]}`,
			borderRadius: '4px',
			padding: theme.spacing(2),
			overflow: 'auto',
		});

		const content = css({
			whiteSpace: 'pre-wrap',
			wordBreak: 'normal',
		});

		return {
			content,
			block,
		};
	}, [theme]);
};
