import Config from '@src/config';
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSaveURLForTokenMutation } from '../../api';
import { downloadFileByUrl } from '@src/common/helpers/files';

const LONG_WAIT_THRESHOLD = 15_000;
const POLL_PERIOD = 5_000;

export const useTraceViewer = (traceFileURL: string) => {
	const [token, setToken] = useState<string>(null);
	const [saveURL, { isSuccess: isSaveURLSuccess }] =
		useSaveURLForTokenMutation();

	// whenever the traceFileURL changes, generate a new token and call API to save the URL
	useEffect(() => {
		const token = uuidv4();
		setToken(token);
		saveURL({ token, url: traceFileURL });
	}, [traceFileURL, saveURL]);

	// poll the API to check if the trace file is ready
	const intervalRef = useRef(null);
	const intervalStartTime = useRef(null);
	const [done, setDone] = useState(false);
	const [isLongWait, setIsLongWait] = useState(false);
	useEffect(() => {
		if (!isSaveURLSuccess) {
			return;
		}
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		intervalStartTime.current = Date.now();
		intervalRef.current = setInterval(async () => {
			const res = await fetch(
				`${Config.api.baseUrl}/api/file-proxy/status/${token}`
			);
			const json = await res.json();
			// if done, set done and clear the interval
			if (json.status === 'done') {
				setDone(true);
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
			// otherwise, if the wait is long, set the isLongWait flag
			else if (Date.now() - intervalStartTime.current > LONG_WAIT_THRESHOLD) {
				setIsLongWait(true);
			}
		}, POLL_PERIOD);
	}, [isSaveURLSuccess, token]);

	// generate the src URL for the iframe only after the save URL API call is successful
	const src = useMemo(() => {
		if (!isSaveURLSuccess || !token) {
			return null;
		}
		return `https://trace.playwright.dev/?trace=${Config.api.baseUrl}/api/file-proxy/stream/${token}`;
	}, [isSaveURLSuccess, token]);

	const downloadFile = useCallback(() => {
		downloadFileByUrl(traceFileURL, 'trace.zip');
	}, [traceFileURL]);

	return {
		done,
		src,
		isLongWait,
		downloadFile,
	};
};
