import {
	ApplicationUser,
	EnvironmentSettings,
	GithubAppIntegrationInfo,
	GitlabAppIntegrationInfo,
	SettingsStatus,
} from '@src/modules/settings/service';
import { APIApplication } from '@src/modules/application/api/types';

export type ApplicationSettings = Omit<
	APIApplication,
	'settings' | 'sdkKey' | 'apiKey' | 'hasValidLoginFunction'
> & {
	githubAppIntegrationInfo: GithubAppIntegrationInfo;
	gitlabAppIntegrationInfo: GitlabAppIntegrationInfo;
	environments: EnvironmentSettings[];
	settingsStatus: SettingsStatus;
};

export enum ApplicationUserRoles {
	Owner = 'owner',
	Admin = 'admin',
	Member = 'member',
}

export type AvailableApplication = Pick<ApplicationSettings, 'id' | 'name'> &
	Pick<ApplicationUser, 'role'>;

export type ApplicationForInternal = Pick<APIApplication, 'apiKey' | 'name'>;
