import { useMemo } from 'react';
import { useIsMobile } from '@src/common/hooks/useIsMobile';
import { useParams } from 'react-router-dom';

export const useMobileExploreUserStoriesCollection = () => {
	const { collectionId } = useParams<'collectionId'>();

	const isMobile = useIsMobile();

	const { displayCollections, displayUserStories } = useMemo(() => {
		const displayCollections = (isMobile && !collectionId) || !isMobile;
		const displayUserStories = (isMobile && !!collectionId) || !isMobile;

		return {
			displayCollections,
			displayUserStories,
		};
	}, [isMobile, collectionId]);

	return {
		isMobile,
		displayCollections,
		displayUserStories,
	};
};
