// remove warnings about dependencies, modal content should change on modal open/closed
/* eslint-disable  react-hooks/exhaustive-deps  */

import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { OpenedConfirmationModalVariant } from '@src/modules/user-stories/components/StoryForm/constants';
import { generateConfirmationDialogContent } from '@src/modules/user-stories/components/StoryForm/helpers/generateConfirmationDialogContent';
import { useNavigate } from 'react-router-dom';
import { settingsRouter } from '@src/modules/settings/service';

type Props = {
	isConfirmationAlertDialogOpened: boolean;
	setIsConfirmationAlertDialogOpened: Dispatch<SetStateAction<boolean>>;
	modalVariant: OpenedConfirmationModalVariant;
	onDelete: () => void;
	onAbort: () => void;
	handleCloseStory: () => void;
};

type GetHandlersProps = Omit<Props, 'modalVariant'>;

export const useGetConfirmationModalContent = ({
	isConfirmationAlertDialogOpened,
	setIsConfirmationAlertDialogOpened,
	modalVariant,
	onDelete,
	onAbort,
	handleCloseStory,
}: Props) => {
	const {
		settingsIncompleteHandlers,
		deleteHandlers,
		abortHandlers,
		unsavedChangesHandlers,
		noValidLoginFunctionHandlers,
	} = useGetConfirmationModalHandlers({
		isConfirmationAlertDialogOpened,
		setIsConfirmationAlertDialogOpened,
		onDelete,
		onAbort,
		handleCloseStory,
	});

	return useMemo(() => {
		switch (modalVariant) {
			case OpenedConfirmationModalVariant.DELETE: {
				return deleteHandlers;
			}

			case OpenedConfirmationModalVariant.UNSAVED_CHANGES: {
				return unsavedChangesHandlers;
			}

			case OpenedConfirmationModalVariant.SETTINGS_INCOMPLETE: {
				return settingsIncompleteHandlers;
			}

			case OpenedConfirmationModalVariant.NO_VALID_LOGIN_FUNCTION: {
				return noValidLoginFunctionHandlers;
			}

			case OpenedConfirmationModalVariant.ABORT: {
				return abortHandlers;
			}

			case OpenedConfirmationModalVariant.DEFAULT:
				return null;
		}
	}, [
		isConfirmationAlertDialogOpened,
		modalVariant,
		deleteHandlers,
		unsavedChangesHandlers,
		settingsIncompleteHandlers,
		noValidLoginFunctionHandlers,
		abortHandlers,
	]);
};

const useGetConfirmationModalHandlers = ({
	isConfirmationAlertDialogOpened,
	setIsConfirmationAlertDialogOpened,
	onDelete,
	onAbort,
	handleCloseStory,
}: GetHandlersProps) => {
	const navigate = useNavigate();

	const defaultOnSuccess = useCallback(() => {
		setIsConfirmationAlertDialogOpened(false);
	}, [setIsConfirmationAlertDialogOpened]);

	const deleteHandlers = useMemo(() => {
		const onCancel = () => {
			onDelete();
			setIsConfirmationAlertDialogOpened(false);
			handleCloseStory();
		};

		return generateConfirmationDialogContent(
			OpenedConfirmationModalVariant.DELETE,
			{ onSuccess: defaultOnSuccess, onCancel }
		);
	}, [isConfirmationAlertDialogOpened]);

	const unsavedChangesHandlers = useMemo(() => {
		return generateConfirmationDialogContent(
			OpenedConfirmationModalVariant.UNSAVED_CHANGES,
			{
				onSuccess: defaultOnSuccess,
				onCancel: () => {
					setIsConfirmationAlertDialogOpened(false);
					handleCloseStory();
				},
			}
		);
	}, [isConfirmationAlertDialogOpened]);

	const settingsIncompleteHandlers = useMemo(() => {
		const onCancel = () => {
			navigate(settingsRouter.wizard.absolutePath);
		};

		return generateConfirmationDialogContent(
			OpenedConfirmationModalVariant.SETTINGS_INCOMPLETE,
			{ onSuccess: defaultOnSuccess, onCancel }
		);
	}, [isConfirmationAlertDialogOpened]);

	const noValidLoginFunctionHandlers = useMemo(() => {
		return generateConfirmationDialogContent(
			OpenedConfirmationModalVariant.NO_VALID_LOGIN_FUNCTION,
			{ onSuccess: defaultOnSuccess }
		);
	}, [isConfirmationAlertDialogOpened]);

	const abortHandlers = useMemo(() => {
		const onCancel = () => {
			onAbort();
			setIsConfirmationAlertDialogOpened(false);
		};

		return generateConfirmationDialogContent(
			OpenedConfirmationModalVariant.ABORT,
			{ onSuccess: defaultOnSuccess, onCancel }
		);
	}, [isConfirmationAlertDialogOpened]);

	return {
		deleteHandlers,
		abortHandlers,
		unsavedChangesHandlers,
		settingsIncompleteHandlers,
		noValidLoginFunctionHandlers,
	};
};
