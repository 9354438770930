declare global {
	interface Window {
		_checksum_allow_incomplete_settings_generation: boolean;
		_checksum_allow_tg_abort_when_verifying: boolean;
	}
}

export const window_allow_incomplete_settings_generation = () =>
	window._checksum_allow_incomplete_settings_generation;

export const window_allow_tg_abort_when_verifying = () =>
	window._checksum_allow_tg_abort_when_verifying;

export {};
