import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { grey } from '@mui/material/colors';

export const useActionDescriptionStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2),
			border: `1px solid ${grey[300]}`,
			borderRadius: theme.spacing(2),
			gap: theme.spacing(1),
		});

		const row = css({
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(1),
		});

		return {
			container,
			row,
		};
	}, [theme]);
};
