import { ConfirmationDialog } from '@src/common/components/ConfirmationDialog/ConfirmationDialog';
import React, { Dispatch, SetStateAction } from 'react';
import { useDeleteIntegrationConfirmationModal } from '@src/modules/settings/components/GitSettings/components/DeleteIntegrationConfirmationModal/hooks';
import { GitAppMode } from '@src/modules/settings/components/GitSettings/hooks';

export const DeleteIntegrationConfirmationModal = ({
	deleteIntegrationModalOpenedMode,
	setDeleteIntegrationModalOpenedMode,
}: {
	deleteIntegrationModalOpenedMode: GitAppMode;
	setDeleteIntegrationModalOpenedMode: Dispatch<SetStateAction<GitAppMode>>;
}) => {
	const { modalContent } = useDeleteIntegrationConfirmationModal({
		deleteIntegrationModalOpenedMode,
		setDeleteIntegrationModalOpenedMode,
	});

	return <ConfirmationDialog {...modalContent} />;
};
