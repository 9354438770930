import { useLayoutOptions } from '@src/common/state/volatile/hooks';
import { LayoutOptions } from './types';
import { useMemo } from 'react';

export const useDefaultLayout = (layoutOptions?: LayoutOptions) => {
	const options = useLayoutOptions();
	const mergedOptions = useMemo(
		() => ({ ...options, ...layoutOptions }),
		[options, layoutOptions]
	);
	const containerMaxWidth = mergedOptions.containerMaxWidth ?? 'lg';
	return { options: mergedOptions, containerMaxWidth };
};
