import { useMemo } from 'react';
import { LayoutOptions } from '../DefaultLayout/types';

export const useInternalLayout = () => {
	const options = useMemo(() => {
		const options: Partial<LayoutOptions> = {
			hideSideNav: true,
			hideSettingsNotCompletedBanner: true,
			containerMaxWidth: 'xl',
		};
		return options;
	}, []);
	return { options };
};
