import { useCallback } from 'react';
import { setDoNotShowModalToOpenPrReviewInSeparateWindow } from '@src/common/state/persistent/slice';
import { useAppDispatch } from '@src/store/hooks';
import { useDoNotShowModalToOpenPrReviewInSeparateWindow } from '@src/common/state/persistent/hooks';
import { userStoriesExplorerRoutes } from '@src/modules/user-stories-exploration/service';
import { useNavigate } from 'react-router-dom';
import { UserStory } from '@src/modules/user-stories/types';

export const useOpenPrInNewWindowModal = ({
	story,
	onClose,
	activeCollectionId,
}: {
	story: UserStory;
	activeCollectionId: string;
	onClose: () => void;
}) => {
	const dispatch = useAppDispatch();
	const doNotShowModalToOpenPrReviewInSeparateWindow =
		useDoNotShowModalToOpenPrReviewInSeparateWindow();

	const navigate = useNavigate();

	const handleChangeCheckbox = useCallback(
		(e, value: boolean) => {
			dispatch(setDoNotShowModalToOpenPrReviewInSeparateWindow(value));
		},
		[dispatch]
	);

	const onSubmit = useCallback(() => {
		window.open(
			story.recentGeneration.pullRequestURL,
			'_blank',
			'rel=noopener noreferrer'
		);

		onClose();

		// open story form after open pr
		navigate(
			userStoriesExplorerRoutes.flow.make({
				collectionId: activeCollectionId,
				storyId: story.id,
			})
		);
	}, [story, onClose, navigate, activeCollectionId]);

	return {
		onSubmit,
		checkboxValue: doNotShowModalToOpenPrReviewInSeparateWindow,
		handleChangeCheckbox,
	};
};
