import { useMemo } from 'react';
import useToggle from '@src/common/hooks/useToggle';
import { TestGenerationLogStep } from '../../types';
import Config from '@src/config';
import { APIActionHistory } from '@src/modules/test-generation-labeling/api/types';

const LLMResponseKey = Config.labeling.responseKey;

export const useActionDescription = ({
	actionHistory,
	activeTestGenerationLogStep,
	activeStoredStep,
}: {
	actionHistory?: APIActionHistory[];
	activeTestGenerationLogStep: TestGenerationLogStep;
	activeStoredStep: TestGenerationLogStep;
}) => {
	const actionHistoryThoughts = useMemo(() => {
		if (!actionHistory) {
			return '';
		}

		return actionHistory?.map(({ thought }) => thought).join(',\n');
	}, [actionHistory]);

	const actionType = useMemo(() => {
		if (!activeTestGenerationLogStep) {
			return;
		}

		if (LLMResponseKey === 'sanitizedLLMResponse') {
			return activeTestGenerationLogStep.sanitizedLLMResponse?.action?.type;
		}

		return activeTestGenerationLogStep.rawLLMResponseJSON?.command?.args?.type;
	}, [activeTestGenerationLogStep]);

	const {
		showLabeledResponse,
		toggleShowOriginalOrLabeledResponse,
		responseForDisplay,
	} = useReadOnlyLLMResponse({
		activeTestGenerationLogStep,
		activeStoredStep,
	});

	return {
		actionType,
		actionHistoryThoughts,
		showLabeledResponse,
		toggleShowOriginalOrLabeledResponse,
		responseForDisplay,
	};
};

export const useReadOnlyLLMResponse = ({
	activeTestGenerationLogStep,
	activeStoredStep,
}: {
	activeTestGenerationLogStep: TestGenerationLogStep;
	activeStoredStep: TestGenerationLogStep;
}) => {
	const {
		isOn: showLabeledResponse,
		toggle: toggleShowOriginalOrLabeledResponse,
	} = useToggle(true);

	// sanitized response according to switch view mode
	const responseForDisplay = useMemo(() => {
		if (!activeTestGenerationLogStep) {
			return;
		}

		if (!activeStoredStep) {
			return activeTestGenerationLogStep[LLMResponseKey];
		}

		return showLabeledResponse
			? activeStoredStep[LLMResponseKey]
			: activeTestGenerationLogStep[LLMResponseKey];
	}, [activeStoredStep, activeTestGenerationLogStep, showLabeledResponse]);

	return {
		showLabeledResponse,
		toggleShowOriginalOrLabeledResponse,
		responseForDisplay,
	};
};
