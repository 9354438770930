import {
	githubAppIntegrationInfo,
	GithubAppIntegrationStatus,
	listSettingsMock,
	settingsStatusInfo,
} from '@src/modules/settings/service';
import { handleAddEventsToSessionRecordingsList } from '@src/modules/session-recordings/service';
import { userStoriesMock } from '@src/modules/user-stories/service';
import { APITestGenerationStatus } from '@src/modules/test-generations/service';

const closePr = (storyId: string, status: APITestGenerationStatus) => {
	const story = userStoriesMock.find(({ id }) => id === storyId);

	story.testGenerations.last().prClosedAt = new Date().toISOString();
	story.testGenerations.last().status = status;
};

export const mockClosePr = (storyId: string) => {
	closePr(storyId, APITestGenerationStatus.PRClosed);
};

export const mockMergePr = (storyId: string) => {
	closePr(storyId, APITestGenerationStatus.PRMerged);
};

export const mockConfirmSetupSdk = (value?: boolean) => {
	settingsStatusInfo.sdkReceivedSessionRecordings = value ?? true;

	handleAddEventsToSessionRecordingsList();
};

export const mockGithubAppIntegration = (
	value?: GithubAppIntegrationStatus
) => {
	if (value === 'not-installed') {
		githubAppIntegrationInfo.installed = false;
	}

	settingsStatusInfo.githubAppIntegrationStatus = value ?? 'active';
};

export const mockDefaultEnvironmentName = (value?: string) => {
	//TODO: add a settingsProfile identificator to change the profile name in
	//specific mock profileSettings obj

	const environmentName = value ?? 'Default';

	listSettingsMock[0].name = environmentName;
	settingsStatusInfo.hasValidEnvironmentSettings = true;
};
