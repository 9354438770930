import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UserStory } from '@src/modules/user-stories/service';
import {
	matchPath,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { userStoriesExplorerRoutes } from '@src/modules/user-stories-exploration/routes/routes';
import { usePrevious } from 'react-use';
import { makeEmptyUserStory } from '@src/modules/user-stories/service';
import { UserStoriesCollection } from '@src/modules/collections/service';

export const useActiveStory = ({
	stories,
	activeCollection,
}: {
	stories: UserStory[];
	activeCollection: UserStoriesCollection;
}) => {
	const { storyId } = useParams<'storyId'>();
	const navigate = useNavigate();
	const location = useLocation();

	//ref to prevent set stories after invalidation after new story is created
	const isNewStoryCreatedRef = useRef(false);

	const pathRequiresActiveStory = !!useMemo(
		() =>
			matchPath(userStoriesExplorerRoutes.flow.absolutePath, location.pathname),
		[location.pathname]
	);

	const previousStoryId = usePrevious(storyId);
	const [activeStory, setActiveStory] = useState<UserStory>(
		pathRequiresActiveStory ? getStoryById(stories, storyId) : undefined
	);

	// when the storyId param wasn't provided,
	// once establishing the active story, navigate to it
	useEffect(() => {
		if (!pathRequiresActiveStory) {
			return;
		}
		if (!storyId && activeStory) {
			navigate(
				userStoriesExplorerRoutes.flow.make({
					collectionId: activeCollection?.id,
					storyId: activeStory.id,
				})
			);
		}
	}, [
		storyId,
		activeStory,
		activeCollection,
		navigate,
		pathRequiresActiveStory,
	]);

	// when the active story isn't set or the storyId param changes,
	// set the active story accordingly
	useEffect(() => {
		if (!pathRequiresActiveStory) {
			return;
		}
		if (
			(!activeStory || storyId !== previousStoryId) &&
			!isNewStoryCreatedRef.current
		) {
			setActiveStory(getStoryById(stories, storyId));
		}
	}, [stories, activeStory, storyId, previousStoryId, pathRequiresActiveStory]);

	// update the active story when stories change
	useEffect(() => {
		if (
			activeStory &&
			activeStory.id === storyId &&
			!isNewStoryCreatedRef.current
		) {
			setActiveStory(getStoryById(stories, activeStory.id));
		}
		// set is new story created to false after first invalidation of stories
		isNewStoryCreatedRef.current = false;
	}, [stories, activeStory, storyId]);

	// when active story is no longer required, clear it
	useEffect(() => {
		if (!pathRequiresActiveStory && activeStory) {
			setActiveStory(undefined);
		}
	}, [pathRequiresActiveStory, activeStory, setActiveStory]);

	const [newStory, setNewStory] = useState<UserStory>(undefined);
	const handleCreateNewStory = useCallback(() => {
		setNewStory(
			makeEmptyUserStory({
				collectionId: activeCollection?.id,
				defaultStartURL: activeCollection?.defaultStartURL,
			})
		);
	}, [setNewStory, activeCollection]);

	// prepare quit function which navigates away from the active story
	const handleCloseStory = useCallback(() => {
		setNewStory(undefined);
		setActiveStory(undefined);
		isNewStoryCreatedRef.current = false;

		navigate(
			userStoriesExplorerRoutes.userStoriesCollection.make(
				{
					collectionId: activeCollection?.id,
				},
				location.search.toString()
			)
		);
	}, [activeCollection, location.search, navigate, setNewStory]);

	// when a new story is created, navigate to the relevant page
	const handleNewStoryCreatedSuccessfully = useCallback(
		(createdStory: UserStory & { collection: { id: string } }) => {
			isNewStoryCreatedRef.current = true;
			setActiveStory(createdStory);
			setNewStory(undefined);

			navigate(
				userStoriesExplorerRoutes.flow.make({
					collectionId: createdStory.collectionId,
					storyId: createdStory.id,
				})
			);
		},
		[navigate]
	);

	return {
		activeStory: newStory ?? activeStory,
		handleCloseStory,
		handleCreateNewStory,
		handleNewStoryCreatedSuccessfully,
	};
};

const getStoryById = (stories: UserStory[], id?: string): UserStory => {
	return id ? stories?.find((story) => story.id === id) : undefined;
};
