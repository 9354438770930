import { useAuthCode } from '@src/common/state/persistent/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticateRoutes } from '@src/modules/authentication/routes/routes';
import { useLazyGetSetupInfoQuery } from '@src/modules/authentication/api';
import { settingsRouter } from '@src/modules/settings/service';

export const useCreateNewApplicationPage = () => {
	const authCode = useAuthCode();

	const [getSetupInfo] = useLazyGetSetupInfoQuery();
	const [isLoginLoading, setIsLoginLoading] = useState(false);

	const navigate = useNavigate();

	const afterCreateCallback = useCallback(async () => {
		await getSetupInfo(true);
		//set loading manually to prevent state change on unmount
		setIsLoginLoading(false);
		navigate(settingsRouter.wizard.absolutePath);
	}, [getSetupInfo, navigate]);

	useEffect(() => {
		if (!authCode) {
			navigate(authenticateRoutes.authenticate.absolutePath);
		}
	}, [authCode, navigate]);

	return {
		afterCreateCallback,
		isLoginLoading,
	};
};
