import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { ReactNode } from 'react';

export const TextFieldLabelWithInfo = ({
	title,
	info,
	required = false,
}: {
	title: ReactNode;
	info?: ReactNode;
	required?: boolean;
}) => {
	return (
		<Stack flexDirection={'row'} alignItems={'center'} gap={1}>
			{title}
			<If condition={required}>*</If>
			<If condition={!!info}>
				<Tooltip title={info}>
					<InfoOutlined />
				</Tooltip>
			</If>
		</Stack>
	);
};
