import {
	useCreateSettingsProfileMutation,
	useDeleteEnvironmentMutation,
	useUpdateSettingsProfileMutation,
} from '@src/modules/settings/api';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { EnvironmentSettings } from '@src/modules/settings/types';

export type UseSettingsAPIProps = {
	setNewEnvironmentId: Dispatch<SetStateAction<string>>;
	activeEnvironmentSettings: EnvironmentSettings;
	environments: EnvironmentSettings[];
};

export const useSettingsAPI = ({
	setNewEnvironmentId,
	activeEnvironmentSettings,
	environments,
}: UseSettingsAPIProps) => {
	const [
		createSettingsProfile,
		{ isLoading: isCreating, data: createdEnvironment },
	] = useCreateSettingsProfileMutation();

	const [updateSettingsProfile, { isLoading: isUpdating }] =
		useUpdateSettingsProfileMutation();

	const [handleDeleteEnvironment] = useDeleteEnvironmentMutation();

	const isSaving = isCreating || isUpdating;

	const save = useCallback(
		(values) => {
			if (activeEnvironmentSettings?.id) {
				return updateSettingsProfile({
					id: activeEnvironmentSettings.id,
					update: values,
				});
			} else {
				return createSettingsProfile(values);
			}
		},
		[activeEnvironmentSettings, createSettingsProfile, updateSettingsProfile]
	);

	const onDeleteEnvironment = useCallback(async () => {
		if (activeEnvironmentSettings.isDefault) {
			toast.error('Can not delete default environment');
			return;
		}

		if (activeEnvironmentSettings.id) {
			await handleDeleteEnvironment({ id: activeEnvironmentSettings.id });

			// set default environment as active after optimistic delete
			setNewEnvironmentId(
				(environments.find(({ isDefault }) => isDefault) ?? environments[0])?.id
			);
		}
	}, [
		handleDeleteEnvironment,
		environments,
		activeEnvironmentSettings,
		setNewEnvironmentId,
	]);

	useEffect(() => {
		if (createdEnvironment) {
			setNewEnvironmentId(createdEnvironment.id);
		}
	}, [createdEnvironment, setNewEnvironmentId]);

	return {
		save,
		isSaving,
		onDeleteEnvironment,
	};
};
