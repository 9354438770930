import { css, useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useScreenshotsSelectStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			minWidth: '200px',
			marginBottom: theme.spacing(1),
		});

		return {
			container,
		};
	}, [theme]);
};
