import { useCallback } from 'react';
import useBoolean from 'react-use/esm/useBoolean';

const useToggle = (
	initState = false
): {
	isOn: boolean;
	off: () => void;
	on: () => void;
	toggle: () => void;
} => {
	const [isOn, toggle] = useBoolean(initState);
	const on = useCallback(() => {
		toggle(true);
	}, [toggle]);
	const off = useCallback(() => {
		toggle(false);
	}, [toggle]);

	return { isOn, off, on, toggle };
};

export default useToggle;
