export function validateDuplicateTags(items) {
	const tagMap: { [key: string]: Set<string> } = {};
	const duplicates = [];

	// First pass: build the tag map
	items.forEach((item) => {
		if (!tagMap[item.tag]) {
			tagMap[item.tag] = new Set();
		}
		tagMap[item.tag].add(item.fileId);
	});

	// Second pass: check for duplicates
	items.forEach((item) => {
		if (tagMap[item.tag].size > 1) {
			duplicates.push(item);
		}
	});

	return duplicates;
}
