import { APITestGenerationStatus } from '@src/modules/test-generations/service';
import { v4 as uuid } from 'uuid';
import {
	APICollection,
	APIInboxDataResponse,
	CreateCollectionAPIArgs,
	StoriesStatistics,
} from '@src/modules/collections/api/types';
import {
	APIUserStory,
	userStoriesMock,
} from '@src/modules/user-stories/service';

export const createAPICollection = (
	body: CreateCollectionAPIArgs
): APICollection => {
	return {
		id: uuid(),
		name: body.name,
		autoDetectFlows: false,
		autoDetectFlowsDescription: null,
		storiesStatistics: getDefaultStatistics(),
	};
};

export const makeStatisticsForCollection = (
	collectionId: string
): StoriesStatistics => {
	return userStoriesMock
		.filter((story) => story.collection.id === collectionId)
		.reduce((acc, story) => {
			const recentGeneration = getRecentTestGeneration(story);

			acc.count++;
			if (recentGeneration) {
				acc.statusCount[recentGeneration.status]++;
			}
			acc.startURLs[story.startURL] = acc.startURLs[story.startURL]
				? acc.startURLs[story.startURL] + 1
				: 1;
			return acc;
		}, getDefaultStatistics());
};

export const makeAPIInboxResponse = (): APIInboxDataResponse => {
	// show user stories that are not cleared after recent action
	const { inboxUserStories, statistics: inboxStatistics } =
		getInboxUserStoriesData();

	return {
		userStories: inboxUserStories,
		inboxStatistics,
	};
};

const getIsStoryInInbox = (story, recentTestGeneration) => {
	const recentAction = recentTestGeneration?.completedAt ?? story.createdAt;

	return !!story.userStoryEngagements?.at(0)?.clearedAt
		? recentAction > story.userStoryEngagements?.at(0)?.clearedAt
		: true;
};

const getInboxUserStoriesData = () => {
	return userStoriesMock.reduce(
		(acc, story) => {
			const recentTestGeneration = getRecentTestGeneration(story);

			if (getIsStoryInInbox(story, recentTestGeneration)) {
				acc.inboxUserStories.push(story);
			}

			if (!!recentTestGeneration) {
				acc.statistics.statusCount[recentTestGeneration.status]++;
			}
			acc.statistics.count++;

			return acc;
		},
		{ statistics: getDefaultStatistics(), inboxUserStories: [] }
	);
};

const getRecentTestGeneration = (story: APIUserStory) => {
	return story.testGenerations
		.reverse()
		.find((generation) => !generation.isAborted);
};

const getDefaultStatistics = () => ({
	count: 0,
	statusCount: {
		[APITestGenerationStatus.Completed]: 0,
		[APITestGenerationStatus.Failed]: 0,
		[APITestGenerationStatus.Verifying]: 0,
		[APITestGenerationStatus.Queued]: 0,
		[APITestGenerationStatus.Running]: 0,
		[APITestGenerationStatus.PRClosed]: 0,
		[APITestGenerationStatus.PRMerged]: 0,
	},
	startURLs: {},
});
