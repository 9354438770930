import { ScreenshotSelectOption } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/types';

export enum ScreenshotOptions {
	DEFAULT = 'screenshot',
	TAGGED = 'checksumIdTaggedScreenshot',
}

//screenshot select options
export const ScreenshotSelectOptions: ScreenshotSelectOption[] = [
	{
		title: 'screenshot',
		value: ScreenshotOptions.DEFAULT,
	},
	{
		title: 'checksum tagged screenshot',
		value: ScreenshotOptions.TAGGED,
	},
];
