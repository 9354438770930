import LogProviderInterface, {
	LogProviderOptions,
} from '@src/services/logger/providers/LogProviderInterface';
import Logger from 'js-logger';
import { LogVerbosity } from '@src/services/logger/types';

// set defaults for js-logger
// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults({
	defaultLevel: Logger.TRACE,
	formatter: function (messages, context) {
		if (context.name) {
			messages.unshift(`[${context.name}]`);
		}
		messages.unshift(new Date().toLocaleTimeString().split(' ')[0]);
	},
});

class ConsoleLogProvider implements LogProviderInterface {
	private logger;

	constructor(
		component: string,
		{ subComponent = undefined }: LogProviderOptions
	) {
		const name = `${component}${subComponent ? `/${subComponent}` : ''}`;
		this.logger = Logger.get(name);
	}

	log(verbosity: LogVerbosity, message: string, data: object, params) {
		switch (verbosity) {
			default:
			case LogVerbosity.Debug:
				return this.logger.debug(message, data, params);
			case LogVerbosity.Info:
				return this.logger.info(message, data, params);
			case LogVerbosity.Warn:
				return this.logger.warn(message, data, params);
			case LogVerbosity.Error:
			case LogVerbosity.Critical:
				return this.logger.error(message, data, params);
			case LogVerbosity.Trace:
				return this.logger.trace(message, data, params);
		}
	}
}

export default ConsoleLogProvider;
