import {
	AccountTreeOutlined,
	FactCheckOutlined,
	PlayCircleOutline,
	Settings,
	SvgIconComponent,
} from '@mui/icons-material';
import { ApplicationRoutes } from '@src/app/router/routes';
import Config from '@src/config';

export const DrawerWidth = 60;
type DrawerButton = {
	text: string;
	icon: SvgIconComponent;
	path: string;
	hide?: boolean;
};

export const TopDrawerButtons: DrawerButton[] = [
	{
		text: 'Session Recordings',
		icon: PlayCircleOutline,
		path: ApplicationRoutes.sessionRecordings.path,
		hide: Config.env === 'production',
	},
	{
		text: 'Test Generation',
		icon: AccountTreeOutlined,
		path: ApplicationRoutes.userStoriesExplorer.path,
	},
	{
		text: 'Test Results',
		icon: FactCheckOutlined,
		path: ApplicationRoutes.testRuns.path,
	},
];

export const BottomDrawerButtons: DrawerButton[] = [
	{
		text: 'Settings',
		icon: Settings,
		path: ApplicationRoutes.settings.path,
	},
];

export const DrawerButtons = [...TopDrawerButtons, ...BottomDrawerButtons];
