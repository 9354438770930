import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { APIAuthenticationResult } from '@src/modules/authentication/api/types';
import { transformAPIAuthenticationResult } from '@src/modules/authentication/api/transformers';
import { APIDependencies } from '@src/api/constants';
import {
	setPersistentActiveApplicationId,
	setPersistentAuthenticationData,
} from '@src/common/state/persistent/slice';
import {
	setActiveApplication,
	setAuthenticationStatus,
	setRedirectToAfterAuthentication,
} from '@src/common/state/volatile/slice';
import { ApplicationRoutes } from '@src/app/router/routes';
import { authenticateRoutes } from '@src/modules/authentication/routes/routes';
import { AuthenticationResult } from '@src/modules/authentication/types';
import { settingsAPI } from '@src/modules/settings/service';

export const processAuthenticationResponse = (
	data: APIAuthenticationResult,
	/* eslint-disable-next-line */
	dispatch: ThunkDispatch<any, any, AnyAction>,
	shouldSetPersistentAuthenticationData = false
) => {
	const authenticationResult = transformAPIAuthenticationResult(data);

	dispatch(
		setRedirectToAfterAuthentication(
			getRedirectAfterLoginPath(authenticationResult)
		)
	);

	if (shouldSetPersistentAuthenticationData) {
		dispatch(setPersistentAuthenticationData(authenticationResult));
	}

	if (!!data.applications.length) {
		const persistentApplicationId =
			APIDependencies?.getState()?.persistent?.appId;

		let activeApplication =
			authenticationResult.applications.find(
				({ id }) => id === persistentApplicationId
			) ??
			authenticationResult.applications[0] ??
			null;

		if (activeApplication) {
			dispatch(setPersistentActiveApplicationId(activeApplication.id));
		}

		dispatch(setActiveApplication(activeApplication));

		//initiate the get applications query
		dispatch(
			settingsAPI.util.upsertQueryData(
				'getApplications',
				undefined,
				authenticationResult?.applications ?? []
			)
		);
	}

	dispatch(setAuthenticationStatus(authenticationResult));
};

const getRedirectAfterLoginPath = (
	authenticationResult: AuthenticationResult
) => {
	// for new user create an
	if (
		!authenticationResult.applications.length &&
		!authenticationResult.user.internal
	) {
		return authenticateRoutes.newApplication.absolutePath;
	}

	if (
		authenticationResult.user.internal ||
		!!authenticationResult.applications.length
	) {
		return ApplicationRoutes.userStoriesExplorer.path;
	}

	// means no redirects, should stay in the authentication page
	return null;
};
