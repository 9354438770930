import {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormValues } from '@src/modules/user-stories/components/StoryForm/types';
import { useGetAllUserStoriesFilesQuery } from '@src/modules/user-stories/api';
import { v4 as uuid } from 'uuid';
import { usePrevious } from 'react-use';
import { toast } from 'react-toastify';
import { validateDuplicateTags } from './helpers';

export const useFilesViewAndUpload = ({
	control,
	activeUserStoryId,
	triggerFieldValidation,
	userStoriesFilesWatcher,
	submitCount,
	errors = {},
}) => {
	const {
		fields,
		prepend,
		remove: removeDynamicField,
		replace,
		update,
	} = useFieldArray<FormValues>({
		control,
		name: 'userStoryFiles' as never,
	});

	// all files that are already uploaded in active application
	const { data: allFilesForApplication = [] } = useGetAllUserStoriesFilesQuery({
		id: activeUserStoryId,
	});

	// available options to select in dropdown
	const options = useMemo(
		() => allFilesForApplication.map(({ tag }) => tag),
		[allFilesForApplication]
	);

	// backup files to cancel changes
	const backupFieldsRef = useRef([]);

	const handleUpdateStateFilesWithBackup = useCallback(() => {
		replace(backupFieldsRef.current);
	}, [replace]);

	const setBackupFiles = useCallback(() => {
		backupFieldsRef.current = fields;
	}, [fields]);

	// file indexes to show in an edit modal
	const [fileIndexesToUpdate, setFileIndexesToUpdate] = useState([]);
	const [isEditModalOpened, setIsEditModalOpened] = useState(false);

	const [fileIndexToDelete, setFileIndexToDelete] = useState(NaN);

	const isDeleteConfirmationModalOpened = !Number.isNaN(fileIndexToDelete);

	const { handleSelectFileFromDropdown, handleFileChange } =
		useFilesChangeHandlers({
			prepend,
			allFilesForApplication,
			setFileIndexesToUpdate,
			setIsEditModalOpened,
			setBackupFiles,
		});

	const { closeModal, onEdit, onCancel } = useEditFiles({
		setBackupFiles,
		setIsEditModalOpened,
		setFileIndexesToUpdate,
		handleUpdateStateFilesWithBackup,
	});

	const {
		buttons,
		onCloseDeleteConfirmationModal,
		fileToDeleteConfirmationDescription,
		removeFile,
	} = useDeleteConfirmationModal({
		setFileIndexToDelete,
		userStoriesFilesWatcher,
		fileIndexToDelete,
		fields,
		setFileIndexesToUpdate,
		removeDynamicField,
	});

	const { onSave } = useOnSave({
		allFilesForApplication,
		setFileIndexesToUpdate,
		userStoriesFilesWatcher,
		update,
		setIsEditModalOpened,
		triggerFieldValidation,
	});

	// state dynamic files fields with index field to support delete and edit in modal
	const fieldsToEditWithIndexes = useMemo(
		() =>
			fileIndexesToUpdate.map((index) => ({
				...fields[index],
				index,
			})),
		[fileIndexesToUpdate, fields]
	);

	// detect if userStoryFiles validation failed
	const isStoryFilesErrors = useMemo(
		() =>
			Object.keys(errors).some((errorKey) =>
				errorKey.includes('userStoryFiles[')
			),
		[errors]
	);

	const previousSubmitCount = usePrevious(submitCount);

	// if form has been submitted and userStoryFiles validation failed
	// open modal with fields marked with error messages
	useEffect(() => {
		if (submitCount - previousSubmitCount === 1 && isStoryFilesErrors) {
			setFileIndexesToUpdate(
				Array.from(Array(userStoriesFilesWatcher.length).keys())
			);
			setIsEditModalOpened(true);
		}
	}, [
		submitCount,
		userStoriesFilesWatcher,
		previousSubmitCount,
		isStoryFilesErrors,
	]);

	const allowUploadSameFileAgain = useCallback((event) => {
		event.target.value = null;
	}, []);

	return {
		isEditModalOpened,
		options,
		handleSelectFileFromDropdown,
		handleFileChange,
		removeFile,
		closeModal,
		onSave,
		fieldsToEditWithIndexes,
		onEdit,
		onCancel,
		userStoriesFilesWatcher,
		isDeleteConfirmationModalOpened,
		onCloseDeleteConfirmationModal,
		buttons,
		fileToDeleteConfirmationDescription,
		allowUploadSameFileAgain,
	};
};

const useOnSave = ({
	allFilesForApplication,
	userStoriesFilesWatcher,
	triggerFieldValidation,
	setFileIndexesToUpdate,
	setIsEditModalOpened,
	update,
}) => {
	const getAllFilesToValidate = useCallback(
		(values = []) => {
			const newStoryFiles = values.filter((storyFile) => !storyFile.fileId);

			const existingMutatedFiles = allFilesForApplication.reduce(
				(acc, existingFile) => {
					const existingMutatedFile = values.find(
						(formStoryFile) =>
							formStoryFile.id === existingFile.id &&
							formStoryFile.tag !== existingFile.tag
					);

					if (!!existingMutatedFile) {
						acc.push(existingMutatedFile);
					}
					acc.push(existingFile);

					return acc;
				},
				[]
			);

			return validateDuplicateTags([...newStoryFiles, ...existingMutatedFiles]);
		},
		[allFilesForApplication]
	);

	const onSave = useCallback(async () => {
		const isInvalid = getAllFilesToValidate([...userStoriesFilesWatcher]);
		// trigger userStoryFiles field validation

		const fieldsToValidate = userStoriesFilesWatcher.map(
			(_, index) => `userStoryFiles[${index}].tag`
		);

		const res = await triggerFieldValidation(fieldsToValidate);

		if (isInvalid.length > 0) {
			toast.error(
				`Duplication found for tag: ${
					isInvalid.at(0)?.tag ?? ''
				}. Tag must be unique`
			);

			return false;
		}

		// in case validation failed
		if (!res) {
			return false;
		}

		//close edit modal
		setFileIndexesToUpdate([]);
		setIsEditModalOpened(false);

		// update fields state after each save as it is not updated after each value change
		userStoriesFilesWatcher.forEach((value, index) => {
			update(index, value);
		});

		return true;
	}, [
		setIsEditModalOpened,
		setFileIndexesToUpdate,
		triggerFieldValidation,
		userStoriesFilesWatcher,
		update,
		getAllFilesToValidate,
	]);

	return {
		onSave,
	};
};

const useDeleteConfirmationModal = ({
	setFileIndexToDelete,
	userStoriesFilesWatcher,
	fileIndexToDelete,
	fields,
	setFileIndexesToUpdate,
	removeDynamicField,
}) => {
	const fileName = userStoriesFilesWatcher[fileIndexToDelete]?.tag;
	const fileToDeleteConfirmationDescription = `Are you sure you want to remove file "${fileName}" from this flow?`;

	const onCloseDeleteConfirmationModal = useCallback(() => {
		setFileIndexToDelete(NaN);
	}, [setFileIndexToDelete]);

	const removeFile = useCallback(
		(index) => {
			setFileIndexToDelete(index);
		},
		[setFileIndexToDelete]
	);

	const handleRemoveFileOnConfirm = useCallback(() => {
		// find file state type file from state
		const fileToRemove = fields[fileIndexToDelete];

		// remove file from state
		if (fileToRemove) {
			setFileIndexesToUpdate((prevState) =>
				prevState.filter((stateIndex) => stateIndex !== fileIndexToDelete)
			);
			// remove file from indexes useState in case edit modal opened
			removeDynamicField(fileIndexToDelete);
		}
		onCloseDeleteConfirmationModal();
	}, [
		onCloseDeleteConfirmationModal,
		fileIndexToDelete,
		removeDynamicField,
		setFileIndexesToUpdate,
		fields,
	]);

	const buttons = [
		{
			callback: onCloseDeleteConfirmationModal,
			text: 'cancel',
		},
		{
			callback: handleRemoveFileOnConfirm,
			text: 'continue',
		},
	];

	return {
		removeFile,
		buttons,
		onCloseDeleteConfirmationModal,
		fileToDeleteConfirmationDescription,
	};
};

const useEditFiles = ({
	setFileIndexesToUpdate,
	setIsEditModalOpened,
	setBackupFiles,
	handleUpdateStateFilesWithBackup,
}) => {
	const closeModal = useCallback(() => {
		setFileIndexesToUpdate([]);
		setIsEditModalOpened(false);
	}, [setFileIndexesToUpdate, setIsEditModalOpened]);

	const onEdit = useCallback(
		(index) => {
			setBackupFiles();

			setFileIndexesToUpdate([index]);
			setIsEditModalOpened(true);
		},
		[setBackupFiles, setFileIndexesToUpdate, setIsEditModalOpened]
	);

	// if changes were made in Edit Modal cancel button handler
	const onCancel = useCallback(() => {
		// replace all userStoryFiles fields with locked backup values
		handleUpdateStateFilesWithBackup();

		closeModal();
	}, [handleUpdateStateFilesWithBackup, closeModal]);

	return {
		onCancel,
		closeModal,
		onEdit,
	};
};

const useFilesChangeHandlers = ({
	prepend,
	setIsEditModalOpened,
	setFileIndexesToUpdate,
	allFilesForApplication,
	setBackupFiles,
}) => {
	// select files from native file picker handler
	const handleFileChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			setBackupFiles();

			const files = Array.from(event.target.files);

			// format files to state type
			const formattedFiles = files.map((file) => ({
				formFieldId: uuid(),
				fileId: '',
				file,
				tag: '',
				name: file.name,
				type: file.type,
			}));

			// update state
			prepend(formattedFiles);

			// open edit files modal
			setIsEditModalOpened(true);
			setFileIndexesToUpdate(Array.from(Array(files.length).keys()));
		},
		[prepend, setBackupFiles, setFileIndexesToUpdate, setIsEditModalOpened]
	);

	// select file from autocomplete dropdown handler
	const handleSelectFileFromDropdown = useCallback(
		(event, value) => {
			// don't allow deselecting from dropdown
			if (event.target.ariaSelected === 'true') {
				return;
			}
			// last option that was selected
			const newValue = value[value.length - 1];

			// find file from all available story files list for application
			const { id, file, tag } = allFilesForApplication.find(
				({ tag }) => tag === newValue
			);

			// update state with formatted file object
			prepend({
				formFieldId: id,
				id,
				fileId: file.id,
				tag: tag,
				name: file.name,
				file,
			});
		},
		[allFilesForApplication, prepend]
	);

	return {
		handleFileChange,
		handleSelectFileFromDropdown,
	};
};
