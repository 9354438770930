import { SettingsStatus } from '@src/modules/settings/service';
import { GitHub } from '@mui/icons-material';
import { ReactComponent as GitLab } from '@assets/icons/GitLab.svg';

export const GitIcon = ({
	settingsStatus,
}: {
	settingsStatus?: SettingsStatus;
}) => {
	return (
		<If condition={!!settingsStatus}>
			<If condition={settingsStatus.isGithubAppSuccessfullyIntegrated}>
				<GitHub />
			</If>

			<If condition={settingsStatus.isGitlabAppSuccessfullyIntegrated}>
				<GitLab />
			</If>
		</If>
	);
};
