import { SettingsStatus } from '@src/modules/settings/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useProjectSettings } from '@src/app/hooks/useProjectSettings';
import { isRoleGreaterThan } from '@src/modules/authentication/service';
import {
	ApplicationUserRoles,
	useApplicationSettingsQueryState,
} from '@src/modules/application/service';

export enum GitAppMode {
	Gitlab = 'gitlab',
	Github = 'github',
	ChooseApp = 'choose',
	Loading = 'loading',
}

export const useGitSettings = ({
	settingsStatus,
}: {
	settingsStatus: SettingsStatus;
}) => {
	const {
		data: applicationSettings,
		isLoading: isLoadingGithubAppIntegrationInfo,
	} = useApplicationSettingsQueryState();

	const githubAppIntegrationInfo = useMemo(() => {
		return applicationSettings?.githubAppIntegrationInfo;
	}, [applicationSettings]);

	const [modeToShow, setModeToShow] = useState(GitAppMode.Loading);

	// ref to prevent set choose mode after initial setup
	const initialSetupRef = useRef(false);

	const handleChooseGitLab = useCallback((e) => {
		setModeToShow(GitAppMode.Gitlab);
	}, []);

	const handleChooseGitHub = useCallback((e) => {
		setModeToShow(GitAppMode.Github);
	}, []);

	// initial mode setup
	useEffect(() => {
		if (
			!githubAppIntegrationInfo ||
			!settingsStatus ||
			initialSetupRef.current
		) {
			return;
		}

		let mode = null;

		// if github/gitlab integrated show corresponding page
		if (settingsStatus.isGitlabAppSuccessfullyIntegrated) {
			mode = GitAppMode.Gitlab;
		}

		if (githubAppIntegrationInfo.installed) {
			mode = GitAppMode.Github;
		}

		setModeToShow(mode ?? GitAppMode.ChooseApp);

		initialSetupRef.current = true;
	}, [settingsStatus, githubAppIntegrationInfo]);

	const {
		setDeleteIntegrationModalOpenedMode,
		handleDeleteGitIntegration,
		deleteIntegrationModalOpenedMode,
	} = useDeleteIntegrationModal({ settingsStatus });

	const { onBack, onDelete } = useHeaderNavButtons({
		settingsStatus,
		modeToShow,
		handleDeleteGitIntegration,
		setModeToShow,
	});

	return {
		githubAppIntegrationInfo,
		isLoadingGithubAppIntegrationInfo,
		deleteIntegrationModalOpenedMode,
		setDeleteIntegrationModalOpenedMode,
		shouldDisplayButtonsHeader: !!onBack,
		shouldDisplayFooter: !!onDelete,
		modeToShow,
		handleChooseGitHub,
		handleChooseGitLab,
		onBack,
		onDelete,
	};
};

const useHeaderNavButtons = ({
	settingsStatus,
	handleDeleteGitIntegration,
	modeToShow,
	setModeToShow,
}) => {
	return useMemo((): {
		onBack?: () => void;
		onDelete?: () => void;
	} => {
		// if active tab is not git provider do not show buttons
		if (![GitAppMode.Github, GitAppMode.Gitlab].includes(modeToShow)) {
			return {};
		}

		// show back button only for not integrated git providers
		const onBack = settingsStatus.isGitIntegrated
			? undefined
			: () => setModeToShow(GitAppMode.ChooseApp);

		// show delete button only for active git providers
		return {
			onBack,
			onDelete: handleDeleteGitIntegration(modeToShow),
		};
	}, [settingsStatus, modeToShow, handleDeleteGitIntegration, setModeToShow]);
};

const useDeleteIntegrationModal = ({ settingsStatus }) => {
	const { userRole } = useProjectSettings();

	const [
		deleteIntegrationModalOpenedMode,
		setDeleteIntegrationModalOpenedMode,
	] = useState<GitAppMode | null>(null);

	// show delete integration button if handler exists
	// show button only if git provider tab is active
	const handleDeleteGitIntegration = useCallback(
		(mode: GitAppMode) => {
			const isDeleteModeWithProvider = [
				GitAppMode.Gitlab,
				GitAppMode.Github,
			].includes(mode);

			if (
				// enforce only role > member to see button
				isRoleGreaterThan(userRole, ApplicationUserRoles.Member) &&
				!!settingsStatus?.isGitIntegrated &&
				isDeleteModeWithProvider
			) {
				return () => setDeleteIntegrationModalOpenedMode(mode);
			}
		},
		[userRole, settingsStatus?.isGitIntegrated]
	);

	return {
		handleDeleteGitIntegration,
		deleteIntegrationModalOpenedMode,
		setDeleteIntegrationModalOpenedMode,
	};
};
