import { useCallback } from 'react';

export function useWindowOpenHandler(
	url: string,
	{ target = '_blank', preventDefault = false, stopPropagation = false } = {}
) {
	return useCallback(
		(e) => {
			if (stopPropagation) {
				e.stopPropagation();
			}

			if (preventDefault) {
				e.preventDefault();
			}

			return window.open(url, target);
		},
		[url, target, preventDefault, stopPropagation]
	);
}
