import {
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
} from '@mui/material';

export function SessionRecordingsListItemSkeleton() {
	return (
		<ListItem disablePadding divider>
			<ListItemButton>
				<ListItemText
					primary={<Skeleton variant="rectangular" width={'65%'} height={20} />}
					primaryTypographyProps={{
						variant: 'h6',
						sx: { marginBottom: 1 },
					}}
					secondary={
						<Skeleton variant="rectangular" width={'35%'} height={10} />
					}
				/>
			</ListItemButton>
		</ListItem>
	);
}
