// function to transform environments array to avoid using fragments in select as it is not supported
export const transformEnvsSettingsToMenuItemsList = (
	environmentsSettings = []
) => {
	return environmentsSettings.reduce((acc, env) => {
		acc.push(
			...[
				{ name: env.name, id: env.id },
				...env.environmentUsers.map((user) => ({ ...user, envName: env.name })),
			]
		);
		return acc;
	}, []);
};

export const getEnvironmentUserMenuItemLabel = ({ role }) => {
	return role ? role : 'default user';
};
