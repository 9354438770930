import { useCallback, useMemo } from 'react';
import { UserStoriesCollection } from '@src/modules/collections/types';
import { sortBy } from 'lodash';
import {
	ALL_FLOWS_COLLECTION_ID,
	INBOX_COLLECTION_ID,
} from '@src/modules/collections/constants';

type InputProps = {
	inputSize: 'small' | 'medium';
	label?: string;
};

export const useCollectionsListDropdown = ({
	collections = [],
	isFormField = false,
	handleChangeCollection,
}: {
	collections: UserStoriesCollection[];
	isFormField?: boolean;
	handleChangeCollection: (collectionId: string) => void;
}) => {
	const { inputSize, label }: InputProps = useMemo(() => {
		if (isFormField) {
			return {
				inputSize: 'medium',
				label: 'Collection',
			};
		}

		return {
			inputSize: 'small',
		};
	}, [isFormField]);

	//sort collections alphabetically, will be filtered in future
	const filteredCollections: UserStoriesCollection[] = useMemo(() => {
		const collectionsWithoutInbox = collections.filter(
			({ id }) => ![INBOX_COLLECTION_ID, ALL_FLOWS_COLLECTION_ID].includes(id)
		);

		return sortBy(collectionsWithoutInbox, [
			function (o) {
				return o.name;
			},
		]);
	}, [collections]);

	const onChangeCollection = useCallback(
		(event) => {
			if (!event.target.value) {
				return;
			}

			handleChangeCollection?.(event.target.value);
		},
		[handleChangeCollection]
	);

	return {
		onChangeCollection,
		filteredCollections,
		inputSize,
		label,
	};
};
