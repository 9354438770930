import { makeStatisticsForCollection } from '@src/modules/collections/api/mock/helpers';
import { APICollection } from '@src/modules/collections/api/types';

export const collectionsMock: APICollection[] = [
	{
		id: 'collection' + 1,
		name: 'collection1',
		autoDetectFlows: true,
		autoDetectFlowsDescription: null,
		storiesStatistics: makeStatisticsForCollection('collection1'),
	},
	{
		id: 'collection' + 2,
		name: 'collection2',
		storiesStatistics: makeStatisticsForCollection('collection2'),
		autoDetectFlowsDescription: null,
		autoDetectFlows: false,
	},
];
