import { useCallback, useMemo } from 'react';
import { DefaultAppBarOptions } from './types';
import { MenuComponentForMode } from './constants';
import { useNavigate } from 'react-router-dom';
import { settingsRouter } from '@src/modules/settings/service';
import { useLazyGetSetupInfoQuery } from '@src/modules/authentication/service';
import useToggle from '@src/common/hooks/useToggle';
import { useAuthenticationInfo } from '@src/common/state/volatile/hooks';

export const useDefaultAppBar = ({ mode }: Partial<DefaultAppBarOptions>) => {
	const addNewApplicationModalHandlers = useCreateNewApplication();
	const authInfo = useAuthenticationInfo();
	const isInternalUser = !!authInfo?.user?.internal;

	const CustomMenu = useMemo(() => {
		return MenuComponentForMode[mode];
	}, [mode]);

	return {
		CustomMenu,
		...addNewApplicationModalHandlers,
		isInternalUser,
	};
};

const useCreateNewApplication = () => {
	const [getSetupInfo] = useLazyGetSetupInfoQuery();

	const navigate = useNavigate();

	const {
		isOn: isAddNewApplicationModalOpened,
		on: onOpenAddNewApplicationModal,
		off: onCloseAddNewApplicationModal,
	} = useToggle();

	const afterCreateNewApplicationCallback = useCallback(async () => {
		await getSetupInfo();

		onCloseAddNewApplicationModal();
		navigate(settingsRouter.wizard.absolutePath);
		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	}, [getSetupInfo, onCloseAddNewApplicationModal, navigate]);

	return {
		onOpenAddNewApplicationModal,
		onCloseAddNewApplicationModal,
		isAddNewApplicationModalOpened,
		afterCreateNewApplicationCallback,
	};
};
