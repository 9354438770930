import { SessionRecording } from '@src/modules/session-recordings/types';
import { useMemo } from 'react';
import { formatMsToFullTime } from '@src/common/helpers/date';

export const useSessionRecordingsListItem = (
	sessionRecording: SessionRecording
) => {
	return useMemo(() => {
		return {
			...sessionRecording,
			durationForDisplay: formatMsToFullTime(sessionRecording.duration),
		};
	}, [sessionRecording]);
};
