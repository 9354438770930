import { css } from '@emotion/react';
import { useMemo } from 'react';

export const useStyles = ({
	scheme,
	offsetX,
	offsetY,
}: {
	scheme?: 'light' | 'dark';

	offsetX?: number;
	offsetY?: number;
}) => {
	return useMemo(() => {
		const box = css`
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			z-index: 10;
			background-color: ${scheme === 'light'
				? 'rgba(255, 255, 255, 0.7)'
				: 'rgba(0, 0, 0, 0.7)'};
		`;

		const loader = css`
			${offsetX ? `margin-left: ${offsetX}px;` : ''}
			${offsetY ? `margin-top: ${offsetY}px;` : ''}
		`;

		return { box, loader };
	}, [scheme, offsetX, offsetY]);
};
