import {
	Box,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import React from 'react';

import { useSessionRecordingsListItemStyles } from '@src/modules/session-recordings/components/SessionRecordingsListItem/styles';
import { useSessionRecordingsListItem } from '@src/modules/session-recordings/components/SessionRecordingsListItem/hooks';
import { SessionRecording } from '@src/modules/session-recordings/types';

export const SessionRecordingsListItem = ({
	sessionRecording,
	activeSessionRecordingId,
}: {
	sessionRecording: SessionRecording;
	activeSessionRecordingId?: string;
}) => {
	const { id, durationForDisplay, date, title, url } =
		useSessionRecordingsListItem(sessionRecording);
	const styles = useSessionRecordingsListItemStyles();

	return (
		<ListItem disablePadding divider>
			<ListItemButton selected={id === activeSessionRecordingId}>
				<ListItemText
					primary={
						<Box css={styles.primaryRow}>
							<Typography variant="h6" noWrap>
								{title}
							</Typography>

							<Typography variant="h6">{durationForDisplay}</Typography>
						</Box>
					}
					secondary={
						<>
							<Typography variant="body2">
								{date}
								<br />
							</Typography>

							<Typography css={styles.urlText}>{url}</Typography>
						</>
					}
				/>
			</ListItemButton>
		</ListItem>
	);
};
