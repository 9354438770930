import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { JsonViewer } from '@src/common/components/JsonViewer/JsonViewer';
import { useIncorrectActionStateStyles } from '@src/modules/test-generation-labeling/components/LabelingFeedback/components/IncorrectActionState/styles';
import { jsonEditorSchema } from '@src/modules/test-generation-labeling/components/LabelingFeedback/constants';
import { APINextActionResponse } from '@src/modules/test-generation-labeling/api/types';

export const IncorrectActionState = ({
	onSubmit,
	onBack,
	onReset,
	onJsonChange,
	jsonValue,
	editorKey,
	isSubmitting,
}: {
	onSubmit: () => void;
	onBack: () => void;
	onReset: () => void;
	onJsonChange: (value: APINextActionResponse) => void;
	jsonValue: APINextActionResponse;
	editorKey: number;
	isSubmitting: boolean;
}) => {
	const styles = useIncorrectActionStateStyles();

	return (
		<Box css={styles.container}>
			<JsonViewer
				label="Make corrections to the action"
				editable
				key={editorKey}
				onChange={onJsonChange}
				value={jsonValue}
				schema={jsonEditorSchema}
			/>

			<Box css={styles.footer}>
				<Button onClick={onBack}>Back</Button>

				<Box css={styles.buttonsContainer}>
					<Button onClick={onReset}>Reset</Button>

					<LoadingButton loading={isSubmitting} onClick={onSubmit}>
						Submit
					</LoadingButton>
				</Box>
			</Box>
		</Box>
	);
};
