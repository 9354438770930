import { CircularProgress, Stack, Typography } from '@mui/material';
import { SerializedStyles } from '@emotion/react';
import useLoadingCircularProgressStyles from '@src/common/components/LoadingCircularProgress/styles';

export function LoadingCircularProgress({
	children,
	styles: stackStyles,
	circularProgressSize = 16,
}: {
	children: React.ReactNode;
	styles?: SerializedStyles;
	circularProgressSize?: number;
}) {
	const styles = useLoadingCircularProgressStyles({ stackStyles });

	return (
		<Stack css={styles.stack}>
			<CircularProgress
				css={styles.circularProgress}
				size={circularProgressSize}
			/>
			<Typography variant="caption">{children}</Typography>
		</Stack>
	);
}
