import {
	APIActionHistory,
	APINextActionResponse,
	AutogptLLMResponse,
} from '@src/modules/test-generation-labeling/api/types';

export enum TestGenerationStepLabelStatus {
	Unlabeled = 'unlabeled',
	InProgress = 'in_progress',
	Labeled = 'labeled',
}

export type TestGenerationLogStep = {
	index: number;
	goal: string;
	steps: string;
	appName: string;
	runId: string;
	testId: string;
	testStartTimestamp: number;
	actionHistory: APIActionHistory[];
	screenshot: string;
	checksumIdTaggedScreenshot: string;
	sanitizedLLMResponse: APINextActionResponse;
	rawLLMResponse: string;
	rawLLMResponseJSON: AutogptLLMResponse;

	// management
	status: TestGenerationStepLabelStatus;
	tags?: string[];
	favorite?: boolean;
	labeledAt?: number;
};

export enum TestGenerationLabelingTaskStatus {
	Pending = 'pending',
	InProgress = 'in_progress',
	Completed = 'completed',
}

export type TestGenerationLabelingTask = {
	id: string;
	url: string;
	uuid: string;
	appName: string;
	runId: string;
	testId: string;
	timestamp: number;
	completedSuccessfully: boolean;
	expectedGoals: string[];
	hitGoals: string[];
	goalsScore: number;
	// management
	currentUnlabelledStepIndex: number;
	stepsCount: number;
	labeledStepsCount: number;
	status: TestGenerationLabelingTaskStatus;
};

export type TestGenerationLog = TestGenerationLabelingTask & {
	entries: TestGenerationLogStep[];
};
