import rrwebPlayer from 'rrweb-player';
import RrwebJSONStreamParser from '@src/modules/session-recordings/components/SessionReplayer/helpers/RrwebJSONStreamParser';

//stream parser initiation function
export const initRrwebJSONStreamParser = (recordingUrl, setEvents) => {
	return new RrwebJSONStreamParser({
		recordingUrl,
		setEvents,
	});
};

export const initRrwebReplayer = (events, timestamp = 0, replayerSize) => {
	//needs at least 2 events to create instance of rrweb
	const replayer = new rrwebPlayer({
		target: document.querySelector('#rrweb-replayer'),
		props: {
			width: replayerSize.width,
			height: replayerSize.height,
			events,
			skipInactive: false,
		},
	});

	if (timestamp) {
		replayer.goto(timestamp);
	}

	return replayer;
};

export const destroyRrwebReplayer = (replayer?: rrwebPlayer) => {
	if (!replayer) {
		return;
	}

	const container = replayer.getReplayer().wrapper.parentElement.parentElement;
	replayer.getReplayer().destroy();
	container.remove();
	replayer = undefined;
};
