import api from '@src/api';

export const traceViewerAPI = api
	.enhanceEndpoints({ addTagTypes: ['TraceViewer'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			saveURLForToken: builder.mutation<void, { token: string; url: string }>({
				query: (args) => ({
					method: 'POST',
					url: `file-proxy/save/${args.token}`,
					body: { url: args.url },
				}),
			}),
		}),
	});

export const { useSaveURLForTokenMutation } = traceViewerAPI;
