import { useWindowDimensions } from '@src/common/hooks/useWindowDimensions';
import { useMemo } from 'react';
import { getTextAreaRowsByWindowHeight } from '@src/modules/user-stories/components/StoryForm/components/StepsTextAreaFormField/helpers';

export const useStepsTextAreaFormField = () => {
	const { height } = useWindowDimensions();

	return useMemo(() => {
		return getTextAreaRowsByWindowHeight(height);
	}, [height]);
};
