import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { ScreenshotOptions, ScreenshotSelectOptions } from '../../constants';
import { useScreenshotsSelectStyles } from './styles';

export const ScreenshotsSelect = ({
	activeScreenshotOption,
	onSelectScreenshot,
}: {
	activeScreenshotOption: ScreenshotOptions;
	onSelectScreenshot: (event: SelectChangeEvent) => void;
}) => {
	const styles = useScreenshotsSelectStyles();

	return (
		<FormControl css={styles.container} size="medium">
			<Select value={activeScreenshotOption} onChange={onSelectScreenshot}>
				{ScreenshotSelectOptions.map(({ title, value }) => (
					<MenuItem key={value} value={value}>
						{title}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
