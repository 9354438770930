import api from '@src/api';
import {
	transformAPIEntryActionsToEntryActions,
	transformAPITestGenerationLabelingQueue,
	transformAPITestGenerationLogToTestGenerationLog,
} from '@src/modules/test-generation-labeling/api/transformers';
import {
	TestGenerationLabelingTask,
	TestGenerationLog,
	TestGenerationLogStep,
} from '@src/modules/test-generation-labeling/types';
import {
	APINextActionResponse,
	LabelingResponseKey,
	LabelingResult,
} from '@src/modules/test-generation-labeling/api/types';

export const testGenerationLabelingAPI = api
	.enhanceEndpoints({
		addTagTypes: [
			'TestGenerationLog',
			'TestGenerationLabelingQueue',
			'StoredTestGenerationLogSteps',
			'TestGenerationLogTags',
		],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getTestGenerationLog: builder.query<
				TestGenerationLog,
				{ taskId?: string; testUuid?: string }
			>({
				query: ({ taskId, testUuid }) => ({
					url: `test-generation-labeling/manager/next`,
					params: {
						taskId,
						testUuid,
					},
				}),
				providesTags: ['TestGenerationLog'],
				invalidatesTags: ['TestGenerationLabelingQueue'] as never,
				transformResponse: transformAPITestGenerationLogToTestGenerationLog,
			}),

			getLabelingStepTags: builder.query<string[], void>({
				query: () => ({
					url: `test-generation-labeling/manager/tags`,
				}),
				providesTags: ['TestGenerationLogTags'],
			}),

			getStoredTestGenerationStepLabels: builder.query<
				TestGenerationLogStep[],
				{ testUuid: string }
			>({
				query: ({ testUuid }) => ({
					url: `test-generation-labeling/tests/${testUuid}/steps`,
				}),
				providesTags: ['StoredTestGenerationLogSteps'],
				transformResponse: transformAPIEntryActionsToEntryActions,
			}),

			labelTestGenerationLogStep: builder.mutation<
				void,
				{
					sessionId: string;
					stepId: string;
					labelingResult: LabelingResult;
					correction?: Record<LabelingResponseKey, {} | APINextActionResponse>;
				}
			>({
				query: ({ sessionId, stepId, labelingResult, correction }) => {
					return {
						url: `test-generation-labeling/tests/${sessionId}/steps/${stepId}/label`,
						method: 'PATCH',
						body: { labelingResult, update: correction },
					};
				},
				invalidatesTags: ['StoredTestGenerationLogSteps'],
			}),

			tagTestGenerationLogStep: builder.mutation<
				void,
				{
					sessionId: string;
					stepId: string;
					tags?: string[];
					favorite?: boolean;
				}
			>({
				query: ({ sessionId, stepId, tags, favorite }) => {
					return {
						url: `test-generation-labeling/tests/${sessionId}/steps/${stepId}/tag`,
						method: 'PATCH',
						body: { tags, favorite },
					};
				},
				invalidatesTags: [
					'StoredTestGenerationLogSteps',
					'TestGenerationLogTags',
				],
			}),

			getTestGenerationLabelingQueue: builder.query<
				TestGenerationLabelingTask[],
				void
			>({
				query: () => ({
					url: `test-generation-labeling/queue`,
				}),
				providesTags: ['TestGenerationLabelingQueue'],
				transformResponse: transformAPITestGenerationLabelingQueue,
			}),

			addTestGenerationLogToQueue: builder.mutation<
				void,
				{
					url: string;
				}
			>({
				query: ({ url }) => {
					return {
						url: `test-generation-labeling/queue`,
						method: 'POST',
						body: { url },
					};
				},
				invalidatesTags: ['TestGenerationLabelingQueue'],
			}),

			deleteTestGenerationLogFromQueue: builder.mutation<
				void,
				{
					taskId: string;
				}
			>({
				query: ({ taskId }) => {
					return {
						url: `test-generation-labeling/queue/${taskId}`,
						method: 'DELETE',
					};
				},
				invalidatesTags: ['TestGenerationLabelingQueue'],
			}),
		}),
	});

export const {
	useGetTestGenerationLogQuery,
	useLabelTestGenerationLogStepMutation,
	useTagTestGenerationLogStepMutation,
	useGetTestGenerationLabelingQueueQuery,
	useAddTestGenerationLogToQueueMutation,
	useDeleteTestGenerationLogFromQueueMutation,
	useGetStoredTestGenerationStepLabelsQuery,
	useGetLabelingStepTagsQuery,
} = testGenerationLabelingAPI;
