import {
	APIGithubAppIntegrationInfo,
	APIEnvironmentSettings,
	APISettingsStatus,
} from './types';
import {
	GithubAppIntegrationInfo,
	GithubAppIntegrationStatus,
	GitlabAppIntegrationStatus,
	EnvironmentSettings,
	SettingsStatus,
} from '../types';

export const transformAPIGetSettingsResponse = (
	apiGetSettingsResponse: APIEnvironmentSettings[]
): EnvironmentSettings[] => {
	return apiGetSettingsResponse.map(transformAPISettings);
};

/*export const transformAPIGetApplicationUsersResponse = (
	apiGetSettingsResponse: APISettings[]
): Settings[] => {
	return apiGetSettingsResponse.map(transformAPISettings);
};*/

export const transformAPISettings = (
	apiSettings: APIEnvironmentSettings
): EnvironmentSettings => {
	return {
		...apiSettings,
	};
};

export const transformAPIGithubAppIntegrationInfo = (
	apiGithubAppIntegrationInfo: APIGithubAppIntegrationInfo
): GithubAppIntegrationInfo => {
	return {
		...apiGithubAppIntegrationInfo,
		useCollectionNameInTestPath:
			apiGithubAppIntegrationInfo.useCollectionNameInTestPath,
		hasSelectedRepository: !!apiGithubAppIntegrationInfo.selectedRepository,
		repositories: apiGithubAppIntegrationInfo.repositories?.map((repo) => ({
			...repo,
			selected:
				repo.name === apiGithubAppIntegrationInfo.selectedRepository?.name &&
				repo.owner === apiGithubAppIntegrationInfo.selectedRepository?.owner,
		})),
	};
};

export const transformAPISettingsStatus = (
	apiSettingsStatus: APISettingsStatus
): SettingsStatus => {
	const isGithubAppSuccessfullyIntegrated =
		apiSettingsStatus.githubAppIntegrationStatus === 'active';
	const isGitlabAppSuccessfullyIntegrated =
		apiSettingsStatus.gitlabAppIntegrationStatus === 'active';

	return {
		sdkKey: apiSettingsStatus.sdkKey,
		cliKey: apiSettingsStatus.cliKey,
		githubAppIntegrationStatus: transformAPIGithubAppIntegrationStatus(
			apiSettingsStatus.githubAppIntegrationStatus
		),
		gitlabAppIntegrationStatus: transformAPIGitlabAppIntegrationStatus(
			apiSettingsStatus.gitlabAppIntegrationStatus
		),
		isGitIntegrated:
			isGithubAppSuccessfullyIntegrated || isGitlabAppSuccessfullyIntegrated,
		isGithubAppSuccessfullyIntegrated,
		isGitlabAppSuccessfullyIntegrated,
		hasValidEnvironmentSettings: apiSettingsStatus.hasValidEnvironmentSettings,
		sdkSuccessfullyIntegrated: apiSettingsStatus.sdkReceivedSessionRecordings,
		applicationHasValidLoginFunction:
			apiSettingsStatus.applicationHasValidLoginFunction,
	};
};

export const transformAPIGithubAppIntegrationStatus = (
	apiStatus: APISettingsStatus['githubAppIntegrationStatus']
): GithubAppIntegrationStatus => {
	switch (apiStatus) {
		case 'active':
			return GithubAppIntegrationStatus.ACTIVE;
		case 'no-repositories':
			return GithubAppIntegrationStatus.NO_REPOSITORIES;
		case 'no-selected-repository':
			return GithubAppIntegrationStatus.NO_SELECTED_REPOSITORY;
		case 'not-installed':
			return GithubAppIntegrationStatus.NOT_INSTALLED;
	}
};

export const transformAPIGitlabAppIntegrationStatus = (
	apiStatus: APISettingsStatus['gitlabAppIntegrationStatus']
): GitlabAppIntegrationStatus => {
	switch (apiStatus) {
		case 'active':
			return GitlabAppIntegrationStatus.ACTIVE;
		case 'not-installed':
			return GitlabAppIntegrationStatus.NOT_INSTALLED;
	}
};
