import { SerializedStyles, css } from '@emotion/react';
import { useMemo } from 'react';
import { UserStoryStatus } from '@src/modules/user-stories/types';
import { useTheme } from '@mui/material';

export const useStoriesListStyles = (listStyle: SerializedStyles) => {
	const theme = useTheme();

	return useMemo(() => {
		const list = [
			css({
				display: 'flex',
				flexDirection: 'column',
				gap: theme.spacing(2),
				paddingRight: theme.spacing(1),
			}),
			listStyle,
		];

		const inboxMove = css({
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end',
			gap: theme.spacing(1),
			'svg.move': {
				position: 'relative',
				bottom: '16px',
				left: '25px',
				background: 'white',
				zIndex: 100,
			},
		});

		const dropdown = css({
			marginBottom: '6px',
		});

		return { list, inboxMove, dropdown };
	}, [listStyle, theme]);
};

export const useStoriesEmptyStateStyles = () => {
	return useMemo(() => {
		const box = css({ height: '100%', display: 'flex' });

		const paper = css`
			margin: auto;
			min-width: 150px;
			padding: 20px;
			text-align: center;
		`;

		const titleStack = css({
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: '20px',
			gap: '10px',
		});

		const actionItem = css({
			overflowWrap: 'anywhere',
			textTransform: 'none',
		});

		const noStoriesStack = css({
			display: 'flex',
			flexDirection: 'row',
			gap: '16px',
			justifyContent: 'center',
			svg: {
				marginRight: '8px',
			},
		});

		return {
			noStoriesStack,
			paper,
			box,
			titleStack,
			actionItem,
		};
	}, []);
};

export const useStoryItemStyles = (status: UserStoryStatus, isInboxActive) => {
	const theme = useTheme();

	return useMemo(() => {
		const icon = css`
			position: relative;
		`;

		const row = css`
			display: flex;
			align-items: center;
			gap: ${theme.spacing(1)};
		`;

		const column = css({
			display: 'flex',
			flexDirection: 'column',
		});

		const titleRow = css(row, {
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'start',
		});

		const contentRow = css(row, {
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'end',
		});

		const failed =
			status === UserStoryStatus.Failed
				? css`
				background-color: ${theme.colors.failedColor};
			&:hover {
				background-color: ${theme.colors.failedColorHover};
			`
				: css``;

		const listItem = css({
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(1),
		});

		const listItemButton = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(3),
			padding: theme.spacing(2),
		});

		const clearButton = css({
			minWidth: 0,
			height: '20px',
			color: theme.colors.text.secondary,
			paddingTop: '10px',
		});

		const collectionName = css({
			marginLeft: theme.spacing(3.5),
			color: theme.colors.text.secondary,
			lineHeight: theme.spacing(2),
		});

		return {
			failed,
			row,
			icon,
			listItem,
			clearButton,
			listItemButton,
			titleRow,
			contentRow,
			column,
			collectionName,
		};
	}, [status, theme]);
};
