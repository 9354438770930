import api from '@src/api';

export const userStoriesTestGenerationsAPI = api
	.enhanceEndpoints({
		addTagTypes: [
			'TestGenerations',
			'Collections',
			'UserStories',
			'InboxUserStories',
			'InboxData',
			'AllUserStories',
		],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			// [getTraceSignedURL]
			getTraceSignedURL: builder.query<
				string,
				{ userStoryId: string; testGenerationId: string }
			>({
				query: ({ userStoryId, testGenerationId }) => ({
					url: `user-stories/${userStoryId}/test-generations/${testGenerationId}/trace`,
				}),
			}),
			// [authorizeTestGenerations]
			authorizeTestGenerations: builder.mutation<
				void,
				{ userStoryId: string; testGenerationId: string; generatePr?: boolean }
			>({
				query: ({ userStoryId, testGenerationId, generatePr = false }) => {
					// if authorization for internal user then ---- /authorize-test-generations/ ----
					// otherwise ----- /generate-pr/ ----
					const subDirectoryEndpointName = generatePr
						? 'generate-pr'
						: 'authorize-test-generations';

					return {
						url: `user-stories/${userStoryId}/test-generations/${testGenerationId}/${subDirectoryEndpointName}`,
						method: 'POST',
						successToastMessage: 'Test generation authorized',
					};
				},
				invalidatesTags: [
					'UserStories',
					'Collections',
					'InboxUserStories',
					'AllUserStories',
					'InboxData',
				],
			}),
		}),
	});

export const {
	useLazyGetTraceSignedURLQuery,
	useAuthorizeTestGenerationsMutation,
} = userStoriesTestGenerationsAPI;
