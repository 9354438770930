import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useSessionRecordingsListItemStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const primaryRow = css({
			display: 'flex',
			paddingBottom: theme.spacing(1),
			justifyContent: 'space-between',
		});

		const urlText = css({
			color: theme.colors.text.secondary,
		});

		return {
			primaryRow,
			urlText,
		};
	}, [theme]);
};
