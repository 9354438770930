import { Route } from 'react-router-dom';
import { InternalAppRoute } from '@src/routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { TestGenerationLabeling } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/TestGenerationLabeling';
import { testGenerationLabelingRoutes } from '@src/modules/test-generation-labeling/routes/routes';
import { TestGenerationLabelingQueue } from '../pages/TestGenerationLabelingQueue/TestGenerationLabelingQueue';
import { InternalUserPermissions } from '@src/modules/authentication/service';

export function TestGenerationLabelingRouter() {
	return (
		<AppRouter>
			<Route
				path={testGenerationLabelingRoutes.testGenerationLabeling.path}
				element={<InternalAppRoute component={TestGenerationLabeling} />}
			/>
			<Route
				path={testGenerationLabelingRoutes.labeler.path}
				element={<InternalAppRoute component={TestGenerationLabeling} />}
			/>
			<Route
				path={testGenerationLabelingRoutes.session.path}
				element={<InternalAppRoute component={TestGenerationLabeling} />}
			/>
			<Route
				path={testGenerationLabelingRoutes.step.path}
				element={<InternalAppRoute component={TestGenerationLabeling} />}
			/>
			<Route
				path={testGenerationLabelingRoutes.queue.path}
				element={
					<InternalAppRoute
						permissions={InternalUserPermissions.TestGenerationLabelingReview}
						component={TestGenerationLabelingQueue}
					/>
				}
			/>
		</AppRouter>
	);
}
