import {
	APIGithubAppIntegrationInfo,
	APIEnvironmentSettings,
	APISettingsStatus,
	ApplicationUser,
} from '../types';

export const listSettingsMock: APIEnvironmentSettings[] = [
	{
		id: '123',
		environmentUsers: [],
		environmentBaseURL: 'https://www.example.com',
		loginURL: 'https://www.example.com/login',
		preTestHook: '',
		isDefault: true,
		name: 'default',
	},
];

export const githubAppIntegrationInfo: APIGithubAppIntegrationInfo = {
	//installed: true, //to make githubAppIntegrationInfo completed
	installed: false,
	installationURL: 'https://www.google.com/',
	selectedRepository: undefined,
	repositories: undefined,
	useCollectionNameInTestPath: false,
};

export const settingsStatusInfo: APISettingsStatus = {
	sdkKey: 'sdkKeyExample',
	cliKey: 'cliKeyExample',
	sdkReceivedSessionRecordings: false,
	githubAppIntegrationStatus: 'no-repositories',
	//sdkReceivedSessionRecordings: true, //to make the settingsStatusInfo completed
	//githubAppIntegrationStatus: 'active',
	gitlabAppIntegrationStatus: 'not-installed',
	hasValidEnvironmentSettings: false,
	applicationHasValidLoginFunction: true,
};

//let id = 0;

export const appUsers: Record<string, ApplicationUser[]> = {
	/*[applications[0].application.name]: [
		{
			id: (++id).toString(),
			role: ApplicationUserRoles.Member,
			invitationStatus: InvitationStatus.Accepted,
			user: {
				id: 'user' + id,
				email: 'lebroneJames@mail.com',
				internal: false,
				permissions: [],
			},
		},
		{
			id: (++id).toString(),
			role: ApplicationUserRoles.Admin,
			invitationStatus: InvitationStatus.Pending,
			user: {
				id: 'user' + id,
				email: 'kyrieIrving@mail.com',
				internal: false,
				permissions: [],
			},
		},
	],
	[applications[1].application.name]: [
		{
			id: (++id).toString(),
			role: ApplicationUserRoles.Member,
			invitationStatus: InvitationStatus.Accepted,
			user: {
				id: 'user' + id,
				email: 'kristapsPorzingis@mail.com',
				internal: false,
				permissions: [],
			},
		},
		{
			id: (++id).toString(),
			role: ApplicationUserRoles.Admin,
			invitationStatus: InvitationStatus.Pending,
			user: {
				id: 'user' + id,
				email: 'isaahThomas@mail.com',
				internal: false,
				permissions: [],
			},
		},
	],*/
};
