/* eslint-disable */

import { configureStore } from '@reduxjs/toolkit';
import api, { setAPIDependencies } from '@src/api';
import { setupListeners } from '@reduxjs/toolkit/query';
import apiMiddleware from '@src/api/apiMiddleware';
import applicationReducer from './reducer';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import localForage from 'localforage';
import { AppState } from './types';

// configure redux persist
const persistConfig = {
	key: 'checksumai',
	storage: localForage,
	whitelist: ['persistent'],
	version: 1,
	// https://www.freecodecamp.org/news/how-to-use-redux-persist-when-migrating-your-states-a5dee16b5ead/
	// migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, applicationReducer);

export const store = configureStore({
	reducer: persistedReducer,
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat([apiMiddleware, api.middleware]),
});

export const persistor = persistStore(store);

export const getState: () => AppState = store.getState;
export const getDispatch = () => store.dispatch;

setAPIDependencies({ getState, getDispatch });

setupListeners(store.dispatch);
