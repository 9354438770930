import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useApplicationsTableStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const tableContainer = css({
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(0.5),
		});

		const tableRow = css({
			border: 0,
			borderBottom: `1px solid ${theme.colors.borderColor.main}`,
			display: 'grid',
			gridTemplateColumns: '200px auto 70px',
		});

		return {
			tableRow,
			tableContainer,
		};
	}, [theme]);
};
