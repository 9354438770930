import {
	FormMode,
	useGitlabAppSettings,
} from '@src/modules/settings/components/GitSettings/components/GitlabAppSettings/hooks';
import {
	Button,
	IconButton,
	InputAdornment,
	Link,
	Typography,
} from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import Box from '@mui/material/Box';
import { useGitlabAppSettingsStyles } from '@src/modules/settings/components/GitSettings/components/GitlabAppSettings/styles';
import { ChangeUseCollectionNameInTestPathCheckbox } from '@src/modules/settings/components/GitSettings/components/ChangeUseCollectionNameInTestPathCheckbox/ChangeUseCollectionNameInTestPathCheckbox';

export const GitlabAppSettings = () => {
	const {
		onSubmit,
		onCancel,
		toggleShowToken,
		formMode,
		showToken,
		register,
		errors,
		isCreateOrUpdateLoading,
		shouldDisplayCancelButton,
		useCollectionNameInTestPath,
	} = useGitlabAppSettings();

	const styles = useGitlabAppSettingsStyles();

	return (
		<Choose>
			<When condition={formMode === FormMode.Loading}>
				<LoadingCircularProgress circularProgressSize={20}>
					<Typography variant="subtitle2">
						Loading GitLab Integration info...
					</Typography>
				</LoadingCircularProgress>
			</When>

			<Otherwise>
				<Box css={styles.container}>
					<If condition={formMode === FormMode.Save}>
						<Typography gutterBottom>
							Please{' '}
							<Link
								css={styles.link}
								href="https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html"
								target="_blank"
							>
								create a project access token
							</Link>{' '}
							for your GitLab project and enter the project id and access token
						</Typography>
					</If>

					<If condition={formMode === FormMode.Change}>
						<Typography gutterBottom>
							GitLab integration is active with following project:
						</Typography>
					</If>

					{/*add validation?*/}
					<FormTextField
						label="Project ID"
						placeholder="Project Id"
						name="projectId"
						register={register}
						errors={errors}
						disabled={formMode === FormMode.Change}
					/>

					<FormTextField
						label="Project access token"
						placeholder="Project access token"
						name="accessToken"
						register={register}
						errors={errors}
						disabled={formMode === FormMode.Change}
						type={showToken ? 'text' : 'password'}
						// autoComplete prop to prevent the google prefill with cached passwords
						autoComplete="new-password"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={toggleShowToken}
										edge="end"
									>
										<Choose>
											<When condition={showToken}>
												<VisibilityOff />
											</When>

											<Otherwise>
												<Visibility />
											</Otherwise>
										</Choose>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<Box css={styles.buttonsContainer}>
						<LoadingButton
							variant="contained"
							loading={isCreateOrUpdateLoading}
							onClick={onSubmit}
							css={styles.button}
						>
							<Choose>
								<When condition={formMode === FormMode.Change}>Change</When>

								<Otherwise>Save</Otherwise>
							</Choose>
						</LoadingButton>

						<If condition={shouldDisplayCancelButton}>
							<Button variant="outlined" onClick={onCancel} css={styles.button}>
								Cancel
							</Button>
						</If>
					</Box>

					<ChangeUseCollectionNameInTestPathCheckbox
						value={useCollectionNameInTestPath}
					/>
				</Box>
			</Otherwise>
		</Choose>
	);
};
