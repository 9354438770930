import {
	TestGeneration,
	TestGenerationStatus,
	transformAPITestGeneration,
} from '@src/modules/test-generations/service';
import {
	CreateUserStoryAPIArgs,
	CustomFile,
	UpdateUserStoryAPIArgs,
	UserStory,
	UserStoryFile,
	UserStoryOrigin,
	UserStoryStatus,
} from '../types';
import {
	APICreateUserStoryArgs,
	APIUpdateUserStoryArgs,
	APIUserStoryFile,
	APIUserStory,
	APIFile,
} from './types';
import { sortBy } from 'lodash';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/service';
import { getStoryLastActivityDate } from '@src/modules/user-stories/helpers';

const userStoriesStatusOrder = {
	[UserStoryStatus.Running]: 1,
	[UserStoryStatus.Queued]: 2,
	[UserStoryStatus.Verifying]: 3,
	[UserStoryStatus.Completed]: 4,
	[UserStoryStatus.Failed]: 5,
	[UserStoryStatus.Aborted]: 6,
	[UserStoryStatus.Idle]: 7,
	[UserStoryStatus.PRClosed]: 8,
	[UserStoryStatus.PRMerged]: 9,
};

export const transformAPIUserStories = (
	apiUserStories: APIUserStory[]
): UserStory[] => {
	const userStories = apiUserStories.map(transformAPIUserStory);

	return sortBy(
		userStories,
		({ status }) => userStoriesStatusOrder[status] || Infinity
	);
};

export const transformAPIUserStory = (
	apiUserStory: APIUserStory
): UserStory => {
	const generations = (apiUserStory.testGenerations || [])
		.map(transformAPITestGeneration)
		.sort((a, b) => {
			return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
		});
	const recentGeneration = generations
		.reverse()
		.find((generation) => generation.status !== TestGenerationStatus.Aborted);

	return {
		...apiUserStory,
		environmentUser: apiUserStory.environmentUser,
		defaultStartURL: apiUserStory?.collection?.defaultStartURL,
		engagement: apiUserStory.userStoryEngagements?.at(0) ?? null,
		title: apiUserStory.title.trim(),
		steps: apiUserStory.steps.trim(),
		id: apiUserStory.id.toString(),
		collectionName: apiUserStory.collection?.name ?? null,
		collectionId: apiUserStory.collection?.id ?? INBOX_COLLECTION_ID,
		origin: transformAPIUserStoryOrigin(apiUserStory.origin),
		status: getUserStoryStatus(recentGeneration, apiUserStory?.editedAt),
		generations: generations,
		recentGeneration,
		userStoryFiles: transformAPIUserStoriesFiles(apiUserStory?.userStoryFiles),
		completedRecentTestGenerationStoryStatus:
			convertTestGenerationStatusToCompletedTestGenerationUserStoryStatus(
				recentGeneration
			),
		recentActivityDate: getStoryLastActivityDate(
			recentGeneration,
			apiUserStory.createdAt
		),
	};
};

export const convertTestGenerationStatusToUserStoryStatus = (
	testGenerationStatus: TestGenerationStatus
): UserStoryStatus => {
	switch (testGenerationStatus) {
		case TestGenerationStatus.Queued:
			return UserStoryStatus.Queued;
		case TestGenerationStatus.Running:
			return UserStoryStatus.Running;
		case TestGenerationStatus.Verifying:
			return UserStoryStatus.Verifying;
		case TestGenerationStatus.Completed:
			return UserStoryStatus.Completed;
		case TestGenerationStatus.Failed:
			return UserStoryStatus.Failed;
		case TestGenerationStatus.Aborted:
			return UserStoryStatus.Aborted;
		case TestGenerationStatus.Edited:
			return UserStoryStatus.Edited;
		case TestGenerationStatus.PRClosed:
			return UserStoryStatus.PRClosed;
		case TestGenerationStatus.PRMerged:
			return UserStoryStatus.PRMerged;
	}
	return UserStoryStatus.Idle;
};

export const convertTestGenerationStatusToCompletedTestGenerationUserStoryStatus =
	(recentTestGeneration: TestGeneration): UserStoryStatus | null => {
		if (!recentTestGeneration) {
			return UserStoryStatus.Idle;
		}

		switch (recentTestGeneration?.status) {
			case TestGenerationStatus.Completed:
				return UserStoryStatus.Completed;
			case TestGenerationStatus.Failed:
				return UserStoryStatus.Failed;
			case TestGenerationStatus.PRMerged:
				return UserStoryStatus.PRMerged;
			case TestGenerationStatus.PRClosed:
				return UserStoryStatus.PRClosed;

			default:
				return null;
		}
	};

export const transformUpdateUserStoryArgsToAPI = ({
	collectionId,
	startURL,
	...update
}: UpdateUserStoryAPIArgs['update']): APIUpdateUserStoryArgs['update'] => {
	return {
		...update,
		startURL: startURL === '' ? null : startURL,
		collectionId: collectionId === INBOX_COLLECTION_ID ? null : collectionId,
	};
};

export const transformCreateUserStoryArgsToAPI = ({
	collectionId,
	startURL,
	...args
}: CreateUserStoryAPIArgs): APICreateUserStoryArgs => ({
	...args,
	startURL: startURL === '' ? null : startURL,
	collectionId: collectionId === INBOX_COLLECTION_ID ? undefined : collectionId,
});

const transformAPIUserStoryOrigin = (
	apiOrigin: APIUserStory['origin']
): UserStoryOrigin => {
	switch (apiOrigin) {
		default:
		case 'd2s':
			return UserStoryOrigin.D2S;
		case 'user':
			return UserStoryOrigin.USER;
	}
};

const getUserStoryStatus = (
	recentGeneration?: TestGeneration,
	editedAt?: string
) => {
	if (!editedAt && !recentGeneration) {
		return UserStoryStatus.Idle;
	}

	if (editedAt) {
		if (recentGeneration) {
			const recentTestGenerationLastActionDate =
				recentGeneration?.completedAt ?? recentGeneration.createdAt;

			return new Date(editedAt) > new Date(recentTestGenerationLastActionDate)
				? UserStoryStatus.Edited
				: convertTestGenerationStatusToUserStoryStatus(recentGeneration.status);
		}

		return UserStoryStatus.Edited;
	}

	return convertTestGenerationStatusToUserStoryStatus(recentGeneration.status);
};

export const transformAPIUserStoriesFiles = (
	apiUserStoryFiles: APIUserStoryFile[] = []
): UserStoryFile[] => {
	return apiUserStoryFiles.map((storyFile) => ({
		...storyFile,
		file: transformAPIFile(storyFile.file),
	}));
};

export const transformAPIFile = (apiFile: APIFile): CustomFile => {
	return {
		...apiFile,
		// get file name from path that is a last part after "/"
		name: apiFile.path.split('/').last(),
	};
};

export const transformStoryFileAgsToAPI = (body: {
	tag: string;
	file: File;
}) => {
	return {
		fileExtension: body.file.name.split('.').last(),
		fileTag: body.tag,
		fileType: body.file.type,
	};
};
