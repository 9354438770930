import {
	sessionRecordingsList,
	sessionRecordingsListWithEvents,
} from '@src/modules/session-recordings/api/mock/data';

export const handleAddEventsToSessionRecordingsList = () => {
	sessionRecordingsListWithEvents.forEach((recording) => {
		sessionRecordingsList.push(recording);
	});
};
