import { useCallback, useEffect, useState } from 'react';
import { OAuthProvider } from '@src/modules/authentication/types';
import { IResolveParams } from 'reactjs-social-login';
import { toast } from 'react-toastify';
import { usePrevious } from 'react-use';
import { APIAuthenticationResult } from '@src/modules/authentication/api/types';

export const useOAuth = ({
	isError,
	data,
}: {
	isError: boolean;
	data: APIAuthenticationResult;
}) => {
	const [provider, setProvider] = useState<OAuthProvider | null>(null);
	const [codeOrAccessToken, setCodeOrAccessToken] = useState<string | null>(
		null
	);

	//authentication token from login response without application or internal code
	const [authCode, setAuthCode] = useState<string | null>(null);

	//store previous provider to reset auth token
	const previousProvider = usePrevious(provider);

	const onLoginStart = useCallback(() => {
		setProvider(null);
		setCodeOrAccessToken(null);
	}, []);

	const onLogoutSuccess = useCallback(async () => {
		setProvider(null);
	}, []);

	//handles the oauth success
	const onOauthResolve = useCallback(
		async ({ data, provider }: IResolveParams) => {
			//reset token of already created user on provider change
			if (!!previousProvider && previousProvider !== provider) {
				setAuthCode(null);
			}

			if (data.code && provider === 'github') {
				setCodeOrAccessToken(data.code);
				setProvider('github');

				return;
			}

			setProvider(provider as OAuthProvider);
			setCodeOrAccessToken(data?.access_token);
		},
		[setProvider, setCodeOrAccessToken, previousProvider]
	);

	//handles the oauth failed
	const onOauthReject = useCallback(() => {
		toast('Something went wrong, please try again later...');
	}, []);

	//reset state on login error
	useEffect(() => {
		if (isError) {
			setProvider(null);
			setCodeOrAccessToken(null);
		}
	}, [isError]);

	useEffect(() => {
		//set token of already created user
		if (!!data?.code) {
			setAuthCode(data.code);
		}
	}, [data?.code]);

	return {
		provider,
		onLoginStart,
		codeOrAccessToken,
		onLogoutSuccess,
		onOauthReject,
		onOauthResolve,
		authCode,
	};
};
