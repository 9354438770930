import {
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useCallback,
	useMemo,
} from 'react';
import { StepsValues } from '@src/modules/settings/pages/wizard/constants';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from '@src/app/router/routes';
import {
	Step,
	SubmitRefFields,
} from '@src/modules/settings/pages/wizard/types';

type Props = {
	activeStep: Step;
	steps: Step[];
	submitFormRef: MutableRefObject<SubmitRefFields>;
	setActiveStep: Dispatch<SetStateAction<Step>>;
};

export const useWizardStepperButtons = ({
	activeStep,
	steps,
	submitFormRef,
	setActiveStep,
}: Props) => {
	//next Button text content
	const nextButtonText = useMemo(() => {
		return activeStep.name === StepsValues.SET_GIT_INTEGRATION
			? 'Done'
			: 'Next';
	}, [activeStep]);

	const {
		setupSdkHandlers,
		setGitIntegrationHandlers,
		configureEnvironmentHandlers,
	} = useStepperCallbackHandlers({
		activeStep,
		steps,
		submitFormRef,
		setActiveStep,
	});

	//stepper buttons callbacks
	const { onNext, onBack, onSkip } = useMemo(() => {
		switch (activeStep.name) {
			case StepsValues.SETUP_SDK:
				return setupSdkHandlers;

			case StepsValues.CONFIGURE_ENVIRONMENT:
				return configureEnvironmentHandlers;

			case StepsValues.SET_GIT_INTEGRATION:
				return setGitIntegrationHandlers;
		}
	}, [
		activeStep,
		configureEnvironmentHandlers,
		setupSdkHandlers,
		setGitIntegrationHandlers,
	]);

	return {
		onNext,
		onSkip,
		onBack,
		nextButtonText,
	};
};

const useStepperCallbackHandlers = ({
	activeStep: { name: activeStepName, completed: isActiveStepCompleted },
	steps,
	submitFormRef,
	setActiveStep,
}: Props) => {
	const activeStepIndex = useMemo(() => {
		return steps.findIndex(({ name }) => name === activeStepName) ?? 0;
	}, [steps, activeStepName]);

	//on move to the next step handler
	const moveToNextStep = useCallback(() => {
		if (activeStepIndex === steps.length - 1) {
			return;
		}

		setActiveStep(steps[activeStepIndex + 1]);
	}, [activeStepIndex, steps, setActiveStep]);

	//on move to the previous step handler
	const getOnBackHandler = useCallback(() => {
		if (activeStepIndex === 0) {
			return;
		}

		return () => setActiveStep(steps[activeStepIndex - 1]);
	}, [activeStepIndex, steps, setActiveStep]);

	//stepper navigation sdk handlers
	const setupSdkHandlers = useMemo(() => {
		return {
			onNext: isActiveStepCompleted ? moveToNextStep : null,
			onBack: null,
			onSkip: isActiveStepCompleted ? null : moveToNextStep,
		};
	}, [isActiveStepCompleted, moveToNextStep]);

	//stepper navigation env settings handlers
	const configureEnvironmentHandlers = useMemo(() => {
		return {
			onNext: async () => {
				if (isActiveStepCompleted && !submitFormRef.current.isDirty) {
					moveToNextStep();

					return;
				}

				await submitFormRef.current.submitForm();

				if (!submitFormRef.current?.getIsError()) {
					moveToNextStep();
				}
			},
			onBack: getOnBackHandler(),
			onSkip: isActiveStepCompleted ? null : moveToNextStep,
		};
	}, [isActiveStepCompleted, moveToNextStep, getOnBackHandler, submitFormRef]);

	const navigate = useNavigate();

	const navigateToTestGenerations = useCallback(() => {
		navigate(ApplicationRoutes.userStoriesExplorer.absolutePath);
	}, [navigate]);

	//stepper navigation github integration handler
	const setGitIntegrationHandlers = useMemo(() => {
		return {
			onNext: isActiveStepCompleted ? navigateToTestGenerations : null,
			onBack: getOnBackHandler(),
			onSkip: isActiveStepCompleted ? null : navigateToTestGenerations,
		};
	}, [navigateToTestGenerations, isActiveStepCompleted, getOnBackHandler]);

	return {
		configureEnvironmentHandlers,
		setupSdkHandlers,
		setGitIntegrationHandlers,
	};
};
