import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export function useDebounce(fn: Function, ms: number, deps: unknown[] = []) {
	// eslint-disable-next-line
	const debounceFn = useDebouncedCallback(fn as any, ms);

	useEffect(() => {
		debounceFn();
		// eslint-disable-next-line
	}, [debounceFn, ...deps]);

	// invoke callback on unmount if debounce status is pending
	useEffect(
		() => () => {
			debounceFn.flush();
		},
		[debounceFn]
	);

	return debounceFn;
}
