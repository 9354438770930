import { useSelectGithubRepositoryMutation } from '../../../../api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	GithubAppIntegrationInfo,
	GithubAppRepository,
} from '@src/modules/settings/types';
import Config from '@src/config';

export const useGithubAppSettings = ({
	githubAppIntegrationInfo,
	isLoadingInfo,
}: {
	githubAppIntegrationInfo: GithubAppIntegrationInfo;
	isLoadingInfo: boolean;
}) => {
	const [selectRepository, { isLoading: isSelectLoading }] =
		useSelectGithubRepositoryMutation();

	const [selectedRepository, setSelectedRepository] =
		useState<GithubAppRepository>(null);

	const selectedRepositoryLink = useMemo(() => {
		return selectedRepository
			? `https://github.com/${selectedRepository.owner}/${selectedRepository.name}`
			: '';
	}, [selectedRepository]);

	//githubAppIntegrationInfo data
	const {
		isAllRepositoriesEmpty,
		repositories,
		hasSelectedRepository,
		installed,
		installationURL,
	} = useMemo(() => {
		const { installed, repositories, hasSelectedRepository, installationURL } =
			githubAppIntegrationInfo;

		return {
			installed,
			isAllRepositoriesEmpty: !repositories,
			repositories,
			hasSelectedRepository,
			installationURL,
		};
	}, [githubAppIntegrationInfo]);

	//repository select change handler
	const onChangeSelect = useCallback(
		async (e) => {
			if (!repositories) {
				return;
			}

			const repository = repositories.find(
				({ name }) => name === e.target.value
			);

			try {
				setSelectedRepository(repository);
				await selectRepository(repository);
			} catch {
				setSelectedRepository(selectedRepository);
			}
		},
		[repositories, selectRepository, selectedRepository]
	);

	//set initial selected repository
	useEffect(() => {
		if (!githubAppIntegrationInfo) {
			return;
		}

		const initialSelectedRepository =
			githubAppIntegrationInfo.repositories?.find(({ selected }) => selected);

		setSelectedRepository(initialSelectedRepository ?? null);
	}, [githubAppIntegrationInfo]);

	return {
		installationURL,
		isLoadingInfo,
		isAllRepositoriesEmpty,
		repositories,
		hasSelectedRepository,
		installed,
		selectedRepositoryName: selectedRepository?.name ?? '',
		selectedRepositoryLink,
		onChangeSelect,
		isSelectLoading,
		handleRedirect: mockHandleSetGithubDataToLocalStorageOnRedirect,
	};
};

//mock the integration with github with local storage
const mockHandleSetGithubDataToLocalStorageOnRedirect = () => {
	if (!Config.api.mock) {
		return;
	}

	const data = {
		installed: true,
		selectedRepository: undefined,
		repositories: [
			{ name: 'name1', owner: 'owner1', selected: false },
			{ name: 'name2', owner: 'owner2', selected: false },
		],
	};

	localStorage.setItem('github-integration', JSON.stringify(data));
};

/*
//clear local storage -> after get data
const mockClearLocalStorage = () => {
	if (!Config.api.mock || !localStorage.getItem('github-integration')) {
		return;
	}

	localStorage.removeItem('github-integration');
};
*/
