import { Box, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export function StoryListItemSkeleton() {
	const styles = useStoryListItemSkeletonStyles();

	return (
		<Box css={styles.container}>
			<Box css={styles.header}>
				<Skeleton
					variant="rectangular"
					css={[styles.skeletonDefault, styles.spacingRight]}
				/>
				<Skeleton variant="rectangular" css={styles.skeletonLarge} />
			</Box>

			<Box css={styles.mainContent}>
				<Typography variant="body2" css={styles.text}>
					<Skeleton variant="rectangular" css={styles.skeletonMedium} />
				</Typography>
				<Skeleton variant="circular" css={styles.skeletonDefault} />
				<Skeleton variant="rectangular" css={styles.skeletonDefault} />
			</Box>
		</Box>
	);
}

export const useStoryListItemSkeletonStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			display: 'block',
		});

		const header = css({
			display: 'flex',
			alignItems: 'center',
			marginBottom: theme.spacing(2.5),
		});

		const mainContent = css({
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing(1),
		});

		const skeletonDefault = css({
			width: '20px',
			height: '20px',
		});

		const skeletonMedium = css({
			width: '110px',
			height: '16px',
		});

		const skeletonLarge = css(skeletonDefault, {
			width: '200px',
		});

		const spacingRight = css(skeletonDefault, {
			marginRight: theme.spacing(1),
		});

		const text = css(skeletonMedium, {
			color: theme.colors.text.secondary,
		});

		return {
			text,
			spacingRight,
			skeletonDefault,
			skeletonMedium,
			skeletonLarge,
			mainContent,
			container,
			header,
		};
	}, [theme]);
};
