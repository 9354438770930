import { Box, Button, Typography } from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCollectionModalStyles } from '@src/modules/collections/components/CollectionFormModal/styles';
import { CollectionFormValues } from '@src/modules/collections/types';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

export const EditOrCreateCollectionModalContent = ({
	isNew,
	errors,
	registerEditCollectionField,
	handleCloseModal,
	isSubmitLoading,
	handleSubmitCollectionForm,
}: {
	isNew: boolean;
	errors: FieldErrors<CollectionFormValues>;
	registerEditCollectionField: UseFormRegister<CollectionFormValues>;
	handleCloseModal: () => void;
	isSubmitLoading: boolean;
	handleSubmitCollectionForm: () => Promise<void>;
}) => {
	const styles = useCollectionModalStyles();

	return (
		<>
			<Typography variant="h3">
				<Choose>
					<When condition={isNew}>New collection</When>
					<Otherwise>Edit collection</Otherwise>
				</Choose>
			</Typography>

			<FormTextField
				name="name"
				register={registerEditCollectionField}
				label="Collection Name"
				errors={errors}
				required
			/>

			<FormTextField
				name="defaultStartURL"
				register={registerEditCollectionField}
				label="Default Start URL"
				errors={errors}
				info="The default URL or path to navigate to before starting tests in this collection. Relative value starting with '/' or full URL are allowed."
			/>

			<FormTextField
				multiline
				rows={3}
				name="description"
				register={registerEditCollectionField}
				label="Collection Description"
				errors={errors}
			/>

			<Box css={styles.buttonsContainerEnd}>
				<Button onClick={handleCloseModal}>Cancel</Button>

				<LoadingButton
					loading={isSubmitLoading}
					variant="contained"
					onClick={handleSubmitCollectionForm}
				>
					Save
				</LoadingButton>
			</Box>
		</>
	);
};
