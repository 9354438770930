import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useExploreUserStoriesPageStyles = ({
	shouldDisplayAutoDetectButton = false,
	shouldDisplayPagination = false,
}) => {
	const theme = useTheme();
	return useMemo(() => {
		const gridContainer = css({
			height: '100%',
		});

		const section = css({
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			padding: `${theme.spacing(4)} 0`,
		});

		const pageHeader = {
			container: css({
				marginBottom: theme.spacing(2),
			}),
			main: css({
				display: 'flex',
				alignItems: 'center',
			}),
			newStoryButton: css({
				marginLeft: 'auto',
			}),
		};

		const listContainer = css({
			height: `100%`,
			display: 'flex',
			flexDirection: 'column',
		});

		const list = css({
			height: '100%',
			overflow: `auto`,
			paddingTop: 0,
			paddingBottom: 0,
		});

		const pagesSearchBar = css({
			marginBottom: theme.spacing(2),
		});

		const storiesHeader = css({
			marginBottom: theme.spacing(2),
		});

		const autoDetectionButton = css({
			paddingBottom: theme.spacing(2),
		});

		const paginationContainer = css({
			display: 'flex',
			paddingBottom: theme.spacing(2.5),
			paddingTop: theme.spacing(0.5),
		});

		const row = css({
			display: 'flex',
			gap: theme.spacing(2),
			width: '100%',
			justifyContent: getJustifyContentForAutoDetectAndPaginationRow({
				shouldDisplayAutoDetectButton,
			}),
		});

		const pagination = css({
			visibility: shouldDisplayPagination ? 'hidden' : 'visible',
		});

		return {
			section,
			pageHeader,
			listContainer,
			list,
			pagesSearchBar,
			storiesHeader,
			autoDetectionButton,
			gridContainer,
			paginationContainer,
			row,
			pagination,
		};
	}, [theme, shouldDisplayAutoDetectButton, shouldDisplayPagination]);
};

const getJustifyContentForAutoDetectAndPaginationRow = ({
	shouldDisplayAutoDetectButton,
}) => {
	if (shouldDisplayAutoDetectButton) {
		return 'space-between';
	} else {
		return 'center';
	}
};
