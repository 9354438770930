import api from '@src/api';
import { APISessionRecording } from '@src/modules/session-recordings/api/types';

export const sessionRecordingsApi = api
	.enhanceEndpoints({
		addTagTypes: ['SessionRecordings'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getSessionRecordings: builder.query<APISessionRecording[], void>({
				query: () => ({
					url: `session-recordings`,
				}),
				providesTags: ['SessionRecordings'],
			}),

			getSessionRecordingUrl: builder.query<{ recordingUrl: string }, string>({
				query: (recordingId) => ({
					url: `session-recording/${recordingId}`,
				}),
			}),
		}),
	});

export const { useGetSessionRecordingsQuery, useGetSessionRecordingUrlQuery } =
	sessionRecordingsApi;
