import { tesGenerationLogData } from '@src/modules/test-generation-labeling/api/mock/tg-log-data';

export const tgLabelingQueueData = [
	{
		url: 'https://www.path.to/queued/file.log.json',
		status: 'in_progress',
		stepsCount: 4,
		labeledStepsCount: 2,
		id: tesGenerationLogData.testGenerationLabelingTask.id,
	},
	{
		url: 'https://www.second.path/to/queued/file.log.json',
		status: 'pending',
		id: tesGenerationLogData.testGenerationLabelingTask.id,
	},
];

export const tagsOptions = ['tag1', 'tag2', 'tag3', 'tag4', 'tag5'];
