import { ReactNode } from 'react';
import DefaultLayout from '../DefaultLayout';
import { useInternalLayout } from './hooks';

function InternalLayout({ children }: Props) {
	const { options } = useInternalLayout();

	return <DefaultLayout layoutOptions={options}>{children}</DefaultLayout>;
}

export default InternalLayout;

export const InternalLayoutHOC = (Component) => (props) =>
	(
		<InternalLayout>
			<Component {...props} />
		</InternalLayout>
	);

type Props = {
	children: ReactNode;
};
