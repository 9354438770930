import { useGetSettingsStatus } from '@src/modules/settings/service';
import { useMemo } from 'react';
import { SETTINGS_BANNER_CONTENT } from '@src/common/components/SettingsNotCompletedBanner/constants';
import { BannerContent } from '@src/common/components/SettingsNotCompletedBanner/types';

export const useSettingsNotCompletedBanner = (): {
	bannerContent?: BannerContent;
} => {
	const { settingsStatus } = useGetSettingsStatus();

	const bannerContent = useMemo(() => {
		if (!settingsStatus) {
			return;
		}

		const isSettingsCompleted =
			settingsStatus.isGitIntegrated &&
			settingsStatus.hasValidEnvironmentSettings;

		//return the banner content according to the settingsStatus sdk gh integration and env settings
		if (!isSettingsCompleted) {
			return SETTINGS_BANNER_CONTENT;
		} /*else if (!settingsStatus.sdkSuccessfullyIntegrated) {
			return SDK_BANNER_CONTENT;
		}*/

		return;
	}, [settingsStatus]);

	return {
		bannerContent,
	};
};
