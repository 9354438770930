import { ApplicationForm } from '@src/modules/application/components/ApplicationForm/ApplicationForm';
import { Box, Dialog } from '@mui/material';
import { useApplicationFormModalStyles } from '@src/modules/application/components/ApplicationFormModal/styles';
import { AddNewAppModalMode } from '@src/modules/application/components/ApplicationForm/types';

export const ApplicationFormModal = ({
	open,
	onClose,
	afterCreateApplicationCallback,
	mode = AddNewAppModalMode.AddApplication,
}: {
	open: boolean;
	onClose: () => void;
	afterCreateApplicationCallback: () => Promise<void>;
	mode?: AddNewAppModalMode;
}) => {
	const styles = useApplicationFormModalStyles();

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
			<Box css={styles.container}>
				<ApplicationForm
					afterCreateApplicationCallback={afterCreateApplicationCallback}
					mode={mode}
				/>
			</Box>
		</Dialog>
	);
};
