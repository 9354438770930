import { css } from '@emotion/react';
import { useMemo } from 'react';

const DefaultErrorTextHelperHeight = 22;

export const useErrorEnabledTextFieldStyle = (
	hasErrorMessage = true,
	errorTextHelperHeight = DefaultErrorTextHelperHeight
) => {
	return useMemo(() => {
		if (!hasErrorMessage) {
			return {
				FormHelperTextPropsSX: {},
				InputPropsSX: {},
				errorEnabledField: css({}),
			};
		}

		const errorEnabledField = css({
			marginBottom: `${errorTextHelperHeight}px !important`,
		});

		const FormHelperTextPropsSX = {
			// position: 'absolute',
			bottom: -errorTextHelperHeight,
			marginLeft: 0,
			marginTop: 0,
			height: 0,
		};

		const InputPropsSX = {
			marginBottom: '3px',
		};

		return {
			FormHelperTextPropsSX,
			InputPropsSX,
			errorEnabledField,
		};
	}, [errorTextHelperHeight, hasErrorMessage]);
};
