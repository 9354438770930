import { useYupValidationResolver } from '@src/common/hooks/useYupValidationResolver';
import { validationSchema } from '@src/modules/collections/components/CollectionFormModal/validation';
import { useForm } from 'react-hook-form';
import { getFormValues } from '@src/modules/collections/hooks/helpers/collectionFormHelpers';
import { useEffect } from 'react';
import { CollectionFormValues } from '@src/modules/collections/types';

export const useCollectionForm = ({ collectionToUpdate }) => {
	//resolver to add validation to the page form
	const resolver = useYupValidationResolver(validationSchema);

	const {
		register: registerEditCollectionField,
		reset,
		formState: { isDirty, errors, isSubmitSuccessful, dirtyFields },
		handleSubmit,
		getValues,
	} = useForm<CollectionFormValues>({
		defaultValues: getFormValues(),
		resolver,
	});

	//update form fields once selectedPageToUpdate changes
	useEffect(() => {
		reset(getFormValues(collectionToUpdate));

		// reset values only when collectionToUpdate becomes defined
		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	}, [!!collectionToUpdate, reset]);

	//reset values to set isDirty to false
	useEffect(() => {
		if (isSubmitSuccessful) {
			reset(getValues());
		}
	}, [isSubmitSuccessful, getValues, reset]);

	return {
		handleSubmit,
		registerEditCollectionField,
		isDirty,
		reset,
		errors,
		dirtyFields,
	};
};
