import { isNotEmptyArray } from '@src/common/helpers/arrays';

export const useShouldDisplay = ({
	filteredStories,
	isFetchingNewStories,
	isAllFlowsCollectionActive,
	isInboxCollectionActive,
}) => {
	const shouldDisplayAutoDetectButton =
		!!isNotEmptyArray(filteredStories) &&
		!isFetchingNewStories &&
		!isAllFlowsCollectionActive &&
		!isInboxCollectionActive;

	return {
		shouldDisplayAutoDetectButton,
	};
};
