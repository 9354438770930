import { useYupValidationResolver } from '@src/common/hooks/useYupValidationResolver';
import { stepTwoValidationSchema } from '@src/modules/settings/components/ApplicationEnvironmentSettings/validation/validation';
import { useForm } from 'react-hook-form';
import { EnvironmentSettingsFormValues } from '@src/modules/settings/components/ApplicationEnvironmentSettings/types';
import { generateValues } from '@src/modules/settings/components/ApplicationEnvironmentSettings/helpers';

export const useApplicationEnvironmentSettingsForm = ({
	activeEnvironmentSettings,
}) => {
	// form management
	const resolver = useYupValidationResolver(stepTwoValidationSchema);
	const {
		register,
		handleSubmit,
		resetField,
		formState: { errors, dirtyFields, isDirty },
		control,
		setValue,
		clearErrors,
		reset,
		watch,
		...rest
	} = useForm<EnvironmentSettingsFormValues>({
		// values: InitialSettingsProfileValues,
		//defaultValues: initValues,
		values: generateValues(activeEnvironmentSettings),
		resolver,
	});

	const environmentUsersWatcher = watch('environmentUsers');

	return {
		register,
		handleSubmit,
		errors,
		dirtyFields,
		isDirty,
		control,
		setValue,
		clearErrors,
		reset,
		resetField,
		environmentUsersWatcher,
		...rest,
	};
};
