import { TestEventTypes } from '@src/services/analytics/types/test';
import { GTMEventsFilter } from '@src/services/analytics/providers/GoogleTagManagerAnalyticsProvider/types';

export const GoogleTagManagerEventsFilter: GTMEventsFilter = {
	[TestEventTypes.TestEvent1]: {
		eventName: 'test_event_1',
		proxy: {
			options: {
				trackAfterNum: 1,
				segmentTrackingByCount: { 1: true },
			},
			trackAlways: true,
		},
	},
};
