import { setupWorker } from 'msw/browser';
import { useEffect } from 'react';
import Config from '@src/config';
import { collectionsMockHandlers } from '@src/modules/collections/service';
import { authenticationMockHandlers } from '@src/modules/authentication/service';
import { testRunsMockHandlers } from '@src/modules/test-runs/service';
import { settingsMockHandlers } from '@src/modules/settings/service';
import { sessionRecordingsMockHandlers } from '@src/modules/session-recordings/service';
import { tesGenerationLabelingMockHandlers } from '@src/modules/test-generation-labeling/service';
import { userStoriesMockHandlers } from '@src/modules/user-stories/service';

// NOTE: Add all mock handlers here. Use spread operator to flatten the array ([...mockHandlersA, ...mockHandlersB]).
const MockHandlers = [
	...collectionsMockHandlers,
	...userStoriesMockHandlers,
	...authenticationMockHandlers,
	...testRunsMockHandlers,
	...settingsMockHandlers,
	...sessionRecordingsMockHandlers,
	...tesGenerationLabelingMockHandlers,
];

export const useMocks = () => {
	// This effect only happens once, when the app is loaded.
	// If required by the config var, it starts the mock service worker with the mock handlers
	useEffect(() => {
		if (!Config.api.mock) {
			return;
		}

		const worker = setupWorker(...MockHandlers);
		worker.start();

		return () => {
			worker.stop();
		};
	}, []);
};
