import { List } from '@mui/material';
import { CollectionListItemSkeleton } from '@src/modules/collections/components/CollectionListItem/CollectionListItemSkeleton';

export function CollectionListSkeleton({ count = 3 }: { count?: number }) {
	return (
		<List>
			{Array.from({ length: count }).map((_, index) => (
				<CollectionListItemSkeleton key={index} />
			))}
		</List>
	);
}
