import { css } from '@emotion/react';
import { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { StepsValues } from './constants';

export const useWizardStyles = () => {
	const theme = useTheme();

	//get fontWeigth of active step dynamically
	const getStepLabel = useCallback(
		(activeStepName: StepsValues, currentStepName: StepsValues) => {
			const isActive = activeStepName === currentStepName;

			return css({
				fontWeight: isActive ? 700 : 400,
			});
		},
		[]
	);

	//get skip button visibility of active step dynamically
	const getSkipForNowButtonVisibility = useCallback((display: boolean) => {
		return css({
			opacity: display ? 1 : 0,
		});
	}, []);

	const styles = useMemo(() => {
		const container = css`
			padding: ${theme.spacing(4)} 0;
			height: 100%;
		`;

		const centered = css`
			display: flex;
			justify-content: center;
			height: 100%;
		`;

		const content = css`
			display: flex;
			flex-direction: column;
			gap: ${theme.spacing(2)};
			max-width: 1000px;
			justify-content: space-between;
			height: 100%;
		`;

		const mainContent = css`
			flex-grow: 1;
		`;

		const buttonsWrapper = css`
			display: flex;
			justify-content: center;
			gap: ${theme.spacing(5)};
			margin-top: ${theme.spacing(2)};
			margin-bottom: ${theme.spacing(4)};
			justify-self: end;
		`;

		const stepper = css`
			margin-bottom: ${theme.spacing(2)};
		`;

		const subtitle = css({
			color: theme.colors.text.secondary,
			textAlign: 'center',
		});

		const link = css(subtitle, {
			textDecoration: 'none',
		});

		return {
			container,
			centered,
			content,
			buttonsWrapper,
			stepper,
			link,
			subtitle,
			mainContent,
		};
	}, [theme]);

	return {
		...styles,
		getStepLabel,
		getSkipForNowButtonVisibility,
	};
};
