import { FieldValues, FormState } from 'react-hook-form';

export const getReactHookFormDirtyFields = <
	FormValues extends FieldValues = FieldValues
>(
	formValues: FormValues,
	dirtyFields: FormState<FormValues>['dirtyFields'],
	filter?: (keyof FormValues)[]
): Partial<FormValues> => {
	return Object.keys(dirtyFields).reduce((acc, key) => {
		if (filter && !filter.includes(key as keyof FormValues)) {
			return acc;
		}
		return {
			...acc,
			[key]: formValues[key as keyof FormValues],
		};
	}, {} as Partial<FormValues>);
};
