import { delay, http, HttpResponse, RequestHandler } from 'msw';
import { APIBaseURL } from '@src/api/constants';
import {
	APIGithubAppIntegrationInfo,
	APISelectGithubRepository,
	APIEnvironmentSettings,
	APISettingsStatus,
	SettingsProfileCreateAPI,
	SettingsProfileUpdateAPI,
} from '@src/modules/settings/api/types';
import {
	githubAppIntegrationInfo,
	listSettingsMock,
	settingsStatusInfo,
} from '../data';

export const settingsSetupMockHandlers: RequestHandler[] = [
	// [getSettingsProfiles]
	http.get<{}, SettingsProfileCreateAPI>(
		`${APIBaseURL}/settings/environment`,
		async ({ request, params, cookies }) => {
			await delay(2000);

			return HttpResponse.json<APIEnvironmentSettings[]>(listSettingsMock);
		}
	),

	// [getSettingsStatus]
	http.get(
		`${APIBaseURL}/settings/status`,
		async ({ request, params, cookies }) => {
			await delay(2000);

			return HttpResponse.json<APISettingsStatus>(settingsStatusInfo);
		}
	),

	// [createSettingsProfile]
	http.post<{}, SettingsProfileCreateAPI>(
		`${APIBaseURL}/settings/environment/`,
		async ({ request, params, cookies }) => {
			await delay(2000);

			const requestBody = await request.json();

			const newSettingsProfile = {
				...requestBody,
				name: requestBody.name ?? 'Default',
				isDefault: !!requestBody.isDefault,
			};

			listSettingsMock.push(newSettingsProfile);

			return HttpResponse.json('Success!');
		}
	),

	// [getGHAppIntegrationInfo]
	http.get(
		`${APIBaseURL}/settings/github-app-integration/`,
		async ({ request, params, cookies }) => {
			await delay(2000);
			const localStorageData = JSON.parse(
				localStorage.getItem('github-integration') ?? '{}'
			);

			if (!!Object.keys(localStorageData).length) {
				Object.entries(localStorageData).forEach(([key, value]) => {
					githubAppIntegrationInfo[key] = value;
				});
			}

			return HttpResponse.json<APIGithubAppIntegrationInfo>(
				githubAppIntegrationInfo
			);
		}
	),

	//[selectGHRepository]
	http.post<{}, APISelectGithubRepository>(
		`${APIBaseURL}/settings/github-app-integration/select-repository`,
		async ({ request, params, cookies }) => {
			await delay(2000);

			if (!githubAppIntegrationInfo.repositories) {
				//error
			}

			const { owner, name } = await request.json();

			const selectedRepository = githubAppIntegrationInfo.repositories.find(
				(repository) => repository.name === name && repository.owner === owner
			);

			settingsStatusInfo.githubAppIntegrationStatus = 'active';

			if (!selectedRepository) {
				//error
			}

			settingsStatusInfo.githubAppIntegrationStatus = 'active';
			githubAppIntegrationInfo.selectedRepository = selectedRepository;
			githubAppIntegrationInfo.repositories =
				githubAppIntegrationInfo.repositories.map((repository) =>
					repository.name === name && repository.owner === owner
						? { ...repository, selected: true }
						: { ...repository, selected: false }
				);

			return HttpResponse.json<APIGithubAppIntegrationInfo>(
				githubAppIntegrationInfo
			);
		}
	),

	// [updateSettingsProfile]
	http.patch<{ name: string }, SettingsProfileUpdateAPI>(
		`${APIBaseURL}/settings/environment/:name/`,
		async ({ request, params, cookies }) => {
			await delay(2000);

			const paramsProfileName = params.name;

			const requestBody = await request.json();

			const settingsProfile = listSettingsMock.find(({ name }) => {
				return paramsProfileName === name;
			});

			if (!!settingsProfile) {
				Object.entries(requestBody).forEach(([key, value]) => {
					settingsProfile[key] = value;
				});

				const profileName = requestBody?.id ?? 'default';

				settingsProfile.name = profileName;
				settingsStatusInfo.hasValidEnvironmentSettings = true;
			}

			return HttpResponse.json<APIEnvironmentSettings>(settingsProfile);
		}
	),
];
