import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useApplicationsTableRowStyles } from '@src/modules/application/components/ApplicationsTable/components/ApplicationTableRow/styles';
import { useApplicationsTableRow } from '@src/modules/application/components/ApplicationsTable/components/ApplicationTableRow/hooks';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ApplicationForInternal } from '@src/modules/application/types';
import { SerializedStyles } from '@emotion/react';

export const ApplicationsTableRow = ({
	application,
	tableRowStyles,
}: {
	application: ApplicationForInternal;
	tableRowStyles: SerializedStyles;
}) => {
	const { handleCopy, copyStatus } = useApplicationsTableRow({ application });

	const styles = useApplicationsTableRowStyles();

	return (
		<TableRow css={tableRowStyles}>
			<TableCell component="th" scope="row" css={styles.tableCell}>
				{application.name}
			</TableCell>

			<TableCell component="th" scope="row" css={styles.tableCell}>
				{application.apiKey}
			</TableCell>

			<TableCell
				component="th"
				scope="row"
				align="right"
				css={styles.tableCell}
			>
				<Tooltip title={copyStatus || 'Copy API key'} placement="top">
					<IconButton onClick={handleCopy} size="small" className="copy-button">
						<ContentCopyIcon fontSize="inherit" />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};
