import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { SerializedStyles } from '@emotion/react';
import { useSearchBar } from './hooks';

export function SearchBar({
	placeholder,
	fullWidth = false,
	styles,
	onChange,
	clearable = true,
}: {
	placeholder: string;
	fullWidth?: boolean;
	styles?: SerializedStyles;
	onChange: (searchTerm: string) => void;
	clearable?: boolean;
}) {
	const { searchTerm, handleChange, handleClear } = useSearchBar(onChange);
	return (
		<TextField
			placeholder={placeholder}
			variant="outlined"
			fullWidth={fullWidth}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
				endAdornment: (
					<If condition={clearable && !!searchTerm.length}>
						<IconButton
							aria-label="clear"
							size="small"
							onClick={handleClear}
							edge="end"
						>
							<ClearIcon />
						</IconButton>
					</If>
				),
			}}
			css={styles}
			onChange={handleChange}
			value={searchTerm}
		/>
	);
}
