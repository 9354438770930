import DefaultAppBar from '../DefaultAppBar';
import { useDefaultLayoutStyles } from './styles';
import { SideNavigationMenu } from '../SideNavigationMenu/SideNavigationMenu';
import { Box, Container, Toolbar } from '@mui/material';
import { SettingsNotCompletedBanner } from '@src/common/components/SettingsNotCompletedBanner/SettingsNotCompletedBanner';
import { LayoutOptions } from './types';
import { WithChildren } from '@src/common/types/react';
import { useDefaultLayout } from './hooks';

function DefaultLayout({ children, layoutOptions }: WithChildren<Props>) {
	const styles = useDefaultLayoutStyles();
	const { options, containerMaxWidth } = useDefaultLayout(layoutOptions);

	return (
		<Box css={styles.root}>
			<If condition={!options.hideAppBar}>
				<DefaultAppBar {...options.appBarOptions} />
			</If>
			<If condition={!options.hideSideNav}>
				<SideNavigationMenu {...options.sideNavOptions} />
			</If>
			<Container
				maxWidth={containerMaxWidth}
				component="main"
				css={styles.main}
				disableGutters={options.containerDisableGutters}
			>
				<If condition={!options.hideAppBar}>
					<Toolbar /> {/* to offset the height of the AppBar */}
				</If>
				<If condition={!options.hideSettingsNotCompletedBanner}>
					<SettingsNotCompletedBanner />
				</If>
				{children}
			</Container>
		</Box>
	);
}

export default DefaultLayout;

export const DefaultLayoutHOC = (Component) => (props) =>
	(
		<DefaultLayout>
			<Component {...props} />
		</DefaultLayout>
	);

type Props = {
	layoutOptions?: LayoutOptions;
};
