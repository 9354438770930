import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useTestGenerationsActivityLogStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const storyTitle = css({
			color: theme.colors.text.secondary,
		});

		const showMore = css(storyTitle, {
			textDecoration: 'underline',
			cursor: 'pointer',
		});

		const scrollContainer = css({
			maxHeight: 100,
			overflowY: 'auto',
			marginBottom: theme.spacing(2),
			paddingRight: theme.spacing(2),
		});

		return { storyTitle, showMore, scrollContainer };
	}, [theme]);
};
