import { Route, Routes } from 'react-router-dom';
import { PageNotFound } from '../PageNotFound';

export function AppRouter({ children }) {
	return (
		<Routes>
			{children}
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
}
