import {
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
} from '@mui/material';
import { useCollectionListItemSkeletonStyles } from '@src/modules/collections/components/CollectionListItem/styles';

export function CollectionListItemSkeleton() {
	const styles = useCollectionListItemSkeletonStyles();

	return (
		<ListItem disablePadding divider>
			<ListItemButton>
				<ListItemText
					primary={
						<Skeleton variant="rectangular" css={styles.primarySkeleton} />
					}
					primaryTypographyProps={{
						variant: 'h6',
						css: styles.primaryText,
					}}
					secondary={
						<Skeleton variant="rectangular" css={styles.secondarySkeleton} />
					}
				/>
			</ListItemButton>
		</ListItem>
	);
}
