import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useConfirmationDialogStyles = () => {
	return useMemo(() => {
		const buttonsContainer = css`
			display: flex;
			justify-content: right;
			gap: 20px;
		`;

		return {
			buttonsContainer,
		};
	}, []);
};
