import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useApplicationsTableRowStyles = () => {
	return useMemo(() => {
		const hideScrollbar = css`
			&::-webkit-scrollbar {
				display: none;
			}
		`;

		const tableCell = css(hideScrollbar, {
			border: 0,
			alignContent: 'center',
			overflow: 'auto',
		});

		return {
			tableCell,
		};
	}, []);
};
