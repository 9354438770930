import {
	useSettingsAPI,
	UseSettingsAPIProps,
} from '@src/modules/settings/components/ApplicationEnvironmentSettings/hooks/useSettingsAPI';
import { ForwardedRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useApplicationEnvironmentSettingsForm } from '@src/modules/settings/components/ApplicationEnvironmentSettings/hooks/useApplicationEnvironmentSettingsFormActions/useApplicationEnvironmentSettingsForm';
import { useConfirmationModal } from '@src/modules/settings/components/ApplicationEnvironmentSettings/hooks/useConfirmationModal';
import { OpenedConfirmationModalVariant } from '@src/modules/settings/components/ApplicationEnvironmentSettings/constants';
import { EnvironmentSettingsFormValues } from '../../types';
import { getReactHookFormDirtyFields } from '@src/common/helpers/forms';
import { formatEnvironmentUserForUpdate } from '../../helpers';

type Props = UseSettingsAPIProps & { submitFormRef: ForwardedRef<unknown> };

export const useApplicationEnvironmentSettingsFormActions = (props: Props) => {
	const { setNewEnvironmentId, submitFormRef } = props;

	const modalVariantRef = useRef<OpenedConfirmationModalVariant | null>(null);

	const { save, isSaving, onDeleteEnvironment } = useSettingsAPI(props);

	const formReturn = useApplicationEnvironmentSettingsForm(props);

	const {
		isDirty,
		dirtyFields,
		handleSubmit,
		trigger: triggerValidation,
	} = formReturn;

	const { submitForm } = useSubmitForm({
		save,
		handleSubmit,
		submitFormRef,
		dirtyFields,
		isDirty,
	});

	const {
		confirmationModalContent,
		open: isConfirmationModalOpened,
		openConfirmationModal,
		setPotentialActiveEnvironmentId,
	} = useConfirmationModal({
		onContinueWithoutUnsavedNewChanges: () => setNewEnvironmentId(''),
		changeEnvironment: setNewEnvironmentId,
		onDeleteEnvironment,
		modalVariantRef,
		submitForm,
	});

	const {
		onChangeEnvironment,
		handleDeleteEnvironment,
		onCreateNewEnvironment,
		handleSubmitForm,
	} = useProcessOrOpenConfirmationModalHandlers({
		setNewEnvironmentId,
		openConfirmationModal,
		setPotentialActiveEnvironmentId,
		isDirty,
		dirtyFields,
		submitForm,
		triggerValidation,
		activeEnvironmentSettingsId: props.activeEnvironmentSettings?.id ?? '',
	});

	return {
		isSaving,
		onDeleteEnvironment: handleDeleteEnvironment,
		onChangeEnvironment,
		confirmationModalContent,
		isConfirmationModalOpened,
		openConfirmationModal,
		onCreateNewEnvironment,
		submitForm: handleSubmitForm,
		...formReturn,
	};
};

// hook to open a confirmation dialog or process function if conditions are true
const useProcessOrOpenConfirmationModalHandlers = ({
	isDirty,
	openConfirmationModal,
	setPotentialActiveEnvironmentId,
	setNewEnvironmentId,
	dirtyFields,
	submitForm,
	triggerValidation,
	activeEnvironmentSettingsId,
}) => {
	const onChangeEnvironment = useCallback(
		(e) => {
			// if form dirty show dialog
			if (isDirty) {
				openConfirmationModal(
					OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_ENVIRONMENT
				);
				// set e.target.value to local state to update active environment in confirmation dialog
				setPotentialActiveEnvironmentId(e.target.value);
			} else {
				setNewEnvironmentId(e.target.value);
			}
		},
		[
			isDirty,
			setNewEnvironmentId,
			openConfirmationModal,
			setPotentialActiveEnvironmentId,
		]
	);

	const handleDeleteEnvironment = useCallback(() => {
		openConfirmationModal(OpenedConfirmationModalVariant.DELETE_ENVIRONMENT);
	}, [openConfirmationModal]);

	const onCreateNewEnvironment = useCallback(() => {
		if (isDirty) {
			openConfirmationModal(
				OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_TO_NEW_ENVIRONMENT
			);
		} else {
			setNewEnvironmentId('');
		}
	}, [isDirty, openConfirmationModal, setNewEnvironmentId]);

	const handleSubmitForm = useCallback(async () => {
		// manually validate form to avoid validation fail after confirmation modal close
		const isValidationSucceed = await triggerValidation();

		if (!isValidationSucceed) {
			return;
		}

		const dirtyEnvironmentUsers = dirtyFields['environmentUsers'];
		const dirtyEnvironmentName = !!dirtyFields['name'];

		const isNewEnvironmentCreating = !activeEnvironmentSettingsId;

		const isInitialEnvironmentUsersRolesOrEnvNameDirty = () => {
			// if environment name or env user role changed show dialog
			if (
				(!dirtyEnvironmentUsers && !dirtyEnvironmentName) ||
				// if new environment is being created do not show dialogs
				isNewEnvironmentCreating
			) {
				return false;
			}

			// if id is not dirty then env user is not newly appended
			// detect only if initial env users roles were changed
			const isInitialEnvironmentUsersRolesDirty = !!dirtyEnvironmentUsers?.some(
				(envUser) => !envUser.id && !!envUser.role
			);

			return isInitialEnvironmentUsersRolesDirty || dirtyEnvironmentName;
		};

		if (isInitialEnvironmentUsersRolesOrEnvNameDirty()) {
			openConfirmationModal(
				OpenedConfirmationModalVariant.ENV_NAME_OR_USER_ROLE_CHANGED
			);
		} else {
			// submit form if no env user role or env name changed
			submitForm();
		}
	}, [
		dirtyFields,
		openConfirmationModal,
		submitForm,
		triggerValidation,
		activeEnvironmentSettingsId,
	]);

	return {
		onChangeEnvironment,
		handleDeleteEnvironment,
		onCreateNewEnvironment,
		handleSubmitForm,
	};
};

const useSubmitForm = ({
	handleSubmit,
	dirtyFields,
	save,
	submitFormRef,
	isDirty,
}) => {
	//ref to handle error from the handleSubmit
	const errorRef = useRef(false);

	// handle form submission
	const submitForm = useCallback(async () => {
		return handleSubmit(
			async (formValues: EnvironmentSettingsFormValues) => {
				const formFields =
					getReactHookFormDirtyFields<EnvironmentSettingsFormValues>(
						formValues,
						dirtyFields,
						[
							'environmentBaseURL',
							'loginURL',
							'preTestHook',
							'environmentUsers',
							'isDefault',
							'name',
						]
					);

				await save({
					...formFields,
					environmentUsers: formatEnvironmentUserForUpdate(
						formFields.environmentUsers
					),
				});
				errorRef.current = false;
			},
			(...x) => {
				console.log('error', ...x);
				errorRef.current = true;
			}
		)();
	}, [handleSubmit, save, dirtyFields, errorRef]);

	//move submit form from parent component
	useImperativeHandle(submitFormRef, () => ({
		submitForm: async () => {
			return await submitForm();
		},
		getIsError: () => {
			return errorRef.current;
		},
		isDirty,
	}));

	return {
		submitForm,
	};
};
