import palette from '../palette';

const MuiCssBaseline = {
	styleOverrides: `
		html, body, #root {
			height: 100%;
		}
		body, header {
			font-family: 'Inter', sans-serif !important;
		}
		a {
			text-decoration: none;
		}
		form {
			margin: 0;
		}
		header {
			background-color: inherit;
		}
		button:focus-visible,
		div:focus-visible,
		a:focus-visible {
			box-shadow: 0 0 0 3px ${palette.text} !important;
			outline: none;
		}
	`,
};

export default MuiCssBaseline;
