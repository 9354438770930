import { delay, http, HttpResponse, RequestHandler } from 'msw';
import { userStoriesMock } from './data';
import { APIBaseURL } from '@src/api/constants';
import {
	APICreateUserStoryArgs,
	APIUserStory,
} from '@src/modules/user-stories/api/types';
import { CreateUserStoryAPIArgs } from '@src/modules/user-stories/types';
import { transformCreateMockUserStoryArgsToAPI } from '@src/modules/user-stories/api/mock/transformers';
import {
	APITestGeneration,
	APITestGenerationStatus,
} from '@src/modules/test-generations/service';

const BaseURL = `${APIBaseURL}/user-stories`;

export const userStoriesMockHandlers: RequestHandler[] = [
	//get all user-stories mock
	http.get(BaseURL, async ({ request, params, cookies }) => {
		const collectionId = new URL(request.url).searchParams.get('collectionId');

		await delay(2000);

		const filteredUserStories = collectionId
			? userStoriesMock.filter(
					({ collection }) => collection.id === collectionId
			  )
			: userStoriesMock;

		return HttpResponse.json<APIUserStory[]>(filteredUserStories);
	}),

	//create new user-story mock
	http.post<{}, APICreateUserStoryArgs>(
		BaseURL,
		async ({ request, params, cookies }) => {
			const requestBody = await request.json();

			const newUserStory: APIUserStory =
				transformCreateMockUserStoryArgsToAPI(requestBody);

			await delay(2000);

			userStoriesMock.push(newUserStory);

			return HttpResponse.json<APIUserStory>(newUserStory);
		}
	),

	//abort test generation
	http.post<{ id: string }, APICreateUserStoryArgs>(
		`${BaseURL}/:id/abortGeneration`,
		async ({ request, params, cookies }) => {
			const abortedUserStory = userStoriesMock.find(
				({ id }) => id === params.id
			);

			abortedUserStory.testGenerations.last().status =
				APITestGenerationStatus.Running;
			abortedUserStory.testGenerations.last().isAborted = true;

			return HttpResponse.json<APIUserStory>(abortedUserStory);
		}
	),

	// clearFromInbox
	http.post<{ id: string }, APICreateUserStoryArgs>(
		`${BaseURL}/:id/clearFromInbox`,
		async ({ request, params, cookies }) => {
			const storyToClearFromInbox = userStoriesMock.find(
				({ id }) => id === params.id
			);

			Object.assign(storyToClearFromInbox, {
				userStoryEngagements: [
					{
						clearedAt: new Date().toISOString(),
						lastSeenAt:
							storyToClearFromInbox.userStoryEngagements?.at(0)?.lastSeenAt ??
							null,
					},
				],
			});

			return HttpResponse.json();
		}
	),

	http.post<{ id: string }, APICreateUserStoryArgs>(
		`${BaseURL}/:id/generate`,
		async ({ request, params, cookies }) => {
			const userStory = userStoriesMock.find(({ id }) => id === params.id);

			const date = new Date().toISOString();

			const testGeneration: APITestGeneration = {
				id: self.crypto.randomUUID(),
				generationBatchId: self.crypto.randomUUID(),
				internalTestId: self.crypto.randomUUID(),
				userStoryId: params.id,
				status: APITestGenerationStatus.Running,
				isAborted: false,
				createdAt: date,
				updatedAt: date,
				prJobStatus: 'unknown',
			};

			userStory.testGenerations.push(testGeneration);

			await delay(10000);

			userStory.testGenerations.last().status =
				APITestGenerationStatus.Verifying;

			await delay(5000);

			userStory.testGenerations.last().status =
				APITestGenerationStatus.Completed;
			userStory.testGenerations.last().completedAt = new Date().toISOString();

			return HttpResponse.json<APIUserStory>(userStory);
		}
	),

	//update existing user-story mock
	http.patch(`${BaseURL}/:id`, async ({ request, params, cookies }) => {
		const requestBody = (await request.json()) as CreateUserStoryAPIArgs;

		const userStoryIndex = userStoriesMock.findIndex(
			({ id }) => params.id === id
		);

		await delay(2000);

		const updatedUserStory = userStoriesMock[userStoryIndex];

		if (!!updatedUserStory) {
			Object.entries(requestBody).forEach(([key, value]) => {
				updatedUserStory[key] = value;
			});

			updatedUserStory['updatedAt'] = new Date().toISOString();
		}

		return HttpResponse.json<APIUserStory>(updatedUserStory);
	}),

	//update existing user-story mock
	http.delete(`${BaseURL}/:id`, ({ request, params, cookies }) => {
		userStoriesMock.splice(
			userStoriesMock.findIndex(({ id }) => {
				return id === params.id;
			}),
			1
		);

		return HttpResponse.json({ success: true });
	}),
];
