import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import {
	useAutoCompleteFilterInputStyles,
	useTestRunsHeaderStyles,
} from '@src/modules/test-runs/components/TestRunsHeader/styles';

export const TestRunsHeader = ({ filterAutocompleteInputsData }) => {
	const styles = useTestRunsHeaderStyles();

	return (
		<Box css={styles.container}>
			{filterAutocompleteInputsData.map(
				({ value, onChange, options, name }, index) => (
					<AutoCompleteFilterInput
						key={name + index}
						value={value}
						onChange={onChange}
						name={name}
						options={options}
					/>
				)
			)}
		</Box>
	);
};

const AutoCompleteFilterInput = ({ name, options, onChange, value }) => {
	const styles = useAutoCompleteFilterInputStyles();

	return (
		<Autocomplete
			disablePortal
			options={options}
			css={styles.autocompleteInput}
			onChange={onChange}
			value={value}
			renderInput={(params) => (
				<TextField {...params} label={name} margin="normal" size="small" />
			)}
		/>
	);
};
