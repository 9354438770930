import { useEffect, useMemo, useState } from 'react';
import { DefaultLayoutHOC } from '@src/layout/DefaultLayout';
import {
	checkIfRouteConditionIsSatisfied,
	checkIfRouteRestrictionsAreSatisfied,
	getComponentOrChildrenComponent,
} from './helpers';
import { useAppDispatch } from '@src/store/hooks';
import {
	popLayoutOptions,
	setLayoutOptions,
} from '@src/common/state/volatile/slice';
import { isMobile } from 'react-device-detect';
import {
	useApplicationsInfo,
	useAuthenticationInfo,
	useIsAuthenticated,
} from '@src/common/state/volatile/hooks';
import Logger from '@src/services/logger';
import { LogComponents } from '@src/services/logger/types';
import { AppRouteProps, RouteRestrictions } from './types';
import { WithChildren } from '@src/common/types/react';

// remove eslint-disable-next-line when logger is in use
/* eslint-disable-next-line   @typescript-eslint/no-unused-vars  */
const logger = new Logger(LogComponents.Routing, {
	subComponent: 'useAppRoute',
	mute: true,
});

export const useAppRoute = ({
	layoutHOC,
	component,
	withLayout,
	layoutOptions,
	condition,
	conditionDependencies,
	authenticated,
	children,
	allowMobile,
	role,
	permissions,
	permissionsAtLeastOneOf,
	permissionsAll,
	internal,
	requireApplications,
}: // path,
WithChildren<AppRouteProps>) => {
	// useEffect(() => {
	// 	if (component && children) {
	// 		console.error('AppRoute for', path, 'has component and children');
	// 	}
	// }, [component, children]);

	// Establish the layout
	const LayoutHOC = useMemo(
		() => (layoutHOC ? layoutHOC : DefaultLayoutHOC),
		[layoutHOC]
	);

	// Build the component with the layout
	const Component = useMemo(() => {
		const componentOrChildrenComponent = getComponentOrChildrenComponent(
			component,
			children
		);
		return withLayout
			? LayoutHOC(componentOrChildrenComponent)
			: componentOrChildrenComponent;
	}, [LayoutHOC, withLayout, component, children]);

	// Make sure route conditions are satisfied
	const isRouteConditionSatisfied = useRouteCondition(
		condition,
		conditionDependencies
	);

	const areRouteRestrictionsSatisfied = useRouteRestrictions({
		role,
		permissions,
		permissionsAtLeastOneOf,
		permissionsAll,
		internal,
		authenticated,
		requireApplications,
	});

	// Denote if the user is authenticated
	const isUserAuthenticated = useIsAuthenticated();

	// should redirect to login page if the route is authenticated and the user didn't sign in
	const redirectByAuthRoute = authenticated && !isUserAuthenticated;
	const redirectToAuthentication = redirectByAuthRoute;

	// handle route layout options
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (layoutOptions) {
			dispatch(setLayoutOptions(layoutOptions));
			return () => {
				dispatch(popLayoutOptions());
			};
		}
	}, [dispatch, layoutOptions]);

	const shouldDisplayMobilePage = isMobile && !allowMobile;

	return {
		Component,
		isRouteAvailable:
			isRouteConditionSatisfied && areRouteRestrictionsSatisfied,
		redirectToAuthentication,
		shouldDisplayMobilePage,
	};
};

const useRouteCondition = (condition, conditionDependencies) => {
	const [isRouteConditionSatisfied, setIsRouteConditionSatisfied] = useState(
		checkIfRouteConditionIsSatisfied(condition)
	);

	useEffect(() => {
		setIsRouteConditionSatisfied(checkIfRouteConditionIsSatisfied(condition));
	}, [condition, conditionDependencies]);

	return isRouteConditionSatisfied;
};

const useRouteRestrictions = (restrictions: RouteRestrictions) => {
	const authenticationInfo = useAuthenticationInfo();
	const applicationsInfo = useApplicationsInfo();

	const [areRouteRestrictionsSatisfied, setAreRouteRestrictionsSatisfied] =
		useState(
			checkIfRouteRestrictionsAreSatisfied(
				restrictions,
				authenticationInfo,
				applicationsInfo
			)
		);

	useEffect(() => {
		setAreRouteRestrictionsSatisfied(
			checkIfRouteRestrictionsAreSatisfied(
				restrictions,
				authenticationInfo,
				applicationsInfo
			)
		);
	}, [restrictions, authenticationInfo, applicationsInfo]);

	return areRouteRestrictionsSatisfied;
};
