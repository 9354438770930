import { grey } from '@mui/material/colors/';

const colors = {
	// xxx: '#E0E0E0',
	white: '#fff',
	failedColor: '#fdeeed',
	failedColorHover: '#f2e5e4',

	divider: 'rgba(0, 0, 0, 0.12)',

	text: {
		primary: 'rgba(0, 0, 0, 0.87)',
		secondary: 'rgba(0, 0, 0, 0.6)',
	},

	background: {
		hoverButtonLight: 'rgba(25, 118, 210, 0.04)',
	},

	error: {
		main: '#f44336',
	},

	success: {
		main: '#4caf50',
	},

	codeBlock: {
		dark: '#2d2d2d',
		textColor: '#d6d6d6',
	},

	borderColor: {
		main: grey[300],
	},

	grey: {
		main: grey[300],
	},

	primary: {
		main: '#007bff',
		button: '#1976d2',
	},

	yellow: '#ffff00',
} as const;

export default colors;
