import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { ToastContent, ToastOptions, toast } from 'react-toastify';
import { APIDependencies, DefaultToastOptions } from './constants';
import { APIDependenciesType } from './types';
import { loggedOut } from '@src/common/state/persistent/slice';
import {
	setAuthenticationStatus,
	setRedirectToAfterAuthentication,
} from '@src/common/state/volatile/slice';

const tagTypes = [];

const makeAPI = () => {
	const _api = createApi({
		reducerPath: 'api',
		baseQuery: axiosBaseQuery(),
		endpoints: (builder) => ({}),
		// TODO
		//refetchOnFocus: true,
		//refetchOnReconnect: true,
		refetchOnMountOrArgChange: true,
	});

	const originalEnhanceEndpoints = _api.enhanceEndpoints;
	_api.enhanceEndpoints = (endpoints) => {
		if (endpoints.addTagTypes) {
			tagTypes.push(...endpoints.addTagTypes);
		}
		return originalEnhanceEndpoints(endpoints);
	};

	return _api;
};
/**
 * This is the base query method, implemented with axios, executing all requests coming through the API.
 */
const axiosBaseQuery =
	(): BaseQueryFn<
		{
			url: string;
			method?: AxiosRequestConfig['method'];
			body?: AxiosRequestConfig['data'];
			params?: AxiosRequestConfig['params'];
			headers?: AxiosRequestConfig['headers'];
			disableErrorToast?: boolean;
			errorToastMessage?: ToastContent;
			successToastMessage?: ToastContent;
			toastOptions?: ToastOptions;
			makeErrorFromResponse?: (
				response: AxiosError['response']
			) => ToastContent;
		},
		unknown,
		unknown
	> =>
	async ({
		url,
		method = 'GET',
		body,
		params,
		headers,
		disableErrorToast,
		errorToastMessage,
		makeErrorFromResponse,
		successToastMessage,
		toastOptions,
	}) => {
		headers = addAuthenticationHeaders(headers);
		try {
			const result = await axios({
				url: APIDependencies.baseURL + url,
				method,
				data: body,
				params,
				// withCredentials: true,
				headers,
			});
			if (successToastMessage) {
				toast.success(successToastMessage, {
					...DefaultToastOptions,
					...toastOptions,
				});
			}
			return { data: result.data };
		} catch (axiosError) {
			let err = axiosError as AxiosError;
			const method = err.config?.method;

			// logout on 401 error response status
			if (err.response?.status === 401) {
				const dispatch = APIDependencies.getDispatch();

				if (dispatch) {
					toast.error('Your session has been expired, login again');

					dispatch(setRedirectToAfterAuthentication(null));
					dispatch(loggedOut());
					dispatch(setAuthenticationStatus(undefined));
				}
			}

			// TODO: also include get?
			if (
				!disableErrorToast &&
				(errorToastMessage || (method && method !== 'get'))
			) {
				const message =
					makeErrorFromResponse?.(axiosError.response) ??
					errorToastMessage ??
					'An error occurred, please try again later';
				toast.error(message, {
					...DefaultToastOptions,
					...toastOptions,
					// prevent same errors duplicates in toast queue
					toastId: url + method,
				});
			}
			return {
				error: { status: err.response?.status, data: err.response?.data },
			};
		}
	};

const addAuthenticationHeaders = (
	headers: AxiosRequestConfig['headers'] = {}
) => {
	const authCode = APIDependencies.getState?.()?.persistent?.authCode;
	if (authCode) {
		headers['Authorization'] = `Bearer ${authCode}`;
	}

	const activeApplication =
		APIDependencies.getState?.()?.volatile?.applicationsInfo.activeApplication;

	const persistentAppId = APIDependencies.getState?.()?.persistent?.appId;

	const appId = activeApplication?.id ?? persistentAppId;

	if (!!appId) {
		headers['AppId'] = appId;
	}

	return headers;
};

const api = makeAPI();
export default api;

export const setAPIDependencies = (
	dependencies: Partial<APIDependenciesType>
) => {
	Object.keys(dependencies).forEach((key) => {
		APIDependencies[key] = dependencies[key];
	});
};

export const invalidateAllTags = () => {
	return api.util.invalidateTags(tagTypes);
};
