import { ApplicationUserRoles } from '@src/modules/application/service';

const RolesByOrder = [
	ApplicationUserRoles.Member,
	ApplicationUserRoles.Admin,
	ApplicationUserRoles.Owner,
];

export const isRoleGreaterThan = (
	role: ApplicationUserRoles,
	targetRole: ApplicationUserRoles
) => {
	const roleIndex = Object.values(RolesByOrder).indexOf(role);
	const targetRoleIndex = Object.values(RolesByOrder).indexOf(targetRole);

	return roleIndex > targetRoleIndex;
};
