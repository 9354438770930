import { css } from '@emotion/react';
import { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useStyles = ({ isSingleUser }) => {
	const theme = useTheme();

	const getDynamicFieldRowStyles = useCallback(
		(roleExists = false) => {
			// do not show role input if there is single env user with no role
			const shouldDisplayRoleInput = !(isSingleUser && !roleExists);

			return css({
				display: 'grid',
				gap: theme.spacing(2),
				gridTemplateColumns: `2fr 2fr ${shouldDisplayRoleInput ? '1fr' : ''} ${
					!isSingleUser ? 'auto minmax(50px, auto)' : ''
				}`,
			});
		},
		[isSingleUser, theme]
	);

	const styles = useMemo(() => {
		const row = css`
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: ${theme.spacing(2)};
		`;

		const container = css`
			display: grid;
			gap: ${theme.spacing(2)};
			padding: ${theme.spacing(4)} 0;
		`;

		const buttonsContainer = css`
			display: flex;
			align-items: end;
			margin-bottom: ${theme.spacing(2.5)};
			gap: ${theme.spacing(2)};
		`;

		const buttonsContainerEnd = css(buttonsContainer, {
			justifyContent: 'end',
		});

		const checkbox = css`
			margin-top: ${theme.spacing(1)};
		`;

		const tooltipIcon = css`
			margin: 0 ${theme.spacing(0.5)};
			vertical-align: middle;
			position: relative;
			top: -1px;
		`;

		const fieldset = css`
			border: none;
			padding: 0;
			margin: 0;
			position: relative;
		`;

		const dynamicFieldAction = css({
			display: 'flex',
			alignItems: 'center',
			marginBottom: theme.spacing(3),
		});

		const testUsersTitle = css`
			display: flex;
			button {
				margin-left: auto;
			}
		`;

		const envsSelect = css`
			margin-top: ${theme.spacing(4)};
			margin-bottom: ${theme.spacing(2)};
			min-width: 340px;
		`;

		return {
			row,
			container,
			tooltipIcon,
			fieldset,
			dynamicFieldAction,
			testUsersTitle,
			checkbox,
			buttonsContainer,
			buttonsContainerEnd,
			envsSelect,
		};
	}, [theme]);

	return {
		styles,
		getDynamicFieldRowStyles,
	};
};
