import { ReactComponent as Playwright } from '@assets/images/Playwright.svg';
import { useTraceViewerCustomAppBarMenuStyles } from './styles';

export const TraceViewerCustomAppBarMenu = () => {
	const styles = useTraceViewerCustomAppBarMenuStyles();
	return (
		<div css={styles.customMenu}>
			<Playwright />
			<span>Trace Viewer</span>
		</div>
	);
};
