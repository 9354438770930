import { useCallback, useEffect, useMemo, useState } from 'react';
import useToggle from '@src/common/hooks/useToggle';
import { useCreateOrUpdateGitlabAppInfoMutation } from '@src/modules/settings/api';
import { useForm } from 'react-hook-form';
import { GitlabAppIntegrationAPIArgsInfo } from '@src/modules/settings/api/types';
import { useYupValidationResolver } from '@src/common/hooks/useYupValidationResolver';
import { validationSchema } from './validation';
import { getDefaultValues } from '@src/modules/settings/components/GitSettings/components/GitlabAppSettings/helpers';
import { useApplicationSettingsQueryState } from '@src/modules/application/service';

export enum FormMode {
	Save = 'save',
	Change = 'change',
	Loading = 'loading',
}

export const useGitlabAppSettings = () => {
	const { data } = useApplicationSettingsQueryState();

	const gitlabSettings = useMemo(() => {
		return data?.gitlabAppIntegrationInfo;
	}, [data]);

	const [
		createOrUpdateGitlabAppInfo,
		{ isLoading: isCreateOrUpdateLoading, isSuccess: isCreateOrUpdateSuccess },
	] = useCreateOrUpdateGitlabAppInfoMutation();

	const resolver = useYupValidationResolver(validationSchema);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<GitlabAppIntegrationAPIArgsInfo>({
		values: getDefaultValues(gitlabSettings),
		resolver,
	});

	const [formMode, setFormMode] = useState<FormMode>(FormMode.Loading);
	const [isSavedDataExist, setIsSavedDataExist] = useState(true);

	// toggler to show/hide acces token field content
	const { toggle: toggleShowToken, isOn: showToken } = useToggle(false);

	// show cancel if user already saw change
	const shouldDisplayCancelButton = useMemo(() => {
		return isSavedDataExist && formMode === FormMode.Save;
	}, [formMode, isSavedDataExist]);

	// create or update on save, and switch mode on change
	const onUpdateOrCreate = useCallback(
		async (values: GitlabAppIntegrationAPIArgsInfo) => {
			if (formMode === FormMode.Save) {
				createOrUpdateGitlabAppInfo(values);
			} else {
				setFormMode(FormMode.Save);
			}
		},
		[createOrUpdateGitlabAppInfo, formMode]
	);

	/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	const onSubmit = useCallback(handleSubmit(onUpdateOrCreate), [
		onUpdateOrCreate,
		handleSubmit,
	]);

	const onCancel = useCallback(() => {
		setFormMode(FormMode.Change);
	}, []);

	// set initial form mode
	useEffect(() => {
		if (!gitlabSettings) {
			return;
		}

		const isDataExists =
			!!gitlabSettings?.projectId && !!gitlabSettings?.accessToken;

		setIsSavedDataExist(isDataExists);
		setFormMode(isDataExists ? FormMode.Change : FormMode.Save);
	}, [gitlabSettings]);

	useEffect(() => {
		if (isCreateOrUpdateSuccess) {
			setFormMode(FormMode.Change);
		}
	}, [isCreateOrUpdateSuccess]);

	return {
		onSubmit,
		toggleShowToken,
		formMode,
		showToken,
		isCreateOrUpdateLoading,
		isSavedDataExist,
		register,
		errors,
		shouldDisplayCancelButton,
		onCancel,
		useCollectionNameInTestPath: gitlabSettings?.useCollectionNameInTestPath,
	};
};
