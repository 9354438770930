import { Box, Typography } from '@mui/material';
import { useViewOnlyTextBlockStyles } from '@src/modules/test-generation-labeling/components/ActionDescription/components/ViewOnlyTextBlock/styles';

export const ViewOnlyTextBlock = ({
	content,
	label,
	placeholder,
}: {
	content: string;
	label?: string;
	placeholder?: string;
}) => {
	const styles = useViewOnlyTextBlockStyles();

	return (
		<Box>
			<If condition={!!label}>
				<Typography variant="caption">{label}</Typography>
			</If>

			<Box css={styles.block}>
				<Choose>
					<When condition={!!content}>
						<Typography css={styles.content}>{content}</Typography>
					</When>

					<Otherwise>
						{/* replace text.secondary with constant color*/}
						<Typography color="text.secondary">{placeholder}</Typography>
					</Otherwise>
				</Choose>
			</Box>
		</Box>
	);
};
