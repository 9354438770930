import {
	formatMsToMinutes,
	formatTimestampAsLocaleStringWithoutSeconds,
} from '@src/common/helpers/date';
import { TestSuiteRun, TestSuiteRunStatus } from '../../types';
import { useMemo } from 'react';
import { useWindowOpenHandler } from '@src/common/hooks/useWindowOpenHandler';

export const useTestRunListItem = (testRun: TestSuiteRun) => {
	const testRunData = useMemo(() => {
		const successfulResults = testRun.results.healed + testRun.results.passed;
		const startedAt = formatTimestampAsLocaleStringWithoutSeconds(
			testRun.startedAt
		);
		const duration = testRun.duration
			? formatMsToMinutes(testRun.duration)
			: undefined;

		return {
			successfulResults,
			failedResults: testRun.results.failed,
			startedAt,
			branch: testRun.branch,
			duration,
			name: testRun.name,
		};
	}, [testRun]);

	const openPR = useWindowOpenHandler(testRun.pullRequestURL, {
		preventDefault: true,
	});

	// show results component if all results data exists and status is not running
	const shouldDisplayResults = useMemo(() => {
		const isAllResultsExist = ![
			testRun.results.healed,
			testRun.results.passed,
			testRun.results.failed,
		].includes(null);

		return isAllResultsExist && testRun.status !== TestSuiteRunStatus.Running;
	}, [testRun]);

	return {
		...testRunData,
		shouldDisplayResults,
		openPR,
	};
};
