import { window_allow_tg_abort_when_verifying } from '@src/config/window.config';
import { useMemo } from 'react';
import { UserStoryStatus } from '../../types';

export const useStoryFormActionsBar = ({
	storyStatus,
	isDirty,
	isFormFieldsDisabled,
	isSettingsStatusLoading,
	isStoryFormValid,
}: {
	storyStatus: UserStoryStatus;
	isDirty: boolean;
	isFormFieldsDisabled: boolean;
	isStoryFormValid: boolean;
	isSettingsStatusLoading: boolean;
}) => {
	return useMemo(() => {
		return {
			isSaveDisabled: !isDirty || isFormFieldsDisabled,
			isGenerateDisabled:
				isDirty ||
				isSettingsStatusLoading ||
				isFormFieldsDisabled ||
				!isStoryFormValid,
			forceAllowAborting:
				storyStatus === UserStoryStatus.Verifying &&
				window_allow_tg_abort_when_verifying(),
		};
	}, [
		isDirty,
		isFormFieldsDisabled,
		isSettingsStatusLoading,
		storyStatus,
		isStoryFormValid,
	]);
};
