import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useAutoDetectFlowsButtonStyles = ({
	buttonStyles,
	isLoading,
}: {
	buttonStyles?: SerializedStyles;
	isLoading: boolean;
}) => {
	const theme = useTheme();

	return useMemo(() => {
		//show disabled styles after loading is finished
		const loadingFinishedStyles = isLoading
			? undefined
			: {
					':disabled': {
						border: 'none',
						color: theme.colors.primary.button,
					},
			  };

		const button = css(buttonStyles, loadingFinishedStyles);

		return { button };
	}, [theme, buttonStyles, isLoading]);
};
