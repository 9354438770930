import { settingsRouter } from '@src/modules/settings/service';

export const SETTINGS_BANNER_CONTENT = {
	text: 'Before we can generate tests, you need to finish setting up Checksum.',
	link: {
		href: settingsRouter.wizard.absolutePath,
		name: 'Go to the setup wizard',
	},
};

export const SDK_BANNER_CONTENT = {
	text: "It seems like you haven't completed integrating our SDK yet.",
	link: {
		href: settingsRouter.wizard.absolutePath,
		name: 'Complete SDK Integration',
	},
};
