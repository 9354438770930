import { APIApplicationEnvironmentUser } from '@src/modules/settings/api/types';

export type EnvironmentSettings = {
	id: string;
	environmentBaseURL: string;
	loginURL: string;
	preTestHook?: string;
	name: string;
	isDefault: boolean;
	environmentUsers: ApplicationEnvironmentUser[];
};

export type ApplicationEnvironmentUser = APIApplicationEnvironmentUser;

export type GithubAppRepository = {
	owner: string;
	name: string;
	selected: boolean;
};

export type GithubAppIntegrationInfo = {
	installed: boolean;
	installationURL: string;
	hasSelectedRepository: boolean;
	repositories?: GithubAppRepository[];
	useCollectionNameInTestPath: boolean;
};

export enum GithubAppIntegrationStatus {
	ACTIVE = 'active',
	NO_REPOSITORIES = 'no-repositories',
	NO_SELECTED_REPOSITORY = 'no-selected-repository',
	NOT_INSTALLED = 'not-installed',
}

export enum GitlabAppIntegrationStatus {
	ACTIVE = 'active',
	NOT_INSTALLED = 'not-installed',
}

export type SettingsStatus = {
	sdkKey: string;
	sdkSuccessfullyIntegrated: boolean;
	cliKey: string;
	githubAppIntegrationStatus: GithubAppIntegrationStatus;
	gitlabAppIntegrationStatus: GitlabAppIntegrationStatus;
	isGitIntegrated: boolean;
	isGithubAppSuccessfullyIntegrated: boolean;
	isGitlabAppSuccessfullyIntegrated: boolean;
	hasValidEnvironmentSettings: boolean;
	applicationHasValidLoginFunction: boolean;
};
