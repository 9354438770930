import { useTestGenerationsActivityLog } from '@src/modules/user-stories/components/StoryFormActionsBar/components/hooks';
import { Box, Typography } from '@mui/material';
import { useTestGenerationsActivityLogStyles } from '@src/modules/user-stories/components/StoryFormActionsBar/components/styles';
import { UserStory } from '@src/modules/user-stories/types';

export const TestGenerationsActivityLog = ({ story }: { story: UserStory }) => {
	const { formattedGenerationsNotes, onShowMore, shouldDisplayShowMoreOption } =
		useTestGenerationsActivityLog({
			story,
		});

	const styles = useTestGenerationsActivityLogStyles();

	return (
		<Box css={styles.scrollContainer}>
			{formattedGenerationsNotes.map((note, index) => {
				return (
					<Typography
						key={note + index}
						variant="body2"
						css={styles.storyTitle}
					>
						{note}
					</Typography>
				);
			})}

			<If condition={shouldDisplayShowMoreOption}>
				<Typography variant="body2" css={styles.showMore} onClick={onShowMore}>
					Show More
				</Typography>
			</If>
		</Box>
	);
};
