import {
	ALL_FLOWS_COLLECTION_ID,
	INBOX_COLLECTION_ID,
	useGetUserStoriesCollectionsQuery,
	useInboxCollectionAndStories,
	useInboxCollectionAndStoriesBadgesData,
	UserStoriesCollection,
} from '@src/modules/collections/service';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { userStoriesExplorerRoutes } from '../../../routes/routes';
import { useCollectionsFilter } from './useCollectionsFilter';
import { useIsMobile } from '@src/common/hooks/useIsMobile';
import { getCollectionById, getInitialActiveCollectionId } from './helpers';
import { ApplicationRoutes } from '@src/app/router/routes';
import { CollectionModalMode } from '@src/modules/user-stories-exploration/pages/explore-user-stories/constants';
import { TestGenerationStatus } from '@src/modules/test-generations/service';
import { UserStory } from '@src/modules/user-stories/service';

export const useCollections = () => {
	const {
		collections,
		isLoading: isLoadingCollections,
		isError: isErrorLoadingCollections,
	} = useCollectionListWithInbox();

	const isMobile = useIsMobile();
	const { collectionId } = useParams<'collectionId'>();
	const navigate = useNavigate();

	const previousCollectionId = usePrevious(collectionId);

	const { isAllFlowsCollectionActive, isInboxCollectionActive } = useMemo(
		() => ({
			isAllFlowsCollectionActive: collectionId === ALL_FLOWS_COLLECTION_ID,
			isInboxCollectionActive: collectionId === INBOX_COLLECTION_ID,
		}),
		[collectionId]
	);

	const [activeCollectionId, setActiveCollectionId] = useState<string | null>(
		getInitialActiveCollectionId(collections, collectionId)
	);

	const activeCollection = useMemo(() => {
		return collections.find(({ id }) => id === activeCollectionId);
	}, [collections, activeCollectionId]);

	const [isCollectionModalOpened, setIsCollectionModalOpened] = useState(false);
	const [openedCollectionModalMode, setOpenedCollectionModalMode] =
		useState<CollectionModalMode>(CollectionModalMode.Create);
	const [collectionIdForUpdate, setCollectionIdForUpdate] = useState<
		string | null
	>(null);
	const [
		afterAddNewCollectionModalCloseCallback,
		setAfterAddNewCollectionModalCloseCallback,
	] = useState(() => (collectionId: string) => {});

	const inboxBadgesData = useInboxCollectionAndStoriesBadgesData({
		activeCollectionId,
	});

	//open close page modal handlers
	const openNewCollectionModal = useCallback(() => {
		setIsCollectionModalOpened(true);
		setOpenedCollectionModalMode(CollectionModalMode.Create);
	}, []);

	//open prefilled page form modal
	const openExistingCollectionModal = useCallback(
		(collectionId: string, collectionModalMode: CollectionModalMode) => {
			setCollectionIdForUpdate(collectionId);
			setIsCollectionModalOpened(true);

			setOpenedCollectionModalMode(collectionModalMode);
		},
		[]
	);

	const closeCollectionModal = useCallback(() => {
		setCollectionIdForUpdate(null);
		setIsCollectionModalOpened(false);
		setOpenedCollectionModalMode(CollectionModalMode.Create);
	}, []);

	// when the pageName param wasn't provided,
	// once establishing the active page, navigate to it
	useEffect(() => {
		if (!collectionId && activeCollectionId && !isMobile) {
			navigate(
				userStoriesExplorerRoutes.userStoriesCollection.make({
					collectionId: activeCollectionId,
				})
			);
		}
	}, [collectionId, activeCollectionId, navigate, isMobile]);

	//set first page name as active page name if url pageName is invalid
	useEffect(() => {
		if (isLoadingCollections) {
			return;
		}

		// reset active page name if no collections
		if (!collections?.length) {
			navigate(ApplicationRoutes.userStoriesExplorer.path);
			setActiveCollectionId(undefined);
			return;
		}

		const activePageByUrlPageName = getCollectionById(
			collections,
			collectionId
		);

		if (
			!activePageByUrlPageName &&
			activeCollectionId === collectionId &&
			!isMobile
		) {
			setActiveCollectionId(collections[0].id);
			navigate(
				userStoriesExplorerRoutes.userStoriesCollection.make({
					collectionId: collections[0].id,
				})
			);
		}
	}, [
		collections,
		collectionId,
		navigate,
		isMobile,
		activeCollectionId,
		isLoadingCollections,
	]);

	// when the active page isn't set or the pageName param changes,
	// set the active page accordingly
	useEffect(() => {
		if (!collections?.length) {
			return;
		}

		if (!activeCollectionId || collectionId !== previousCollectionId) {
			setActiveCollectionId(
				getInitialActiveCollectionId(collections, collectionId)
			);
		}
	}, [collections, activeCollectionId, collectionId, previousCollectionId]);

	const {
		filteredCollections,
		setActiveSearchTerm,
		hasFilteredOutCollections,
	} = useCollectionsFilter(collections);

	return {
		collections,
		activeCollectionId,
		activeCollection,
		isLoadingCollections,
		isErrorLoadingCollections,
		filteredCollections,
		setActiveSearchTerm,
		hasFilteredOutCollections,
		isCollectionModalOpened,
		collectionIdForUpdate,
		openNewCollectionModal,
		openExistingCollectionModal,
		closeCollectionModal,
		openedCollectionModalMode,
		afterAddNewCollectionModalCloseCallback,
		setAfterAddNewCollectionModalCloseCallback,
		inboxBadgesData,
		isAllFlowsCollectionActive,
		isInboxCollectionActive,
	};
};

const useCollectionListWithInbox = () => {
	const {
		inboxCollection,
		inboxUserStories,
		isLoading: isInboxCollectionLoading,
		isError: isInboxCollectionError,
	} = useInboxCollectionAndStories();

	const {
		data: commonCollections,
		isLoading: isLoadingCollections,
		isError: isErrorLoadingCollections,
	} = useGetUserStoriesCollectionsQuery();

	const allTestsCollection = useMemo(() => {
		if (!commonCollections || !inboxCollection) {
			return;
		}

		return createAllStoriesCollection(commonCollections, inboxUserStories);
	}, [commonCollections, inboxCollection, inboxUserStories]);

	const collections = useMemo(() => {
		if (!commonCollections || !inboxCollection) {
			return [];
		}

		return [inboxCollection, allTestsCollection, ...commonCollections];
	}, [commonCollections, allTestsCollection, inboxCollection]);

	return {
		collections,
		isLoading: isInboxCollectionLoading || isLoadingCollections,
		isError: isErrorLoadingCollections || isInboxCollectionError,
	};
};

const createAllStoriesCollection = (
	collections: UserStoriesCollection[],
	inboxStories: UserStory[] = []
): UserStoriesCollection => {
	const storiesStatistics = collections.reduce(
		(acc, collection) => {
			const currentCollectionStatistics = collection.storiesStatistics;

			acc.count += currentCollectionStatistics.count;

			Object.entries(currentCollectionStatistics.statusCount).forEach(
				([key, value]) => {
					acc.statusCount[key] += value;
				}
			);

			return acc;
		},
		{
			count: 0,
			startURLs: [],
			statusCount: {
				[TestGenerationStatus.Completed]: 0,
				[TestGenerationStatus.PRMerged]: 0,
				[TestGenerationStatus.PRClosed]: 0,
				[TestGenerationStatus.Failed]: 0,
				[TestGenerationStatus.Queued]: 0,
				[TestGenerationStatus.Running]: 0,
				[TestGenerationStatus.Verifying]: 0,
			},
		} as UserStoriesCollection['storiesStatistics']
	);

	const identifiedStories = inboxStories.filter(
		(story) => story.collectionId === INBOX_COLLECTION_ID
	);

	storiesStatistics.count += identifiedStories.length;

	return {
		isFlowAutoDetectionActivated: false,
		id: ALL_FLOWS_COLLECTION_ID,
		description: '',
		autoDetectFlowsDescription: null,
		name: 'All test flows',
		defaultStartURL: '',
		storiesStatistics,
	};
};
