import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { OpenedConfirmationModalVariant } from '../constants';
import { AlertDialogContent } from '@src/common/components/ConfirmationDialog/types';
import { useGetConfirmationModalContent } from '@src/modules/user-stories/components/StoryForm/hooks/useGetConfirmationModalContent';
import { SettingsStatus } from '@src/modules/settings/service';
import { window_allow_incomplete_settings_generation } from '@src/config/window.config';

type ReturnType = {
	handleDeleteStory: () => void;
	handleAbortStoryTestGeneration: () => void;
	handleGenerateTest: () => void;
	confirmationAlertDialogContent: AlertDialogContent | null;
};

export const useShowConfirmationAlertDialog = ({
	isDirty,
	handleCloseStory,
	setCloseStoryModal,
	onDelete,
	onAbort,
	onGenerate,
	settingsStatus,
}: {
	isDirty: boolean;
	setCloseStoryModal: Dispatch<SetStateAction<() => void>>;
	handleCloseStory: () => void;
	onDelete: () => void;
	onAbort: () => void;
	onGenerate: () => void;
	settingsStatus?: SettingsStatus;
}): ReturnType => {
	const [isConfirmationAlertDialogOpened, setIsConfirmationAlertDialogOpened] =
		useState(false);

	//ref for identifying which king of Confirmation Alert Dialog should be shown
	const modalVariantRef = useRef<OpenedConfirmationModalVariant>(
		OpenedConfirmationModalVariant.DEFAULT
	);

	//is user story dirty
	// content that is used inside the Confirmation Alert Dialog
	const confirmationAlertContent = useGetConfirmationModalContent({
		isConfirmationAlertDialogOpened,
		modalVariant: modalVariantRef.current,
		setIsConfirmationAlertDialogOpened,
		onDelete,
		onAbort,
		handleCloseStory,
	});

	//trigger to open the Delete Confirmation Alert
	const handleDeleteStory = useCallback(() => {
		modalVariantRef.current = OpenedConfirmationModalVariant.DELETE;

		setIsConfirmationAlertDialogOpened(true);
	}, []);

	//trigger to open the Abort Confirmation Alert
	const handleAbortStoryTestGeneration = useCallback(() => {
		modalVariantRef.current = OpenedConfirmationModalVariant.ABORT;

		setIsConfirmationAlertDialogOpened(true);
	}, []);

	//prevent test generation if github integration failed
	const handleGenerateTest = useCallback(() => {
		if (!settingsStatus) {
			return;
		}

		const isSettingsSetupValid =
			(settingsStatus.isGitIntegrated &&
				settingsStatus.hasValidEnvironmentSettings) ||
			window_allow_incomplete_settings_generation();

		if (
			isSettingsSetupValid &&
			settingsStatus.applicationHasValidLoginFunction
		) {
			onGenerate();

			return;
		}

		// show setup incomplete modal first
		modalVariantRef.current = !isSettingsSetupValid
			? OpenedConfirmationModalVariant.SETTINGS_INCOMPLETE
			: OpenedConfirmationModalVariant.NO_VALID_LOGIN_FUNCTION;

		setIsConfirmationAlertDialogOpened(true);
	}, [settingsStatus, onGenerate]);

	//interceptor for closing the story modal in case it is dirty
	//if story form is dirty => Unsaved Changes Confirmation Alert
	useEffect(() => {
		if (!setCloseStoryModal) {
			return;
		}

		let callback = () => handleCloseStory;

		if (isDirty) {
			callback = () => () => {
				modalVariantRef.current =
					OpenedConfirmationModalVariant.UNSAVED_CHANGES;
				setIsConfirmationAlertDialogOpened(true);
			};
		} else {
			modalVariantRef.current = OpenedConfirmationModalVariant.DEFAULT;
		}

		setCloseStoryModal(callback);
	}, [isDirty, setCloseStoryModal, handleCloseStory]);

	return {
		confirmationAlertDialogContent: confirmationAlertContent
			? {
					open: isConfirmationAlertDialogOpened,
					...confirmationAlertContent,
			  }
			: null,
		handleDeleteStory,
		handleGenerateTest,
		handleAbortStoryTestGeneration,
	};
};
