import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { css } from '@emotion/react';

export const useAddNewUserFormStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const formContent = css({
			display: 'grid',
			gap: theme.spacing(1),
			gridTemplateColumns: '2fr 1fr auto',
		});

		return {
			formContent,
		};
	}, [theme]);
};
