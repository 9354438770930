import { getEnvVar, getEnvVarOrFail } from './helpers';
import { LabelingResponseKey } from '@src/modules/test-generation-labeling/service';

type ConfigType = {
	env: 'local' | 'development' | 'staging' | 'production';
	appVersion: string;
	appUrl: string;
	websiteUrl: string;
	publicUrl: string;
	git: {
		versionTag: string;
		sha: string;
		branch: string;
	};
	labeling: {
		responseKey: LabelingResponseKey;
	};
	api: {
		baseUrl: string;
		authHeader: string;
		mock: boolean;
	};
	authentication: {
		github: {
			clientId: string;
		};
		google: {
			clientId: string;
		};
		microsoft: {
			clientId: string;
		};
	};
	crm: {
		intercom: {
			appId: string;
		};
		zendesk: {
			key: string;
		};
		crisp: {
			id: string;
		};
	};
	analytics: {
		segment: {
			apiKey: string;
		};
		gtm: {
			id: string;
		};
		checksum: {
			key: string;
		};
	};
	recaptchaSiteKey: string;
	options: {
		allowPageCreationAndEditing: boolean;
	};
};

const Config: ConfigType = {
	env: getEnvVarOrFail('REACT_APP_ENV'),
	appVersion: getEnvVar('REACT_APP_APP_VERSION', '2.0'),
	appUrl: getEnvVarOrFail('REACT_APP_APPLICATION_URL'),
	websiteUrl: getEnvVarOrFail('REACT_APP_WEBSITE_URL'),
	publicUrl: getEnvVar('REACT_APP_PUBLIC_URL', ''),
	git: {
		versionTag: getEnvVar('REACT_APP_GIT_VERSION_TAG', ''),
		sha: getEnvVar('REACT_APP_GIT_VERSION_SHA', ''),
		branch: getEnvVar('REACT_APP_GIT_VERSION_BRANCH', ''),
	},
	labeling: {
		responseKey: getEnvVar(
			'REACT_APP_LABELING_RESPONSE_KEY',
			'rawLLMResponseJSON'
		),
	},
	api: {
		baseUrl: getEnvVarOrFail('REACT_APP_API_BASE_URL'),
		authHeader: getEnvVar('REACT_APP_AUTH_HEADER', 'ChecksumAppCode'),
		mock: getEnvVar<boolean>('REACT_APP_API_MOCK', false, { boolean: true }),
	},
	authentication: {
		github: {
			clientId: getEnvVar('REACT_APP_GITHUB_CLIENT_ID', ''),
		},
		google: {
			clientId: getEnvVar('REACT_APP_GOOGLE_CLIENT_ID', ''),
		},
		microsoft: {
			clientId: getEnvVar('REACT_APP_MICROSOFT_CLIENT_ID', ''),
		},
	},
	crm: {
		intercom: {
			appId: getEnvVar('REACT_APP_INTERCOM_APP_ID'),
		},
		zendesk: {
			key: getEnvVar('REACT_APP_ZENDESK_KEY'),
		},
		crisp: {
			id: getEnvVar('REACT_APP_CRISP_ID'),
		},
	},
	analytics: {
		segment: {
			apiKey: getEnvVar('REACT_APP_SEGMENT_KEY'),
		},
		gtm: {
			id: getEnvVar('REACT_APP_GOOGLE_TAG_MANAGER_ID'),
		},
		checksum: {
			key: getEnvVar('REACT_APP_CHECKSUM_KEY'),
		},
	},
	recaptchaSiteKey: getEnvVar('REACT_APP_RE_CAPCHA_SITE_KEY'),
	options: {
		allowPageCreationAndEditing: false,
	},
};

export default Config;
