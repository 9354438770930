import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '@src/store/hooks';
import {
	useAuthenticateOAuthMutation,
	useValidateAppNameMutation,
} from '@src/modules/authentication/api';
import { useOAuth } from '@src/modules/authentication/pages/authenticate/hooks/useOAuth';
import { setPersistentActiveApplicationId } from '@src/common/state/persistent/slice';

type FormValues = {
	code: string;
};

export const useAuthenticatePage = () => {
	const dispatch = useAppDispatch();

	const [
		validateAuthCode,
		{ data: codeValidationData, isSuccess: isValidationSuccess },
	] = useValidateAppNameMutation();

	const [oauthLogin, { isError, data }] = useAuthenticateOAuthMutation();
	
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<FormValues>();

	const [internalUserCode, setInternalUserCode] = useState<string | null>(null);
	const [appId, setAppId] = useState<string | null>(null);
	const [isLoginLoading, setIsLoginLoading] = useState(false);

	// display screen with oauth button only if code is provided
	const showOauthOnly = useMemo(() => {
		return (
			!!codeValidationData?.internalCode || !!codeValidationData?.validAppId
		);
	}, [codeValidationData]);

	const {
		provider,
		onLoginStart,
		codeOrAccessToken,
		onLogoutSuccess,
		onOauthReject,
		onOauthResolve,
		authCode,
	} = useOAuth({ isError, data });

	// call validate app or internal code on authenticate
	const onLogin = useCallback(
		async (formValues) => {
			await validateAuthCode({ code: formValues.code });
		},
		[validateAuthCode]
	);

	/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	const onSubmit = useCallback(handleSubmit(onLogin), [handleSubmit, onLogin]);

	//handles the validation response, and shows the oauth screen
	useEffect(() => {
		if (isValidationSuccess) {
			if (!!codeValidationData.validAppId) {
				setAppId(codeValidationData.validAppId);
				dispatch(
					setPersistentActiveApplicationId(codeValidationData.validAppId)
				);
			}

			if (!!codeValidationData.internalCode) {
				setInternalUserCode(codeValidationData.internalCode);
			}
		}
	}, [codeValidationData, isValidationSuccess, dispatch]);

	// run login if validation was successful
	useEffect(() => {
		if (!!codeOrAccessToken && !!provider && !isLoginLoading) {
			//set loading manually to prevent state change on unmount
			setIsLoginLoading(true);

			oauthLogin({
				authCode,
				accessToken: codeOrAccessToken,
				provider,
				appId,
				internalCode: internalUserCode,
			}).finally(() => {
				//set loading manually to prevent state change on unmount
				setIsLoginLoading(false);
			});
		}
	}, [
		isLoginLoading,
		codeOrAccessToken,
		provider,
		oauthLogin,
		appId,
		internalUserCode,
		authCode,
	]);

	return {
		control,
		register,
		onSubmit,
		errors,
		hasError: isError,
		isLoginLoading,
		onLogoutSuccess,
		onOauthResolve,
		onOauthReject,
		onLoginStart,
		showOauthOnly,
	};
};
