import AnalyticsProviderInterface from './providers/AnalyticsProviderInterface';

import {
	FIXED_EVENT_ATTRIBUTES,
	ANALYTICS_PROVIDER_CLASSES,
} from './constants';
import {
	AnalyticsIdentifyType,
	GlobalEventAttributesEnum,
	TrackedEvents,
} from './types';
import Logger from '@src/services/logger';
import { LogComponents } from '@src/services/logger/types';

const logger = new Logger(LogComponents.Analytics, { mute: true });

class AnalyticsManager {
	private readonly providers: AnalyticsProviderInterface[];
	private globalEventAttributes: Partial<
		Record<GlobalEventAttributesEnum, string | number>
	> = {};

	constructor() {
		this.providers = ANALYTICS_PROVIDER_CLASSES.map(
			(ProviderClass) => new ProviderClass()
		);
	}

	init() {
		logger.info('boot');
		this.forEachProvider((provider) => provider.init(), false);
	}

	identify(userId: string, properties: AnalyticsIdentifyType) {
		this.setGlobalEventAttribute(GlobalEventAttributesEnum.UserId, userId);
		const _properties = this.mergeWithGlobalEventAttributes(properties);
		logger.info('identify', { userId, _properties });
		this.forEachProvider((provider) =>
			provider.identify(userId, { ..._properties })
		);

		this.track('identified');
	}

	alias(userId: string, formerId?: string) {
		logger.info('alias', { userId, formerId });
		this.forEachProvider((provider) => provider.alias(userId, formerId));
	}

	reset() {
		this.forEachProvider((provider) => provider.reset());
		this.resetGlobalEventAttributes();
	}

	track(event: TrackedEvents) {
		let type;
		let payload;
		if (typeof event === 'string') {
			type = event;
		} else {
			type = event.type;
			payload = event.payload;
		}

		const mergedPayload = this.mergeWithGlobalEventAttributes(payload);
		logger.info('track', { type, payload: mergedPayload });

		return this.forEachProvider((provider) =>
			provider.track(type, { ...mergedPayload })
		);
	}

	trackPageView(page: string) {
		logger.info('page view', { page });
		this.forEachProvider((provider) => provider.trackPageView(page));
	}

	setGlobalEventAttribute(
		name: GlobalEventAttributesEnum,
		value: string | number
	) {
		this.globalEventAttributes[name] = value;
	}

	resetGlobalEventAttributes() {
		this.globalEventAttributes = {};
	}

	// -------- [Private] -------- //

	private forEachProvider(
		cb: (provider: AnalyticsProviderInterface) => unknown,
		checkActive = true
	) {
		const results: Record<string, unknown> = {};

		this.providers.forEach((provider, index) => {
			try {
				if (checkActive && !provider.isActive()) {
					return;
				}
				results[provider.name] = cb(provider);
			} catch (e) {
				console.error(
					'[analytics] forEachProvider error for provider at index',
					index,
					e
				);
			}
		});

		return results;
	}

	private getProvider<T extends AnalyticsProviderInterface>(providerClass): T {
		const index = ANALYTICS_PROVIDER_CLASSES.indexOf(providerClass);
		return this.providers[index] as T;
	}

	private mergeWithGlobalEventAttributes = (data = {}) => {
		return {
			...FIXED_EVENT_ATTRIBUTES,
			...this.globalEventAttributes,
			...data,
		};
	};
}

const Analytics = new AnalyticsManager();
export { Analytics };
