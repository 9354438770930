export const moveObjectFieldToFirstPosition = (
	obj: Record<string, unknown>,
	field: string
) => {
	if (!obj || typeof obj !== 'object') {
		return {};
	}

	//move field to the first object fields position
	if (obj.hasOwnProperty(field)) {
		const { [field]: value, ...rest } = obj;
		return {
			[field]: value,
			...rest,
		};
	}

	return obj;
};
