import { useEffect, useState } from 'react';
import { useGetUserStoriesCollectionsQuery } from '../api';
import { UserStoriesCollection } from '@src/modules/collections/types';

export const useCollection = (collectionId: string) => {
	const {
		data: collections,
		isLoading,
		isError,
		isFetching,
	} = useGetUserStoriesCollectionsQuery();

	const [collection, setPage] = useState<UserStoriesCollection | null>(
		getCollectionById(collections, collectionId)
	);

	useEffect(() => {
		setPage(getCollectionById(collections, collectionId));
	}, [collections, collectionId]);

	return { collection, isLoading, isError, isFetching };
};

const getCollectionById = (
	collections: UserStoriesCollection[],
	collectionId?: string
): UserStoriesCollection => {
	if (!collectionId) {
		return undefined;
	}
	return collections?.find((collection) => collection.id === collectionId);
};
