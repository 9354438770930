import { GithubAppIntegrationStatus } from '@src/modules/settings/service';
import {
	mockClosePr,
	mockConfirmSetupSdk,
	mockDefaultEnvironmentName,
	mockGithubAppIntegration,
	mockMergePr,
} from '@src/api/mock/helpers';
/* eslint-disable @typescript-eslint/no-explicit-any */

//declare mock handlers for changing mock data in settings mocks

declare global {
	interface Window {
		_checksum_mock_confirm_setup_sdk: () => void;
		_checksum_mock_github_app_integration: (
			status?: GithubAppIntegrationStatus
		) => void;
		_checksum_mock_default_environment_name: (name?: string) => void;

		_checksum_mock_mock_close_pr: (id: string) => void;
		_checksum_mock_merge_pr: (id: string) => void;
	}
}

function addMockFunctionToWindow<T extends (...args: any[]) => any>(
	name: string,
	func: T
): void {
	window[name] = func;
}

//set mock handlers to the window object
addMockFunctionToWindow(
	'_checksum_mock_confirm_setup_sdk',
	mockConfirmSetupSdk
);
addMockFunctionToWindow(
	'_checksum_mock_github_app_integration',
	mockGithubAppIntegration
);
addMockFunctionToWindow(
	'_checksum_mock_default_environment_name',
	mockDefaultEnvironmentName
);

// simulate pr closed pr merged webhook
addMockFunctionToWindow('_checksum_mock_mock_close_pr', mockClosePr);
addMockFunctionToWindow('_checksum_mock_mock_merge_pr', mockMergePr);

export {};
