import { formatTimestampAsLocaleStringWithoutSeconds } from '@src/common/helpers/date';
import {
	TestGeneration,
	TestGenerationStatus,
} from '@src/modules/test-generations/service';

export const getUserStoriesGenerationsNotes = (
	generations: TestGeneration[]
) => {
	return generations.reduce((formattedNotes: string[], generation) => {
		const formattedNote = formatGenerationNote(generation);

		if (formattedNote) {
			formattedNotes.push(formattedNote);
		}

		return formattedNotes;
	}, []);
};

const formatGenerationNote = (generation: TestGeneration) => {
	if (!generation) {
		return null;
	}

	// if completedAt exists then generation is completed or failed
	const timestampDateValue = generation.completedAt ?? generation.updatedAt;
	const makeTimeString = () =>
		formatTimestampAsLocaleStringWithoutSeconds(timestampDateValue);

	const makePRClosedTimeString = () =>
		!!generation.prClosedAt
			? `${formatTimestampAsLocaleStringWithoutSeconds(
					generation.prClosedAt
			  )}, `
			: '';

	switch (generation.status) {
		case TestGenerationStatus.Completed:
			return `${makeTimeString()}, Generated`;
		case TestGenerationStatus.Failed:
			return `${makeTimeString()}, Failed`;
		case TestGenerationStatus.PRClosed:
			return `${makePRClosedTimeString()}PR Closed`;

		case TestGenerationStatus.PRMerged:
			return `${makePRClosedTimeString()}PR Merged`;
		default:
			return null;
	}
};

export const formatEditedStoryAction = (editedAt: string) => {
	if (!editedAt) {
		return null;
	}

	const timeString = formatTimestampAsLocaleStringWithoutSeconds(editedAt);

	return `${timeString}, Edited`;
};
