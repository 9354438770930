import { boolean, object, string } from 'yup';
import {
	customCreateError,
	getEnvUsersWithSameFieldRole,
	getUsernameAndPasswordValidation,
} from '@src/modules/settings/components/ApplicationEnvironmentSettings/validation/helpers';

export const requiredEnvironmentUserSchema = object().shape({
	role: string()
		.trim()
		.test('Role', (value, context) => {
			// from[0].value contains parent object (array item), and from[1].value contains the whole schema
			const allEnvUsers = context.from[1].value.environmentUsers;

			const envUsersWithSameRole = getEnvUsersWithSameFieldRole(
				allEnvUsers,
				value
			);

			if (envUsersWithSameRole.length > 1 && !!value) {
				return customCreateError(context, 'Role must be unique');
			}

			if (allEnvUsers.length > 1 && !value) {
				return customCreateError(context, 'Role is required');
			}

			return true;
		}),
	username: string()
		.trim()
		.test('UserName', (value, context) => {
			return getUsernameAndPasswordValidation(
				context,
				value,
				'Username/email is required'
			);
		}),
	password: string()
		.trim()
		.test('Password', (value, context) => {
			return getUsernameAndPasswordValidation(
				context,
				value,
				'Password is required'
			);
		}),
	isDefault: boolean().required(),
});
