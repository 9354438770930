import { User } from '@src/modules/authentication/api/types';
import { AvailableApplication } from '../application/service';

export enum InternalUserPermissions {
	TestGenerationLabeling = 'tg-labeling',
	TestGenerationLabelingReview = 'tg-labeling-review',
	AuthorizeTestGenerations = 'authorize-test-generations',
}

export type AuthenticationResult = {
	authCode: string;
	user: User;
	applications: AvailableApplication[];
};

export type OAuthProvider = 'github' | 'google';
