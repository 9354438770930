import { useCallback } from 'react';
import { useLogoutMutation } from '../api';

export const useLogout = () => {
	const [logout] = useLogoutMutation();

	const handleLogout = useCallback(() => {
		return logout({});
	}, [logout]);

	return handleLogout;
};
