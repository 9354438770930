import { useCallback, useMemo, useState } from 'react';
import { ScreenshotOptions } from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/constants';
import { TestGenerationLogStep } from '@src/modules/test-generation-labeling/types';

export const useScreenshots = ({
	activeTestGenerationLogStep,
}: {
	activeTestGenerationLogStep?: TestGenerationLogStep;
}) => {
	const [activeScreenshotOption, setActiveScreenshotOption] =
		useState<ScreenshotOptions>(ScreenshotOptions.TAGGED);

	//screenshot that is chosen in the select
	const screenshot = useMemo(() => {
		return activeTestGenerationLogStep
			? activeTestGenerationLogStep[activeScreenshotOption]
			: null;
	}, [activeTestGenerationLogStep, activeScreenshotOption]);

	const onSelectScreenshot = useCallback((event) => {
		setActiveScreenshotOption(event.target.value);
	}, []);

	//create new tab with full width image
	const handleExpandScreenshot = useCallback(() => {
		const image = new Image();
		image.src = screenshot;
		image.style.width = '100vw';
		image.style.height = 'auto';

		const w = window.open('');
		w.document.write(image.outerHTML);
	}, [screenshot]);

	return {
		handleExpandScreenshot,
		screenshot,
		onSelectScreenshot,
		activeScreenshotOption,
	};
};
