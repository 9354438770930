import { useCallback, useState } from 'react';
import FilesUploadingManager from '@src/services/files-uploading-manager';
import { useGetDownloadSignedUrlMutation } from '@src/modules/user-stories/api';
import { downloadFileByUrl } from '@src/common/helpers/files';

export const useChipFile = ({
	onEdit,
	onDelete,
	value: activeElement,
	index,
}) => {
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
	const [isDownloadLoading, setIsDownloadLoading] = useState(false);

	const { isCompleted: isFileUploadingCompleted, isUploading } =
		FilesUploadingManager.getFileUploadingState(activeElement?.file?.id);

	const isError =
		!isFileUploadingCompleted &&
		!(isUploading || activeElement?.file?.available || !activeElement.fileId);

	//open menu handler
	const handleClick = useCallback(
		(event) => {
			if (!isUploading) {
				setAnchorElement(event.currentTarget);
			}
		},
		[setAnchorElement, isUploading]
	);

	//close menu
	const handleClose = useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);

	const handleEdit = useCallback(() => {
		handleClose();
		onEdit(index);
	}, [onEdit, handleClose, index]);

	const handleDelete = useCallback(
		(index) => {
			onDelete(index);
			handleClose();
		},
		[onDelete, handleClose]
	);

	const [getDownloadSignedUrl] = useGetDownloadSignedUrlMutation();

	const handleGetDownloadSignedUrl = useCallback(async () => {
		const response = await getDownloadSignedUrl({
			userStoryFileId: activeElement.id,
		});

		if ('data' in response) {
			try {
				setIsDownloadLoading(true);
				await downloadFileByUrl(response.data.url, activeElement.tag, true);
			} finally {
				setIsDownloadLoading(false);
				setAnchorElement(null);
			}
		}
	}, [
		setIsDownloadLoading,
		setAnchorElement,
		activeElement,
		getDownloadSignedUrl,
	]);

	const color = isError
		? 'error'
		: ('default' as
				| 'error'
				| 'default'
				| 'primary'
				| 'secondary'
				| 'info'
				| 'success'
				| 'warning');

	return {
		color,
		handleDelete,
		handleEdit,
		handleClick,
		anchorElement,
		isError,
		isUploading,
		handleClose,
		handleGetDownloadSignedUrl,
		isDownloadLoading,
		isFileUploadingCompleted,
	};
};
