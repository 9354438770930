import {
	Box,
	Button,
	Chip,
	IconButton,
	ListItem,
	ListItemButton,
	Typography,
} from '@mui/material';
import { StoryStatusBar } from '../StoryStatusBar/StoryStatusBar';
import { useStoryItemStyles } from '@src/modules/user-stories/components/StoriesList/styles';
import { useStoryListItem } from '@src/modules/user-stories/components/StoryListItem/hooks';
import StairsOutlinedIcon from '@mui/icons-material/StairsOutlined';
import { UserStory } from '@src/modules/user-stories/types';
import { SettingsStatus } from '@src/modules/settings/service';

export function StoryListItem({
	story,
	activeCollectionId,
	onXClick,
	handleOpenPrInNewWindowModal,
	isNewForInbox = false,
	settingsStatus,
}: {
	story: UserStory;
	activeCollectionId: string;
	onXClick: (e, story: UserStory) => void;
	handleOpenPrInNewWindowModal: (e, story: UserStory) => void;
	isNewForInbox: boolean;
	settingsStatus: SettingsStatus;
}) {
	const {
		isInboxActive,
		handleOpenStoryForm,
		Icon,
		userStoryChipColor,
		handleClearFromInbox,
		buttonContent,
		onButtonClick,
		badgeText,
		shouldDisplayCollectionName,
	} = useStoryListItem({
		story,
		activeCollectionId,
		onXClick,
		handleOpenPrInNewWindowModal,
	});

	const styles = useStoryItemStyles(story.status, isInboxActive);

	return (
		<ListItem
			css={styles.listItem}
			disablePadding
			onClick={handleOpenStoryForm}
			secondaryAction={
				<If condition={false}>
					<IconButton aria-label="story-options">
						<StairsOutlinedIcon />
					</IconButton>
				</If>
			}
		>
			<ListItemButton css={[styles.failed, styles.listItemButton]}>
				<Box css={styles.titleRow}>
					<Box css={styles.column}>
						<If condition={shouldDisplayCollectionName}>
							<Box css={styles.row}>
								<Typography css={styles.collectionName} variant="caption">
									{story.collectionName}
								</Typography>
							</Box>
						</If>

						<Box css={styles.row}>
							<Icon css={styles.icon} fontSize="small" />
							<Typography>{story.title}</Typography>

							<If condition={!!badgeText}>
								<Chip
									color={userStoryChipColor}
									label={badgeText}
									size="small"
								/>
							</If>

							<If condition={!!isNewForInbox && isInboxActive}>
								<Chip
									color={userStoryChipColor}
									label="new"
									variant="outlined"
									size="small"
								/>
							</If>
						</Box>
					</Box>

					<If condition={isInboxActive}>
						<Button
							size="small"
							css={styles.clearButton}
							onClick={handleClearFromInbox}
						>
							X
						</Button>
					</If>
				</Box>

				<Box css={styles.contentRow}>
					<StoryStatusBar settingsStatus={settingsStatus} story={story} />

					<If condition={isInboxActive}>
						<Button variant="outlined" onClick={onButtonClick}>
							{buttonContent}
						</Button>
					</If>
				</Box>
			</ListItemButton>
		</ListItem>
	);
}
