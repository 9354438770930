import { Route } from 'react-router-dom';
import AppRoute from '@src/routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { authenticateRoutes } from '@src/modules/authentication/routes/routes';
import { AuthenticatePage } from '@src/modules/authentication/pages/authenticate/AuthenticatePage';
import { CreateNewApplicationPage } from '@src/modules/authentication/pages/createNewApplication/CreateNewApplicationPage';
import { RedirectFromAuthenticationIfLoggedIn } from '@src/modules/authentication/routes/RedirectFromAuthenticationIfLoggedIn';

export function AuthenticateRouter() {
	return (
		<AppRouter>
			<Route
				path={authenticateRoutes.authenticate.path}
				element={
					<AppRoute
						withLayout
						allowMobile
						authenticated={false}
						requireApplications={false}
					>
						<RedirectFromAuthenticationIfLoggedIn>
							<AuthenticatePage />
						</RedirectFromAuthenticationIfLoggedIn>
					</AppRoute>
				}
			/>
			<Route
				path={authenticateRoutes.newApplication.path}
				element={
					<AppRoute
						withLayout
						allowMobile
						authenticated={false}
						requireApplications={false}
						component={CreateNewApplicationPage}
					/>
				}
			/>
		</AppRouter>
	);
}
