import { useDeleteGitIntegrationMutation } from '@src/modules/settings/api';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { generateConfirmationDialogContent } from '@src/modules/settings/components/GitSettings/components/DeleteIntegrationConfirmationModal/helpers';
import { AlertDialogContent } from '@src/common/components/ConfirmationDialog/types';
import { GitAppMode } from '@src/modules/settings/components/GitSettings/hooks';

const ProvidersTabModesMap = [GitAppMode.Github, GitAppMode.Gitlab];

export const useDeleteIntegrationConfirmationModal = ({
	deleteIntegrationModalOpenedMode,
	setDeleteIntegrationModalOpenedMode,
}: {
	deleteIntegrationModalOpenedMode: GitAppMode;
	setDeleteIntegrationModalOpenedMode: Dispatch<SetStateAction<GitAppMode>>;
}): { modalContent: AlertDialogContent; isLoading: boolean } => {
	const [deleteGitIntegration, { isLoading }] =
		useDeleteGitIntegrationMutation();

	const handleCloseModal = useCallback(() => {
		setDeleteIntegrationModalOpenedMode(null);
	}, [setDeleteIntegrationModalOpenedMode]);

	const handleDeleteGithubIntegration = useCallback(async () => {
		await deleteGitIntegration();

		handleCloseModal();
	}, [deleteGitIntegration, handleCloseModal]);

	const modalContent = useMemo(() => {
		const textContent = generateConfirmationDialogContent(
			deleteIntegrationModalOpenedMode,
			{
				onSuccess: handleCloseModal,
				onCancel: handleDeleteGithubIntegration,
			}
		);

		return {
			open: ProvidersTabModesMap.includes(deleteIntegrationModalOpenedMode),
			onClose: handleCloseModal,
			...textContent,
		};
	}, [
		handleCloseModal,
		handleDeleteGithubIntegration,
		deleteIntegrationModalOpenedMode,
	]);

	return {
		modalContent,
		isLoading,
	};
};
