import { ApplicationRoutes } from '@src/app/router/routes';
import { prepareModuleRoutes } from '@src/routing';

export const userStoriesExplorerRoutes = prepareModuleRoutes<
	'userStories' | 'userStoriesCollection' | 'flow'
>(
	{
		userStories: {
			path: '/',
		},
		userStoriesCollection: {
			path: '/:collectionId',
		},
		flow: {
			path: '/:collectionId/flow/:storyId',
		},
	},
	ApplicationRoutes.userStoriesExplorer.absolutePath
);
