import { Box, Button, Typography } from '@mui/material';
import { CollectionsListDropdown } from '@src/modules/collections/components/CollectionsListDropdown/CollectionsListDropdown';
import { useDeleteCollectionModal } from '@src/modules/collections/components/CollectionFormModal/components/DeleteCollectionModal/hooks';
import { useDeleteCollectionModalStyles } from '@src/modules/collections/components/CollectionFormModal/components/DeleteCollectionModal/styles';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { UserStoriesCollection } from '@src/modules/collections/types';

export const DeleteCollectionModal = ({
	onClose,
	collectionToDelete,
	collections,
}: {
	onClose: () => void;
	collectionToDelete: UserStoriesCollection;
	collections: UserStoriesCollection[];
}) => {
	const { handleMoveStories, isLoading } = useDeleteCollectionModal({
		collectionToDelete,
		onClose,
	});

	const styles = useDeleteCollectionModalStyles();
	return (
		<>
			<Typography variant="h3">Delete collection</Typography>

			<Typography variant="subtitle1" gutterBottom>
				This collection includes flows therefore can not be deleted.
				<br />
				Empty the collection or use the move all option below in order to
				proceed with deleting the collection.
			</Typography>

			<Box css={styles.buttonsContainer}>
				<If condition={isLoading}>
					<LoadingCircularProgress circularProgressSize={20}>
						<Typography variant="subtitle2">
							Moving test flows and deleting collection...
						</Typography>
					</LoadingCircularProgress>
				</If>

				<If condition={!isLoading}>
					<Typography variant="caption" textAlign="center">
						Move all test flows to
					</Typography>

					<CollectionsListDropdown
						value={collectionToDelete?.id}
						styles={styles.dropdown}
						name="collectionId"
						handleChangeCollection={handleMoveStories}
						collections={collections}
					/>
				</If>
			</Box>

			<Box css={styles.buttonsContainerEnd}>
				<Button onClick={onClose}>Cancel</Button>
			</Box>
		</>
	);
};
