import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/service';
import { UserStory } from '@src/modules/user-stories/types';
import {
	useClearStoryFromInboxMutation,
	useUpdateUserStoryMutation,
} from '@src/modules/user-stories/api';
import { toast } from 'react-toastify';
import { useDoNotShowModalToOpenPrReviewInSeparateWindow } from '@src/common/state/persistent/hooks';
import { userStoriesExplorerRoutes } from '@src/modules/user-stories-exploration/service';
import { useNavigate } from 'react-router-dom';
import { useGetSettingsStatus } from '@src/modules/settings/service';

export const useStoriesList = ({
	onAddNewCollection,
	setAfterAddNewCollectionModalCloseCallback,
	activeCollectionId,
}: {
	onAddNewCollection: () => void;
	setAfterAddNewCollectionModalCloseCallback: Dispatch<
		SetStateAction<(id: string) => void>
	>;
	activeCollectionId: string;
}) => {
	// optimistic update?
	const [clearFromInbox] = useClearStoryFromInboxMutation();

	const [updateStory] = useUpdateUserStoryMutation();

	const [storyToClearFromInbox, setStoryToClearFromInbox] =
		useState<UserStory | null>(null);
	const [storyToOpenPr, setStoryToOpenPr] = useState<UserStory | null>(null);

	const { settingsStatus } = useGetSettingsStatus();

	const doNotShowModalToOpenPrReviewInSeparateWindow =
		useDoNotShowModalToOpenPrReviewInSeparateWindow();

	const navigate = useNavigate();

	const handleClearFromInbox = useCallback(
		(e, story: UserStory) => {
			e.stopPropagation();

			if (story.collectionId === INBOX_COLLECTION_ID) {
				setStoryToClearFromInbox(story);

				return;
			}

			clearFromInbox(story.id);
			setStoryToClearFromInbox(null);
		},
		[clearFromInbox]
	);

	const handleOpenPrInNewWindowModal = useCallback(
		(e, story: UserStory) => {
			e.stopPropagation();

			if (!story.recentGeneration?.pullRequestURL) {
				toast.error('No PR link found');

				return;
			}

			if (doNotShowModalToOpenPrReviewInSeparateWindow) {
				e.preventDefault();

				window.open(story.recentGeneration.pullRequestURL, '_blank');

				// open story form after open pr
				navigate(
					userStoriesExplorerRoutes.flow.make({
						collectionId: activeCollectionId,
						storyId: story.id,
					})
				);
			} else {
				setStoryToOpenPr(story);
			}
		},
		[doNotShowModalToOpenPrReviewInSeparateWindow, activeCollectionId, navigate]
	);

	const handleClosePrInNewWindowModal = useCallback(() => {
		setStoryToOpenPr(null);
	}, []);

	const handleCloseChangeCollectionModal = useCallback(() => {
		setStoryToClearFromInbox(null);
	}, []);

	const onAddNewCollectionAndClearFromInbox = useCallback(() => {
		// open create collection modal
		onAddNewCollection();

		// set callback after the new story is created
		setAfterAddNewCollectionModalCloseCallback(
			() => async (collectionId: string) => {
				// move story, to newly created collection
				await updateStory({
					id: storyToClearFromInbox.id,
					update: { collectionId },
				});

				//clear story from inbox with optimistic update
				clearFromInbox(storyToClearFromInbox.id);

				//close modal
				setStoryToClearFromInbox(null);
			}
		);
	}, [
		updateStory,
		onAddNewCollection,
		clearFromInbox,
		storyToClearFromInbox,
		setAfterAddNewCollectionModalCloseCallback,
	]);

	return {
		onAddNewCollectionAndClearFromInbox,
		handleCloseChangeCollectionModal,
		storyToClearFromInbox,
		handleClearFromInbox,
		storyToOpenPr,
		handleOpenPrInNewWindowModal,
		handleClosePrInNewWindowModal,
		settingsStatus,
	};
};
