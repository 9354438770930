import {
	CONFIRMATION_ALERTS_CONTENTS,
	OpenedConfirmationModalVariant,
} from '@src/modules/collections/constants';
import { UserStoriesCollection } from '@src/modules/collections/types';
import { ConfirmationDialogCallbacks } from '@src/common/components/ConfirmationDialog/types';

export const getFormValues = (collection?: UserStoriesCollection) => {
	if (collection) {
		return {
			name: collection.name,
			initialName: collection.name,
			description: collection.description ?? '',
			defaultStartURL: collection.defaultStartURL ?? '',
			isNewCollection: false,
		};
	} else {
		return {
			name: '',
			description: '',
			initialName: '',
			defaultStartURL: '',
			isNewCollection: true,
		};
	}
};

//TODO: can be refactored, Make this function generic <modal variant>
export const generateConfirmationDialogContent = (
	variant: OpenedConfirmationModalVariant,
	callbacks: ConfirmationDialogCallbacks
) => {
	if (variant === OpenedConfirmationModalVariant.DEFAULT) {
		return null;
	}

	const { title, buttons, description } = CONFIRMATION_ALERTS_CONTENTS[variant];

	const callbacksAsArray = Object.entries(callbacks);

	if (callbacksAsArray.length !== buttons.length) {
		throw new Error('Invalid callbacks amount');
	}

	const buttonsContent = callbacksAsArray.map(([key, value], index) => {
		return {
			callback: value,
			...buttons.at(index),
		};
	});

	return {
		title,
		description,
		buttons: buttonsContent,
		onClose: callbacks.onSuccess,
	};
};
