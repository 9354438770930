import React, { useEffect, useMemo } from 'react';
import { useCopyToClipboard } from '@src/common/hooks/useCopyToClipboard';
import Prism from 'prismjs';

import 'prismjs/themes/prism-okaidia.css'; // Assuming this theme is close to your color scheme

// Import additional PrismJS languages
import 'prismjs/components/prism-javascript';

export const useCodeBlock = ({ children, language }) => {
	const codeString = useMemo(() => {
		return typeof children === 'string'
			? children
			: React.Children.map(children, (child) => child.toString()).join('');
	}, [children]);

	const { handleCopy, copyStatus } = useCopyToClipboard(codeString);

	useEffect(() => {
		Prism.highlightAll();
	}, [children, language]);

	return {
		codeString,
		handleCopy,
		copyStatus,
	};
};
