import { useCallback, useState } from 'react';
import {
	useAddTestGenerationLogToQueueMutation,
	useDeleteTestGenerationLogFromQueueMutation,
	useGetTestGenerationLabelingQueueQuery,
} from '../../api';
import { testGenerationLabelingRoutes } from '@src/modules/test-generation-labeling/routes/routes';
import { TestGenerationLabelingTask } from '@src/modules/test-generation-labeling/types';

export const useTestGenerationLabelingQueue = () => {
	const { data: queue, isLoading: isQueueLoading } =
		useGetTestGenerationLabelingQueueQuery();
	const [newURL, setNewURL] = useState<string>('');
	const onNewURLChange = useCallback(
		(e) => setNewURL(e.target.value),
		[setNewURL]
	);
	const [addTaskToQueueMutation, { isLoading: isAdding }] =
		useAddTestGenerationLogToQueueMutation();
	const addToQueue = useCallback(() => {
		const urlForQueue = newURL.trim();
		if (!urlForQueue.length) {
			return;
		}
		if (!urlForQueue.startsWith('gs://checksum-test-generation-logs/')) {
			alert('URL must start with gs://checksum-test-generation-logs/');
			return;
		}
		addTaskToQueueMutation({ url: urlForQueue });
	}, [newURL, addTaskToQueueMutation]);

	const [deleteTaskFromQueueMutation, { isLoading: isDeleting }] =
		useDeleteTestGenerationLogFromQueueMutation();
	const deleteFromQueue = useCallback(
		(taskId) => {
			deleteTaskFromQueueMutation({ taskId });
		},
		[deleteTaskFromQueueMutation]
	);

	return {
		isQueueLoading,
		queue,
		onNewURLChange,
		addToQueue,
		isAdding,
		deleteFromQueue,
		isDeleting,
	};
};

export const useQueuedTask = ({
	deleteFromQueue,
	task,
}: {
	task: TestGenerationLabelingTask;
	deleteFromQueue: (id: string) => void;
}) => {
	return {
		testGenerationLink: `${testGenerationLabelingRoutes.labeler.make()}?taskId=${
			task.id
		}`,
		handleDeleteFromQueue: () => deleteFromQueue(task.id),
	};
};
