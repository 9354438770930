import { Box, Button, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useExploreUserStoriesPage } from './hooks';
import { useExploreUserStoriesPageStyles } from './styles';
import {
	CollectionList,
	CollectionFormModal,
} from '@src/modules/collections/service';
import {
	StoriesList,
	StoryFormDialog,
} from '@src/modules/user-stories/service';
import { SearchBar } from '@src/common/components/SearchBar/SearchBar';
import { StoriesHeader } from './components/StoriesHeader/StoriesHeader';
import { ConditionalWrapper } from '@src/common/components/ConditionalWrapper';
import { CollectionTitle } from '@src/modules/collections/service';
import { ApplicationRoutes } from '@src/app/router/routes';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AutoDetectTestFlowsButton } from '@src/modules/collections/service';

export const ExploreUserStoriesPage = () => {
	const {
		activeCollectionId,
		activeFilterValue,
		setStoryFilterValue,
		setStorySearchTerm,
		hasFilteredOutStories,
		activeStory,
		handleCloseStory,
		handleCreateNewStory,
		handleNewStoryCreatedSuccessfully,
		setCollectionSearchTerm,
		filteredCollections,
		hasFilteredOutCollections,
		isMobile,
		displayUserStories,
		isFetchingNewStories,
		displayCollections,
		isCollectionModalOpened,
		closeCollectionModal,
		openExistingCollectionModal,
		openNewCollectionModal,
		collectionIdForUpdate,
		collections,
		openedCollectionModalMode,
		afterAddNewCollectionModalCloseCallback,
		setAfterAddNewCollectionModalCloseCallback,
		inboxBadgesData,
		storiesPaginationProps,
		shouldDisplayAutoDetectButton,
		filteredStoriesPerPage,
	} = useExploreUserStoriesPage();

	const styles = useExploreUserStoriesPageStyles({
		shouldDisplayAutoDetectButton,
		shouldDisplayPagination: storiesPaginationProps.count === 1,
	});

	return (
		<Box component="section" css={styles.section}>
			<Box css={styles.pageHeader.container}>
				<Box css={styles.pageHeader.main}>
					<Typography variant="h1">Test Generation</Typography>

					<Button
						variant="contained"
						size="large"
						disableElevation
						css={styles.pageHeader.newStoryButton}
						onClick={handleCreateNewStory}
						startIcon={<AddCircleIcon />}
					>
						New Flow
					</Button>
				</Box>

				<If condition={isMobile}>
					<Typography variant="caption" gutterBottom>
						<If condition={displayCollections}>
							Select a collection to view its test flows
						</If>

						<If condition={displayUserStories}>
							<CollectionTitle collectionId={activeCollectionId} />

							<Box>
								<Link to={ApplicationRoutes.userStoriesExplorer.path}>
									<Button variant="text" size="small">
										← Back to collections
									</Button>
								</Link>
							</Box>
						</If>
					</Typography>
				</If>
			</Box>

			<ConditionalWrapper
				condition={!isMobile}
				wrapper={(children) => (
					<Grid container spacing={5} css={styles.gridContainer}>
						{children}
					</Grid>
				)}
			>
				<If condition={displayCollections}>
					<ConditionalWrapper
						condition={!isMobile}
						wrapper={(children) => (
							<Grid xs={4} css={styles.listContainer}>
								{children}
							</Grid>
						)}
					>
						<SearchBar
							placeholder="Search collections..."
							fullWidth
							styles={styles.pagesSearchBar}
							onChange={setCollectionSearchTerm}
						/>

						<Button
							variant="contained"
							size="large"
							disableElevation
							css={styles.storiesHeader}
							onClick={openNewCollectionModal}
						>
							New Collection
						</Button>

						<CollectionList
							collections={filteredCollections}
							activeCollectionId={activeCollectionId}
							listStyle={styles.list}
							hasFilteredOutCollections={hasFilteredOutCollections}
							openCollectionModal={openExistingCollectionModal}
							inboxBadgesData={inboxBadgesData}
						/>
					</ConditionalWrapper>
				</If>

				<If condition={displayUserStories}>
					<ConditionalWrapper
						condition={!isMobile}
						wrapper={(children) => (
							<Grid xs={8} css={styles.listContainer}>
								{children}
							</Grid>
						)}
					>
						<>
							<StoriesHeader
								containerStyles={styles.storiesHeader}
								activeFilterValue={activeFilterValue}
								setActiveFilterValue={setStoryFilterValue}
								setActiveSearchTerm={setStorySearchTerm}
							/>

							<Box css={styles.row}>
								<If
									// Don't show when stories list empty state is displayed, because empty state contains this button
									condition={shouldDisplayAutoDetectButton}
								>
									<AutoDetectTestFlowsButton
										containerStyles={styles.autoDetectionButton}
									/>
								</If>

								<Box css={styles.paginationContainer}>
									<Pagination
										css={styles.pagination}
										siblingCount={0}
										{...storiesPaginationProps}
									/>
								</Box>
							</Box>

							<StoriesList
								setAfterAddNewCollectionModalCloseCallback={
									setAfterAddNewCollectionModalCloseCallback
								}
								collections={collections}
								stories={filteredStoriesPerPage}
								activeCollectionId={activeCollectionId}
								listStyle={styles.list}
								hasFilteredOutStories={hasFilteredOutStories}
								onNewStory={handleCreateNewStory}
								isFetchingNewStories={isFetchingNewStories}
								onAddNewCollection={openNewCollectionModal}
								inboxBadgesData={inboxBadgesData}
							/>
						</>
					</ConditionalWrapper>
				</If>
			</ConditionalWrapper>

			<StoryFormDialog
				collections={collections}
				isCollectionModalOpened={isCollectionModalOpened}
				onAddNewCollection={openNewCollectionModal}
				handleCloseStory={handleCloseStory}
				activeStory={activeStory}
				onCreateSuccess={handleNewStoryCreatedSuccessfully}
			/>

			<CollectionFormModal
				collections={collections}
				open={isCollectionModalOpened}
				onClose={closeCollectionModal}
				collectionIdForUpdate={collectionIdForUpdate}
				openedCollectionModalMode={openedCollectionModalMode}
				afterAddNewCollectionModalCloseCallback={
					afterAddNewCollectionModalCloseCallback
				}
			/>
		</Box>
	);
};
