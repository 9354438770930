import { useEffect } from 'react';
import { UploadsManagerHandlers } from '.';
import { useAppDispatch } from '@src/store/hooks';
import { AppDispatch } from '@src/store/types';
import FilesUploadingManager from '.';
import {
	addUploadingFile,
	removeUploadingFile,
	UploadsManagerSliceState,
} from '@src/services/files-uploading-manager/state/slice';
import { useSelector } from 'react-redux';
import { getUploadsManagerState } from '@src/services/files-uploading-manager/state/selectors';
import { usePersistentToast } from '@src/common/hooks/usePersistentToast';

export const useUploadsManager = () => {
	const dispatch = useAppDispatch();
	const state = useSelector(getUploadsManagerState);

	// show toast when loading files
	usePersistentToast({
		message: `Uploading files. ${state.uploadingFiles.length} files in pending. Do not refresh the page`,
		showCondition: state.uploadingFiles.length > 0,
	});

	useEffect(() => {
		FilesUploadingManager.setHandlers(makeHandlers(dispatch, state));
	}, [dispatch, state]);
};

const makeHandlers = (
	dispatch: AppDispatch,
	uploadingState: UploadsManagerSliceState
): UploadsManagerHandlers => {
	return {
		addUploadingFile: (fileId: string) => {
			console.log('addUploadingFile', fileId);
			dispatch(addUploadingFile(fileId));
		},
		removeUploadingFile: (fileId: string) => {
			dispatch(removeUploadingFile(fileId));
		},
		getFileUploadingState: (fileId: string) => {
			return {
				isUploading: uploadingState.uploadingFiles.includes(fileId),
				isCompleted: uploadingState.completedFiles.includes(fileId),
			};
		},
	};
};
