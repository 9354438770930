import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

export const useGitlabAppSettingsStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(4),
			maxWidth: '600px',
			justifyContent: 'start',
		});

		const button = css({
			width: '200px',
		});

		const buttonsContainer = css({
			display: 'flex',
			gap: theme.spacing(2),
			width: '100%',
		});

		const link = css({
			textDecoration: 'underline',
		});

		return {
			buttonsContainer,
			container,
			button,
			link,
		};
	}, [theme]);
};
