import {
	useAbortUserStoryGenerationMutation,
	useGenerateUserStoryMutation,
} from '@src/modules/user-stories/api';
import { UserStory } from '@src/modules/user-stories/types';
import { useCallback } from 'react';

export const useGenerateUserStory = ({ story }: { story: UserStory }) => {
	const [
		generateStory,
		{ isLoading: isTriggeringGeneration, isSuccess: isGenerationTriggered },
	] = useGenerateUserStoryMutation();

	const onGenerate = useCallback(() => {
		generateStory({ id: story.id });
	}, [story, generateStory]);

	const {
		isLoading: isAborting,
		isSuccess: isAborted,
		onAbort,
	} = useAbortGeneration({ id: story.id });

	return {
		isTriggeringGeneration,
		isGenerationTriggered,
		onGenerate,
		isAborting,
		isAborted,
		onAbort,
	};
};

const useAbortGeneration = ({ id }: { id: UserStory['id'] }) => {
	const [abortGeneration, { isLoading, isSuccess }] =
		useAbortUserStoryGenerationMutation();

	const onAbort = useCallback(() => {
		abortGeneration({ id });
	}, [id, abortGeneration]);

	return {
		isLoading,
		isSuccess,
		onAbort,
	};
};
