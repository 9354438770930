import {
	useGetSessionRecordingsQuery,
	useGetSessionRecordingUrlQuery,
} from '@src/modules/session-recordings/api';
import { useEffect, useState } from 'react';
import { SessionRecording } from '@src/modules/session-recordings/types';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { sessionRecordingsRoutes } from '@src/modules/session-recordings/routes/routes';
import { useGetSettingsStatus } from '@src/modules/settings/service';

export const useSessionRecordings = () => {
	const { data: sessionRecordings, refetch: refetchSessionRecordings } =
		useGetSessionRecordingsQuery();

	const { settingsStatus } = useGetSettingsStatus();

	const { recordingId } = useParams<'recordingId'>();
	const navigate = useNavigate();

	const previousRecordingId = usePrevious(recordingId);

	//get current session recording url to fetch bytes
	const { data: sessionRecordingUrlData } =
		useGetSessionRecordingUrlQuery(recordingId);

	const [activeSessionRecording, setActiveSessionRecording] =
		useState<null | SessionRecording>(
			getInitialActiveSessionRecording(sessionRecordings, recordingId)
		);

	useEffect(() => {
		if (!!settingsStatus?.sdkSuccessfullyIntegrated) {
			refetchSessionRecordings();
		}
	}, [settingsStatus?.sdkSuccessfullyIntegrated, refetchSessionRecordings]);

	//update url if active session recording exists
	useEffect(() => {
		if (!recordingId && activeSessionRecording) {
			navigate(
				sessionRecordingsRoutes.sessionRecording.make({
					recordingId: activeSessionRecording.id,
				})
			);
		}
	}, [recordingId, activeSessionRecording, navigate]);

	//update active session recording if url is change
	useEffect(() => {
		if (!activeSessionRecording || recordingId !== previousRecordingId) {
			setActiveSessionRecording(
				getInitialActiveSessionRecording(sessionRecordings, recordingId)
			);
		}
	}, [
		recordingId,
		activeSessionRecording,
		previousRecordingId,
		sessionRecordings,
	]);

	return {
		sessionRecordings,
		activeSessionRecordingId: activeSessionRecording?.id,
		recordingUrl: sessionRecordingUrlData?.recordingUrl,
		sdkKey: settingsStatus?.sdkKey,
		sdkSuccessfullyIntegrated: settingsStatus?.sdkSuccessfullyIntegrated,
		isSessionRecordingsEmpty:
			!!sessionRecordings && sessionRecordings.length === 0,
	};
};

const getInitialActiveSessionRecording = (recordings, recordingId) => {
	return recordings?.find(({ id }) => recordingId === id) ?? null;
};
