import Box from '@mui/material/Box';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useApplicationsTableStyles } from '@src/modules/application/components/ApplicationsTable/styles';
import { ApplicationsTableRow } from '@src/modules/application/components/ApplicationsTable/components/ApplicationTableRow/ApplicationsTableRow';
import { ApplicationForInternal } from '@src/modules/application/types';

export const ApplicationsTable = ({
	applications,
}: {
	applications: ApplicationForInternal[];
}) => {
	const styles = useApplicationsTableStyles();

	return (
		<TableContainer component={Box} css={styles.tableContainer}>
			<Table size="small">
				<TableHead>
					<TableRow css={styles.tableRow}>
						<TableCell>Application Name</TableCell>

						<TableCell>API Key</TableCell>

						<TableCell></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{applications.map((application) => {
						return (
							<ApplicationsTableRow
								key={application.apiKey}
								application={application}
								tableRowStyles={styles.tableRow}
							/>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
