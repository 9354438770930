import { userStoriesExplorerRoutes } from '@src/modules/user-stories-exploration/service';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserStoryQuery } from '../../api';
import { toast } from 'react-toastify';

export const useRedirectToFlow = () => {
	const { storyId } = useParams<{ storyId: string }>();
	const navigate = useNavigate();

	const { data, isError, isSuccess } = useGetUserStoryQuery({
		id: storyId,
	});

	useEffect(() => {
		if (isSuccess) {
			navigate(
				userStoriesExplorerRoutes.flow.make({
					storyId,
					collectionId: data?.collectionId,
				})
			);
			return;
		}

		if (isError) {
			toast.error('Flow not found');
			navigate(userStoriesExplorerRoutes.userStories.make());
		}
	}, [navigate, data, isSuccess, isError, storyId]);
};
