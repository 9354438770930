import {
	Button,
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
} from '@mui/material';
import {
	useSelectedApplication,
	useDefaultAppBarMenu,
} from '@src/layout/DefaultAppBar/components/hooks';
import PersonIcon from '@mui/icons-material/Person';
import WebIcon from '@mui/icons-material/Web';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import { MoreVert } from '@mui/icons-material';

export const DefaultAppBarMenu = ({ onAddNewApplication }) => {
	const {
		open,
		anchorElement,
		handleClose,
		handleClick,
		onLogout,
		applications,
		activeApplication,
		handleSelectApplication,
		shouldDisplayAddNewProject,
	} = useDefaultAppBarMenu();

	return (
		<>
			<Choose>
				<When condition={!!activeApplication}>
					<Button
						variant="text"
						startIcon={<PersonIcon />}
						onClick={handleClick}
					>
						{activeApplication?.name}
					</Button>
				</When>
				<Otherwise>
					<IconButton onClick={handleClick}>
						<MoreVert />
					</IconButton>
				</Otherwise>
			</Choose>

			<Menu anchorEl={anchorElement} onClose={handleClose} open={open}>
				<If condition={!!applications}>
					{applications.map((application) => {
						return (
							<ApplicationMenuItem
								activeApplicationName={activeApplication?.name}
								application={application}
								key={application.name}
								onSelectApplication={handleSelectApplication}
							/>
						);
					})}
				</If>

				{/*<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<AddIcon fontSize="small" />
					</ListItemIcon>
					New Project
				</MenuItem>*/}

				<If condition={!!activeApplication}>
					<Divider />
				</If>

				<If condition={shouldDisplayAddNewProject}>
					<MenuItem onClick={onAddNewApplication}>
						<ListItemIcon>
							<AddIcon fontSize="small" />
						</ListItemIcon>
						New Project
					</MenuItem>

					<Divider />
				</If>

				<MenuItem onClick={onLogout}>
					<ListItemIcon>
						<LogoutIcon fontSize="small" />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
};

export const ApplicationMenuItem = ({
	onSelectApplication,
	application,
	activeApplicationName,
}) => {
	const { handleSelectApplication, isSelected } = useSelectedApplication({
		onSelectApplication,
		application,
		activeApplicationName,
	});

	return (
		<MenuItem
			selected={isSelected}
			disabled={isSelected}
			onClick={handleSelectApplication}
		>
			<ListItemIcon>
				<WebIcon fontSize="small" />
			</ListItemIcon>
			{application.name}
		</MenuItem>
	);
};
