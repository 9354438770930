import { css } from '@emotion/react';
import { useMemo } from 'react';
import { DrawerWidth } from '@src/layout/SideNavigationMenu/constants';
import { useTheme } from '@mui/material';

export const useSideNavigationMenuStyles = () => {
	return useMemo(() => {
		const drawer = css({
			width: DrawerWidth,
			flexShrink: 0,
			[`& .MuiDrawer-paper`]: {
				width: DrawerWidth,
				boxSizing: 'border-box',
			},
		});

		const listContainer = css`
			overflow: auto;
			height: 100%;
		`;

		const list = css`
			display: flex;
			flex-direction: column;
			height: 100%;
		`;

		return {
			listContainer,
			list,
			drawer,
		};
	}, []);
};

export const useDrawerButtonStyles = ({ isFirstBottomButton }) => {
	const theme = useTheme();

	return useMemo(() => {
		const link = css`
			color: inherit;
			${isFirstBottomButton ? 'margin-top: auto;' : ''}
		`;

		const listItemButton = css({
			padding: theme.spacing(3),
			justifyContent: 'center',
		});

		const listItemIcon = css({
			justifyContent: 'center',
		});

		return {
			link,
			listItemButton,
			listItemIcon,
		};
	}, [isFirstBottomButton, theme]);
};
