import { string, object } from 'yup';
import { getEnvironmentUrlRegex } from '@src/common/helpers/validation';

const urlRegex = getEnvironmentUrlRegex();

export const validationSchema = object().shape({
	name: string()
		.trim()
		.when(
			['initialName', 'isNewCollection'],
			([initialName, isNewCollection], schema) => {
				if (isNewCollection || (initialName && initialName.trim().length > 0)) {
					return schema.required('Name is required');
				} else {
					schema.notRequired();
				}
			}
		),

	description: string().notRequired(),

	defaultStartURL: string()
		.test(
			'is-relative-or-environment-based',
			'Default start URL should be a valid URL or a relative path (starting with a "/")',
			function (value) {
				return (
					!value ||
					urlRegex.test(value) ||
					(value.startsWith('/') && urlRegex.test(`http://example.com${value}`))
				);
			}
		)
		.notRequired(),
});
