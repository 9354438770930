import { GitlabAppIntegrationAPIArgsInfo } from '@src/modules/settings/api/types';

export const getDefaultValues = (
	data?: GitlabAppIntegrationAPIArgsInfo
): GitlabAppIntegrationAPIArgsInfo => {
	return {
		accessToken: data?.accessToken ?? '',
		projectId: data?.projectId ?? '',
	};
};
