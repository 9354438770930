import { Alert, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSettingsNotCompletedBanner } from '@src/common/components/SettingsNotCompletedBanner/hooks';
import { useSettingsNotCompletedBannerStyles } from './styles';

export const SettingsNotCompletedBanner = () => {
	const { bannerContent } = useSettingsNotCompletedBanner();
	const styles = useSettingsNotCompletedBannerStyles();

	return (
		<If condition={!!bannerContent}>
			<Box css={styles.container}>
				<Alert variant="filled" severity="info" css={styles.alert}>
					<Typography css={styles.text}>{bannerContent.text}</Typography>

					<Link to={bannerContent.link.href}>
						<Typography css={styles.link}>{bannerContent.link.name}</Typography>
					</Link>
				</Alert>
			</Box>
		</If>
	);
};
