import { Badge, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { UserStoriesCollection } from '../../types';
import { useCollectionListItem } from './hooks/useCollectionListItem';
import {
	useCollectionListItemStyles,
	useCollectionPrimaryTextStyles,
} from '@src/modules/collections/components/CollectionListItem/styles';
import { MenuIconButton } from './MenuIconButton';
import { Link } from 'react-router-dom';
import { CollectionModalMode } from '@src/modules/user-stories-exploration/service';
import MailIcon from '@mui/icons-material/Mail';
import { InboxCollectionStatistics } from '@src/modules/collections/hooks/useInboxCollectionAndStories';
import AllInboxIcon from '@mui/icons-material/AllInbox';

export function CollectionListItem({
	collection,
	isActive,
	openCollectionModal,
	href,
	shouldDisplayUpdateMenu,
	inboxCollectionInfo,
}: {
	collection: UserStoriesCollection;
	isActive: boolean;
	shouldDisplayUpdateMenu: boolean;
	href: string;
	openCollectionModal: (
		collectionId: string,
		collectionModalMode: CollectionModalMode
	) => void;
	inboxCollectionInfo: InboxCollectionStatistics;
}) {
	const {
		subtitle,
		isMobile,
		handleEdit,
		handleDelete,
		inboxNewBadgeCount,
		isInboxActive,
		countFormatted,
		isAllFlowsActive,
	} = useCollectionListItem({
		collection,
		openCollectionModal,
		inboxCollectionInfo,
	});

	const styles = useCollectionListItemStyles();

	return (
		<ListItem
			disablePadding
			divider
			secondaryAction={
				<If condition={shouldDisplayUpdateMenu && !isAllFlowsActive}>
					<MenuIconButton onEdit={handleEdit} onDelete={handleDelete} />
				</If>
			}
		>
			<Link to={href} css={styles.link}>
				<ListItemButton
					css={styles.collectionItemButton}
					selected={isActive && !isMobile}
				>
					<If condition={isInboxActive}>
						<Badge
							css={styles.inboxBadgeIcon}
							badgeContent={inboxNewBadgeCount}
							color="primary"
						>
							<MailIcon color="action" />
						</Badge>
					</If>

					<If condition={isAllFlowsActive}>
						<Badge
							css={styles.inboxBadgeIcon}
							badgeContent={inboxNewBadgeCount}
							color="primary"
						>
							<AllInboxIcon color="action" />
						</Badge>
					</If>

					<ListItemText
						primary={
							<CollectionPrimaryText
								countFormatted={countFormatted}
								name={collection.name}
							/>
						}
						primaryTypographyProps={{
							variant: 'h6',
						}}
						secondary={subtitle}
						css={styles.pageItem}
					/>
				</ListItemButton>
			</Link>
		</ListItem>
	);
}

export const CollectionPrimaryText = ({
	name,
	countFormatted,
}: {
	name: string;
	countFormatted: string;
}) => {
	const styles = useCollectionPrimaryTextStyles();

	return (
		<>
			{name} <span css={styles.count}>{countFormatted}</span>
		</>
	);
};
