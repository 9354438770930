import {
	ApplicationEnvironmentUser,
	EnvironmentSettings,
} from '@src/modules/settings/types';
import {
	CONFIRMATION_ALERTS_CONTENTS,
	defaultEnvironmentUser,
	OpenedConfirmationModalVariant,
} from '@src/modules/settings/components/ApplicationEnvironmentSettings/constants';
import { ConfirmationDialogCallbacks } from '@src/common/components/ConfirmationDialog/types';

export const formatEnvironmentUserForUpdate = (
	environmentUsers: ApplicationEnvironmentUser[] = []
) => {
	return environmentUsers.map(({ id, ...userWithoutId }) =>
		id === '' ? userWithoutId : { id, ...userWithoutId }
	);
};

export const generateValues = (
	activeEnvironmentSettings?: EnvironmentSettings
) => {
	const envUsers = activeEnvironmentSettings?.environmentUsers;

	return {
		isDefault: false,
		...activeEnvironmentSettings,
		environmentUsers:
			envUsers?.length > 0 ? envUsers : [defaultEnvironmentUser],
	};
};

// function to generate all props for confirmation dialog component
export const generateConfirmationModalContents = (
	variant: OpenedConfirmationModalVariant | null,
	callbacks: ConfirmationDialogCallbacks
) => {
	const callbacksAsArray = Object.entries(callbacks);

	const { buttons, ...content } = CONFIRMATION_ALERTS_CONTENTS[variant];

	const buttonsContent = callbacksAsArray.map(([key, value], index) => {
		return {
			callback: value,
			...buttons.at(index),
		};
	});

	return {
		...content,
		onClose: callbacks.onSuccess,
		buttons: buttonsContent ?? [],
	};
};
