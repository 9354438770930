import { APIDependenciesType } from './types';
import { ToastOptions } from 'react-toastify';
import Config from '@src/config';

export const APIBaseURL = Config.api.baseUrl + '/api';

export const APIDependencies: APIDependenciesType = {
	getState: undefined,
	getDispatch: undefined,
	baseURL: APIBaseURL + '/',
	authHeader: Config.api.authHeader,
};

export const DefaultToastOptions: ToastOptions = {
	autoClose: 3000,
};
