import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useSessionRecordingsListStyles = () => {
	const theme = useTheme();
	return useMemo(() => {
		const list = [
			css({
				border: `1px solid ${theme.colors.borderColor.main}`,
				height: '100%',
				overflow: 'auto',
			}),
		];

		return {
			list,
		};
	}, [theme]);
};
