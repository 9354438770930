import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from '@src/common/components/ConfirmationDialog/ConfirmationDialog';
import { useUpdateFileWithTagModal } from '@src/modules/user-stories/components/StoryForm/components/FilesViewAndUpload/UpdateFileWithTagModal/hooks';
import { useUpdateFileWithTagModalStyles } from '@src/modules/user-stories/components/StoryForm/components/FilesViewAndUpload/UpdateFileWithTagModal/styles';

export const UpdateFileWithTagModal = ({
	onClose,
	open,
	filesWithTagsAndIndex = [],
	onSave,
	register,
	remove,
	errors,
	onCancel,
}) => {
	const styles = useUpdateFileWithTagModalStyles();

	const {
		confirmationModalContent,
		handleCloseModalOnBackdropClick,
		formattedErrors,
	} = useUpdateFileWithTagModal({ closeEditModal: onClose, errors });

	return (
		<>
			<Dialog
				fullWidth
				open={open}
				onClose={handleCloseModalOnBackdropClick}
				maxWidth="md"
			>
				<DialogContent>
					<DialogTitle>
						Name your files
						<Typography>
							You can reference the files in the flow steps using these names.
						</Typography>
					</DialogTitle>

					<Choose>
						<When condition={!!filesWithTagsAndIndex.length}>
							<Box css={styles.section}>
								{filesWithTagsAndIndex.map((file) => {
									return (
										<Box css={styles.row} key={file.id + file.index}>
											<If condition={!file.id}>
												<Typography noWrap>{file.name}</Typography>
											</If>

											<FormTextField
												label={`Name for ${file.name}`}
												info="Name of the file that will be used in test suites"
												required
												variant="outlined"
												register={register}
												errors={formattedErrors}
												// placeholder={file.name}
												name={`userStoryFiles[${file.index}].tag`}
												hasErrorMessage
											/>

											<IconButton
												onClick={() => {
													remove(file.index);
												}}
												css={styles.detachIconButton}
											>
												<DeleteIcon />
											</IconButton>
										</Box>
									);
								})}
							</Box>
						</When>

						<Otherwise>
							<Typography gutterBottom textAlign="center">
								All files were detached. Click save to apply changes.
							</Typography>
						</Otherwise>
					</Choose>

					<Box css={styles.buttonsContainer}>
						<Button onClick={onCancel}>Cancel</Button>
						<Button onClick={onSave}>Save</Button>
					</Box>
				</DialogContent>
			</Dialog>

			<ConfirmationDialog {...confirmationModalContent} />
		</>
	);
};
