import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useUpdateFileWithTagStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
		});

		const inputRow = css({
			display: 'grid',
			gridTemplateColumns: '1fr auto',
			gap: theme.spacing(1.5),
			alignItems: 'center',
			'li[role="option"][aria-selected="true"]': {
				cursor: 'default',
			},
		});

		const chipsContainer = css({
			display: 'flex',
			gap: '10px',
			flexWrap: 'wrap',
		});

		const hidden = css({
			display: 'none',
		});

		const uploadNewButton = css({
			marginTop: '8px',
		});

		return {
			section,
			chipsContainer,
			inputRow,
			hidden,
			uploadNewButton,
		};
	}, [theme]);
};
