import { List } from '@mui/material';
import { SessionRecordingsListItemSkeleton } from '@src/modules/session-recordings/components/SessionRecordingsListItem/SessionRecordingsListItemSkeleton';

export function SessionRecordingsListListSkeleton({
	count = 3,
}: {
	count?: number;
}) {
	return (
		<List>
			{Array.from({ length: count }).map((_, index) => (
				<SessionRecordingsListItemSkeleton key={index} />
			))}
		</List>
	);
}
