import { UserStory, UserStoryStatus } from '../types';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';

export function GeneratingCircularProgress({ story }: { story: UserStory }) {
	return (
		<LoadingCircularProgress>
			<Choose>
				<When condition={story.status === UserStoryStatus.Queued}>
					Queued, pending generation...
				</When>
				<When condition={story.status === UserStoryStatus.Running}>
					Generating...
				</When>
				<When condition={story.status === UserStoryStatus.Verifying}>
					Verifying...
				</When>
			</Choose>
		</LoadingCircularProgress>
	);
}
