import { useAllApplicationsPage } from '@src/modules/application/pages/AllApplicationsPage/hooks';
import { Typography } from '@mui/material';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import Box from '@mui/material/Box';

import { useAllApplicationsPageStyles } from '@src/modules/application/pages/AllApplicationsPage/styles';
import { ApplicationsTable } from '@src/modules/application/components/ApplicationsTable/ApplicationsTable';
import { FormTextField } from '@src/common/components/FormTextField';

export const AllApplicationsPage = () => {
	const { applications, isLoading, searchTerm, handleChange } =
		useAllApplicationsPage();

	const styles = useAllApplicationsPageStyles();

	return (
		<Box css={styles.section}>
			<Choose>
				<When condition={isLoading}>
					<LoadingCircularProgress>
						<Typography variant="h6">Loading applications...</Typography>
					</LoadingCircularProgress>
				</When>

				<Otherwise>
					<FormTextField
						name="search"
						value={searchTerm}
						onChange={handleChange}
						label="Search application"
						placeholder="Application name"
					/>

					<ApplicationsTable applications={applications} />
				</Otherwise>
			</Choose>
		</Box>
	);
};
