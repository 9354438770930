import {
	UserStory,
	UserStoryOrigin,
	UserStoryStatus,
} from '@src/modules/user-stories/types';
import { MUIColor } from '@src/style/theme/types';
import { convertTestGenerationStatusToUserStoryStatus } from '@src/modules/user-stories/api/transformers';

export const getChipColor = (userStory: UserStory): MUIColor | null => {
	if (!userStory) {
		return null;
	}

	// if story status is edited then get  color according to last test gen status
	const actualStoryStatus =
		userStory.status === UserStoryStatus.Edited
			? userStory.completedRecentTestGenerationStoryStatus
			: userStory.status;

	return getChipColorByStatus(actualStoryStatus);
};

const getChipColorByStatus = (
	storyStatus: UserStoryStatus
): MUIColor | null => {
	switch (storyStatus) {
		case UserStoryStatus.Completed:
		case UserStoryStatus.PRMerged:
			return 'success';
		case UserStoryStatus.Failed:
			return 'error';
		case UserStoryStatus.PRClosed:
		case UserStoryStatus.Idle:
			return 'info';

		default:
			return 'info';
	}
};

export const getOpenStoryFormCTAText = (story: UserStory) => {
	// TODO: add the recentTestGeneration as userStory status field to the user story
	const recentTestGenerationStatusAsUserStoryStatus =
		convertTestGenerationStatusToUserStoryStatus(
			story.recentGeneration?.status
		);

	if (recentTestGenerationStatusAsUserStoryStatus === UserStoryStatus.Idle) {
		const isStoryD2SAndEdited =
			story.status === UserStoryStatus.Edited &&
			story.origin === UserStoryOrigin.D2S;

		const isStoryCreatedByUserAndNotEdited =
			story.status !== UserStoryStatus.Edited &&
			story.origin === UserStoryOrigin.USER;

		if (isStoryD2SAndEdited || isStoryCreatedByUserAndNotEdited) {
			return 'View and Generate';
		}
	}

	return story.status === UserStoryStatus.Failed
		? 'Fix and Generate'
		: 'Edit and Generate';
};

export const getStoryBadgeTextContent = (story: UserStory) => {
	switch (story.completedRecentTestGenerationStoryStatus) {
		case UserStoryStatus.Completed:
			return 'test generated';
		case UserStoryStatus.Failed:
			return 'failed to generate';
		case UserStoryStatus.Idle:
			return story.origin === UserStoryOrigin.USER ? null : 'flow detected';
		// For now, don't show badge for PR closed/merged
		// case UserStoryStatus.PRMerged:
		// 	return 'PR merged';
		// case UserStoryStatus.PRClosed:
		// 	return 'PR closed';

		default:
			return null;
	}
};
