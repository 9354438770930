import { Button, Box, Autocomplete } from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import { UpdateFileWithTagModal } from '@src/modules/user-stories/components/StoryForm/components/FilesViewAndUpload/UpdateFileWithTagModal/UpdateFileWithTagModal';
import { ChipFile } from '@src/modules/user-stories/components/StoryForm/components/FilesViewAndUpload/ChipFile/ChipFile';
import { useFilesViewAndUpload } from '@src/modules/user-stories/components/StoryForm/components/FilesViewAndUpload/hooks';
import { ConfirmationDialog } from '@src/common/components/ConfirmationDialog/ConfirmationDialog';
import { useUpdateFileWithTagStyles } from '@src/modules/user-stories/components/StoryForm/components/FilesViewAndUpload/styles';
import { Add } from '@mui/icons-material';

export const FilesViewAndUpload = ({
	control,
	userStoriesFilesWatcher,
	activeUserStoryId,
	triggerFieldValidation,
	errors,
	register,
	submitCount,
}) => {
	const styles = useUpdateFileWithTagStyles();

	const {
		removeFile,
		handleSelectFileFromDropdown,
		handleFileChange,
		options,
		closeModal,
		onSave,
		fieldsToEditWithIndexes,
		onEdit,
		onCancel,
		isEditModalOpened,
		isDeleteConfirmationModalOpened,
		onCloseDeleteConfirmationModal,
		buttons,
		fileToDeleteConfirmationDescription,
		allowUploadSameFileAgain,
	} = useFilesViewAndUpload({
		control,
		activeUserStoryId,
		triggerFieldValidation,
		userStoriesFilesWatcher,
		submitCount,
		errors,
	});

	return (
		<Box css={styles.section}>
			<Box css={styles.inputRow}>
				<Autocomplete
					options={options}
					disablePortal
					multiple
					disableClearable
					onChange={handleSelectFileFromDropdown}
					value={userStoriesFilesWatcher.map(({ tag }) => tag)}
					renderInput={(params) => (
						<FormTextField
							name="userStoryFiles"
							{...params}
							label="Attached files"
							placeholder={
								userStoriesFilesWatcher.length
									? undefined
									: 'Select files from the list or upload new ones'
							}
							margin="normal"
						/>
					)}
					renderTags={(list) => {
						return (
							<Box css={styles.chipsContainer}>
								{list.map((value, index) => (
									<ChipFile
										key={value + index}
										value={userStoriesFilesWatcher.find(
											(item) => value === item.tag
										)}
										index={index}
										onDelete={removeFile}
										onEdit={onEdit}
									/>
								))}
							</Box>
						);
					}}
				/>

				<input
					multiple
					accept="*"
					css={styles.hidden}
					id="autocomplete-files-uploader-input"
					type="file"
					onChange={handleFileChange}
					onClick={allowUploadSameFileAgain}
				/>
				<label htmlFor="autocomplete-files-uploader-input">
					<Button
						variant="outlined"
						css={styles.uploadNewButton}
						component="span"
						size="large"
						startIcon={<Add />}
					>
						Upload New Files
					</Button>
				</label>
			</Box>

			<UpdateFileWithTagModal
				errors={errors}
				register={register}
				remove={removeFile}
				onSave={onSave}
				filesWithTagsAndIndex={fieldsToEditWithIndexes}
				onClose={closeModal}
				open={isEditModalOpened}
				onCancel={onCancel}
			/>

			<ConfirmationDialog
				open={isDeleteConfirmationModalOpened}
				onClose={onCloseDeleteConfirmationModal}
				buttons={buttons}
				title="Remove this file?"
				description={fileToDeleteConfirmationDescription}
			/>
		</Box>
	);
};
