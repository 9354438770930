import { transformAPIAvailableApps } from '@src/modules/application/service';
import { AuthenticationResult } from '../types';
import { APIAuthenticationResult } from './types';

export const transformAPIAuthenticationResult = (
	result: APIAuthenticationResult
): AuthenticationResult => {
	return {
		authCode: result.code,
		user: result.user,
		applications: transformAPIAvailableApps(result.applications),
	};
};

/*
const transformAPIAuthenticationRole = (
	apiAuthenticationRole: APIAuthenticationResult['role']
): ApplicationUserRoles => {
	switch (apiAuthenticationRole) {
		case 'owner':
			return ApplicationUserRoles.Owner;
		case 'admin':
			return ApplicationUserRoles.Admin;
		case 'member':
			return ApplicationUserRoles.Member;
		default:
			return ApplicationUserRoles.Member;
	}
};
*/
