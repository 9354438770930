// regex to allow file:// http:// https://
export const ENV_BASE_URL_REGEX =
	/^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|file:\/\/)([a-zA-Z0-9]+:[a-zA-Z0-9]+@)?(?!localhost)[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})+(\/[^\s]*)?/;

// regex to allow all above but also http://localhost:[PORT]/
export const ENV_BASE_URL_REGEX_WITH_LOCALHOST =
	/^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|file:\/\/)([a-zA-Z0-9]+:[a-zA-Z0-9]+@)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})*(\/[^\s]*)?/;

// TODO: create a test file and configuration to run tests
// ENV_BASE_URL_REGEX
// const testUrls = [
// 	'http://example.com',                             // true
// 	'https://example.com',                            // true
// 	'file://localhost/path/to/file',                  // false
// 	'https://www.example.com',                        // true
// 	'http://www.example.com',                         // true
// 	'file://example.com',                             // true
// 	'adsasd.asdas.dasdasd',                           // false
// 	'ftp://example.com',                              // false
// 	'http:/example.com',                              // false
// 	'http:// example.com',                            // false
// 	'http://exa mple.com',                            // false
// 	'http://.com',                                    // false
// 	'file:///path/to/file',                           // false
// 	'http://example.co.uk',                           // true
// 	'https://example.co.uk',                          // true
// 	'file://example.co.uk',                           // true
// 	'https://sub.domain.com',                         // true
// 	'http://sub.domain.com',                          // true
// 	'file://sub.domain.com',                          // true
// 	'http://example',                                 // false
// 	'https://example',                                // false
// 	'file://example',                                 // false
// 	'https://watsi:watsibugbash@sandbox.watsi.com',   // true
// 	'http://localhost',                               // false
// 	'https://localhost',                              // false
// 	'file://localhost',                               // false
// ];

// for ENV_BASE_URL_REGEX_WITH_LOCALHOST all the same as with ENV_BASE_URL_REGEX_WITH_LOCALHOST
// but allows localhost url
