import { useJsonViewer } from './useJsonViewer';
import { useIncorrectActionStateStyles } from './styles';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { ReactNode } from 'react';

//@ts-ignore
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

type Value = string | Record<string, unknown> | unknown[];

export const JsonViewer = ({
	editable = false,
	value,
	expandValue = value,
	onChange,
	label,
	editorKey,
	CustomInitialStateBlock,
	schema,
	disableExpandNodes = false,
}: {
	editable?: boolean;
	label?: string;
	editorKey?: number;
	value: Value;
	expandValue?: Value;
	onChange?: (value: Value) => void;
	CustomInitialStateBlock?: ReactNode;
	schema?: Record<string, unknown>;
	disableExpandNodes?: boolean;
}) => {
	const {
		mode,
		onEditable,
		handleToggleIsExpandModalOpened,
		isExpandModalOpened,
		viewerValue,
		rerenderEditorKey,
		handleExpandAllViewerNodes,
	} = useJsonViewer({
		editable,
		editorKey,
		value,
		expandValue,
		disableExpandNodes,
	});

	const styles = useIncorrectActionStateStyles();

	return (
		<Box>
			<Box css={styles.label}>
				<Typography variant="caption">{label}</Typography>

				<Button css={styles.button} onClick={handleToggleIsExpandModalOpened}>
					Expand
				</Button>
			</Box>

			<Choose>
				<When condition={!!CustomInitialStateBlock}>
					{CustomInitialStateBlock}
				</When>

				<Otherwise>
					<Box css={styles.editorBlock}>
						<JsonEditor
							ref={handleExpandAllViewerNodes}
							key={rerenderEditorKey}
							mode={mode}
							value={viewerValue}
							onEditable={onEditable}
							onChange={onChange}
							schema={schema}
							//options is a required field, caused the console errors
							options={{}}
						/>
					</Box>
				</Otherwise>
			</Choose>

			<Dialog
				maxWidth="xl"
				open={isExpandModalOpened}
				onClose={handleToggleIsExpandModalOpened}
			>
				<DialogTitle>{label}</DialogTitle>

				<DialogContent>
					<Box css={styles.modalContent}>
						<JsonEditor
							ref={handleExpandAllViewerNodes}
							key={rerenderEditorKey}
							mode={mode}
							value={viewerValue}
							onEditable={onEditable}
							onChange={onChange}
							schema={schema}
							//options is a required field, caused the console errors
							options={{}}
						/>
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
};
