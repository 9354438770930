import { useCallback, useEffect } from 'react';
import { FormState, UseFormHandleSubmit } from 'react-hook-form';
import { UserStory } from '../../../types';
import {
	useCompleteFileUploadingMutation,
	useCreateUserStoryMutation,
	useGetSignedUrlMutation,
	useUpdateUserStoryMutation,
} from '../../../api';
import { getReactHookFormDirtyFields } from '@src/common/helpers/forms';
import { FormValues } from '../types';
import FilesUploadingManager from '@src/services/files-uploading-manager';

export const useUpdateOrCreateStory = ({
	story,
	isNewStoryCreation,
	onCreateSuccess,
	dirtyFields,
	handleSubmit,
}: {
	story: UserStory;
	isNewStoryCreation: boolean;
	onCreateSuccess?: (story: UserStory) => void;
	dirtyFields: FormState<FormValues>['dirtyFields'];
	handleSubmit: UseFormHandleSubmit<FormValues, FormValues>;
}) => {
	const [
		updateStory,
		{
			isLoading: isUpdating,
			isSuccess: isUpdateSuccess,
			isError: isUpdateError,
		},
	] = useUpdateUserStoryMutation();

	const [
		createStory,
		{
			isLoading: isCreating,
			isSuccess: isCreateSuccess,
			isError: isCreateError,
			data: createdStory,
		},
	] = useCreateUserStoryMutation();

	const [getSignedUrl] = useGetSignedUrlMutation();
	const [completeFileUploading] = useCompleteFileUploadingMutation();

	useEffect(() => {
		if (isCreateSuccess && createdStory) {
			onCreateSuccess?.(createdStory);
		}
	}, [isCreateSuccess, createdStory, onCreateSuccess]);

	const onSave = useCallback(
		async (formValues: FormValues) => {
			const getSignedUrlHandler = (storyFile) =>
				getSignedUrl({
					tag: storyFile.tag,
					file: storyFile.file,
					id: story.id,
				});

			const handleCompleteFileUploading = (fileId: string) => {
				completeFileUploading({ fileId });
			};

			const uploadFilesHandler = () => {
				return handleUploadNewFiles(
					getSignedUrlHandler,
					formValues.userStoryFiles,
					handleCompleteFileUploading
				);
			};

			const getFormattedUploadingFiles = async () => {
				const uploadingFiles = await uploadFilesHandler();

				const attachedUserStoryFilesIds = story.userStoryFiles.map(
					({ id }) => id
				);

				return uploadingFiles.map((storyFile) => {
					const id = attachedUserStoryFilesIds.includes(storyFile.id)
						? storyFile.id
						: undefined;

					return { ...storyFile, id };
				});
			};

			if (isNewStoryCreation) {
				const formattedUploadingFiles = await getFormattedUploadingFiles();

				return createStory({
					...formValues,
					userStoryFiles: formattedUploadingFiles,
				});
			}

			const dirtyUpdateFields = getReactHookFormDirtyFields<FormValues>(
				formValues,
				dirtyFields,
				['title', 'steps', 'collectionId', 'startURL', 'environmentUserId']
			);

			const formattedUploadingFiles = await getFormattedUploadingFiles();

			return updateStory({
				id: story.id,
				update: {
					...dirtyUpdateFields,
					userStoryFiles: formattedUploadingFiles,
				},
			});
		},
		[
			completeFileUploading,
			getSignedUrl,
			isNewStoryCreation,
			createStory,
			updateStory,
			story,
			dirtyFields,
		]
	);

	/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	const onSubmit = useCallback(handleSubmit(onSave), [handleSubmit, onSave]);

	return {
		onSubmit,
		hasError: isUpdateError || isCreateError,
		isLoading: isUpdating || isCreating,
		isUpdateSuccess,
	};
};

const handleUploadNewFiles = async (
	callBack,
	formValuesFiles,
	handleCompleteFileUploading
) => {
	return await Promise.all(
		formValuesFiles.map(async (storyFile) => {
			if (storyFile.id) {
				return { ...storyFile, file: undefined, formFieldId: undefined };
			}

			const fileId = await FilesUploadingManager.upload(
				() => callBack(storyFile),
				storyFile.file,
				handleCompleteFileUploading
			);

			return {
				...storyFile,
				fileId,
				file: undefined,
				formFieldId: undefined,
				type: undefined,
			};
		})
	);
};
