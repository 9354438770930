import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useGitSettingsStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const gitButtonsContainer = css({
			display: 'flex',
			gap: theme.spacing(2),
			'.gitlab-button': {
				svg: {
					fill: theme.colors.white,
					width: '20px',
				},
			},
		});

		const buttonsNavHeader = css({
			marginBottom: theme.spacing(2),
		});

		const footer = css({
			marginTop: theme.spacing(10),
			'.delete': {
				cursor: 'pointer',
			},
		});

		const chooseGitProviderContainer = css({
			display: 'flex',
			gap: theme.spacing(2),
			justifyContent: 'center',
			// alignItems: 'center',
			flexDirection: 'column',
		});

		return {
			gitButtonsContainer,
			chooseGitProviderContainer,
			buttonsNavHeader,
			footer,
		};
	}, [theme]);
};
