import { CRM } from '@src/services/crm/index';
import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import { UserDataForCRM } from '@src/services/crm/types';
import {
	useActiveApplication,
	useIsAuthenticated,
} from '@src/common/state/volatile/hooks';

export function useCRM() {
	const isAuthenticated = useIsAuthenticated();
	const activeApplication = useActiveApplication();

	const userDataForCRM: UserDataForCRM = useMemo(() => {
		if (!activeApplication) {
			return;
		}
		return { applicationName: activeApplication.name };
	}, [activeApplication]);

	const wasLoggedIn = usePrevious(isAuthenticated);

	// reset on logout
	useEffect(() => {
		if (wasLoggedIn && !isAuthenticated) {
			CRM.reset();
		}
	}, [wasLoggedIn, isAuthenticated]);

	// (re)identify on user data change
	useEffect(() => {
		if (isAuthenticated && userDataForCRM) {
			CRM.identify(userDataForCRM);
		}
	}, [isAuthenticated, userDataForCRM]);
}
