import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TestGenerationLogStep } from '@src/modules/test-generation-labeling/types';
import { APINextActionResponse } from '@src/modules/test-generation-labeling/api/types';
import Config from '@src/config';

const LLMResponseKey = Config.labeling.responseKey;

export const useLLMResponseEdit = ({
	activeTestGenerationLogStep,
	activeStoredStep,
}: {
	activeTestGenerationLogStep?: TestGenerationLogStep;
	activeStoredStep?: TestGenerationLogStep;
}) => {
	//editable json data
	const [LLMResponse, setLLMResponse] = useState<
		APINextActionResponse | undefined
	>();
	//key to rerender the content in the react viewer component
	const [editorKey, setEditorKey] = useState(1);

	const feedbackBottomRef = useRef(null);

	const activeStep = useMemo(() => {
		if (!activeTestGenerationLogStep) {
			return;
		}

		if (activeStoredStep) {
			return activeStoredStep;
		}

		return activeTestGenerationLogStep;
	}, [activeTestGenerationLogStep, activeStoredStep]);

	//update viewer key for json viewer content update
	const updateEditorKey = useCallback(() => {
		setEditorKey((prevKey) => prevKey + 1);
	}, []);

	//resets the json editor changes
	const onReset = useCallback(() => {
		if (!activeStep) {
			return;
		}

		setLLMResponse(activeStep[LLMResponseKey]);
		updateEditorKey();

		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	}, [activeStep]);

	//json editor value change handler
	const onChange = useCallback(
		(value: APINextActionResponse) => {
			if (!activeStep) {
				return;
			}

			setLLMResponse(value);
		},
		[activeStep]
	);

	//scroll to the bottom of the editor block once was opened
	const scrollToJsonEditorBottom = useCallback(() => {
		feedbackBottomRef.current.scrollIntoView({ behavior: 'smooth' });
	}, []);

	useEffect(() => {
		if (!activeStep) {
			return;
		}

		setLLMResponse(activeStep[LLMResponseKey]);

		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	}, [activeTestGenerationLogStep, activeStoredStep]);

	return {
		feedbackBottomRef,
		LLMResponse,
		onReset,
		scrollToJsonEditorBottom,
		onChange,
		editorKey,
		updateEditorKey,
	};
};
