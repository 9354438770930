import { css } from '@emotion/react';
import { useMemo } from 'react';

export const useTraceViewerStyles = (isLongWait) => {
	return useMemo(() => {
		const container = css({
			width: '100%',
			height: '100%',
		});

		const loader = css({
			position: 'absolute',
			top: 0,
			width: '100%',
			height: '100%',
			background: '#93939357',
			justifyContent: 'center',
		});

		const longWaitNote = css({
			opacity: isLongWait ? 1 : 0,
			transition: 'opacity 0.5s',
			position: 'absolute',
			p: {
				marginTop: '10px',
				a: {
					cursor: 'pointer',
				},
			},
		});

		return {
			container,
			loader,
			longWaitNote,
		};
	}, [isLongWait]);
};
