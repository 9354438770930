import { Add } from '@mui/icons-material';
import { Box, Paper, Stack, Typography, Button } from '@mui/material';
import { useStoriesEmptyState } from './hooks/useStoriesEmptyState';
import { useStoriesEmptyStateStyles } from './styles';
import { AutoDetectTestFlowsButton } from '@src/modules/collections/service';

export function StoriesEmptyState({
	hasFilteredOutStories,
	onNewStory,
}: {
	hasFilteredOutStories: boolean;
	onNewStory: () => void;
}) {
	const { Icon, title, subtitle } = useStoriesEmptyState({
		hasFilteredOutStories,
	});

	const styles = useStoriesEmptyStateStyles();

	return (
		<Box css={styles.box}>
			<Paper css={styles.paper} elevation={0}>
				<Stack css={styles.titleStack}>
					<Icon />
					<Typography variant="body1">{title}</Typography>
				</Stack>
				<Choose>
					<When condition={hasFilteredOutStories}>
						<Typography>{subtitle}</Typography>
					</When>

					<Otherwise>
						<Stack css={styles.noStoriesStack}>
							<AutoDetectTestFlowsButton
								variant="contained"
								buttonStyles={styles.actionItem}
							/>

							<Button
								onClick={onNewStory}
								variant="contained"
								css={styles.actionItem}
							>
								<Add fontSize="small" />
								Add a new test flow
							</Button>
						</Stack>
					</Otherwise>
				</Choose>
			</Paper>
		</Box>
	);
}

// function AddStoryFab() {
// 	return (
// 		<Fab
// 			sx={{ position: 'absolute', right: 16, bottom: 16 }}
// 			color="primary"
// 			aria-label="add"
// 		>
// 			<AddIcon />
// 		</Fab>
// 	);
// }
