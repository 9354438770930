import { delay, http, HttpResponse, RequestHandler } from 'msw';
import { APIBaseURL } from '@src/api/constants';
import { APISessionRecording } from '@src/modules/session-recordings/api/types';
import {
	sessionRecordingsList,
	sessionRecordingsURLs,
} from '@src/modules/session-recordings/api/mock/data';

export const sessionRecordingsMockHandlers: RequestHandler[] = [
	// [getSessionRecordings]
	http.get<{}, APISessionRecording>(
		`${APIBaseURL}/session-recordings`,
		async ({ request, params, cookies }) => {
			await delay(2000);

			return HttpResponse.json<APISessionRecording[]>(sessionRecordingsList);
		}
	),

	//[getFetchUrlByRecordingId]
	http.get<{ recordingId: string }, { recordingUrl: string }>(
		`${APIBaseURL}/session-recording/:recordingId`,
		async ({ request, params, cookies }) => {
			const sessionRecording = sessionRecordingsURLs.find(
				({ id }) => id === params.recordingId
			);

			if (!sessionRecording) {
				return HttpResponse.json(
					{ error: 'no such session recording' },
					{
						status: 404,
					}
				);
			}

			return HttpResponse.json({ recordingUrl: sessionRecording.recordingUrl });
		}
	),
];
