import { css } from '@emotion/react';
import { useMemo } from 'react';

export const useTraceViewerCustomAppBarMenuStyles = () => {
	return useMemo(() => {
		const customMenu = css({
			svg: {
				width: '150px',
				height: 'auto',
			},
			span: {
				position: 'absolute',
				width: 'max-content',
				right: '24px',
				bottom: '4px',
				fontSize: '14px',
				fontWeight: '500',
			},
		});

		return {
			customMenu,
		};
	}, []);
};
