import { SerializedStyles } from '@emotion/react';
import { List } from '@mui/material';
import { isNotEmptyArray } from '@src/common/helpers/arrays';
import {
	CollectionListItem,
	UserStoriesCollection,
} from '@src/modules/collections/service';
import {
	CollectionModalMode,
	userStoriesExplorerRoutes,
} from '@src/modules/user-stories-exploration/service';
import { usePageListStyles } from './styles';
import { CollectionListSkeleton } from './CollectionListSkeleton';
import { useCollectionList } from '@src/modules/collections/components/CollectionList/hooks';
import { InboxCollectionAndStoriesBadgesData } from '@src/modules/collections/hooks/useInboxCollectionAndStories';

export function CollectionList({
	collections,
	activeCollectionId,
	listStyle,
	hasFilteredOutCollections,
	openCollectionModal,
	inboxBadgesData,
}: {
	collections: UserStoriesCollection[];
	activeCollectionId: string;
	listStyle: SerializedStyles;
	hasFilteredOutCollections: boolean;
	openCollectionModal: (
		collectionId: string,
		collectionModalMode: CollectionModalMode
	) => void;
	inboxBadgesData: InboxCollectionAndStoriesBadgesData;
}) {
	const { shouldDisplayUpdateMenu } = useCollectionList();

	const styles = usePageListStyles({ listStyle });

	return (
		<Choose>
			<When condition={isNotEmptyArray(collections)}>
				<List css={styles.list}>
					{collections.map((collection) => (
						<CollectionListItem
							inboxCollectionInfo={inboxBadgesData.inboxCollectionInfo}
							shouldDisplayUpdateMenu={shouldDisplayUpdateMenu(collection.id)}
							key={collection.id}
							href={userStoriesExplorerRoutes.userStoriesCollection.make({
								collectionId: collection.id,
							})}
							collection={collection}
							openCollectionModal={openCollectionModal}
							isActive={collection.id === activeCollectionId}
						/>
					))}
				</List>
			</When>
			<When condition={hasFilteredOutCollections}>
				<></>
			</When>
			<Otherwise>
				<CollectionListSkeleton count={4} />
			</Otherwise>
		</Choose>
	);
}
