import { APICollection } from '@src/modules/collections/service';
import { APITestGenerationStatus } from '@src/modules/test-generations/service';
import { APIUserStory } from '@src/modules/user-stories/api/types';
import { v4 as uuid } from 'uuid';

const date = new Date().toISOString();

type MakeAPIUserStoryArgs = {
	title: string;
	steps: string;
	startURL: string;
	collection?: Partial<APICollection> & Pick<APICollection, 'id'>;
	origin?: 'user' | 'd2s';
};

export const makeAPIUserStory = (
	{ title, steps, startURL, collection, origin = 'user' }: MakeAPIUserStoryArgs,
	withTestGeneration = false
): APIUserStory => {
	const id = uuid();

	return {
		id: uuid(),
		title,
		steps,
		collection,
		startURL,
		origin,
		payload: {},
		environmentUser: undefined,
		userStoryFiles: [],
		testGenerations: withTestGeneration
			? [
					{
						createdAt: date,
						userStoryId: id.toString(),
						id: uuid(),
						generationBatchId: uuid(),
						internalTestId: uuid(),
						isAborted: false,
						status: APITestGenerationStatus.Queued,
						updatedAt: date,
						prJobStatus: 'unknown',
					},
			  ]
			: [],
		userStoryEngagements: null,
		createdAt: date,
		updatedAt: date,
		username: 'user name',
		password: 'password',
	};
};
