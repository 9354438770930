import { Box, Button, Typography } from '@mui/material';
import { IncorrectActionState } from './components/IncorrectActionState/IncorrectActionState';
import { useLabelingFeedback } from './useLabelingFeedback';
import { useLabelingFeedbackStyles } from '@src/modules/test-generation-labeling/components/LabelingFeedback/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	APINextActionResponse,
	LabelingResult,
} from '@src/modules/test-generation-labeling/api/types';

export const LabelingFeedback = ({
	onSubmit,
	onReset,
	onJsonChange,
	onOpenEditJson,
	jsonValue,
	editorKey,
	isSubmitting,
}: {
	onSubmit: (labelingResult: LabelingResult) => Promise<void>;
	onReset: () => void;
	onJsonChange: (value: APINextActionResponse) => void;
	onOpenEditJson: () => void;
	jsonValue: APINextActionResponse;
	editorKey: number;
	isSubmitting: boolean;
}) => {
	const {
		isActionIncorrect,
		handleIsNotCorrectAction,
		handleNotSure,
		onBack,
		handleSubmitJsonEdit,
		handleApproveLogStep,
	} = useLabelingFeedback({
		onReset,
		onOpenEditJson,
		onSubmit,
	});

	const styles = useLabelingFeedbackStyles();

	return (
		<Box css={styles.container}>
			<If condition={!isActionIncorrect}>
				<Box css={styles.column}>
					<Typography variant="h4">Is the action correct?</Typography>

					<Box css={styles.centered}>
						<LoadingButton
							onClick={handleApproveLogStep}
							loading={isSubmitting}
							variant="contained"
						>
							Yes
						</LoadingButton>

						<Button onClick={handleIsNotCorrectAction} variant="outlined">
							No
						</Button>

						<Button onClick={handleNotSure} variant="outlined">
							Not Sure
						</Button>
					</Box>
				</Box>
			</If>

			<If condition={isActionIncorrect}>
				<IncorrectActionState
					isSubmitting={isSubmitting}
					editorKey={editorKey}
					onSubmit={handleSubmitJsonEdit}
					onReset={onReset}
					onBack={onBack}
					onJsonChange={onJsonChange}
					jsonValue={jsonValue}
				/>
			</If>
		</Box>
	);
};
