import { useEffect } from 'react';
import Logger from '@src/services/logger';
import { LogComponents } from '@src/services/logger/types';
import { useAppTheme } from '@src/style/theme';
import { useLogout } from '@src/modules/authentication/service';
import {
	useAuthenticationInfo,
	useIsLoggingIn,
} from '@src/common/state/volatile/hooks';
import { useMocks } from './useMocks';
import { useProviders } from '@src/services';
import { useAuthCode } from '@src/common/state/persistent/hooks';
import { useLazyGetSetupInfoQuery } from '@src/modules/authentication/service';
import { setIsLoggingIn } from '@src/common/state/volatile/slice';
import { useAppDispatch } from '@src/store/hooks';

const logger = new Logger(LogComponents.App, {
	subComponent: 'hooks',
	mute: true,
});

export const useApp = () => {
	useSetGithubOauthCodeToStorage();

	useMocks();
	const theme = useAppTheme();
	const { isLoading } = useAuthenticationHandshake();
	useProviders();

	return { theme, isLoading };
};

const useAuthenticationHandshake = () => {
	const dispatch = useAppDispatch();
	const authCode = useAuthCode();
	const authenticationInfo = useAuthenticationInfo();
	const isLoggingIn = useIsLoggingIn();

	const [getSetupInfo, { isError, isLoading: isGetSetupInfoLoading }] =
		useLazyGetSetupInfoQuery();

	logger.info('isAuthenticated', {
		isAuthenticated: authenticationInfo.authenticated,
	});

	//avoid run get setup info after login
	useEffect(() => {
		if (!!authCode) {
			if (!authenticationInfo.authenticated && !isLoggingIn) {
				getSetupInfo();
			} else if (
				// reset is logging in after getSetupInfo had to be triggered
				!!authenticationInfo.authenticated &&
				!!isLoggingIn
			) {
				dispatch(setIsLoggingIn(false));
			}
		}
	}, [authCode, getSetupInfo, authenticationInfo, isLoggingIn, dispatch]);

	// reauthenticate if the app code exists
	logger.info('getSetupInfo/authenticate', { authCode, isError, status });

	// in case we have an app code but an error returns from the authentication call
	// logout to clear the app code allowing to reauthenticate
	const logout = useLogout();
	useEffect(() => {
		if (authCode && isError) {
			logout();
		}
	}, [authCode, isError, logout]);

	return {
		// loading state during the authentication call and after the call while not yet authenticated
		isLoading:
			isGetSetupInfoLoading ||
			(!authenticationInfo.authenticated && !!authCode),
	};
};

const useSetGithubOauthCodeToStorage = () => {
	//used for sharing the code (given by oauth github) between opened pages
	//this code is being removed right after it is read in the Authentication module
	useEffect(() => {
		//after redirect to the github oauth page the ?code=...&state=github should appear
		//get this code and state from url and set to the local storage
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');
		const state = urlParams.get('state');

		if (!!code && state === '_github') {
			localStorage.setItem('github', code);
			//close window once the code is set
			window.close();
		}
	}, []);
};

export const useAppWithContextProviders = () => {};
