import {
	ModuleRoute,
	ModuleRouteMetadata,
	ModuleRoutes,
	ModuleRoutesMetadata,
} from './types';

/**
 * Based on the module route metadata and the base path, prepares the module route
 * @param moduleRouteMetaData
 * @param basePath the absolute path up to this route
 * @returns
 */
export const prepareModuleRoute = (
	moduleRouteMetaData: ModuleRouteMetadata,
	basePath: string
): ModuleRoute => {
	const absolutePath =
		basePath +
		(moduleRouteMetaData.path.endsWith('/')
			? moduleRouteMetaData.path.substring(
					0,
					moduleRouteMetaData.path.length - 1
			  )
			: moduleRouteMetaData.path);
	return {
		path: moduleRouteMetaData.path,
		routerPath: moduleRouteMetaData.isRouter
			? `${moduleRouteMetaData.path}/*`
			: undefined,
		layoutOptions: moduleRouteMetaData.layoutOptions,
		optionalParams: moduleRouteMetaData.optionalParams,
		absolutePath,
		make: (params?: Record<string, string>, queryParams?: string) =>
			makeRouteURL(absolutePath, params, queryParams),
	};
};

/**
 * Given multiple module routes metadata and the base path, prepares the module route per each
 * @param moduleRoutesMetaData
 * @param basePath
 * @returns
 */
export const prepareModuleRoutes = <RouteNames extends string>(
	moduleRoutesMetaData: ModuleRoutesMetadata<RouteNames>,
	basePath: string
): ModuleRoutes<RouteNames> => {
	return Object.keys(moduleRoutesMetaData).reduce(
		(moduleRoutes, routeName) => ({
			...moduleRoutes,
			[routeName as RouteNames]: prepareModuleRoute(
				moduleRoutesMetaData[routeName],
				basePath
			),
		}),
		{}
	) as ModuleRoutes<RouteNames>;
};

/**
 * Given the absolute path template (including :param notation) and the params object, makes the route URL
 * @param absolutePath
 * @param params
 * @param queryParams
 * @returns
 */
export const makeRouteURL = (
	absolutePath: string,
	params: Record<string, string> = {},
	queryParams = ''
) => {
	let url = absolutePath;
	for (const paramName in params) {
		url = url.replace(`:${paramName}`, params[paramName]);
	}

	return url + queryParams;
};
