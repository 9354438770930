import { useMemo } from 'react';
import {
	TestGenerationLogStep,
	TestGenerationStepLabelStatus,
} from '@src/modules/test-generation-labeling/types';

const formatDate = (date: number) => {
	return new Date(date).toLocaleString();
};

export const useTestGenerationLabelingHeader = ({
	testGenerationLogStep,
	activeStoredStep,
}: {
	testGenerationLogStep?: TestGenerationLogStep;
	activeStoredStep?: TestGenerationLogStep;
}) => {
	return useMemo(() => {
		if (!testGenerationLogStep) {
			return {
				title: '',
				subTitle: '',
				date: '',
				labeledAtMessage: '',
			};
		}

		const labeledAtMessage =
			activeStoredStep &&
			activeStoredStep.status === TestGenerationStepLabelStatus.Labeled
				? `Labeled on ${formatDate(activeStoredStep.labeledAt)}`
				: null;

		return {
			title: `${testGenerationLogStep.goal} (${testGenerationLogStep.testId}) #${testGenerationLogStep.index}`,
			subTitle: `${testGenerationLogStep.appName} ${testGenerationLogStep.runId}`,
			date: formatDate(testGenerationLogStep.testStartTimestamp),
			labeledAtMessage,
		};
	}, [testGenerationLogStep, activeStoredStep]);
};
