import api from '@src/api';
import {
	transformAPIAllApplications,
	transformAPIApplication,
} from '@src/modules/application/api/transformers';
import {
	ApplicationForInternal,
	ApplicationSettings,
	AvailableApplication,
} from '@src/modules/application/types';

export const applicationsApi = api
	.enhanceEndpoints({ addTagTypes: ['ApplicationSettings'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			//[createApplication]
			createApplication: builder.mutation<
				AvailableApplication,
				{ appName: string }
			>({
				query: (body) => {
					return {
						url: `applications`,
						method: 'POST',
						errorToastMessage: `Application with name "${body.appName}" already exists.`,
						body,
					};
				},
			}),

			getApplicationSettings: builder.query<ApplicationSettings | null, void>({
				query: () => ({
					url: `applications/settings`,
				}),
				providesTags: ['ApplicationSettings'],
				transformResponse: transformAPIApplication,
			}),

			getAllApplicationsForInternalUser: builder.query<
				ApplicationForInternal[],
				void
			>({
				query: () => {
					return {
						url: `applications/all-for-internal`,
					};
				},
				transformResponse: transformAPIAllApplications,
			}),
		}),
	});

export const {
	useCreateApplicationMutation,
	useGetAllApplicationsForInternalUserQuery,
} = applicationsApi;

export const useApplicationSettingsQueryState =
	applicationsApi.endpoints.getApplicationSettings.useQueryState;
