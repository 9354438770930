import { CRMProviderInterface } from '@src/services/crm/providers/CRMProviderInterface';
import Config from '@src/config';

import Logger from '@src/services/logger';
import { LogComponents } from '@src/services/logger/types';
import { UserDataForCRM } from '@src/services/crm/types';
import { Crisp } from 'crisp-sdk-web';

// remove eslint-disable-next-line once logger is in use
/* eslint-disable-next-line   @typescript-eslint/no-unused-vars  */
const logger = new Logger(LogComponents.CRM, {
	subComponent: 'Crisp',
	mute: true,
});

class CrispCRMProvider implements CRMProviderInterface {
	private active = false;
	private providerName = 'crisp';

	public get name() {
		return this.providerName;
	}

	init() {
		if (!Config.crm.crisp.id) {
			return;
		}
		Crisp.configure(Config.crm.crisp.id);
		this.active = true;
	}

	identify(userData: UserDataForCRM) {
		//Crisp.user.setCompany()
		// Crisp.user.setEmail('john.doe@gmail.com');
		// Crisp.user.setNickname('John Doe');
		Crisp.session.setData({
			applicationName: userData.applicationName,
		});
	}

	isActive() {
		return this.active;
	}

	show() {
		Crisp.chat.show();
	}

	hide() {
		Crisp.chat.hide();
	}

	open() {
		Crisp.chat.open();
	}

	close() {
		Crisp.chat.close();
	}

	showNewMessage(message) {}

	trackEvent(event) {
		// Crisp.session.pushEvent('signup');
		// // Or with custom parameters
		// Crisp.session.pushEvent('purchase', {
		// 	price: 66.66,
		// 	item: 'XXXX_XXXX',
		// });
	}

	reset() {
		Crisp.session.reset();
	}
}

export default CrispCRMProvider;
