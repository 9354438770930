import { InternalApplicationRoutes } from '@src/app/router/routes';
import { prepareModuleRoutes } from '@src/routing';

export const testGenerationLabelingRoutes = prepareModuleRoutes<
	'testGenerationLabeling' | 'step' | 'session' | 'labeler' | 'queue'
>(
	{
		testGenerationLabeling: {
			path: '/',
		},
		queue: {
			path: '/queue',
		},
		labeler: {
			path: '/labeler',
		},
		session: {
			path: '/labeler/:sessionId',
		},
		step: {
			path: '/labeler/:sessionId/:stepId',
		},
	},
	InternalApplicationRoutes.testGenerationLabeling.absolutePath
);
