import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

export const useSessionRecordingsEmptyStateStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			gap: theme.spacing(4),
			paddingBottom: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
		});

		const loadingBlock = css({
			marginBottom: theme.spacing(2),
		});

		const codeBlockContainer = css({
			width: '100%',
			padding: `0 ${theme.spacing(8)}`,
		});

		const subtitle = css({
			color: theme.colors.text.secondary,
			textAlign: 'center',
		});

		const link = css(subtitle, {
			textDecoration: 'none',
		});

		return {
			loadingBlock,
			container,
			codeBlockContainer,
			subtitle,
			link,
		};
	}, [theme]);
};
