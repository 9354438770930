import { useDefaultPage } from './hooks';
import { Navigate } from 'react-router-dom';
import { ApplicationRoutes } from '../router/routes';

export const DefaultPage = () => {
	const { displayInternalDefaultPage } = useDefaultPage();

	return (
		<Choose>
			<When condition={displayInternalDefaultPage}>
				<Navigate to={ApplicationRoutes.internal.path} />
			</When>
			<Otherwise>
				<Navigate to={ApplicationRoutes.userStoriesExplorer.path} />
			</Otherwise>
		</Choose>
	);
};
