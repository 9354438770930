import { DefaultAppBarOptions } from './types';
import { AppBar, Toolbar, IconButton, Stack, Button } from '@mui/material';
import Logo from '@assets/images/logo.svg';
import { DefaultAppBarMenu } from './components/DefaultAppBarMenu';
import useDefaultAppBarStyles from '@src/layout/DefaultAppBar/styles';
import { useDefaultAppBar } from './hooks';
import { ApplicationFormModal } from '@src/modules/application/service';
import { Link } from 'react-router-dom';
import { ApplicationRoutes } from '@src/app/router/routes';
import { AllInclusive } from '@mui/icons-material';

function DefaultAppBar({
	hideDefaultMenu,
	mode,
	customMenuProps,
	menuStackStyle,
}: Partial<DefaultAppBarOptions>) {
	const {
		CustomMenu,
		onOpenAddNewApplicationModal,
		onCloseAddNewApplicationModal,
		isAddNewApplicationModalOpened,
		afterCreateNewApplicationCallback,
		isInternalUser,
	} = useDefaultAppBar({ mode });
	const styles = useDefaultAppBarStyles(menuStackStyle);

	return (
		<>
			<AppBar position="fixed" color="default" css={styles.appBar}>
				<Toolbar css={styles.appBarContent}>
					<IconButton
						size="large"
						edge="start"
						aria-label="menu"
						css={styles.iconButton}
						disableRipple
					>
						<img src={Logo} alt="" height={35} />
					</IconButton>

					<Stack css={styles.menuStack}>
						<If condition={isInternalUser}>
							<Link to={ApplicationRoutes.internal.make()}>
								<Button startIcon={<AllInclusive />}>Internal</Button>
							</Link>
						</If>
						<If condition={!hideDefaultMenu}>
							<DefaultAppBarMenu
								onAddNewApplication={onOpenAddNewApplicationModal}
							/>
						</If>
						<If condition={!!CustomMenu}>
							<CustomMenu {...customMenuProps} />
						</If>
					</Stack>
				</Toolbar>
			</AppBar>

			<ApplicationFormModal
				open={isAddNewApplicationModalOpened}
				onClose={onCloseAddNewApplicationModal}
				afterCreateApplicationCallback={afterCreateNewApplicationCallback}
			/>
		</>
	);
}

export default DefaultAppBar;
