import { ManagedUser } from '../types';
import {
	useDeleteSupportUserMutation,
	useRefreshUserSeedMutation,
} from '../../api';

export const useUsersTableRow = ({ user }: { user: ManagedUser }) => {
	const [refreshUser] = useRefreshUserSeedMutation();
	const [deleteUser] = useDeleteSupportUserMutation();
	return {

		refreshUser,
		deleteUser,
	};
};
