import { useCallback, useMemo } from 'react';
import {
	useCreateUserStoriesCollectionMutation,
	useUpdateCollectionMutation,
} from '@src/modules/collections/api';
import { toast } from 'react-toastify';
import { OpenedConfirmationModalVariant } from '@src/modules/collections/constants';
import { CollectionFormValues } from '@src/modules/collections/types';
import { getReactHookFormDirtyFields } from '@src/common/helpers/forms';

export const useCollectionFormHandlers = ({
	collectionToUpdate,
	onCloseCollectionModal,
	dirtyFields,
	isDirty,
	handleSubmit,
	modalVariantRef,
	afterAddNewCollectionModalCloseCallback,
}) => {
	const [
		createUserStoryCollection,
		{ isLoading: isCreateNewCollectionLoading },
	] = useCreateUserStoriesCollectionMutation();

	const [updateCollection, { isLoading: isUpdateCollectionLoading }] =
		useUpdateCollectionMutation();

	//submit button should be loading until all update/create requests finished
	const isSubmitLoading = useMemo(() => {
		return isCreateNewCollectionLoading || isUpdateCollectionLoading;
	}, [isCreateNewCollectionLoading, isUpdateCollectionLoading]);

	//create new collection handler
	const createNewCollectionHandler = useCallback(
		async (values: CollectionFormValues) => {
			const response = await createUserStoryCollection(values);

			if ('data' in response) {
				await afterAddNewCollectionModalCloseCallback(response.data.id);
			}

			onCloseCollectionModal();
		},
		[
			onCloseCollectionModal,
			createUserStoryCollection,
			afterAddNewCollectionModalCloseCallback,
		]
	);

	//update existing collection handler
	const updateExistingCollectionHandler = useCallback(
		async (formValues: CollectionFormValues): Promise<void> => {
			if (!isDirty) {
				onCloseCollectionModal();

				return;
			}

			const update = getReactHookFormDirtyFields<CollectionFormValues>(
				formValues,
				dirtyFields,
				['name', 'description', 'defaultStartURL']
			);

			//updated name of the collection if was changed
			await updateCollection({
				collectionId: collectionToUpdate.id,
				update,
			});

			onCloseCollectionModal();
		},
		[
			onCloseCollectionModal,
			dirtyFields,
			isDirty,
			updateCollection,
			collectionToUpdate,
		]
	);

	//update or create collection handler
	const submitCollectionFormHandler = useCallback((): Promise<void> => {
		return handleSubmit(
			(formValues) => {
				if (/^inbox$/i.test(formValues.name)) {
					toast.error('Invalid collection name');

					return;
				}

				if (collectionToUpdate !== null) {
					return updateExistingCollectionHandler(formValues);
				} else {
					return createNewCollectionHandler(formValues);
				}
			},
			//set modalVariant to default in a successful submit case
			() => {
				modalVariantRef.current = OpenedConfirmationModalVariant.DEFAULT;
			}
		)();
	}, [
		modalVariantRef,
		collectionToUpdate,
		createNewCollectionHandler,
		updateExistingCollectionHandler,
		handleSubmit,
	]);

	return {
		submitCollectionFormHandler,
		isSubmitLoading,
	};
};
