import {
	AlertDialogButton,
	AlertDialogContent,
	ConfirmationDialogCallbacks,
} from '@src/common/components/ConfirmationDialog/types';
import { GitAppMode } from '@src/modules/settings/components/GitSettings/hooks';

const generateModalTextContent = (
	provider: GitAppMode
): Pick<AlertDialogContent, 'title' | 'description'> & {
	buttons: Omit<AlertDialogButton, 'callback'>[];
} => {
	const formattedProvider =
		provider === GitAppMode.Github ? 'GitHub' : 'GitLab';

	return {
		title: `${formattedProvider} integration will be removed`,
		description: `Removing the integration with your Git repository will disable Pull Requests for generated and auto-healed tests. Are you sure you want to proceed?`,
		buttons: [{ text: `Cancel` }, { text: 'Yes, delete' }],
	};
};

export const generateConfirmationDialogContent = (
	variant: GitAppMode | null,
	callbacks: ConfirmationDialogCallbacks
) => {
	if (!variant) {
		return { title: '', description: '', buttons: [] };
	}

	const { title, buttons, description } = generateModalTextContent(variant);

	const callbacksAsArray = Object.entries(callbacks);

	if (callbacksAsArray.length !== buttons.length) {
		throw new Error('Invalid callbacks amount');
	}

	const buttonsContent = callbacksAsArray.map(([key, value], index) => {
		return {
			callback: value,
			...buttons.at(index),
		};
	});

	return {
		title,
		description,
		buttons: buttonsContent,
		onClose: callbacks.onSuccess,
	};
};
