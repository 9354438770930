import { SessionEventTypesEnum } from './types';

class SessionClass {
	private readonly SessionDuration: number = 30 * 60 * 1000; // 30 minutes
	private isNew: boolean = true;
	private timeoutId: NodeJS.Timeout | undefined;
	private isIdle: boolean = false;
	private eventSubscribers: Record<SessionEventTypesEnum, Function[]> = {
		[SessionEventTypesEnum.Idle]: [],
		[SessionEventTypesEnum.RecoverFromIdle]: [],
		[SessionEventTypesEnum.UserActivity]: [],
	};

	init() {
		this.listenForUserActivity();
		this.resetTimer();
	}

	isNewSession(falsify = false) {
		if (!this.isNew) {
			return false;
		}
		if (falsify) {
			this.isNew = false;
		}
		return true;
	}

	subscribeToIdleEvent(type: SessionEventTypesEnum, callback: Function) {
		if (
			this.eventSubscribers[type].find(
				(subscribedCallback) => subscribedCallback === callback
			)
		) {
			return;
		}
		this.eventSubscribers[type].push(callback);
	}

	unsubscribeFromIdleEvent(type: SessionEventTypesEnum, callback: Function) {
		const index = this.eventSubscribers[type].indexOf(callback);
		if (index > -1) {
			this.eventSubscribers[type].splice(index, 1);
		}
	}

	private listenForUserActivity = () => {
		window.onmousemove = this.resetTimer;
		window.onmousedown = this.resetTimer; // catches touchscreen presses as well
		window.ontouchstart = this.resetTimer; // catches touchscreen swipes as well
		window.onclick = this.resetTimer; // catches touchpad clicks as well
		window.onkeydown = this.resetTimer;
		window.addEventListener('scroll', this.resetTimer, true);
	};

	private handleIdle = () => {
		this.isIdle = true;
		this.invokeSubscribers(SessionEventTypesEnum.Idle);
	};

	private resetTimer = () => {
		this.invokeSubscribers(SessionEventTypesEnum.UserActivity);

		// if reset triggered while idle,
		// app is recovering from idle
		if (this.isIdle) {
			this.invokeSubscribers(SessionEventTypesEnum.RecoverFromIdle);
			this.isIdle = false;
		}

		// set new timeout
		// if timeout active, clear existing timeout first
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		this.timeoutId = setTimeout(this.handleIdle, this.SessionDuration);
	};

	private invokeSubscribers(type: SessionEventTypesEnum) {
		this.eventSubscribers[type].forEach((callback) => callback());
	}
}

const Session = new SessionClass();
export default Session;
