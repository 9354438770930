import { Box, List } from '@mui/material';
import { isNotEmptyArray } from '@src/common/helpers/arrays';
import { StoryListItem } from '@src/modules/user-stories/components/StoryListItem/StoryListItem';
import { UserStory } from '@src/modules/user-stories/types';
import { SerializedStyles } from '@emotion/react';
import { StoriesEmptyState } from './StoriesListEmptyState';
import { StoriesListSkeleton } from './StoriesListSkeleton';
import { useStoriesListStyles } from './styles';
import { UserStoriesCollection } from '@src/modules/collections/service';
import { ChangeCollectionModal } from '@src/modules/user-stories/components/StoriesList/ChangeCollectionModal/ChangeCollectionModal';
import { useStoriesList } from '@src/modules/user-stories/components/StoriesList/hooks/useStoriesList';
import { Dispatch, SetStateAction } from 'react';
import { OpenPrInNewWindowModal } from '@src/modules/user-stories/components/StoriesList/OpenPrInNewWindowModal/OpenPrInNewWindowModal';
import { InboxCollectionAndStoriesBadgesData } from '@src/modules/collections/service';

export function StoriesList({
	stories,
	activeCollectionId,
	listStyle,
	hasFilteredOutStories,
	onNewStory,
	isFetchingNewStories,
	collections,
	onAddNewCollection,
	setAfterAddNewCollectionModalCloseCallback,
	inboxBadgesData,
}: {
	stories?: UserStory[];
	activeCollectionId: string;
	listStyle: SerializedStyles;
	hasFilteredOutStories?: boolean;
	isFetchingNewStories: boolean;
	onNewStory: () => void;
	collections: UserStoriesCollection[];
	onAddNewCollection: () => void;
	setAfterAddNewCollectionModalCloseCallback: Dispatch<
		SetStateAction<(id: string) => void>
	>;
	inboxBadgesData: InboxCollectionAndStoriesBadgesData;
}) {
	const {
		handleClearFromInbox,
		storyToClearFromInbox,
		handleCloseChangeCollectionModal,
		onAddNewCollectionAndClearFromInbox,
		storyToOpenPr,
		handleOpenPrInNewWindowModal,
		handleClosePrInNewWindowModal,
		settingsStatus,
	} = useStoriesList({
		onAddNewCollection,
		setAfterAddNewCollectionModalCloseCallback,
		activeCollectionId,
	});

	const styles = useStoriesListStyles(listStyle);

	return (
		<Choose>
			<When condition={isNotEmptyArray(stories) && !isFetchingNewStories}>
				<List css={styles.list}>
					{stories.map((story) => (
						<Box
							key={story.id}
							sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}
						>
							<StoryListItem
								isNewForInbox={inboxBadgesData.userStoriesBadgesInfo[story.id]}
								settingsStatus={settingsStatus}
								onXClick={handleClearFromInbox}
								story={story}
								activeCollectionId={activeCollectionId}
								handleOpenPrInNewWindowModal={handleOpenPrInNewWindowModal}
							/>
						</Box>
					))}
				</List>

				<ChangeCollectionModal
					onClose={handleCloseChangeCollectionModal}
					story={storyToClearFromInbox}
					collections={collections}
					onAddNewCollection={onAddNewCollectionAndClearFromInbox}
				/>

				<OpenPrInNewWindowModal
					story={storyToOpenPr}
					onClose={handleClosePrInNewWindowModal}
					activeCollectionId={activeCollectionId}
				/>
			</When>

			<When condition={!!stories && !isFetchingNewStories}>
				<StoriesEmptyState
					hasFilteredOutStories={hasFilteredOutStories}
					onNewStory={onNewStory}
				/>
			</When>

			<Otherwise>
				<StoriesListSkeleton listStyle={styles.list} />
			</Otherwise>
		</Choose>
	);
}

// function AddStoryFab() {
// 	return (
// 		<Fab
// 			sx={{ position: 'absolute', right: 16, bottom: 16 }}
// 			color="primary"
// 			aria-label="add"
// 		>
// 			<AddIcon />
// 		</Fab>
// 	);
// }
