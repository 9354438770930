import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	FormControlLabel,
	Typography,
} from '@mui/material';
import { useOpenPrInNewWindowModal } from '@src/modules/user-stories/components/StoriesList/OpenPrInNewWindowModal/hooks';
import { useOpenPrInNewWindowModalStyles } from '@src/modules/user-stories/components/StoriesList/OpenPrInNewWindowModal/styles';
import { UserStory } from '@src/modules/user-stories/types';

export const OpenPrInNewWindowModal = ({
	story,
	onClose,
	activeCollectionId,
}: {
	story: UserStory;
	activeCollectionId: string;
	onClose: () => void;
}) => {
	const { checkboxValue, handleChangeCheckbox, onSubmit } =
		useOpenPrInNewWindowModal({ story, onClose, activeCollectionId });

	const styles = useOpenPrInNewWindowModalStyles();

	return (
		<Dialog fullWidth open={!!story} onClose={onClose} maxWidth="sm">
			<DialogContent css={styles.section}>
				<Typography variant="subtitle1" gutterBottom>
					The PR will open in a separate window for your review.
					<br />
					You may also download and view the trace.
				</Typography>

				<FormControlLabel
					value={checkboxValue}
					onChange={handleChangeCheckbox}
					control={<Checkbox />}
					label="Don't show this again"
				/>

				{/*<Checkbox checked={checkboxValue} onChange={handleChangeCheckbox} />*/}

				<Box css={styles.buttonsContainerEnd}>
					<Button onClick={onClose}>Cancel</Button>

					<Button onClick={onSubmit} variant="contained">
						OK
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
