import { Box, CircularProgress } from '@mui/material';
import { useStyles } from './styles';

// Note: Usually parent component should have position:relative

export const LoadingBlockOverlay = ({
	condition,
	scheme = 'light',
	size = 20,
	offsetX,
	offsetY,
}: {
	condition: boolean;
	scheme?: 'light' | 'dark';
	size?: number;
	offsetX?: number;
	offsetY?: number;
}) => {
	const styles = useStyles({ scheme, offsetX, offsetY });
	return (
		<If condition={condition}>
			<Box css={styles.box}>
				<CircularProgress color="inherit" size={size} css={styles.loader} />
			</Box>
		</If>
	);
};
