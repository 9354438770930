import { css } from '@emotion/react';
import { useMemo } from 'react';

export const useLoadingPageStyles = () => {
	return useMemo(() => {
		const container = css({});

		return {
			container,
		};
	}, []);
};
