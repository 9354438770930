import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useSessionRecordingsPageStyles = () => {
	const theme = useTheme();
	return useMemo(() => {
		const section = css({
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			padding: `${theme.spacing(4)} 0`,
		});

		const pageHeader = {
			container: css({
				padding: `${theme.spacing(2)} 0`,
			}),
			main: css({
				display: 'flex',
				alignItems: 'center',
				height: '100%',
			}),
			newStoryButton: css({
				marginLeft: 'auto',
			}),
		};

		const listContainer = css({
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		});

		const list = css({
			height: '100%',
			overflow: `auto`,
			paddingTop: 0,
			paddingBottom: 0,
		});

		const pagesSearchBar = css({
			marginBottom: theme.spacing(2),
		});

		const storiesHeader = css({
			marginBottom: theme.spacing(2),
		});

		const replayerEmptyState = {
			container: css({
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				height: '100%',
			}),
			title: css({
				fontWeight: 'bold',
			}),
		};

		return {
			section,
			pageHeader,
			listContainer,
			list,
			pagesSearchBar,
			storiesHeader,
			replayerEmptyState,
		};
	}, [theme]);
};
