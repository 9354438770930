import {
	APITestGenerationLabelingTask,
	APITestGenerationLog,
	APITestGenerationLogStep,
} from '@src/modules/test-generation-labeling/api/types';
import {
	TestGenerationLabelingTask,
	TestGenerationLabelingTaskStatus,
	TestGenerationLog,
	TestGenerationLogStep,
	TestGenerationStepLabelStatus,
} from '@src/modules/test-generation-labeling/types';
import Config from '@src/config';

export const transformAPIEntryActionToEntryAction = (
	entry: APITestGenerationLogStep
): TestGenerationLogStep => {
	return {
		...entry,
		status: transformAPITestGenerationStepLabelStatus(entry.status),
		screenshot: entry.screenshot?.base64Image,
		checksumIdTaggedScreenshot: entry.checksumIdTaggedScreenshot?.base64Image,
	};
};

export const transformAPIEntryActionsToEntryActions = (
	entries: APITestGenerationLogStep[]
): TestGenerationLogStep[] => {
	return entries
		.filter(({ [Config.labeling.responseKey]: LLMResponse }) => !!LLMResponse)
		.map((entry: APITestGenerationLogStep) => {
			return transformAPIEntryActionToEntryAction(entry);
		});
};

export const transformAPITestGenerationLogToTestGenerationLog = (
	session: APITestGenerationLog
): TestGenerationLog => {
	const activeEntries = session.testGenerationStepLabelsFromJSON.slice(1); // remove of the first step that has no valuable info inside

	const entries = transformAPIEntryActionsToEntryActions(activeEntries);

	return {
		...transformAPITestGenerationLabelingTask(
			session.testGenerationLabelingTask
		),
		entries,
	};
};

export const transformAPITestGenerationLabelingQueue = (
	apiQueue: APITestGenerationLabelingTask[]
): TestGenerationLabelingTask[] => {
	return apiQueue.map(transformAPITestGenerationLabelingTask);
};

const transformAPITestGenerationLabelingTask = (
	apiTask: APITestGenerationLabelingTask
): TestGenerationLabelingTask => {
	return {
		...apiTask,
		uuid: apiTask.testUuid,
		status: transformAPITestGenerationSessionStatus(apiTask.status),
		currentUnlabelledStepIndex: apiTask.currentUnlabelledStepIndex ?? 1,
	};
};

const transformAPITestGenerationSessionStatus = (
	apiStatus: APITestGenerationLog['testGenerationLabelingTask']['status']
): TestGenerationLabelingTaskStatus => {
	switch (apiStatus) {
		case 'pending':
			return TestGenerationLabelingTaskStatus.Pending;
		case 'in_progress':
			return TestGenerationLabelingTaskStatus.InProgress;
		case 'completed':
			return TestGenerationLabelingTaskStatus.Completed;
	}
};

const transformAPITestGenerationStepLabelStatus = (
	apiStatus: APITestGenerationLog['testGenerationStepLabelsFromJSON'][number]['status']
): TestGenerationStepLabelStatus => {
	switch (apiStatus) {
		case 'unlabeled':
			return TestGenerationStepLabelStatus.Unlabeled;
		case 'in_progress':
			return TestGenerationStepLabelStatus.InProgress;
		case 'labeled':
			return TestGenerationStepLabelStatus.Labeled;
	}
};
