import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useTestGenerationLabelingStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			display: 'flex',
			flexDirection: 'column',
			padding: `${theme.spacing(4)} 0`,
		});

		const container = css({
			display: 'flex',
			flexDirection: 'column',

			padding: `${theme.spacing(2)}`,
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(2),
		});

		const imageContainer = css(container, {
			height: '100%',
			justifyContent: 'center',
		});

		const image = css(container, {
			maxWidth: '100%',
		});

		const imageHeader = css({
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		});

		const grid = css({
			marginTop: theme.spacing(2),
		});

		const centeredContainer = css({
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		});

		return {
			section,
			container,
			grid,
			imageContainer,
			image,
			imageHeader,
			centeredContainer,
		};
	}, [theme]);
};
