import { SelectChangeEvent } from '@mui/material';
import { UserStoryStatus } from '@src/modules/user-stories/service';
import { useCallback } from 'react';
import { StoriesFilterAllValue } from './constants';

export type ActiveFilterSetter = (value: UserStoryStatus | null) => void;

export const useStoriesHeader = ({
	setActiveFilterValue,
}: {
	setActiveFilterValue: ActiveFilterSetter;
}) => {
	const onFilterChange = useCallback(
		(event: SelectChangeEvent<UserStoryStatus | null>) => {
			const value =
				event.target.value === StoriesFilterAllValue
					? null
					: (event.target.value as UserStoryStatus);
			setActiveFilterValue(value);
		},
		[setActiveFilterValue]
	);

	return { onFilterChange };
};
