import { UserStory, UserStoryFile } from '@src/modules/user-stories/types';
import {
	ALL_FLOWS_COLLECTION_ID,
	INBOX_COLLECTION_ID,
} from '@src/modules/collections/service';
import { EnvironmentSettings } from '@src/modules/settings/service';
import { UserStoryFileWithFileData } from '@src/modules/user-stories/components/StoryForm/types';

export const getStoryFormInitialValues = (
	story: UserStory = {} as UserStory,
	environmentsSettings: EnvironmentSettings[] = []
) => {
	// prevent creating new test flow in the inbox and in all test flows collection
	const collectionId = [INBOX_COLLECTION_ID, ALL_FLOWS_COLLECTION_ID].includes(
		story?.collectionId
	)
		? ''
		: story?.collectionId;

	const defaultUserId = getDefaultUserId(environmentsSettings);

	return {
		userStoryFiles: getDefaultUserStoriesFiles(story?.userStoryFiles),
		environmentUserId: story?.environmentUser?.id ?? defaultUserId,
		collectionId,
		title: story.title,
		steps: story.steps,
		defaultStartURL: story.defaultStartURL ?? '',
		startURL: story.startURL ?? '',
		username: story.username ?? '',
		password: story.password ?? '',
	};
};

const getDefaultUserId = (environments: EnvironmentSettings[]) => {
	const defaultEnvironment = environments.find(({ isDefault }) => isDefault);

	const defaultUser = defaultEnvironment?.environmentUsers?.find(
		({ isDefault }) => isDefault
	);

	return defaultUser?.id;
};

const getDefaultUserStoriesFiles = (
	storyFiles: UserStoryFile[] = []
): UserStoryFileWithFileData[] => {
	return storyFiles.map((storyFile) => {
		return {
			formFieldId: storyFile.id,
			file: storyFile.file,
			tag: storyFile.tag ?? '',
			id: storyFile.id,
			name: storyFile.file.name,
			fileId: storyFile.file.id,
		};
	}, []);
};
