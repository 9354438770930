import { useChangeUseCollectionNameInTestPathCheckbox } from '@src/modules/settings/components/GitSettings/components/ChangeUseCollectionNameInTestPathCheckbox/hooks';
import { Checkbox, FormControlLabel } from '@mui/material';

export const ChangeUseCollectionNameInTestPathCheckbox = ({
	value = false,
}) => {
	const { onChangeUseCollectionNameInTestPath, isLoading, isCurrentUserAdmin } =
		useChangeUseCollectionNameInTestPathCheckbox();

	return (
		<If condition={isCurrentUserAdmin}>
			<FormControlLabel
				sx={{ width: 'fit-content' }}
				label="Use collection name in test path"
				control={
					<Checkbox
						checked={value}
						disabled={isLoading}
						onChange={onChangeUseCollectionNameInTestPath}
						color="primary"
					/>
				}
			/>
		</If>
	);
};
