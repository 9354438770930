import React from 'react';
import { FormTextField } from '@src/common/components/FormTextField';
import {
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Tooltip,
} from '@mui/material';
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
} from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { useDynamicEnvironmentUserRow } from '@src/modules/settings/components/ApplicationEnvironmentSettings/components/DynamicEnvironmentUserRow/hooks';
import { ConditionalWrapper } from '@src/common/components/ConditionalWrapper';
import { ApplicationEnvironmentUser } from '@src/modules/settings/types';
import { SerializedStyles } from '@emotion/react';
import { EnvironmentSettingsFormValues } from '@src/modules/settings/components/ApplicationEnvironmentSettings/types';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export const DynamicEnvironmentUserRow = ({
	envUser,
	styles,
	register,
	errors,
	index,
	isSingleUser,
	control,
	onChangeEnvironmentUserIsDefault,
	onDeleteEnvironmentUser,
	isEnvUserDefault,
	getDynamicFieldRowStyles,
}: {
	envUser: ApplicationEnvironmentUser;
	getDynamicFieldRowStyles: (condition: boolean) => SerializedStyles;
	styles: Record<string, SerializedStyles>;
	register: UseFormRegister<EnvironmentSettingsFormValues>;
	index: number;
	errors: FieldErrors<EnvironmentSettingsFormValues>;
	isSingleUser: boolean;
	control: Control<EnvironmentSettingsFormValues, unknown>;
	onChangeEnvironmentUserIsDefault: (
		checked: boolean,
		changedEnvUserIndex: number
	) => void;
	onDeleteEnvironmentUser: (index: number) => Promise<void>;
	isEnvUserDefault: boolean;
}) => {
	const {
		handleDeleteEnvironmentUser,
		handleChangeIsDefault,
		showPassword,
		toggleShowPassword,
		passwordInputType,
	} = useDynamicEnvironmentUserRow({
		onDeleteEnvironmentUser,
		onChangeEnvironmentUserIsDefault,
		index,
	});

	return (
		<Box key={envUser.id} css={getDynamicFieldRowStyles(!!envUser.role)}>
			<FormTextField
				label="Username or email"
				info="The username/email our agent will use to login"
				required
				variant="outlined"
				register={register}
				errors={errors}
				name={`environmentUsers[${index}].username`}
				hasErrorMessage
			/>

			<FormTextField
				label="Password"
				info="The password our agent will use to login"
				required
				type={passwordInputType}
				variant="outlined"
				register={register}
				errors={errors}
				name={`environmentUsers[${index}].password`}
				hasErrorMessage
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={toggleShowPassword}
								edge="end"
							>
								<>
									<If condition={showPassword}>
										<VisibilityOff />
									</If>

									<If condition={!showPassword}>
										<Visibility />
									</If>
								</>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>

			<If condition={!(isSingleUser && !envUser.role)}>
				<FormTextField
					label="Role"
					info="The role of this user, tests will be labeled accordingly"
					placeholder="e.g. host, guest"
					required
					variant="outlined"
					register={register}
					errors={errors}
					name={`environmentUsers[${index}].role`}
					hasErrorMessage
				/>
			</If>

			<If condition={!isSingleUser}>
				<ConditionalWrapper
					condition={isEnvUserDefault}
					wrapper={(children) => (
						<Tooltip
							title="When a user is not specified in the test, this user will be used"
							placement="top"
						>
							{children}
						</Tooltip>
					)}
				>
					<span css={styles.dynamicFieldAction}>
						<FormControlLabel
							label="Default"
							control={
								<Controller
									control={control}
									name={`environmentUsers.${index}.isDefault`}
									render={({ field: props }) => (
										<Checkbox
											{...props}
											checked={props.value}
											onChange={handleChangeIsDefault}
											disabled={props.value}
											color="primary"
										/>
									)}
								/>
							}
						/>
					</span>
				</ConditionalWrapper>

				<ConditionalWrapper
					condition={isEnvUserDefault}
					wrapper={(children) => (
						<Tooltip
							title="The default user cannot be removed. If you switched the default user, first save changes."
							placement="top"
						>
							{children}
						</Tooltip>
					)}
				>
					<span css={styles.dynamicFieldAction}>
						<IconButton
							onClick={handleDeleteEnvironmentUser}
							disabled={isEnvUserDefault}
						>
							<DeleteIcon color={isEnvUserDefault ? 'disabled' : undefined} />
						</IconButton>
					</span>
				</ConditionalWrapper>
			</If>
		</Box>
	);
};
