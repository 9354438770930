import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useSettingsNotCompletedBannerStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			paddingTop: theme.spacing(2),
		});

		const alert = css({
			alignItems: 'center',
			'.MuiAlert-message': {
				display: 'flex',
				width: '100%',
			},
			a: {
				marginLeft: '5px',
			},
		});

		const text = css({
			color: theme.colors.white,
			display: 'inline',
		});

		const link = css(text, {
			textDecoration: 'underline',
		});

		return { alert, container, text, link };
	}, [theme]);
};
