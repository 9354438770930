import { delay, http, HttpResponse, RequestHandler } from 'msw';
import {
	APICollection,
	APIInboxDataResponse,
	CreateCollectionAPIArgs,
	ReadUserStoriesAPIArgs,
	UpdateCollectionAPIArgs,
} from '../types';
import { APIBaseURL } from '@src/api/constants';
import {
	createAPICollection,
	makeAPIInboxResponse,
} from '@src/modules/collections/api/mock/helpers';
import { userStoriesMock } from '@src/modules/user-stories/service';
import { collectionsMock } from '@src/modules/collections/api/mock/data';

export const collectionsMockHandlers: RequestHandler[] = [
	// getUserStoriesCollections
	http.get(
		`${APIBaseURL}/user-stories-collections`,
		({ request, params, cookies }) => {
			return HttpResponse.json<APICollection[]>(collectionsMock);
		}
	),

	// getUserInboxCollectionAndStories
	http.get(
		`${APIBaseURL}/user-stories-inbox`,
		({ request, params, cookies }) => {
			return HttpResponse.json<APIInboxDataResponse>(makeAPIInboxResponse());
		}
	),

	// createUserStoriesCollection
	http.post<{}, CreateCollectionAPIArgs>(
		`${APIBaseURL}/user-stories-collections`,
		async ({ request, params, cookies }) => {
			const body = await request.json();

			await delay(1000);

			const collection = createAPICollection(body);

			collectionsMock.push(collection);

			return HttpResponse.json(collection);
		}
	),

	// readInboxUserStories
	http.post<{}, ReadUserStoriesAPIArgs>(
		`${APIBaseURL}/user-stories-inbox/readAll`,
		async ({ request, params, cookies }) => {
			const body = await request.json();

			const date = new Date().toISOString();

			userStoriesMock.forEach((story) => {
				if (body.userStoriesIds.find((id) => story.id === id)) {
					Object.assign(story, {
						userStoryEngagements: [{ lastSeenAt: date, clearedAt: null }],
					});
				}
			});

			return HttpResponse.json();
		}
	),

	// updateCollection
	http.patch<{ collectionId: string }, UpdateCollectionAPIArgs['update']>(
		`${APIBaseURL}/user-stories-collections/:collectionId`,
		async ({ request, params, cookies }) => {
			const collection = collectionsMock.find(
				({ id }) => id === params.collectionId
			);

			const body = await request.json();

			Object.assign(collection, body);

			await delay(1000);

			return HttpResponse.json(collection);
		}
	),

	// moveUserStoriesToAnotherCollection
	http.patch<{ collectionId: string }, { moveToCollectionId: string }>(
		`${APIBaseURL}/user-stories-collections/:collectionId/transferStories`,
		async ({ request, params, cookies }) => {
			const body = await request.json();

			const collectionMoveTo = collectionsMock.find(
				({ id }) => id === body.moveToCollectionId
			);

			userStoriesMock.forEach((story) => {
				if (story.collection.id === params.collectionId) {
					story.collection = collectionMoveTo;
				}
			});

			await delay(1000);

			return HttpResponse.json();
		}
	),

	// autoDetectFlows
	http.patch<{ collectionId: string }, { activated: boolean }>(
		`${APIBaseURL}/user-stories-collections/:collectionId/autoDetectFlows`,
		async ({ request, params, cookies }) => {
			const collection = collectionsMock.find(
				({ id }) => id === params.collectionId
			);

			collection.autoDetectFlows = true;

			await delay(1000);

			return HttpResponse.json({ activated: true });
		}
	),

	// deleteCollection
	http.delete<{ collectionId: string }, {}>(
		`${APIBaseURL}/user-stories-collections/:collectionId`,
		async ({ request, params, cookies }) => {
			const collectionToDelete = collectionsMock.find(
				({ id }) => id === params.collectionId
			);

			const isCollectionStoriesExist = userStoriesMock.some(
				(story) => story.collection.id === params.collectionId
			);

			if (!collectionToDelete || isCollectionStoriesExist) {
				return HttpResponse.json({ error: 'Bad request' }, { status: 404 });
			}

			collectionsMock.splice(
				collectionsMock.findIndex(({ id }) => id === collectionToDelete.id),
				1
			);

			await delay(1000);

			return HttpResponse.json({ success: true });
		}
	),
];
