import { useTestReport } from '@src/modules/test-runs/pages/test-report/hooks';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { Button, Box } from '@mui/material';
import { useTestReportStyles } from './styles';
import { Link } from 'react-router-dom';
import { testRunsRoutes } from '../../routes/routes';
import { KeyboardBackspace } from '@mui/icons-material';

export const TestReport = () => {
	const { divRef, isLoading } = useTestReport();
	const styles = useTestReportStyles({ isLoading });

	return (
		<Box component="section" css={styles.section}>
			<Link to={testRunsRoutes.testRuns.absolutePath}>
				<Button variant="text" css={styles.backToTestRunsButton}>
					<KeyboardBackspace /> Back to test runs
				</Button>
			</Link>

			<If condition={isLoading}>
				<LoadingCircularProgress styles={styles.loading}>
					Loading report...
				</LoadingCircularProgress>
			</If>

			<div id="report-placeholder" css={styles.reportDiv} ref={divRef} />
		</Box>
	);
};
