import { useGetUserInboxCollectionAndStoriesQuery } from '@src/modules/collections/api';

interface QueryOptions {
	skip?: boolean;
	force?: boolean;
	pollingInterval?: number;
	// eslint-disable-next-line
	selectFromResult?: (result: any) => any;
	// eslint-disable-next-line
	meta?: Record<string, any>;
}

// const pollingInterval = process.env.REACT_APP_ENV === 'local' ? 30_000 : 5000;

const defaultOptions: QueryOptions = {
	pollingInterval: 5000,
};

export const useInboxCollectionAndStories = (
	options: QueryOptions = defaultOptions
) => {
	const { data, ...rest } = useGetUserInboxCollectionAndStoriesQuery(
		undefined,
		options
	);

	return {
		inboxCollection: data?.inboxCollection,
		inboxUserStories: data?.userStories,
		...rest,
	};
};
