import { useAuthenticationInfo } from '@src/common/state/volatile/hooks';
import { useMemo } from 'react';

export const useDefaultPage = () => {
	const authenticationInfo = useAuthenticationInfo();

	// when the user is authenticated but has no app name, we display the internal default page
	const displayInternalDefaultPage = useMemo(() => {
		return (
			authenticationInfo.authenticated && !!authenticationInfo.user?.internal
		);
	}, [authenticationInfo]);

	return { displayInternalDefaultPage };
};
