import { css } from '@emotion/react';
import { useMemo } from 'react';
import { grey } from '@mui/material/colors';

export const useIncorrectActionStateStyles = () => {
	return useMemo(() => {
		const label = css({
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		});

		//add z index to the button in case that editor has its padding top and overlays
		//content above
		const button = css({
			zIndex: 2,
		});

		const override = css`
			.jsoneditor-menu,
			.jsoneditor-navigation-bar {
				display: none;
			}

			.jsoneditor {
				//add border color from theme
				border: 1px solid ${grey[300]};
				border-radius: 4px;
			}
		`;

		const modalContent = css(
			override,
			`
			width: 60vw;
			height: 60vh;
		`
		);

		const editorBlock = css(
			override,
			`
			.jsoneditor-tree {
				min-height: 100px;
				max-height: 200px;
			}
		`
		);

		const customInitialStateBlock = css({
			minHeight: '100px',
			maxHeight: '200px',
			//add border color from theme
			border: `1px solid ${grey[300]}`,
			borderRadius: '4px',
		});

		return {
			customInitialStateBlock,
			editorBlock,
			modalContent,
			button,
			override,
			label,
		};
	}, []);
};
