import { useCallback, useMemo, useState } from 'react';

export const useUpdateFileWithTagModal = ({ closeEditModal, errors }) => {
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

	const handleCloseEditModal = useCallback(() => {
		setIsConfirmationModalOpen(false);

		closeEditModal();
	}, [closeEditModal]);

	// buttons array config to build buttons in confimation modal
	const buttons = useMemo(
		() => [
			{
				callback: handleCloseEditModal,
				text: 'continue',
			},
			{
				callback: () => setIsConfirmationModalOpen(false),
				text: 'cancel',
			},
		],
		[handleCloseEditModal]
	);

	// disable close edit modal on backdrop click
	const handleCloseModalOnBackdropClick = useCallback(
		(_, reason) => {
			if (reason !== 'backdropClick') {
				handleCloseEditModal();
			}
		},
		[handleCloseEditModal]
	);

	const formattedErrors = transformErrors(errors);

	return {
		confirmationModalContent: {
			onClose: handleCloseEditModal,
			buttons,
			open: isConfirmationModalOpen,
			title: 'Tag duplications detected',
			description:
				'Duplications detected. Files with tag that already exists will be removed. Continue?',
		},
		handleCloseModalOnBackdropClick,
		formattedErrors,
	};
};

function transformErrors({ userStoryFiles = [], ...errors }) {
	const userStoryFilesErrorsFormatted = userStoryFiles.reduce(
		(acc, error, index) => {
			if (error) {
				acc[`userStoryFiles[${index}].tag`] = error.tag;
			}

			return acc;
		},
		{}
	);

	return Object.assign(errors, userStoryFilesErrorsFormatted);
}
