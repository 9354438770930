import { useMemo } from 'react';
import { useApplicationSettingsQueryState } from '@src/modules/application/service';
import { initValues } from '@src/modules/settings/components/ApplicationEnvironmentSettings/constants';

export const useActiveSettingsProfile = ({
	activeEnvironmentId,
}: {
	activeEnvironmentId: string;
}) => {
	const { data: applicationData, isLoading: isLoadingSettingsProfile } =
		useApplicationSettingsQueryState();

	const activeEnvironmentSettings = useMemo(() => {
		if (activeEnvironmentId === '') {
			return initValues;
		}

		return applicationData?.environments?.find((settingsProfile) =>
			activeEnvironmentId
				? settingsProfile.id === activeEnvironmentId
				: settingsProfile.isDefault
		);
	}, [applicationData?.environments, activeEnvironmentId]);

	return {
		activeEnvironmentSettings,
		isLoadingSettingsProfile,
		environments: applicationData?.environments ?? [],
	};
};
