import { Route } from 'react-router-dom';
import AppRoute from '@src/routing/components/AppRoute/AppRoute';
import { AppRouter } from '@src/routing/components/AppRouter';
import { settingsRouter } from './routes';
import { Wizard } from '../pages/wizard/Wizard';
import { Settings } from '../pages/settings/Settings';

export function SettingsRouter() {
	return (
		<AppRouter>
			<Route
				path={settingsRouter.settings.path}
				element={<AppRoute component={Settings} />}
			/>
			<Route
				path={settingsRouter.wizard.path}
				element={<AppRoute component={Wizard} />}
			/>
		</AppRouter>
	);
}
