import { Link, Typography } from '@mui/material';
import * as React from 'react';

export const TextContentHeader = ({ disableMultipleEnvironments = false }) => {
	return (
		<div>
			<Typography>Configuring the default testing environment</Typography>

			<ol>
				<li>
					<Typography>
						To generate tests, our test generation agent requires an environment
						and at least one registered user to execute test flows.
					</Typography>
				</li>

				<li>
					<Typography>
						Any stable environment will do (e.g. production, staging or preview)
						as long as it is publicly accessible through a valid URL.
					</Typography>
				</li>

				<li>
					<Typography>
						Our agent will perform actions within the provided user's account,
						so we recommend creating a dedicated user for testing. We support
						multiple testing users for more complex scenarios.
					</Typography>
				</li>

				<li>
					<Typography>
						Login must not involve Captcha, 2FA and should be a simple username
						and password login.
					</Typography>
				</li>
			</ol>

			<Typography>
				For more information see our <Link>documentation</Link>
			</Typography>

			<If condition={disableMultipleEnvironments}>
				<br />

				<Typography>
					Setup your first environment. You will be able to setup more
					environments later.
				</Typography>
			</If>
		</div>
	);
};
