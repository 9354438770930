import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

const useDefaultAppBarStyles = (menuStackStyle: string) => {
	const theme = useTheme();

	return useMemo(() => {
		const appBar = css`
			z-index: ${theme.zIndex.drawer + 1};
			background-color: white;
			box-shadow: none;
			border-bottom: 1px solid ${theme.colors.borderColor.main};
		`;

		const appBarContent = css`
			display: flex;
			justify-content: space-between;
		`;

		const iconButton = css({
			marginRight: theme.spacing(3),
		});

		const menuStack = css([
			{
				flexDirection: 'row',
			},
			menuStackStyle,
		]);

		return { appBar, appBarContent, iconButton, menuStack };
	}, [theme, menuStackStyle]);
};

export default useDefaultAppBarStyles;
