import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useGetTestRunReportURLQuery,
	useLazyGetTraceURLQuery,
} from '@src/modules/test-runs/api';
import { testRunsRoutes } from '@src/modules/test-runs/routes/routes';
import { useSearchParams } from 'react-router-dom';

// @ts-ignore
import raw from './raw/index.txt';
import {
	TraceDownloadSuccessToastContent,
	TraceDownloadSuccessToastId,
} from '@src/common/components/TraceDownloadSuccessToastContent';
import { downloadFileByUrl } from '@src/common/helpers/files';
import { toast } from 'react-toastify';
import { ApplicationRoutes } from '@src/app/router/routes';
import Config from '@src/config';
import { transformReportHTML } from './raw/transform-report-html';
import { sleep } from '@src/common/helpers/timing';

declare global {
	interface Window {
		checksum_test_run_id?: string;
		playwrightReportBase64?: string;
		onreportload?: () => void;
	}
}

// Whether to use the dynamic report HTML or the static one
// With dynamic HTML, we modify the report HTML before rendering it
// With static HTML, it has already been modified
const USE_DYNAMIC_REPORT_HTML = true;

export const useTestReport = () => {
	const { id } = useParams<'id'>();
	const navigate = useNavigate();

	// observer for dynamically added elements
	// with URLs that require modification
	const reportObserverRef = useRef<MutationObserver>(null);

	// target div to append the report HTML to
	const divRef = useRef<HTMLDivElement>();

	// in case arriving here without test run id, redirect to test runs page
	useEffect(() => {
		if (id) {
			window.checksum_test_run_id = id;
		}
		if (!id) {
			navigate(testRunsRoutes.testRuns.absolutePath);
		}
		return () => {
			window.checksum_test_run_id = undefined;
			window.onreportload = undefined;
			reportObserverRef.current?.disconnect();
		};
	}, [id, navigate]);

	useTrace(id);

	// get report url data
	const {
		data: reportURLData,
		isSuccess: reportUrlLoadedSuccessfully,
		isLoading: isLoadingReportURL,
	} = useGetTestRunReportURLQuery({ id }, { skip: !id });

	const [reportHTML, setReportHTML] = useState<string | null>(null);
	const [altToken, setAltToken] = useState<string | null>(null);
	const [isReportZipSet, setIsReportZipSet] = useState<boolean>(false);
	const [isReportZipSetMountingLoading, setIsReportZipSetMountingLoading] =
		useState(false);

	// Wait for the report URL data to load
	// Set the alt token and report HTML
	// Set the report zip (window.playwrightReportBase64) before rendering the report
	useEffect(() => {
		if (!reportUrlLoadedSuccessfully) {
			return;
		}
		// if report url is available, fetch the html, extract the base64 encoded zip and set it to expected window field
		if (reportURLData) {
			const { reportURL, altToken } = reportURLData;
			setAltToken(altToken);
			fetch(reportURL)
				.then((response) => {
					return response.text();
				})
				.then((html) => {
					setReportHTML(html);
					const regex = /window.playwrightReportBase64 = "(.*)";/;
					const match = html.match(regex);
					if (match) {
						window.playwrightReportBase64 = match[1];
						setIsReportZipSet(true);
					}
				});
		}
		// in case test runs fetch complete but no report url, redirect to test runs page
		else {
			navigate(testRunsRoutes.testRuns.absolutePath);
		}
	}, [id, reportUrlLoadedSuccessfully, navigate, reportURLData]);

	// After the report zip is set, mount the report HTML
	useEffect(() => {
		if (!isReportZipSet) {
			return;
		}

		setIsReportZipSetMountingLoading(true);

		if (USE_DYNAMIC_REPORT_HTML) {
			// Once the report zip is set,
			// transform the report HTML and append it to the target div.
			// After appending the HTML, call callOnReportLoad to trigger start the observer and trigger window.onreportload
			try {
				const text = transformReportHTML(reportHTML, id);
				const fragment = document.createRange().createContextualFragment(text);
				if (divRef.current) {
					divRef.current.append(fragment);
					callOnReportLoad(altToken).then((observer) => {
						reportObserverRef.current = observer;
					});
				}
			} finally {
				setIsReportZipSetMountingLoading(false);
			}
		} else {
			// If not using dynamic HTML, fetch the static HTML and append it to the target div.
			// This will have the static HTML render using the zip file data set in the window object.
			fetch(raw)
				.then((r) => r.text())
				.then((text) => {
					const fragment = document
						.createRange()
						.createContextualFragment(text);
					if (divRef.current) {
						// @ts-ignore
						divRef.current.append(fragment);
					}
				})
				.finally(() => {
					setIsReportZipSetMountingLoading(false);
				});
		}
	}, [isReportZipSet, reportHTML, altToken, id]);

	return {
		divRef,
		isLoading:
			isLoadingReportURL || !isReportZipSet || isReportZipSetMountingLoading,
	};
};

const callOnReportLoad = async (altToken: string) => {
	while (!window.onreportload) {
		await sleep(200);
	}
	const observer = observeNewElements(altToken);
	window.onreportload();
	return observer;
};

/**
 * Watch for new images and links in the report HTML and call the fixURL function to update their URLs.
 */
const observeNewElements = (altToken: string) => {
	function checkAndUpdateElement(node) {
		if (node.nodeType !== Node.ELEMENT_NODE) return;

		if (node.tagName === 'IMG' || node.tagName === 'A') {
			// Update the URL using the updateURL function
			const attribute = node.tagName === 'IMG' ? 'src' : 'href';
			const currentURL = node.getAttribute(attribute);
			if (currentURL) {
				const newURL = fixURL(currentURL, altToken);
				if (newURL !== currentURL) {
					node.setAttribute(attribute, newURL);
					// For images, wait for them to load
					// to potentially apply further logic
					if (node.tagName === 'IMG') {
						node.addEventListener('load', onImageLoad);
					}
				}
			}
		} else {
			// If the node is not <img> or <a>, check its children recursively
			node.querySelectorAll('img, a').forEach((child) => {
				checkAndUpdateElement(child);
			});
		}
	}
	const observer = new MutationObserver((mutationsList) => {
		try {
			mutationsList.forEach((mutation) => {
				switch (mutation.type) {
					case 'childList':
						mutation.addedNodes.forEach(checkAndUpdateElement);
						break;
					case 'attributes':
						checkAndUpdateElement(mutation.target);
						break;
				}
			});
		} catch (error) {
			console.warn('Error in Test Report MutationObserver:', error);
		}
	});

	observer.observe(document.getElementById('report-placeholder'), {
		childList: true,
		subtree: true,
		attributeFilter: ['src', 'href'],
	});

	return observer;
};

/**
 * Modifying URLs as following:
 * 1. URLs pointing to checksum's API must include the altToken query parameter.
 * 2. On local environment, replace the API URL with the local API URL.
 * 3. URLs pointing to the trace viewer must include the trace and view query parameters.
 */
const fixURL = (url: string, altToken: string) => {
	if (!url) {
		return url;
	}
	let newSrc = url;

	if (url.includes('https://api.checksum.ai') && !url.includes('altToken=')) {
		if (Config.env === 'local') {
			// Replace the API URL with the local API URL
			newSrc = newSrc.replace('https://api.checksum.ai', Config.api.baseUrl);
		}
		// Check if a query string already exists in the URL
		const separator = newSrc.includes('?') ? '&' : '?';
		// Append the token parameter
		newSrc = `${newSrc}${separator}altToken=${altToken}`;
	}

	if (url.startsWith('trace/index.html?trace=')) {
		let trace = 'true';
		if (url.endsWith('/trace')) {
			const match = url.match(/\/([^/]+)\/trace$/);
			if (match) {
				trace = match[1];
			}
		}
		const urlObj = new URL(url.replace('trace/index.html?trace=', ''));
		const fileName = urlObj.searchParams.get('fileName');

		// newSrc = newSrc.replace('trace/index.html?trace=', window.location.href);
		newSrc = window.location.href;
		// Check if a query string already exists in the URL
		const separator = newSrc.includes('?') ? '&' : '?';
		// Append the trace and view parameters
		newSrc = `${newSrc}${separator}trace=${trace}&view=true`;
		// Add file name to the query string
		if (fileName) {
			newSrc = `${newSrc}&fileName=${fileName}`;
		}
	}

	return newSrc;
};

/**
 * Fix image mismatch dimensions after the image is loaded
 */
const onImageLoad = (e) => {
	const img: HTMLImageElement = e.target;
	img.removeEventListener('load', onImageLoad);
	// only apply to images with the test-result-image-mismatch section
	if (!img.closest('[data-testid=test-result-image-mismatch]')) {
		return;
	}

	let containerElement = img.parentElement;
	try {
		const previousSibling = img.parentElement.previousElementSibling;
		let shouldUpdateDimensions = true;
		if (
			previousSibling.tagName === 'IMG' &&
			(previousSibling as HTMLImageElement).alt === 'Expected'
		) {
			containerElement = previousSibling.parentElement;
			shouldUpdateDimensions = false;
		}
		if (shouldUpdateDimensions) {
			const valueStack = [
				img.naturalWidth.toString(),
				img.naturalHeight.toString(),
			].reverse();
			previousSibling.querySelectorAll('span').forEach((span) => {
				if (span.textContent === '0') {
					span.textContent = valueStack.pop();
				}
			});
		}
	} catch (error) {}

	// update image width and height based on the parent element's aspect ratio and the image's aspect ratio
	const parentAspectRatio =
		containerElement.clientWidth / containerElement.clientHeight;
	const imgAspectRatio = img.naturalWidth / img.naturalHeight;
	let width, height;

	if (imgAspectRatio > parentAspectRatio) {
		// Image is wider than the container
		width = containerElement.clientWidth;
		height = containerElement.clientWidth / imgAspectRatio;
	} else {
		// Image is taller than the container
		height = containerElement.clientHeight;
		width = containerElement.clientHeight * imgAspectRatio;
	}

	img.width = width;
	img.style.width = width + 'px';
	img.height = height;
	img.style.height = height + 'px';
};

/**
 * Handles trace view/download based on query params
 */
const useTrace = (id) => {
	const [queryParams, setQueryParams] = useSearchParams();
	const trace = queryParams.get('trace');
	const isViewMode = queryParams.get('view') === 'true';
	const fileName = queryParams.get('fileName');

	// this currently downloads the trace file and then instructs the user
	// to open the trace viewer manually
	const [getTraceSignedURL] = useLazyGetTraceURLQuery();
	useEffect(() => {
		if (!id || !trace) {
			return;
		}
		// first, delete from query params to avoid downloading again on refresh
		queryParams.delete('trace');
		queryParams.delete('view');
		queryParams.delete('fileName');
		setQueryParams(queryParams);
		// next, get the signed url and download the file
		(async () => {
			const response = await getTraceSignedURL({ id, testId: trace, fileName });

			// in view mode, open the trace viewer directly
			if (isViewMode) {
				window.location.href = `${
					Config.appUrl
				}/#${ApplicationRoutes.traceViewer.make()}?url=${encodeURIComponent(
					response.data
				)}`;
				return;
			}

			await downloadFileByUrl(response.data, 'trace.zip');
			toast.success(TraceDownloadSuccessToastContent, {
				toastId: TraceDownloadSuccessToastId,
				autoClose: false,
				closeOnClick: false,
			});
		})();
	}, [
		id,
		trace,
		getTraceSignedURL,
		queryParams,
		setQueryParams,
		isViewMode,
		fileName,
	]);
};
