import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { CodeBlock } from '@src/common/components/CodeBlock';
import { ApplicationRoutes } from '@src/app/router/routes';
import { Link } from 'react-router-dom';
import { settingsRouter } from '@src/modules/settings/service';
import { Settings } from '@mui/icons-material';
import { useTestRunsPageStyles } from './styles';

export const TestRunsEmptyState = ({ setupIncomplete }) => {
	const styles = useTestRunsPageStyles();
	return (
		<Box css={styles.container}>
			<Typography variant="h4">
				<Choose>
					<When condition={setupIncomplete}>
						To generate and run tests, first finish setting up Checksum.
					</When>
					<Otherwise>Test run reports will be displayed here.</Otherwise>
				</Choose>
			</Typography>
			<Box display="flex" flexDirection="column" gap={1} pt={2}>
				<Choose>
					<When condition={setupIncomplete}>
						<Link to={settingsRouter.wizard.absolutePath}>
							<Button variant="contained" startIcon={<Settings />}>
								Setup Checksum
							</Button>
						</Link>
					</When>
					<Otherwise>
						<Typography component="div">
							If you haven't yet done so,{' '}
							<Link
								to={ApplicationRoutes.userStoriesExplorer.path}
								css={styles.generateTestsLink}
							>
								generate tests
							</Link>{' '}
							and then run
						</Typography>
						<CodeBlock language="terminal">npx checksumai test</CodeBlock>
					</Otherwise>
				</Choose>
			</Box>
		</Box>
	);
};
