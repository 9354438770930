import * as React from 'react';
import Box from '@mui/material/Box';
import {
	Button,
	Link,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import { SetupSDK } from '../../components/SetupSDK/SetupSDK';
import { ApplicationEnvironmentSettings } from '@src/modules/settings/components/ApplicationEnvironmentSettings/ApplicationEnvironmentSettings';
import { useWizard } from './hooks';
import { StepsValues } from '@src/modules/settings/pages/wizard/constants';
import { LoadingButton } from '@mui/lab';
import { useWizardStyles } from './styles';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { GitSettings } from '../../components/GitSettings/GitSettings';

export function Wizard() {
	const {
		activeStep,
		setIsFormSubmitting,
		isButtonLoading,
		submitFormRef,
		nextButtonText,
		onNext,
		onBack,
		onSkip,
		sdkKey,
		steps,
		isSettingsStatusLoading,
		settingsStatus,
	} = useWizard();

	const styles = useWizardStyles();

	return (
		<Box component="section" css={styles.container}>
			<Box css={styles.centered}>
				<Choose>
					<When condition={isSettingsStatusLoading}>
						<LoadingCircularProgress>
							Loading Wizard Settings...
						</LoadingCircularProgress>
					</When>

					<Otherwise>
						<Box css={styles.content}>
							<Box>
								<Typography variant="h2" gutterBottom textAlign="center">
									Setup Checksum
								</Typography>

								<Stepper
									activeStep={activeStep.value}
									css={styles.stepper}
									alternativeLabel
									nonLinear
								>
									{steps.map(({ name, completed }) => (
										<Step key={name} completed={completed}>
											<StepLabel>
												<Typography
													css={styles.getStepLabel(activeStep.name, name)}
												>
													{name}
												</Typography>
											</StepLabel>
										</Step>
									))}
								</Stepper>
							</Box>

							<Box css={styles.mainContent}>
								<If condition={activeStep.name === StepsValues.SETUP_SDK}>
									<SetupSDK sdkKey={sdkKey} />
								</If>

								<If
									condition={
										activeStep.name === StepsValues.CONFIGURE_ENVIRONMENT
									}
								>
									<ApplicationEnvironmentSettings
										setIsFormSubmitting={setIsFormSubmitting}
										ref={submitFormRef}
										externalControl
										disableMultipleEnvironments
									/>
								</If>

								<If
									condition={
										activeStep.name === StepsValues.SET_GIT_INTEGRATION
									}
								>
									<GitSettings settingsStatus={settingsStatus} />
								</If>
							</Box>

							<Box>
								<Box css={styles.buttonsWrapper}>
									<Button
										variant="outlined"
										disabled={!onBack}
										onClick={onBack}
									>
										Back
									</Button>

									<Button
										variant="text"
										css={styles.getSkipForNowButtonVisibility(!!onSkip)}
										disabled={!onSkip}
										onClick={onSkip}
									>
										Skip for now
									</Button>

									<LoadingButton
										loading={isButtonLoading}
										variant="outlined"
										disabled={!onNext}
										onClick={onNext}
									>
										{nextButtonText}
									</LoadingButton>
								</Box>

								<Typography variant="subtitle2" css={styles.subtitle}>
									Our team is happy to assist with the integration. For support
									or to schedule a meeting, reach out to{' '}
									<Link href="mailto:support@checksum.ai" css={styles.link}>
										support@checksum.ai
									</Link>
									.
								</Typography>
							</Box>
						</Box>
					</Otherwise>
				</Choose>
			</Box>
		</Box>
	);
}
