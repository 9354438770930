import { FC } from 'react';
import {
	Dialog,
	DialogContent,
	Button,
	DialogContentText,
	DialogTitle,
	DialogActions,
} from '@mui/material';
import { useConfirmationDialogStyles } from './styles';
import { AlertDialogContent } from './types';

export const ConfirmationDialog: FC<AlertDialogContent> = ({
	open,
	title,
	description,
	buttons,
	onClose,
}) => {
	const styles = useConfirmationDialogStyles();

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>

			<DialogContent>
				<DialogContentText>{description}</DialogContentText>
			</DialogContent>

			<DialogActions css={styles.buttonsContainer}>
				{buttons.map(({ callback, text, variant = 'text' }, index) => (
					<Button key={text + index} variant={variant} onClick={callback}>
						{text}
					</Button>
				))}
			</DialogActions>
		</Dialog>
	);
};
