export const preloadImage = (src: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.onload = () => resolve(src);
		image.onerror = () => reject(src);
		image.src = src;
	});
};

export const preloadImages = (sources: string[]): Promise<string>[] => {
	return sources.map((src) => preloadImage(src));
};

export const downloadFileByUrl = async (
	url: string,
	filename: string,
	shouldFetch = false
) => {
	let resultUrl = url;

	if (shouldFetch) {
		const response = await fetch(url, {
			mode: 'cors',
			credentials: 'same-origin',
		});
		const blob = await response.blob();
		resultUrl = URL.createObjectURL(blob);
	}

	const anchorEl = document.createElement('a');
	anchorEl.href = resultUrl;
	anchorEl.download = filename;
	// anchorEl.target = '_blank';
	anchorEl.click();

	//clear anchor element from document after click
	anchorEl.remove();
};
