import { useCallback } from 'react';
import { useAppDispatch } from '@src/store/hooks';
import { useActiveApplication } from '@src/common/state/volatile/hooks';
import { setActiveApplication } from '@src/common/state/volatile/slice';
import { invalidateAllTags } from '@src/api';
import { setPersistentActiveApplicationId } from '@src/common/state/persistent/slice';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from '@src/app/router/routes';
import { useGetApplicationsQueryState } from '@src/modules/settings/service';
import { ApplicationUserRoles } from '@src/modules/application/service';

export const useProjectSettings = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const activeApplication = useActiveApplication();

	const { data: applications = [] } = useGetApplicationsQueryState();

	//select application (for now only in the defaultAppBar menuList)
	const onSelectApplication = useCallback(
		(applicationId: string) => {
			const activeApplication =
				applications?.find(({ id }) => id === applicationId) ?? null;

			dispatch(setActiveApplication(activeApplication));
			dispatch(setPersistentActiveApplicationId(applicationId));
			dispatch(invalidateAllTags());

			navigate(ApplicationRoutes.userStoriesExplorer.path);
		},
		[dispatch, applications, navigate]
	);

	return {
		applications,
		userRole: activeApplication?.role ?? ApplicationUserRoles.Member,
		activeApplication,
		onSelectApplication,
	};
};
