import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useCollectionModalStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			padding: theme.spacing(5),
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(3),
		});

		const buttonsContainer = css({
			display: 'flex',
			gap: theme.spacing(2),
			alignItems: 'center',
		});

		const buttonsContainerEnd = css(buttonsContainer, {
			justifyContent: 'end',
		});

		const buttonsContainerStart = css(buttonsContainer, {
			justifyContent: 'start',
		});

		return {
			section,
			buttonsContainerStart,
			buttonsContainerEnd,
		};
	}, [theme]);
};
