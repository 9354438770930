import { useCallback } from 'react';
import {
	useDeleteCollectionMutation,
	useMoveUserStoriesToAnotherCollectionMutation,
} from '@src/modules/collections/api';
import { UserStoriesCollection } from '@src/modules/collections/types';

export const useDeleteCollectionModal = ({
	collectionToDelete,
	onClose,
}: {
	collectionToDelete: UserStoriesCollection;
	onClose: () => void;
}) => {
	const [moveUserStoriesToAnotherCollection, { isLoading: isMoveLoading }] =
		useMoveUserStoriesToAnotherCollectionMutation();

	const [deleteCollection, { isLoading: isDeleteLoading }] =
		useDeleteCollectionMutation();

	const handleMoveStories = useCallback(
		async (moveToCollectionId: string): Promise<void> => {
			if (!collectionToDelete) {
				return;
			}

			await moveUserStoriesToAnotherCollection({
				collectionId: collectionToDelete.id,
				moveToCollectionId,
			});

			await deleteCollection(collectionToDelete.id);

			onClose();
		},
		[
			collectionToDelete,
			deleteCollection,
			moveUserStoriesToAnotherCollection,
			onClose,
		]
	);

	return {
		handleMoveStories,
		isLoading: isMoveLoading || isDeleteLoading,
	};
};
