import { Tabs, Tab, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { SetupSDK } from '../../components/SetupSDK/SetupSDK';
import { ApplicationEnvironmentSettings } from '../../components/ApplicationEnvironmentSettings/ApplicationEnvironmentSettings';
import { useSettingsPage } from './hooks';
import {
	useCustomTabPanelStyles,
	useSettingsStyles,
} from '@src/modules/settings/pages/settings/styles';
import { ProjectSettingsForm } from '@src/modules/settings/components/ProjectSettingsForm/ProjectSettingsForm';
import { GitSettings } from '@src/modules/settings/components/GitSettings/GitSettings';

export function Settings() {
	const {
		tabValue,
		handleTagChange,
		makeA11yProps,
		settingsStatus,
		shouldDisplaySDKTab,
		tabs,
	} = useSettingsPage();

	const styles = useSettingsStyles();

	return (
		<Box component="section" css={styles.section}>
			<Typography variant="h1" gutterBottom>
				Settings
			</Typography>
			<Box css={styles.tabsContainer}>
				<Tabs
					value={tabValue}
					onChange={handleTagChange}
					aria-label="checksum settings"
				>
					<If condition={shouldDisplaySDKTab}>
						<Tab label="SDK setup" {...makeA11yProps(tabs.sdk)} />
					</If>
					<Tab
						label="Testing environment"
						{...makeA11yProps(tabs.environment)}
					/>
					<Tab
						label="Git integration"
						{...makeA11yProps(tabs.gitIntegration)}
					/>
					<Tab
						label="Project settings"
						{...makeA11yProps(tabs.projectSettings)}
					/>
				</Tabs>
			</Box>
			<CustomTabPanel value={tabValue} index={tabs.sdk}>
				<SetupSDK />
			</CustomTabPanel>

			<CustomTabPanel value={tabValue} index={tabs.environment}>
				<ApplicationEnvironmentSettings />
			</CustomTabPanel>

			<CustomTabPanel value={tabValue} index={tabs.gitIntegration}>
				<GitSettings settingsStatus={settingsStatus} />
			</CustomTabPanel>

			<CustomTabPanel value={tabValue} index={tabs.projectSettings}>
				<ProjectSettingsForm settingsStatus={settingsStatus} />
			</CustomTabPanel>
		</Box>
	);
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	const styles = useCustomTabPanelStyles();

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`settings-tabpanel-${index}`}
			aria-labelledby={`settings-tab-${index}`}
			css={styles.tabPanel}
			{...other}
		>
			<If condition={value === index}>{children}</If>
			{/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
		</Box>
	);
}
