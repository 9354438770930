import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { JsonViewer } from '@src/common/components/JsonViewer/JsonViewer';
import { useActionDescriptionStyles } from '@src/modules/test-generation-labeling/components/ActionDescription/styles';
import {
	TestGenerationLogStep,
	TestGenerationStepLabelStatus,
} from '@src/modules/test-generation-labeling/types';
import { useActionDescription } from '@src/modules/test-generation-labeling/components/ActionDescription/useActionDescription';
import { ViewOnlyTextBlock } from '@src/modules/test-generation-labeling/components/ActionDescription/components/ViewOnlyTextBlock/ViewOnlyTextBlock';

export const ActionDescription = ({
	activeTestGenerationLogStep,
	activeStoredStep,
	editorKey,
}: {
	editorKey: number;
	activeTestGenerationLogStep: TestGenerationLogStep;
	activeStoredStep: TestGenerationLogStep;
}) => {
	const {
		actionHistoryThoughts,
		showLabeledResponse,
		toggleShowOriginalOrLabeledResponse,
		responseForDisplay,
		actionType,
	} = useActionDescription({
		actionHistory: activeTestGenerationLogStep?.actionHistory,
		activeTestGenerationLogStep,
		activeStoredStep,
	});

	const styles = useActionDescriptionStyles();

	return (
		<Box css={styles.container}>
			<Box>
				<Typography variant="caption">Test case</Typography>

				<Typography>{activeTestGenerationLogStep.goal}</Typography>
			</Box>

			<ViewOnlyTextBlock
				label="Test Steps"
				placeholder="Test Steps"
				content={activeTestGenerationLogStep.steps}
			/>

			<JsonViewer
				editorKey={editorKey}
				label="Previous actions taken (thoughts)"
				value={actionHistoryThoughts}
				CustomInitialStateBlock={
					<ViewOnlyTextBlock content={actionHistoryThoughts} />
				}
				expandValue={activeTestGenerationLogStep.actionHistory}
			/>

			<Box>
				<Typography variant="caption">Action</Typography>

				<Typography>{actionType}</Typography>
			</Box>

			<If
				condition={
					activeStoredStep?.status === TestGenerationStepLabelStatus.Labeled
				}
			>
				<FormControlLabel
					control={
						<Switch
							checked={showLabeledResponse}
							onChange={toggleShowOriginalOrLabeledResponse}
						/>
					}
					label="Labeled response"
				/>
			</If>

			<JsonViewer
				editorKey={editorKey}
				label="Action payload"
				value={responseForDisplay}
			/>
		</Box>
	);
};
