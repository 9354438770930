import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '@src/common/hooks/useYupValidationResolver';
import { validationSchema } from '../validation';
import { useConfirmationModal } from './useConfirmationModal';
import { AutoDetectTestFlowsPopUpFormProps } from '@src/modules/collections/components/AutoDetectTestFlowsButton/AutoDetectTestFlowsPopUpForm/AutoDetectTestFlowsPopUpForm';

export const useAutoDetectTestFlowsPopUpForm = ({
	onAutoDetectFlows,
	onClose,
	isFlowAutoDetectionActivated,
	autoDetectFlowsDescription,
	anchor,
}: AutoDetectTestFlowsPopUpFormProps) => {
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

	// validation adjuster to react hook form
	const resolver = useYupValidationResolver(validationSchema);

	const open = Boolean(anchor);

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<{
		description: string;
	}>({
		values: { description: autoDetectFlowsDescription ?? null },
		reValidateMode: 'onChange',
		resolver,
	});

	// according to detecting progress status show different text in save button
	const saveButtonText = isFlowAutoDetectionActivated
		? 'Save and Restart'
		: 'Save';

	// disable save once no changes and is detecting
	const isSaveButtonDisabled = useMemo(() => {
		return isFlowAutoDetectionActivated && !isDirty;
	}, [isDirty, isFlowAutoDetectionActivated]);

	const onSave = useCallback(
		({ description }: { description: string }) => {
			onAutoDetectFlows({ activated: true, description });
			onClose();
		},
		[onAutoDetectFlows, onClose]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSubmit = useCallback(handleSubmit(onSave), [handleSubmit, onSave]);

	// cancel test flows detecting
	const handleStopDetecting = useCallback(() => {
		onAutoDetectFlows({ activated: false });
	}, [onAutoDetectFlows]);

	const onStopDetecting = useCallback(() => {
		setIsConfirmationModalOpen(true);
	}, []);

	const handleBackdropClick = useCallback(() => {
		if (!isConfirmationModalOpen) {
			onClose();
		}
	}, [onClose, isConfirmationModalOpen]);

	// content for stop detecting confirmation modal
	const confirmationModalContent = useConfirmationModal({
		handleStopDetecting,
		isConfirmationModalOpen,
		setIsConfirmationModalOpen,
	});

	const handleTextAreaKeyDown = useCallback((event) => {
		// prevent input unfocus on 'F' or 'f' keys typed
		if (event.key.toLowerCase() === 'f') {
			event.stopPropagation();
		}
	}, []);

	return {
		register,
		errors,
		onSubmit,
		isSaveButtonDisabled,
		onStopDetecting,
		confirmationModalContent,
		saveButtonText,
		handleBackdropClick,
		open,
		handleTextAreaKeyDown,
	};
};
