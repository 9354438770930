import { ApplicationRoutes } from '@src/app/router/routes';
import { prepareModuleRoutes } from '@src/routing';

export const sessionRecordingsRoutes = prepareModuleRoutes<
	'sessionRecordings' | 'sessionRecording'
>(
	{
		sessionRecordings: {
			path: '/',
		},
		sessionRecording: {
			path: '/:recordingId',
		},
	},
	ApplicationRoutes.sessionRecordings.absolutePath
);
