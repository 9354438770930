export enum StepsValues {
	SETUP_SDK = 'Setup SDK',
	CONFIGURE_ENVIRONMENT = 'Configure Testing Environment',
	SET_GIT_INTEGRATION = 'Install Git App',
}

export const STEPS = [
	{
		name: StepsValues.SETUP_SDK,
		completed: false,
		value: 0,
	},
	{
		name: StepsValues.CONFIGURE_ENVIRONMENT,
		completed: false,
		value: 1,
	},
	{
		name: StepsValues.SET_GIT_INTEGRATION,
		completed: false,
		value: 2,
	},
];
