import { EnvironmentSettingsFormValues } from '@src/modules/settings/components/ApplicationEnvironmentSettings/types';

export const defaultEnvironmentUser = {
	id: '',
	username: '',
	password: '',
	role: '',
	isDefault: true,
};

export const initValues: EnvironmentSettingsFormValues = {
	id: '',
	environmentBaseURL: '',
	loginURL: '',
	preTestHook: '',
	environmentUsers: [defaultEnvironmentUser],
	isDefault: false,
	name: '',
};

export enum OpenedConfirmationModalVariant {
	UNSAVED_CHANGES_WHEN_CHANGING_TO_NEW_ENVIRONMENT = 'unsaved-changes-when-changing-to-new-env',
	UNSAVED_CHANGES_WHEN_CHANGING_ENVIRONMENT = 'unsaved-changes-when-changing-env',
	ENV_NAME_OR_USER_ROLE_CHANGED = 'env-name-or-user-role-changed',
	DELETE_ENVIRONMENT = 'delete-environment',
	DEFAULT = 'default',
}

const CHANGE_ENV_CONFIRMATION_MODAL_CONTENT = {
	title: 'Your changes are not saved',
	description:
		"You have made changes to the environment settings that haven't been saved yet. If you leave now, your changes will be lost.",
	buttons: [
		{ text: 'Return to environment' },
		{ text: 'Continue without saving' },
	],
};

export const CONFIRMATION_ALERTS_CONTENTS = {
	[OpenedConfirmationModalVariant.DEFAULT]: null,
	[OpenedConfirmationModalVariant.DELETE_ENVIRONMENT]: {
		title: 'This environment will be deleted',
		description: 'Are you sure you want to delete this environment?',
		buttons: [{ text: 'Return to environment' }, { text: 'Yes, delete' }],
	},
	[OpenedConfirmationModalVariant.ENV_NAME_OR_USER_ROLE_CHANGED]: {
		title: 'Warning: Your test code might require changes',
		description:
			'Environment names and user roles must align with your test code. Make sure they are updated following these changes.',
		buttons: [{ text: 'Cancel' }, { text: 'Proceed' }],
	},
	[OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_TO_NEW_ENVIRONMENT]:
		CHANGE_ENV_CONFIRMATION_MODAL_CONTENT,
	[OpenedConfirmationModalVariant.UNSAVED_CHANGES_WHEN_CHANGING_ENVIRONMENT]:
		CHANGE_ENV_CONFIRMATION_MODAL_CONTENT,
};
