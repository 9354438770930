import { makeAPIUserStory } from '@src/modules/user-stories/api/mock/helpers';

// use collection: { id: 'collectionId' }, instead of collectionsMock in case
// of circular dependency
const data = [
	{
		title: 'story1',
		steps: 'steps1',
		startURL: 'http://localhost.com/start/1',
		collection: { id: 'collection1' },
	},
	{
		title: 'story2',
		steps: 'steps1',
		startURL: 'http://localhost.com/start/1',
		collection: { id: 'collection1' },
		origin: 'd2s' as 'user' | 'd2s',
	},
	{
		title: 'story-qwerty',
		steps: 'steps1',
		startURL: 'http://localhost.com/start/1',
		collection: { id: 'collection2' },
	},
];

export const userStoriesMock = data.map((storyRaw) => {
	return makeAPIUserStory(storyRaw);
});
