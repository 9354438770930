import { object, string } from 'yup';
import { getEnvironmentUrlRegex } from '@src/common/helpers/validation';
import * as yup from 'yup';

const urlRegex = getEnvironmentUrlRegex();

export const storyFormValidationSchema = object({
	title: string().trim().required(),

	collectionId: string().trim().required(),

	steps: string(),

	startURL: string().test(
		'is-relative-or-environment-based',
		'Login URL should be a valid URL or a relative path (starting with a "/")',
		function (value, context) {
			if (context.parent.defaultStartURL && !value) {
				return true;
			}

			return (
				urlRegex.test(value) ||
				(value.startsWith('/') && urlRegex.test(`http://example.com${value}`))
			);
		}
	),

	username: string()
		.trim()
		.test(
			'username-required',
			'Username is required when password is provided.',
			function (value) {
				const { password } = this.parent;

				return !password || !!(password && value);
			}
		),

	password: string()
		.trim()
		.test(
			'password-required',
			'Password is required when username is provided.',
			function (value) {
				const { username } = this.parent;

				return !username || !!(username && value);
			}
		),

	userStoryFiles: yup.array().of(
		yup.object().shape({
			tag: yup
				.string()
				.trim()
				.required('File name is required')
				.test('unique', 'File name must be unique', function (value) {
					const files = this.from[1].value.userStoryFiles;

					return (
						files.filter(
							(file) => file?.tag.toLowerCase() === value.toLowerCase()
						).length <= 1
					);
				}),
			name: yup.string(),
		})
	),
});
