import { sortBy } from 'lodash';
import {
	APIApplicationSettings,
	APIAvailableApplication,
} from '@src/modules/application/api/types';
import {
	ApplicationForInternal,
	ApplicationSettings,
	AvailableApplication,
} from '@src/modules/application/types';
import {
	transformAPIGithubAppIntegrationInfo,
	transformAPIGithubAppIntegrationStatus,
	transformAPIGitlabAppIntegrationStatus,
} from '@src/modules/settings/service';

export const transformAPIAllApplications = (
	applications: ApplicationForInternal[]
): ApplicationForInternal[] => {
	return sortBy(applications, [
		function (o) {
			return o.name;
		},
	]);
};

export const transformAPIApplication = ({
	settings,
	hasValidLoginFunction,
	sdkReceivedSessionRecordings,

	apiKey,
	sdkKey,

	gitlabAppIntegrationStatus,
	githubAppIntegrationStatus,

	gitlabAppIntegrationInfo,
	githubAppIntegrationInfo,

	...applicationSettings
}: APIApplicationSettings): ApplicationSettings => {
	const isGithubAppSuccessfullyIntegrated =
		githubAppIntegrationStatus === 'active';
	const isGitlabAppSuccessfullyIntegrated =
		gitlabAppIntegrationStatus === 'active';

	const defaultEnvironment = settings.find(({ isDefault }) => isDefault);

	return {
		...applicationSettings,
		environments: settings,
		gitlabAppIntegrationInfo,
		githubAppIntegrationInfo: transformAPIGithubAppIntegrationInfo(
			githubAppIntegrationInfo
		),
		settingsStatus: {
			isGitIntegrated:
				isGithubAppSuccessfullyIntegrated || isGitlabAppSuccessfullyIntegrated,
			isGithubAppSuccessfullyIntegrated,
			isGitlabAppSuccessfullyIntegrated,

			githubAppIntegrationStatus: transformAPIGithubAppIntegrationStatus(
				githubAppIntegrationStatus
			),
			gitlabAppIntegrationStatus: transformAPIGitlabAppIntegrationStatus(
				gitlabAppIntegrationStatus
			),
			sdkSuccessfullyIntegrated: sdkReceivedSessionRecordings,

			sdkKey,
			cliKey: apiKey,
			applicationHasValidLoginFunction: hasValidLoginFunction,
			hasValidEnvironmentSettings:
				!!defaultEnvironment?.environmentBaseURL &&
				!!defaultEnvironment?.loginURL,
		},
	};
};

export const transformAPIAvailableApps = (
	apps: APIAvailableApplication[]
): AvailableApplication[] => {
	return apps.map(({ application, role }) => ({
		role,
		id: application.id,
		name: application.name,
	}));
};
