import { Navigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { useRedirectToAfterAuthentication } from '@src/common/state/volatile/hooks';

export const RedirectFromAuthenticationIfLoggedIn = ({
	children,
}: PropsWithChildren) => {
	const path = useRedirectToAfterAuthentication();

	return (
		<Choose>
			<When condition={!!path}>
				<Navigate to={path} />
			</When>
			<Otherwise>{children}</Otherwise>
		</Choose>
	);
};
