import { TestEventTypes, TestEvents } from './test';

export enum ErrorEventType {
	ErrorNotification = 'ErrorNotification',
}

// Define all globally merged event attributes
export enum GlobalEventAttributesEnum {
	Email = 'email',
	UserId = 'userId',
}

// Define all fixed merged event attributes
export enum FixedEventAttributesEnum {
	AppVersion = 'app-version',
	GitVersionTag = 'git-version-tag',
}

export type AnalyticsIdentifyType = {
	authCode: string;
};

// Enumerate all event types
export type EventTypesEnum = TestEventTypes;

// Define unified event interface
export interface EventInterface {
	type: EventTypesEnum;
	payload?: object;
}

export type EventWithComponent<EventType, ComponentType> = {
	type: EventType;
	payload: {
		component: ComponentType;
	};
};

export interface ErrorEvent {
	type: ErrorEventType.ErrorNotification;
	payload: {
		error: string;
	};
}

// Export all tracked event types
export type TrackedEvents = 'identified' | TestEvents;
