import { useProjectSettingsStyles } from '@src/modules/settings/components/ProjectSettingsForm/styles';
import {
	Box,
	Checkbox,
	FormControlLabel,
	Table,
	TableBody,
	TableContainer,
	Typography,
} from '@mui/material';
import { useProjectSettingsForm } from '@src/modules/settings/components/ProjectSettingsForm/hooks';
import { MemberTableRow } from '@src/modules/settings/components/ProjectSettingsForm/components/MemberTableRow/MemberTableRow';
import { AddNewUserForm } from './components/AddNewUserForm/AddNewUserForm';
import { FormTextField } from '@src/common/components/FormTextField';
import { SettingsStatus } from '@src/modules/settings/types';

export const ProjectSettingsForm = ({
	settingsStatus,
}: {
	settingsStatus?: SettingsStatus;
}) => {
	const {
		applicationUsers,
		isAuthenticatedUserAllowedToUpdate,
		activeApplicationName,
		apiKey,
		isHasValidLoginFunctionUpdateLoading,
		handleChangeApplicationHasValidLoginFunction,
		isUserInternal,
	} = useProjectSettingsForm({ settingsStatus });

	const styles = useProjectSettingsStyles();

	return (
		<Box css={styles.section}>
			<Box css={styles.container}>
				<Typography variant="h4" gutterBottom>
					General
				</Typography>

				<FormTextField
					name="projectName"
					label="Project Name"
					placeholder="My Awesome Project"
					value={activeApplicationName}
					fullWidth
					disabled
				/>

				<FormTextField
					name="apiKey"
					label="API Key"
					placeholder="Project API Key"
					value={apiKey}
					fullWidth
					disabled
					variant="standard"
				/>

				<If condition={isUserInternal}>
					<FormControlLabel
						css={styles.checkboxLabel}
						checked={!!settingsStatus?.applicationHasValidLoginFunction}
						onChange={handleChangeApplicationHasValidLoginFunction}
						disabled={isHasValidLoginFunctionUpdateLoading}
						control={<Checkbox />}
						label="Application has valid login function"
					/>
				</If>
			</Box>

			<Box css={styles.container}>
				<Typography variant="h4" gutterBottom>
					Members
				</Typography>

				<If condition={isAuthenticatedUserAllowedToUpdate}>
					<AddNewUserForm />
				</If>

				<MembersTable
					applicationUsers={applicationUsers}
					styles={styles}
					isAuthenticatedUserAllowedToUpdate={
						isAuthenticatedUserAllowedToUpdate
					}
				/>
			</Box>
		</Box>
	);
};

const MembersTable = ({
	styles,
	applicationUsers,
	isAuthenticatedUserAllowedToUpdate,
}) => {
	return (
		<TableContainer component={Box} css={styles.tableContainer}>
			<Table size="small">
				<TableBody>
					{applicationUsers.map(({ role, invitationStatus, id, user }) => {
						return (
							<MemberTableRow
								key={id}
								id={id}
								role={role}
								isAuthenticatedUserAllowedToUpdate={
									isAuthenticatedUserAllowedToUpdate
								}
								email={user.email}
								invitationStatus={invitationStatus}
							/>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
