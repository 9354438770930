import { Box, Typography } from '@mui/material';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import 'rrweb-player/dist/style.css';
import { useSessionReplayer } from './hooks';
import { useSessionReplayerStyles } from './styles';

export const SessionReplayer = ({ recordingUrl }: { recordingUrl: string }) => {
	const { containerNodeRef, isReplayerLoadingRecording } = useSessionReplayer({
		recordingUrl,
	});

	const styles = useSessionReplayerStyles();

	return (
		<>
			<If condition={isReplayerLoadingRecording}>
				<Box css={styles.loadingContainer}>
					<LoadingCircularProgress>
						<Typography variant="h4">Loading recording...</Typography>
					</LoadingCircularProgress>
				</Box>
			</If>

			<div ref={containerNodeRef} id="rrweb-replayer"></div>
		</>
	);
};
