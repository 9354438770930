import api from '@src/api';
import {
	transformAPIUserStoriesFiles,
	transformStoryFileAgsToAPI,
} from '@src/modules/user-stories/api/transformers';
import { UserStoryFile } from '@src/modules/user-stories/types';

export const userStoriesFilesAPI = api
	.enhanceEndpoints({
		addTagTypes: ['UserStoriesFiles'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getSignedUrl: builder.mutation<
				{ url: string; fileId: string },
				{ id: string; tag: string; file: File }
			>({
				query: ({ id, ...body }) => {
					return {
						url: `user-stories/files/get-signed-url`,
						body: transformStoryFileAgsToAPI(body),
						method: 'POST',
					};
				},
			}),
			getDownloadSignedUrl: builder.mutation<
				{ url: string },
				{ userStoryFileId: string }
			>({
				query: (body) => {
					return {
						url: `user-stories/files/get-download-signed-url`,
						body,
						method: 'POST',
					};
				},
			}),
			completeFileUploading: builder.mutation<boolean, { fileId: string }>({
				query: ({ fileId }) => {
					return {
						url: `user-stories/files/${fileId}/complete-file-uploading`,
						method: 'POST',
					};
				},
			}),
			getAllUserStoriesFiles: builder.query<UserStoryFile[], { id: string }>({
				query: ({ id }) => {
					return {
						url: `user-stories/files/for-application`,
						//url: `user-stories/${id}/files`,
					};
				},
				transformResponse: transformAPIUserStoriesFiles,
				providesTags: ['UserStoriesFiles'],
			}),
		}),
	});

export const {
	useGetSignedUrlMutation,
	useGetDownloadSignedUrlMutation,
	useGetAllUserStoriesFilesQuery,
	useCompleteFileUploadingMutation,
} = userStoriesFilesAPI;
