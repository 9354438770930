import { Analytics } from './analytics';
import { useAnalytics } from './analytics/hooks';
import { CRM } from './crm';
import { useCRM } from './crm/hooks';
import { useLogger } from './logger/hooks';
import Session from './session';
import { useUploadsManager } from '@src/services/files-uploading-manager/hooks';

export const initializeServices = () => {
	Analytics.init();
	CRM.init();
	Session.init();
};
export const useProviders = () => {
	useAnalytics();
	useLogger();
	useCRM();
	useUploadsManager();
};
