import { Box, ListItem, ListItemButton, Skeleton } from '@mui/material';
import { useTestRunListItemSkeletonStyles } from '@src/modules/test-runs/components/TestRunListItem/styles';

export function TestRunListItemSkeleton() {
	const styles = useTestRunListItemSkeletonStyles();

	return (
		<ListItem disablePadding divider>
			<ListItemButton css={styles.container}>
				<Box css={[styles.column, styles.gap2, styles.fullWidth]}>
					<Box css={[styles.row, styles.fullWidth, styles.gap2]}>
						<Skeleton variant="circular" width={20} height={20} />

						<Skeleton variant="rectangular" width={'65%'} height={20} />
					</Box>

					<Box
						css={[
							styles.row,
							styles.gap2,
							styles.fullWidth,
							styles.marginLeftMin,
						]}
					>
						<Skeleton variant="circular" width={15} height={15} />

						<Skeleton
							css={styles.marginLeftMin}
							variant="rectangular"
							width={60}
							height={10}
						/>

						<Skeleton variant="rectangular" width={50} height={10} />

						<Skeleton variant="rectangular" width={40} height={10} />

						<Box css={[styles.row, styles.gap1]}>
							<Skeleton variant="circular" width={15} height={15} />

							<Skeleton variant="rectangular" width={65} height={15} />
						</Box>
					</Box>
				</Box>

				<Box css={styles.row}>
					<Skeleton variant="rectangular" width={20} height={10} />
					<Skeleton variant="rectangular" width={20} height={10} />
				</Box>
			</ListItemButton>
		</ListItem>
	);
}
