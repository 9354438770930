import { css } from '@emotion/react';
import { useMemo } from 'react';

export const useStoriesHeaderStyles = () => {
	return useMemo(() => {
		const newStoryButton = css({ marginLeft: 'auto !important' });
		const filterSelect = css({ width: '200px' });

		return {
			newStoryButton,
			filterSelect,
		};
	}, []);
};
