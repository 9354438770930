import { useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { getReactElementSize } from './helpers/getReactElementSize';
import { useDebounce } from '@src/common/hooks/useDebounce';
import rrwebPlayer from 'rrweb-player';
import RrwebJSONStreamParser from './helpers/RrwebJSONStreamParser';
import {
	destroyRrwebReplayer,
	initRrwebReplayer,
	initRrwebJSONStreamParser,
} from './helpers/init';

export const useSessionReplayer = ({
	recordingUrl,
}: {
	recordingUrl?: string;
}) => {
	const [events, setEvents] = useState([]);
	const [isReplayerLoadingRecording, setIsReplayerLoadingRecording] =
		useState(false);

	const previousSessionRecordingUrl = usePrevious(recordingUrl);

	const containerNodeRef = useRef(null);
	const rrwebReplayerRef = useRef<null | rrwebPlayer>(null);
	const jsonStreamParserRef = useRef<null | RrwebJSONStreamParser>(null);

	// Refs for tracking setup status
	const isEventsCastSetupRef = useRef(false);

	//reset effect after session recording change
	useEffect(() => {
		if (
			previousSessionRecordingUrl &&
			recordingUrl &&
			previousSessionRecordingUrl !== recordingUrl
		) {
			// Reset state and refs
			destroyRrwebReplayer(rrwebReplayerRef.current);
			jsonStreamParserRef.current?.close();

			setEvents([]);
			rrwebReplayerRef.current = null;
			jsonStreamParserRef.current = null;
			isEventsCastSetupRef.current = false;
		}
	}, [recordingUrl, previousSessionRecordingUrl]);

	//setup stream parser effect
	useEffect(() => {
		if (!!jsonStreamParserRef.current || !recordingUrl) {
			return;
		}

		const setupStreamParser = async () => {
			setIsReplayerLoadingRecording(true);
			jsonStreamParserRef.current = initRrwebJSONStreamParser(
				recordingUrl,
				setEvents
			);

			await jsonStreamParserRef.current.updateProgress(0);
		};

		setupStreamParser();
	}, [recordingUrl]);

	//debounced callback to notify the parser to set new events
	//in case that cast-event listener processes every millisecond
	const debouncedShowEvent = useDebounce(async (event) => {
		await jsonStreamParserRef.current?.updateProgress(event?.timestamp);
	}, 500);

	//setup replayer effect
	useEffect(() => {
		//initiane replayer, should be at least 2 events
		if (!rrwebReplayerRef.current && events.length) {
			//get replayer height and width according to the parent dimentions
			const replayerSize = getReactElementSize(
				containerNodeRef.current.parentNode
			);

			rrwebReplayerRef.current = initRrwebReplayer(events, 0, replayerSize);
			setIsReplayerLoadingRecording(false);
		}

		//effect for uploading new events
		if (
			!!rrwebReplayerRef.current &&
			!isEventsCastSetupRef.current &&
			!!jsonStreamParserRef.current
		) {
			isEventsCastSetupRef.current = true;

			//events timestamp listener to upload new events
			rrwebReplayerRef.current
				.getReplayer()
				.on('event-cast', async (event: unknown) => {
					debouncedShowEvent(event);
				});
		}
	}, [events, debouncedShowEvent]);

	//effect for adding new uploaded events to the events local state
	useEffect(() => {
		//should run only if replayer is initialized
		if (!events.length || !rrwebReplayerRef.current) {
			return;
		}

		events.forEach((event) => {
			rrwebReplayerRef.current.addEvent(event);
		});
	}, [events]);

	return {
		containerNodeRef,
		isReplayerLoadingRecording,
	};
};
