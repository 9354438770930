import { List } from '@mui/material';
import { TestRunListItemSkeleton } from '@src/modules/test-runs/components/TestRunListItem/TestRunListItemSkeleton';

export function TestRunListSkeleton({ count = 3 }: { count?: number }) {
	return (
		<List>
			{Array.from({ length: count }).map((_, index) => (
				<TestRunListItemSkeleton key={index} />
			))}
		</List>
	);
}
