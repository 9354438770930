import { Chip, CircularProgress, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConditionalWrapper } from '@src/common/components/ConditionalWrapper';
import { useChipFile } from '@src/modules/user-stories/components/StoryForm/components/FilesViewAndUpload/ChipFile/hooks';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { StyledMenu } from '@src/common/components/StyledMenu/StyledMenu';

export const ChipFile = ({ onEdit, onDelete, value: activeElement, index }) => {
	const {
		color,
		handleEdit,
		handleClick,
		handleDelete,
		anchorElement,
		handleClose,
		isUploading,
		isError,
		isDownloadLoading,
		handleGetDownloadSignedUrl,
	} = useChipFile({
		onEdit,
		onDelete,
		value: activeElement,
		index,
	});

	return (
		<If condition={!!activeElement}>
			<ConditionalWrapper
				wrapper={(children) => (
					<Tooltip
						title="File uploading failed, delete it and upload again"
						placement="top"
					>
						{children}
					</Tooltip>
				)}
				condition={isError}
			>
				<Chip
					color={color}
					label={activeElement.tag}
					onDelete={handleClick}
					deleteIcon={
						// disable eslint to allow conditional rendering for deleteIcon in Chip, If or Choose conditions do not work
						/* eslint-disable-next-line*/
						isUploading ? <CircularProgress size={20} /> : <MoreVertIcon />
					}
				/>
			</ConditionalWrapper>

			<StyledMenu
				anchorEl={anchorElement}
				open={!!anchorElement}
				onClose={handleClose}
			>
				<If condition={!isError}>
					<MenuItem onClick={handleEdit} disableRipple>
						<EditIcon />
						Edit
					</MenuItem>
				</If>

				<If condition={activeElement.id}>
					<MenuItem onClick={handleGetDownloadSignedUrl} disableRipple>
						<LoadingIcon condition={isDownloadLoading}>
							<CloudDownloadIcon fontSize="large" />
						</LoadingIcon>
						Download
					</MenuItem>
				</If>

				<MenuItem onClick={() => handleDelete(index)} disableRipple>
					<DeleteIcon />
					Remove
				</MenuItem>
			</StyledMenu>
		</If>
	);
};

const LoadingIcon = ({ condition, children }) => {
	return (
		<Choose>
			<When condition={condition}>
				<CircularProgress size={20} />
			</When>
			<Otherwise>{children}</Otherwise>
		</Choose>
	);
};
