import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useAllApplicationsPageStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			padding: theme.spacing(4),
		});

		return {
			section,
		};
	}, [theme]);
};
