import { AnyAction, Reducer } from 'redux';

/**
 * Intercepts high level store actions and acts upon them
 * @param applicationReducer
 */
const applyInterceptors =
	(applicationReducer: Reducer) => (state, action: AnyAction) => {
		// switch (action.type) {
		// }

		return applicationReducer(state, action);
	};

export default applyInterceptors;
