import { useUpdateUserStoryMutation } from '@src/modules/user-stories/api';
import { useCallback, useMemo } from 'react';
import { UserStory } from '@src/modules/user-stories/types';
import {
	ALL_FLOWS_COLLECTION_ID,
	UserStoriesCollection,
} from '@src/modules/collections/service';

export const useChangeCollectionModal = ({
	story,
	onClose,
	collections,
}: {
	story: UserStory;
	onClose: () => void;
	collections: UserStoriesCollection[];
}) => {
	const [updateStory] = useUpdateUserStoryMutation();

	const filteredCollections = useMemo(() => {
		return collections.filter(
			(collection) => collection.id !== ALL_FLOWS_COLLECTION_ID
		);
	}, [collections]);

	const handleChangeCollection = useCallback(
		async (collectionId: string) => {
			if (!story) {
				return;
			}

			await updateStory({
				id: story.id,
				update: { collectionId },
			});

			onClose();
		},
		[updateStory, story, onClose]
	);

	return {
		filteredCollections,
		handleChangeCollection,
	};
};
