import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useErrorEnabledTextFieldStyle } from '@src/common/styles/useErrorEnabledTextFieldStyle';
import { useMemo } from 'react';

const useAthenticatePageStyles = () => {
	const theme = useTheme();

	const styles = useMemo(() => {
		const greetingBlock = css({
			marginBottom: theme.spacing(2),
		});

		const buttonsBlock = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			padding: `${theme.spacing(2)} 0`,
			width: '100%',
		});

		const authenticateWithCodeBlock = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(1.5),
		});

		const greetingText = css({
			textAlign: 'center',
		});

		const form = css`
			display: flex;
			flex-flow: column;
			gap: ${theme.spacing(2)};
			margin: auto;
			min-width: 300px;
		`;

		const input = css`
			.Mui-error.MuiFormHelperText-root {
				position: static;
			}
		`;

		const codeInput = css`
			${input};
			margin-bottom: 8px;
		`;

		return {
			greetingBlock,
			form,
			codeInput,
			greetingText,
			buttonsBlock,
			authenticateWithCodeBlock,
		};
	}, [theme]);

	const { FormHelperTextPropsSX, errorEnabledField } =
		useErrorEnabledTextFieldStyle();

	return {
		FormHelperTextPropsSX,
		errorEnabledField,
		...styles,
	};
};

export default useAthenticatePageStyles;
