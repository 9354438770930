import { Control, SetValueConfig, useFieldArray } from 'react-hook-form';
import { EnvironmentSettingsFormValues } from '@src/modules/settings/components/ApplicationEnvironmentSettings/types';
import { useDeleteEnvironmentUserMutation } from '@src/modules/settings/api';
import { useCallback, useEffect, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { APIApplicationEnvironmentUser } from '@src/modules/settings/api/types';

export const useEnvironmentUsers = ({
	control,
	environmentUsersWatcher,
	environmentUsersFromResponse = [],
	setValue,
}: {
	control: Control<EnvironmentSettingsFormValues, unknown>;
	environmentUsersWatcher: APIApplicationEnvironmentUser[];
	environmentUsersFromResponse?: APIApplicationEnvironmentUser[];
	setValue: (name: string, value: unknown, options?: SetValueConfig) => void;
}) => {
	const [
		deleteEnvUser,
		{ status: envUserDeleteStatus, isLoading: isDeleteLoading },
	] = useDeleteEnvironmentUserMutation();

	// env user index to delete from form
	const [environmentUserIndexToDelete, setEnvironmentUserIndexToDelete] =
		useState(NaN);

	// environmentUsersWatcher[].id and fields[].id are not the same
	// useFieldArray generates new uuid ids for each field
	const {
		fields,
		append,
		remove: removeDynamicField,
	} = useFieldArray<EnvironmentSettingsFormValues>({
		control,
		name: 'environmentUsers' as never,
	});

	const handleDeleteEnvironmentUser = useCallback(
		async (index: number) => {
			if (!environmentUsersWatcher.at(index) || isDeleteLoading) {
				return;
			}

			// if id is empty then this user is not saved
			if (environmentUsersWatcher[index].id === '') {
				removeDynamicField(index);
			} else {
				setEnvironmentUserIndexToDelete(index);
				await deleteEnvUser({ id: environmentUsersWatcher[index].id });
			}
		},
		[
			removeDynamicField,
			deleteEnvUser,
			environmentUsersWatcher,
			setEnvironmentUserIndexToDelete,
			isDeleteLoading,
		]
	);

	const getIsUserDefault = useCallback(
		(index: number): boolean => {
			const actualEnvUserFormValues = environmentUsersWatcher.at(index);
			const defaultEnvUser = environmentUsersFromResponse.find(
				({ isDefault }) => isDefault
			);

			// return true to not showing the delete button
			if (!(actualEnvUserFormValues && defaultEnvUser)) {
				return true;
			}

			// return true if default user from db or the form values enc user default
			return (
				actualEnvUserFormValues.isDefault ||
				actualEnvUserFormValues.id === defaultEnvUser.id
			);
		},
		[environmentUsersWatcher, environmentUsersFromResponse]
	);

	// remove checked is default if another user was marked as default
	const handleChangeIsEnvUserDefault = useCallback(
		(checked, changedEnvUserIndex) => {
			const defaultUserIndex = environmentUsersWatcher.findIndex(
				({ isDefault }) => isDefault
			);

			// if isDefault active don't allow to uncheck it
			if (!checked && defaultUserIndex === changedEnvUserIndex) {
				return;
			}

			// uncheck previous active isDefault field
			setValue(`environmentUsers.${defaultUserIndex}.isDefault`, false, {
				shouldTouch: true,
				shouldDirty: true,
			});

			// check isDefault
			setValue(`environmentUsers.${changedEnvUserIndex}.isDefault`, checked, {
				shouldTouch: true,
				shouldDirty: true,
			});
		},
		[environmentUsersWatcher, setValue]
	);

	// do not allow to add empty user field if previous is empty
	const handleAddNewEnvUser = useCallback(() => {
		if (
			environmentUsersWatcher.some(({ username, password, role }) => {
				return !username && !password && !role;
			})
		) {
			return;
		}

		// add new dynamic field to form
		append({ role: '', username: '', id: '', isDefault: false, password: '' });
	}, [append, environmentUsersWatcher]);

	// remove env user on delete fulfilled
	useEffect(() => {
		if (
			envUserDeleteStatus === QueryStatus.fulfilled &&
			!Number.isNaN(environmentUserIndexToDelete)
		) {
			removeDynamicField(environmentUserIndexToDelete);
			setEnvironmentUserIndexToDelete(NaN);
		}
	}, [
		envUserDeleteStatus,
		removeDynamicField,
		environmentUserIndexToDelete,
		setEnvironmentUserIndexToDelete,
	]);

	return {
		fields,
		handleAddNewEnvUser,
		handleChangeIsEnvUserDefault,
		handleDeleteEnvironmentUser,
		getIsUserDefault,
	};
};
