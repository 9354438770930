import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useRedirectToFlowStyles = () => {
	return useMemo(() => {
		const loadingContainer = css({
			display: 'flex',
			justifyContent: 'center',
			height: '100%',
			width: '100%',
		});

		return {
			loadingContainer,
		};
	}, []);
};
