import { Dialog, DialogContent } from '@mui/material';
import { StoryForm } from '@src/modules/user-stories/components/StoryForm/StoryForm';
import { FC, useState } from 'react';
import { UserStory } from '@src/modules/user-stories/types';
import { UserStoriesCollection } from '@src/modules/collections/service';
import { useStoryFormDialogStyles } from '@src/modules/user-stories/components/StoryForm/styles';

type Props = {
	activeStory: UserStory;
	onCreateSuccess?: (story: UserStory) => void;
	handleCloseStory: () => void;
	onAddNewCollection: () => void;
	isCollectionModalOpened: boolean;
	collections: UserStoriesCollection[];
};

export const StoryFormDialog: FC<Props> = ({
	activeStory,
	onCreateSuccess,
	handleCloseStory,
	onAddNewCollection,
	isCollectionModalOpened,
	collections,
}) => {
	const [closeStoryModal, setCloseStoryModal] = useState<() => void>(
		() => handleCloseStory
	);

	const styles = useStoryFormDialogStyles();

	return (
		<Dialog
			keepMounted={isCollectionModalOpened}
			open={!!activeStory && !isCollectionModalOpened}
			onClose={closeStoryModal}
			maxWidth="lg"
		>
			<If condition={!!activeStory}>
				<DialogContent css={styles.dialogContent}>
					<StoryForm
						collections={collections}
						onAddNewCollection={onAddNewCollection}
						setCloseStoryModal={setCloseStoryModal}
						handleCloseStory={handleCloseStory}
						story={activeStory}
						onCreateSuccess={onCreateSuccess}
					/>
				</DialogContent>
			</If>
		</Dialog>
	);
};
