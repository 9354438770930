import axios from 'axios';

export type UploadsManagerHandlers = {
	addUploadingFile: (fileId: string) => void;
	removeUploadingFile: (fileId: string) => void;
	getFileUploadingState: (fileId: string) => {
		isUploading: boolean;
		isCompleted: boolean;
	};
};

// create file and url

export class UploadsManager {
	private handlers: UploadsManagerHandlers;

	setHandlers(handlers: UploadsManagerHandlers) {
		this.handlers = handlers;
	}

	getFileUploadingState(fileId: string) {
		return this.handlers.getFileUploadingState(fileId);
	}

	async upload(getSignedUrl, file: File, completeFileUploading) {
		// get signed url and id of file record
		const response: { data: { url: string; fileId: string } } =
			await getSignedUrl();

		if ('data' in response) {
			// if success getSignedUrl -> update loading state
			this.handlers.addUploadingFile(response.data.fileId);
			// start uploading file with uploadSignedUrl
			this.uploadFileWithSignedUrl(file, response.data, completeFileUploading);

			return response.data.fileId;
		}
	}

	private uploadFileWithSignedUrl(
		file: File,
		{ url, fileId }: { url: string; fileId: string },
		completeFileUploading
	) {
		axios
			.put(url, file, {
				headers: {
					// content type should be the same that was used for signed url generating
					'Content-Type': file.type,
				},
			})
			// remove file id from loading state only in successful case
			.then(() => {
				this.handlers.removeUploadingFile(fileId);
				completeFileUploading(fileId);
			});
	}
}

const FilesUploadingManager = new UploadsManager();
export default FilesUploadingManager;
