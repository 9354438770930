import { array, boolean, object, string } from 'yup';
import { EnvironmentSettingsFormValues } from '../types';
import { URL_REGEX } from '@src/common/constants/regex';
import { requiredEnvironmentUserSchema } from '@src/modules/settings/components/ApplicationEnvironmentSettings/validation/requiredEnvironmentUserSchema';
import Config from '@src/config';
import {
	ENV_BASE_URL_REGEX,
	ENV_BASE_URL_REGEX_WITH_LOCALHOST,
} from './constants';

const getEnvironmentUrlRegex = () => {
	if (Config.env === 'local') {
		return ENV_BASE_URL_REGEX_WITH_LOCALHOST;
	}
	return ENV_BASE_URL_REGEX;
};

const urlRegex = getEnvironmentUrlRegex();

export const stepTwoValidationSchema = object<EnvironmentSettingsFormValues>({
	environmentBaseURL: string()
		.required('Start URL is required')
		.matches(urlRegex, 'Seems like an invalid URL'),

	loginURL: string()
		.required('Login URL is required')
		.test(
			'is-relative-or-environment-based',
			'Login URL should be a valid URL or a relative path (starting with a "/")',
			function (value) {
				return (
					urlRegex.test(value) ||
					(value.startsWith('/') && urlRegex.test(`http://example.com${value}`))
				);
			}
		),

	environmentUsers: array()
		.of(requiredEnvironmentUserSchema)
		.test(
			'isDefaultUnique',
			'Only one user can be default',
			function (value, context) {
				// Count the number of items with isDefault true
				return value.filter((item) => item.isDefault).length === 1;
			}
		),

	preTestHook: string()
		.nullable()
		.test(
			'preTestHook-validity',
			'Seems like an invalid URL',
			function (value) {
				if (!value) {
					return true;
				}
				return URL_REGEX.test(value);
			}
		),

	name: string().required('Name is required'),

	isDefault: boolean().required('This field is required'),
});
