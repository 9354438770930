import Config from '@src/config';

const sanitizedResponseJsonEditorSchema = {
	$schema: 'http://json-schema.org/draft-06/schema#',
	type: 'object',
	properties: {
		thought: {
			type: 'string',
		},
		action: {
			$ref: '#/definitions/APINextAction',
		},
		actionHiddenOverride: {
			type: 'object',
			properties: {
				type: {
					$ref: '#/definitions/APINextActionType',
				},
				checksumid: {
					type: 'number',
				},
				value: {
					type: 'string',
				},
				failed: {
					type: 'boolean',
				},
				position: {
					type: 'object',
					properties: {
						x: {
							type: 'number',
						},
						y: {
							type: 'number',
						},
					},
					required: ['x', 'y'],
				},
				selector: {
					type: 'string',
				},
			},
			required: ['type'],
		},
		reason: {
			type: 'string',
		},
		insight: {
			type: 'string',
		},
		plan: {
			type: 'string',
		},
		mistake: {
			type: 'string',
		},
		reference: {
			type: 'number',
		},
		usedInsight: {
			type: 'string',
		},
	},
	required: ['thought'],
	definitions: {
		APINextActionType: {
			type: 'string',
			enum: [
				'click',
				'fill',
				'hover',
				'reset',
				'keystroke',
				'navigate',
				'save',
				'fail',
				'selectoption',
				'finish',
				'dismiss_modal',
			],
		},
		APINextAction: {
			type: 'object',
			properties: {
				type: {
					$ref: '#/definitions/APINextActionType',
				},
				checksumid: {
					type: 'number',
				},
				value: {
					type: 'string',
				},
				failed: {
					type: 'boolean',
				},
				position: {
					type: 'object',
					properties: {
						x: {
							type: 'number',
						},
						y: {
							type: 'number',
						},
					},
					required: ['x', 'y'],
				},
			},
			required: ['type'],
		},
	},
};

const rawResponseJsonEditorSchema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	type: 'object',
	properties: {
		thought: { type: 'string' },
		thoughts: {
			type: 'object',
			properties: {
				html_changes: { type: 'string' },
				previous_step_mistake: { type: 'string' },
				text: { type: 'string' },
				reasoning: { type: 'string' },
				plan: { type: 'string' },
				criticism: { type: 'string' },
				speak: { type: 'string' },
			},
			required: [
				'html_changes',
				'previous_step_mistake',
				'text',
				'reasoning',
				'plan',
				'criticism',
				'speak',
			],
			additionalProperties: false,
		},
		command: {
			type: 'object',
			properties: {
				name: {
					type: 'string',
					enum: ['action', 'save', 'dismiss_modal', 'finish'],
				},
				args: {
					type: 'object',
					properties: {
						type: {
							type: 'string',
							enum: [
								'click',
								'fill',
								'hover',
								'reset',
								'keystroke',
								'navigate',
								'save',
								'fail',
								'selectoption',
								'finish',
								'dismiss_modal',
							],
						},
						fill_value: { type: 'string' },
						checksumid: { type: 'string' },
						description: { type: 'string' },
						key: { type: 'string' },
						value: { type: 'string' },
						full_test_plan: { type: 'string' },
					},
					required: ['type'],
					additionalProperties: false,
				},
			},
			required: ['name', 'args'],
			additionalProperties: false,
		},
	},
	required: ['thought', 'thoughts', 'command'],
	additionalProperties: false,
};

export const jsonEditorSchema =
	Config.labeling.responseKey === 'rawLLMResponseJSON'
		? rawResponseJsonEditorSchema
		: sanitizedResponseJsonEditorSchema;
