import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useStyles } from './styles';
import { useCodeBlock } from '@src/common/components/CodeBlock/hooks';

export const CodeBlock = ({ children, language }) => {
	const { copyStatus, handleCopy, codeString } = useCodeBlock({
		children,
		language,
	});

	const styles = useStyles();

	return (
		<Box css={styles.codeBlock}>
			<Tooltip title={copyStatus || 'Copy code'} placement="top">
				<IconButton
					onClick={handleCopy}
					size="small" // Use the 'small' size prop for a smaller button
					className="copy-button"
					css={styles.tooltip}
				>
					<ContentCopyIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<code className={`language-${language}`}>{codeString}</code>
		</Box>
	);
};
