import { useYupValidationResolver } from '@src/common/hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { validationSchema } from '@src/modules/application/components/ApplicationForm/validation';
import { FormValues } from '@src/modules/application/components/ApplicationForm/types';
import { useCreateApplicationMutation } from '@src/modules/application/api';
import { useCallback, useEffect } from 'react';
import { useAuthenticationInfo } from '@src/common/state/volatile/hooks';
import { getDefaultValuesWithPrefilledDomain } from '@src/modules/application/components/ApplicationForm/helpers';
import { useAppDispatch } from '@src/store/hooks';
import { setPersistentActiveApplicationId } from '@src/common/state/persistent/slice';
import { setActiveApplication } from '@src/common/state/volatile/slice';
import { invalidateAllTags } from '@src/api';
import { ApplicationUserRoles } from '../../types';

export const useApplicationForm = (
	{ afterCreateApplicationCallback } = {
		afterCreateApplicationCallback: () => {},
	}
) => {
	const authInfo = useAuthenticationInfo();
	const dispatch = useAppDispatch();

	const [
		createApplication,
		{ isLoading: isCreateLoading, isSuccess, data: createdApplication },
	] = useCreateApplicationMutation();

	// form management
	const resolver = useYupValidationResolver(validationSchema);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		values: getDefaultValuesWithPrefilledDomain(authInfo?.user),
		reValidateMode: 'onChange',
		resolver,
	});

	const handleCreateApplication = useCallback(
		async (values: FormValues) => {
			createApplication(values);
		},
		[createApplication]
	);

	/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	const onSubmit = useCallback(handleSubmit(handleCreateApplication), [
		handleSubmit,
		handleCreateApplication,
	]);

	// invalidate all queries on new application create
	useEffect(() => {
		if (isSuccess && !!createdApplication) {
			dispatch(setPersistentActiveApplicationId(createdApplication.id));
			dispatch(
				setActiveApplication({
					...createdApplication,
					role: ApplicationUserRoles.Admin,
				})
			);
			dispatch(invalidateAllTags());

			afterCreateApplicationCallback();
		}
	}, [isSuccess, afterCreateApplicationCallback, dispatch, createdApplication]);

	return {
		register,
		errors,
		isCreateLoading,
		onSubmit,
	};
};
