import { Check, FilterAltOff, HourglassEmpty } from '@mui/icons-material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { INBOX_COLLECTION_ID } from '@src/modules/collections/service';

export const useStoriesEmptyState = ({
	hasFilteredOutStories,
}: {
	hasFilteredOutStories: boolean;
}) => {
	const { collectionId } = useParams<'collectionId'>();

	const { Icon, title, subtitle } = useMemo(() => {
		const activeCollectionIsInbox = collectionId === INBOX_COLLECTION_ID;

		if (hasFilteredOutStories) {
			return {
				Icon: FilterAltOff,
				title: 'No test flows match the active search/filters',
				subtitle: `Change the search term and/or filters to see more test flows`,
			};
		} else {
			const title = activeCollectionIsInbox
				? 'Inbox is empty'
				: 'There are no test flows for this collection.';

			return {
				Icon: activeCollectionIsInbox ? Check : HourglassEmpty,
				title,
			};
		}
	}, [hasFilteredOutStories, collectionId]);

	return { Icon, title, subtitle };
};
