import { SettingsStatus } from '@src/modules/settings/types';
import {
	STEPS,
	StepsValues,
} from '@src/modules/settings/pages/wizard/constants';

//get formatted steps with current settings status
export const getSteps = (settingsStatus: SettingsStatus) => {
	if (!settingsStatus) {
		return STEPS;
	}

	const stepsMap = {
		[StepsValues.SETUP_SDK]: settingsStatus.sdkSuccessfullyIntegrated,
		[StepsValues.SET_GIT_INTEGRATION]: settingsStatus.isGitIntegrated,
		[StepsValues.CONFIGURE_ENVIRONMENT]:
			settingsStatus.hasValidEnvironmentSettings,
	};

	const actualSteps = STEPS.filter((step) => {
		return !(step.name === StepsValues.SETUP_SDK && !settingsStatus.sdkKey);
	});

	//return steps with completed field according to the stepsMap condition
	return actualSteps.map((step, index) => ({
		...step,
		completed: stepsMap[step.name],
		value: index,
	}));
};

//get first incomplete step
export const getFirstInactiveStep = (steps) => {
	const firstInactiveStep = steps.find(({ completed }) => !completed);

	return firstInactiveStep ?? steps[0];
};
