import { useLabelTestGenerationLogStepMutation } from '@src/modules/test-generation-labeling/api';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import {
	generateTestGenerationStepHref,
	getNextStep,
} from '@src/modules/test-generation-labeling/pages/TestGenerationLabeling/helpers';
import { toast } from 'react-toastify';
import {
	TestGenerationLogStep,
	TestGenerationStepLabelStatus,
} from '@src/modules/test-generation-labeling/types';
import {
	APINextActionResponse,
	LabelingResponseKey,
	LabelingResult,
} from '@src/modules/test-generation-labeling/api/types';
import Config from '@src/config';

const LLMResponseKey = Config.labeling.responseKey;

export const useUpdateTestGenerationLabelingStep = ({
	entries,
	LLMResponse,
	sessionId,
	stepId,
	updateEditorKey,
	activeTestGenerationLogStep,
	activeStoredStep,
}: {
	entries?: TestGenerationLogStep[];
	LLMResponse?: APINextActionResponse | {};
	sessionId?: string;
	stepId?: string;
	updateEditorKey: () => void;
	activeTestGenerationLogStep: TestGenerationLogStep;
	activeStoredStep: TestGenerationLogStep;
}) => {
	const [
		updateTestGenerationLogStep,
		{
			isLoading: isSubmitting,
			isSuccess,
			reset: resetUpdateTestGenerationState,
		},
	] = useLabelTestGenerationLogStepMutation();

	const navigate = useNavigate();

	const handleUpdateTestGenerationLogStep = useCallback(
		async (labelingResult: LabelingResult) => {
			if (!stepId || !sessionId || !LLMResponse) {
				return;
			}

			//set llmResponse that might be changed in a JSON editor field
			let response = LLMResponse;

			let shouldSendCorrection = labelingResult === LabelingResult.Incorrect;

			if (
				!!activeStoredStep &&
				labelingResult === LabelingResult.Correct &&
				activeStoredStep.status === TestGenerationStepLabelStatus.Labeled
			) {
				//if active step is labeled already, but it was correct initially then
				//the original llmResponse should be sent to BE
				response = activeTestGenerationLogStep[LLMResponseKey];
				shouldSendCorrection = true;
			}

			updateTestGenerationLogStep({
				stepId,
				sessionId,
				labelingResult,
				correction: shouldSendCorrection
					? ({ [LLMResponseKey]: response } as Record<
							LabelingResponseKey,
							APINextActionResponse
					  >)
					: undefined,
			});
		},
		[
			LLMResponse,
			sessionId,
			stepId,
			updateTestGenerationLogStep,
			activeTestGenerationLogStep,
			activeStoredStep,
		]
	);

	useEffect(() => {
		if (isSuccess) {
			const nextStep = getNextStep(entries, stepId);

			updateEditorKey();

			//update json if was corrected either go to next step
			if (nextStep) {
				navigate(
					generateTestGenerationStepHref(sessionId, nextStep.index.toString())
				);
			}

			//scroll top on labeling success
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});

			//toastId to prevent duplications of same message
			toast('Labeling has been successful', { toastId: 'label-step' });
			//reset isLoading and isSuccess of update request state
			resetUpdateTestGenerationState();
		}
	}, [
		entries,
		isSuccess,
		navigate,
		sessionId,
		stepId,
		updateEditorKey,
		resetUpdateTestGenerationState,
	]);

	return {
		handleUpdateTestGenerationLogStep,
		isSubmitting,
	};
};
