import { useEffect, useRef, useState } from 'react';
import { getFirstInactiveStep, getSteps } from './helpers';
import { useWizardStepperButtons } from '@src/modules/settings/pages/wizard/hooks/useGetStepperNavigationButtonsContent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApplicationRoutes } from '@src/app/router/routes';
import { useGetSettingsStatus } from '@src/modules/settings/hooks/useGetSettingsStatus';
import { StepsValues } from '@src/modules/settings/pages/wizard/constants';
import { SettingsStatus } from '@src/modules/settings/types';
import { SubmitRefFields } from '../types';
import { usePersistentAppId } from '@src/common/state/persistent/hooks';
import { usePrevious } from 'react-use';

export const useWizard = () => {
	const { settingsStatus, isLoading: isSettingsStatusLoading } =
		useGetSettingsStatus();

	const [queryParams, setQueryParams] = useSearchParams();

	const [steps, setSteps] = useState(getSteps(settingsStatus));
	const [activeStep, setActiveStep] = useState(
		convertQueryParamToStepValue(settingsStatus, queryParams.get('tab'))
	);

	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	const navigate = useNavigate();

	const isInitialActiveStepSetRef = useRef(false);

	//ref to submit the children form
	const submitFormRef = useRef<SubmitRefFields>({
		submitForm: async () => {},
		getIsError: () => false,
		isDirty: false,
	});

	//stepper buttons callbacks
	const { onNext, onSkip, onBack, nextButtonText } = useWizardStepperButtons({
		activeStep,
		steps,
		submitFormRef,
		setActiveStep,
	});

	//update active step and all steps on settings status updated
	useEffect(() => {
		const actualSteps = getSteps(settingsStatus);

		const isAllStepsCompleted = actualSteps.every(({ completed }) => completed);

		if (isAllStepsCompleted) {
			navigate(ApplicationRoutes.userStoriesExplorer.absolutePath, {
				replace: true,
			});

			return;
		}

		setSteps(actualSteps);
	}, [settingsStatus, navigate]);

	const persistentApplicationId = usePersistentAppId();
	const prevAppId = usePrevious(persistentApplicationId);

	//update active step and all steps on settings status updated
	useEffect(() => {
		const isAppChanged = !!prevAppId && prevAppId !== persistentApplicationId;
		const isInitialSetup =
			!!settingsStatus || !isInitialActiveStepSetRef.current;

		if (isInitialSetup || !isAppChanged) {
			setActiveStep(
				convertQueryParamToStepValue(settingsStatus, queryParams.get('tab'))
			);

			isInitialActiveStepSetRef.current = true;
		}
	}, [settingsStatus, queryParams, persistentApplicationId, prevAppId]);

	useEffect(() => {
		if (queryParams.has('tab')) {
			queryParams.delete('tab');
			setQueryParams(queryParams);
		}

		// allow empty array to remove tab query on page mount
		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	}, []);

	return {
		settingsStatus,
		steps,
		isSettingsStatusLoading,
		sdkKey: settingsStatus?.sdkKey,
		activeStep,
		isButtonLoading: isFormSubmitting,
		setIsFormSubmitting,
		submitFormRef,
		nextButtonText,
		onNext,
		onBack,
		onSkip,
	};
};

const getStepNameFromQueryParam = (query: string) => {
	switch (query) {
		case 'sdk': {
			return StepsValues.SETUP_SDK;
		}
		case 'env': {
			return StepsValues.CONFIGURE_ENVIRONMENT;
		}
		case 'github':
		case 'gitlab':
		case 'git': {
			return StepsValues.SET_GIT_INTEGRATION;
		}
		default: {
			return undefined;
		}
	}
};

const convertQueryParamToStepValue = (
	settingsStatus: SettingsStatus,
	query?: string
) => {
	const actualSteps = getSteps(settingsStatus);

	if (!query) {
		return getFirstInactiveStep(actualSteps);
	}

	const stepName = getStepNameFromQueryParam(query);

	return (
		actualSteps.find(({ name }) => name === stepName) ??
		getFirstInactiveStep(actualSteps)
	);
};
