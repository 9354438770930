import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useCollectionsListDropdownStyles = () => {
	const theme = useTheme();

	const styles = useMemo(() => {
		const createNewCollectionMenuItem = css({
			color: theme.colors.primary.button,

			':hover': {
				backgroundColor: theme.colors.background.hoverButtonLight,
			},

			//TODO: create file for transitions and export as constants
			transition:
				'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
		});

		return {
			createNewCollectionMenuItem,
		};
	}, [theme]);

	const getShouldHideMenuItemStyles = useCallback((condition = false) => {
		return css({
			display: condition ? 'none' : 'block',
		});
	}, []);

	return {
		styles,
		getShouldHideMenuItemStyles,
	};
};
