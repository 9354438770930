import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { OpenedConfirmationModalVariant } from '../constants';
import { generateConfirmationDialogContent } from '@src/modules/collections/hooks/helpers/collectionFormHelpers';

export const useCollectionFormConfirmationModalContent = ({
	onClose,
	isDirty,
	modalVariantRef,
}: {
	onClose: () => void;
	isDirty: boolean;
	modalVariantRef: MutableRefObject<OpenedConfirmationModalVariant>;
}) => {
	const [isConfirmationAlertDialogOpened, setIsConfirmationAlertDialogOpened] =
		useState(false);

	//close modal if form is not dirty, otherwise open confirmation modal
	const handleCloseModal = useCallback(() => {
		if (isDirty) {
			modalVariantRef.current = OpenedConfirmationModalVariant.UNSAVED_CHANGES;

			setIsConfirmationAlertDialogOpened(true);
		} else {
			onClose();
		}
	}, [isDirty, onClose, modalVariantRef]);

	//open confirmation modal on delete collection click
	const handleOpenConfirmationModalOnDeleteCollection = useCallback(() => {
		modalVariantRef.current = OpenedConfirmationModalVariant.DELETE;

		setIsConfirmationAlertDialogOpened(true);
	}, [modalVariantRef]);

	//confirmation modal handlers
	const { /*deleteHandlers,*/ unsavedChangesHandlers } =
		useGetConfirmationModalHandlers({
			isConfirmationAlertDialogOpened,
			setIsConfirmationAlertDialogOpened,
			onClose,
		});

	//confirmation modal content according to the modal variant
	const confirmationAlertContent = useMemo(() => {
		switch (modalVariantRef.current) {
			/*	case OpenedConfirmationModalVariant.DELETE: {
				return deleteHandlers;
			}*/

			case OpenedConfirmationModalVariant.UNSAVED_CHANGES: {
				return unsavedChangesHandlers;
			}

			case OpenedConfirmationModalVariant.DEFAULT:
				return null;
		}
	}, [unsavedChangesHandlers, modalVariantRef]);

	return {
		confirmationAlertContent: confirmationAlertContent
			? {
					...confirmationAlertContent,
					open: isConfirmationAlertDialogOpened,
			  }
			: null,
		handleCloseModal,
		handleOpenConfirmationModalOnDeleteCollection,
		modalVariantRef,
	};
};

const useGetConfirmationModalHandlers = ({
	isConfirmationAlertDialogOpened,
	setIsConfirmationAlertDialogOpened,
	onClose,
}) => {
	const defaultOnSuccess = useCallback(() => {
		setIsConfirmationAlertDialogOpened(false);
	}, [setIsConfirmationAlertDialogOpened]);

	//on continue
	const defaultOnCancel = useCallback(() => {
		setIsConfirmationAlertDialogOpened(false);
		onClose();
	}, [onClose, setIsConfirmationAlertDialogOpened]);

	/*//confirmation modal handlers to delete collection
	const deleteHandlers = useMemo(() => {
		const onCancel = () => {
			onDeleteCollection();
			defaultOnCancel();
		};

		return generateConfirmationDialogContent(
			OpenedConfirmationModalVariant.DELETE,
			{ onSuccess: defaultOnSuccess, onCancel }
		);
	}, [isConfirmationAlertDialogOpened]);*/

	//confirmation modal handlers when unsaved changes
	const unsavedChangesHandlers = useMemo(() => {
		return generateConfirmationDialogContent(
			OpenedConfirmationModalVariant.UNSAVED_CHANGES,
			{ onSuccess: defaultOnSuccess, onCancel: defaultOnCancel }
		);
		/* eslint-disable-next-line  react-hooks/exhaustive-deps  */
	}, [isConfirmationAlertDialogOpened]);

	return {
		/*deleteHandlers,*/
		unsavedChangesHandlers,
	};
};
