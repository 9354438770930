import {
	APITestSuiteRun,
	APITestSuiteRunStatus,
} from '@src/modules/test-runs/api/types';
import { TestSuiteRun, TestSuiteRunStatus } from '../types';

export const transformAPIGetTestRunsResponse = (
	apiTestRuns: APITestSuiteRun[]
): TestSuiteRun[] => {
	const testRuns = apiTestRuns.map(transformAPITestRun);
	return testRuns.sort((a, b) => {
		return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
	});
};

const transformAPITestRun = (apiTestRun: APITestSuiteRun): TestSuiteRun => {
	return {
		...apiTestRun,
		results: {
			passed: apiTestRun.passed,
			failed: apiTestRun.failed,
			healed: apiTestRun.healed,
		},
		tags: apiTestRun.tags ?? [],
		duration: apiTestRun.endedAt
			? new Date(apiTestRun.endedAt).getTime() -
			  new Date(apiTestRun.startedAt).getTime()
			: undefined,
		status: transformTestRunStatus(apiTestRun.status),
	};
};

const transformTestRunStatus = (apiStatus: APITestSuiteRunStatus) => {
	switch (apiStatus) {
		case APITestSuiteRunStatus.Running:
			return TestSuiteRunStatus.Running;
		case APITestSuiteRunStatus.Passed:
			return TestSuiteRunStatus.Passed;
		// case APITestSuiteRunStatus.Healed:
		// 	return TestSuiteRunStatus.Healed;
		case APITestSuiteRunStatus.Failed:
			return TestSuiteRunStatus.Failed;
		// case APITestSuiteRunStatus.Cancelled:
		// 	return TestSuiteRunStatus.Cancelled;
	}
};
