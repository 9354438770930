import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { css } from '@emotion/react';

export const useSettingsStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			padding: `${theme.spacing(4)} 0`,
		});

		const tabsContainer = css({
			borderBottom: `1px solid ${theme.colors.divider}`,
		});

		return { section, tabsContainer };
	}, [theme]);
};

export const useCustomTabPanelStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const tabPanel = css({
			marginTop: theme.spacing(2),
		});

		return {
			tabPanel,
		};
	}, [theme]);
};
