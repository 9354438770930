export const ZERO_DECIMAL = 0.0;
export const ZERO = 0;

export const ONE_MS_DECIMAL = 0.001;
export const ONE_MS = 1;

export const ONE_HUNDRED_MS_DECIMAL = 0.1;
export const ONE_HUNDRED_MS = 100;

export const MS_PER_SECOND = 1000;

export const SECONDS_PER_MINUTE = 60;
export const MS_PER_MINUTE = MS_PER_SECOND * SECONDS_PER_MINUTE;

export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
export const MS_PER_HOUR = MS_PER_MINUTE * MINUTES_PER_HOUR;

export const HOURS_PER_DAY = 24;
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * HOURS_PER_DAY;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * HOURS_PER_DAY;
export const MS_PER_DAY = MS_PER_HOUR * HOURS_PER_DAY;

export const DAYS_PER_WEEK = 7;
export const HOURS_PER_WEEK = HOURS_PER_DAY * DAYS_PER_WEEK;
export const MINUTES_PER_WEEK = MINUTES_PER_DAY * DAYS_PER_WEEK;
export const SECONDS_PER_WEEK = SECONDS_PER_DAY * DAYS_PER_WEEK;
export const MS_PER_WEEK = MS_PER_DAY * DAYS_PER_WEEK;

export const QUARTERS_PER_YEAR = 4;
export const MONTHS_PER_QUARTER = 3;
export const MONTHS_PER_YEAR = MONTHS_PER_QUARTER * QUARTERS_PER_YEAR;

export const DAYS_PER_NON_LEAP_YEAR = 365;
export const DAYS_PER_LEAP_YEAR = 366;

export const MIN_DATE_ISO_STRING = '1970-01-01T00:00:00.000Z';
