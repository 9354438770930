import { AddNewAppModalMode } from '@src/modules/application/components/ApplicationForm/types';

export const MostUsedMailProviders = [
	'gmail.com',
	'outlook.com',
	'hotmail.com',
	'live.com',
	'yahoo.com',
	'icloud.com',
	'aol.com',
	'mail.ru',
	'yandex.com',
	'protonmail.com',
	'zoho.com',
	'gmx.com',
	'gmx.net',
];

export const titleByModeMap: Record<AddNewAppModalMode, string> = {
	[AddNewAppModalMode.AddApplication]: 'To start, give your application a name',
	[AddNewAppModalMode.FirstApplication]: 'Create new project',
};
