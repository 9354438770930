import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VolatileSliceState } from './types';
import { AuthenticationResult } from '@src/modules/authentication/service';
import { AvailableApplication } from '@src/modules/application/service';

const initialAuthenticationInfo: VolatileSliceState['authenticationInfo'] = {
	authenticated: false,
	user: undefined,
	isLoggingIn: false,
};

const initialApplicationsInfo: VolatileSliceState['applicationsInfo'] = {
	activeApplication: null,
};

const initialState: VolatileSliceState = {
	authenticationInfo: initialAuthenticationInfo,
	applicationsInfo: initialApplicationsInfo,
	layoutOptions: {},
	layoutOptionsStack: [],
	redirectToAfterAuthentication: null,
};

export const slice = createSlice({
	name: 'volatile',
	initialState,
	reducers: {
		setLayoutOptions: (state, action) => {
			// NOTE: in this situation each route will define the full layout options without considering options from parent route
			// if we'd like that behavior, we should use a deep merge of the current layoutOptions
			state.layoutOptionsStack.push(state.layoutOptions);
			state.layoutOptions = action.payload;
		},
		popLayoutOptions: (state) => {
			if (!state.layoutOptionsStack.length) {
				return;
			}
			state.layoutOptions = state.layoutOptionsStack.pop();
		},
		setAuthenticationStatus: (
			state,
			action: PayloadAction<AuthenticationResult>
		) => {
			if (!action.payload) {
				state.authenticationInfo = initialAuthenticationInfo;
			} else {
				state.authenticationInfo = {
					...state.authenticationInfo,
					user: action.payload.user,
					authenticated: true,
				};
			}
		},
		setIsLoggingIn: (state, action: PayloadAction<boolean>) => {
			state.authenticationInfo.isLoggingIn = action.payload;
		},
		setActiveApplication: (
			state,
			action: PayloadAction<AvailableApplication>
		) => {
			state.applicationsInfo.activeApplication = action.payload;
		},

		setRedirectToAfterAuthentication: (
			state,
			action: PayloadAction<string>
		) => {
			state.redirectToAfterAuthentication = action.payload;
		},
	},
});

export const {
	setAuthenticationStatus,
	setLayoutOptions,
	popLayoutOptions,
	setActiveApplication,
	setIsLoggingIn,
	setRedirectToAfterAuthentication,
} = slice.actions;

export default slice.reducer;
