import { useEffect, useRef, useState } from 'react';

export const usePageSelectionWidth = () => {
	const formRef = useRef<HTMLElement>();
	const [formWidth, setFormWidth] = useState(undefined);
	useEffect(() => {
		if (formRef.current) {
			setFormWidth(formRef.current.offsetWidth);
		}
	}, [setFormWidth]);
	return { formRef, formWidth };
};
