import { Typography } from '@mui/material';
import { SetupSdkCommandsCodeBlock } from './components/SetupSdkCommandsCodeBlock/SetupSDKCommandsCodeBlock';

export const SetupSDK = ({ sdkKey }: { sdkKey?: string }) => {
	return (
		<>
			<Typography component="p" gutterBottom>
				To generate a full and autonomous end-to-end testing suite, our AI
				models learn how real users use your application. Learning from real
				users allow our models to detect edge cases and execute even complex
				test flows.
			</Typography>

			<Typography component="p" gutterBottom>
				The best results are achieved by deploying our SDK to production. We
				provide strong and automatic privacy controls that allow removing all
				Personal Information. See our privacy documentation
			</Typography>

			<SetupSdkCommandsCodeBlock sdkKey={sdkKey} />
		</>
	);
};
