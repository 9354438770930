import { AnyObject, TestContext, ValidationError } from 'yup';
import { ApplicationEnvironmentUser } from '@src/modules/settings/types';

// create yup error -> function just to reduce lones of code
export const customCreateError = (
	context: TestContext<AnyObject>,
	message: string
): ValidationError => {
	return context.createError({
		path: context.path,
		message,
	});
};

// get all env user with same role
export const getEnvUsersWithSameFieldRole = (
	envUsers: ApplicationEnvironmentUser[],
	envUserRole: string
): ApplicationEnvironmentUser[] => {
	return envUsers.filter((envUser) => {
		return envUser.role === envUserRole;
	});
};

// get all env user with same password and username pair
const getEnvUsersWithSamePasswordAndUserName = (
	envUsers: ApplicationEnvironmentUser[],
	value: { username: string; password: string }
): ApplicationEnvironmentUser[] => {
	return envUsers.filter((envUser) => {
		return (
			envUser.username.trim() === value.username.trim() &&
			envUser.password.trim() === value.password.trim()
		);
	});
};

// get validation logic for username and password fields
export const getUsernameAndPasswordValidation = (
	context: TestContext<AnyObject>,
	value: string,
	requiredMessage: string
): ValidationError | boolean => {
	// from[0].value contains parent object (array item), and from[1].value contains the whole schema
	const allEnvUsers = context.from[1].value.environmentUsers;

	const usersWithSamePasswordAndName = getEnvUsersWithSamePasswordAndUserName(
		allEnvUsers,
		context.parent
	);

	// if password and username pairs not unique mark as not unique corresponding fields
	if (usersWithSamePasswordAndName.length > 1 && !!value) {
		return customCreateError(
			context,
			'Users must have unique pairs of username/email and password'
		);
	}

	// if no value and env users more than one mark field as required
	if (allEnvUsers.length > 1 && !value) {
		return customCreateError(context, requiredMessage);
	}

	return true;
};
