import { css } from '@emotion/react';
import { Box, Container, useTheme } from '@mui/material';
import { FC, PropsWithChildren, useMemo } from 'react';

export const AuthenticatePageLayoutContainer: FC<
	PropsWithChildren<{ isLoading?: boolean }>
> = ({ children, isLoading = false }) => {
	const styles = useAuthenticatePageLayoutContainerStyles({ isLoading });

	return (
		<Container css={styles.container}>
			<Box css={styles.mainBlock}>{children}</Box>
		</Container>
	);
};

const useAuthenticatePageLayoutContainerStyles = ({
	isLoading,
}: {
	isLoading: boolean;
}) => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			maxWidth: theme.breakpoints.only('sm'),
		});

		const mainBlock = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			alignItems: 'center',
			border: isLoading
				? undefined
				: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(2),
			padding: `${theme.spacing(5)} ${theme.spacing(8)}`,
			maxWidth: '430px',
		});

		return {
			container,
			mainBlock,
		};
	}, [theme, isLoading]);
};
