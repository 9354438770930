import { createSlice } from '@reduxjs/toolkit';

export type UploadsManagerSliceState = {
	uploadingFiles: string[];
	completedFiles: string[];
};

const initialState: UploadsManagerSliceState = {
	uploadingFiles: [],
	completedFiles: [],
};

export const slice = createSlice({
	name: 'uploads',
	initialState,
	reducers: {
		addUploadingFile: (state, action) => {
			state.uploadingFiles.push(action.payload);
		},
		removeUploadingFile: (state, action) => {
			state.uploadingFiles = state.uploadingFiles.filter(
				(fileId) => action.payload !== fileId
			);
			state.completedFiles.push(action.payload);
		},
	},
});

// Action creators are generated for each case reducer function
export const { removeUploadingFile, addUploadingFile } = slice.actions;

export default slice.reducer;
