import {
	AddNewAppModalMode,
	ApplicationForm,
} from '@src/modules/application/service';
import { AuthenticatePageLayoutContainer } from '@src/modules/authentication/components/AuthenticatePageLayoutContainer';
import { useCreateNewApplicationPage } from '@src/modules/authentication/pages/createNewApplication/hooks';

export const CreateNewApplicationPage = () => {
	const { afterCreateCallback, isLoginLoading } = useCreateNewApplicationPage();

	return (
		<AuthenticatePageLayoutContainer isLoading={isLoginLoading}>
			<ApplicationForm
				mode={AddNewAppModalMode.FirstApplication}
				afterCreateApplicationCallback={afterCreateCallback}
			/>
		</AuthenticatePageLayoutContainer>
	);
};
