import {
	formatEditedStoryAction,
	getUserStoriesGenerationsNotes,
} from '@src/modules/user-stories/components/StoryFormActionsBar/helpers';
import { useCallback, useMemo, useState } from 'react';
import { UserStory, UserStoryStatus } from '@src/modules/user-stories/types';
import { getStoryCreationNoteParts } from '@src/modules/user-stories/helpers';

const SHOW_MORE_STEP = 3;

export const useTestGenerationsActivityLog = ({
	story,
}: {
	story: UserStory;
}) => {
	const [generationNotesAmount, setShowMoreCount] = useState(SHOW_MORE_STEP);

	const { shouldDisplayShowMoreOption, formattedGenerationsNotes } =
		useMemo(() => {
			// get all notes with created at as first
			let allNotes = getUserStoriesGenerationsNotes(story.generations);

			if (story.createdAt) {
				const [status, date] = getStoryCreationNoteParts(story);
				const createdAtNote = `${date}, ${status}`;

				allNotes = [...allNotes, createdAtNote];
			}

			// attach edited note to the top of the list
			if (!!story.editedAt && story.status === UserStoryStatus.Edited) {
				allNotes = [formatEditedStoryAction(story?.editedAt), ...allNotes];
			}

			// get notes by parts
			const formattedGenerationsNotes = allNotes.slice(
				0,
				generationNotesAmount
			);

			return {
				shouldDisplayShowMoreOption: generationNotesAmount < allNotes.length,
				formattedGenerationsNotes,
			};
		}, [story, generationNotesAmount]);

	// increase amount of displayed generation notes
	const onShowMore = useCallback(() => {
		setShowMoreCount((prevAmount) => prevAmount + SHOW_MORE_STEP);
	}, []);

	return {
		formattedGenerationsNotes,
		shouldDisplayShowMoreOption,
		onShowMore,
	};
};
