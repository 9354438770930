import { NOOP } from './functions';

export const intervalUntil = (
	condition: Function,
	onConditionSatisfied: Function,
	interval: number,
	{ checkOnStart = false, limit = undefined, setClearTimeout = NOOP } = {}
) => {
	// initialize tries
	let tries = 0;
	// prepare set timeout method, including passing back the clear timeout function
	const setCheckTimeout = () => {
		const timeout = setTimeout(check, interval);
		setClearTimeout(() => clearTimeout(timeout));
	};
	// perform check, if condition not satisfied and not passed limit, try again, otherwise stop
	// if condition satisfied, callback and stop
	const check = () => {
		const conditionResult = condition();
		if (!conditionResult) {
			++tries;
			if (limit && limit === tries) {
				return;
			}
			setCheckTimeout();
		}
		try {
			onConditionSatisfied(conditionResult);
		} catch (e) {}
	};

	// start
	// if check on start, check immediately
	// otherwise set initial timeout

	if (checkOnStart) {
		check();
		return;
	}

	setCheckTimeout();
};

export const sleepWithTimer = (ts: number, value?: unknown) => {
	let timerId;
	const sleepPromise = new Promise((resolve) => {
		timerId = setTimeout(() => {
			resolve(value);
		}, ts);
	});
	return { sleepPromise, timerId };
};

export const sleep = (ts: number) => {
	return new Promise((resolve) => setTimeout(resolve, ts));
};
