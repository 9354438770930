import { useCallback, useMemo, useState } from 'react';
import { useLogoutMutation } from '@src/modules/authentication/service';
import { useProjectSettings } from '@src/app/hooks/useProjectSettings';
import { useAuthenticationInfo } from '@src/common/state/volatile/hooks';

export const useDefaultAppBarMenu = () => {
	const { applications, activeApplication, onSelectApplication } =
		useProjectSettings();

	const authInfo = useAuthenticationInfo();

	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

	//open menu handler
	const handleClick = useCallback(
		(event) => {
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement]
	);

	//close menu
	const handleClose = useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);

	//select another application and close menu
	const handleSelectApplication = useCallback(
		(applicationId: string) => {
			onSelectApplication(applicationId);
			handleClose();
		},
		[onSelectApplication, handleClose]
	);

	const [logout] = useLogoutMutation();
	const onLogout = useCallback(() => logout({}), [logout]);

	const shouldDisplayAddNewProject = useMemo(() => {
		return !!authInfo?.user?.internal;
	}, [authInfo]);

	return {
		activeApplication,
		applications,
		anchorElement,
		open: !!anchorElement,
		handleClick,
		handleClose,
		onLogout,
		handleSelectApplication,
		shouldDisplayAddNewProject,
	};
};

export const useSelectedApplication = ({
	onSelectApplication,
	application,
	activeApplicationName,
}) => {
	const isSelected = useMemo(() => {
		return activeApplicationName === application.name;
	}, [activeApplicationName, application.name]);

	const handleSelectApplication = useCallback(() => {
		onSelectApplication(application.id);
	}, [onSelectApplication, application.id]);

	return {
		handleSelectApplication,
		isSelected,
	};
};
