import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

export const useSetupSdkCommandsCodeBlockStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(1),
			padding: theme.spacing(5),
		});

		const stepTitle = css({
			paddingTop: theme.spacing(3),
		});

		const stepSubTitle = css({
			color: theme.colors.text.secondary,
			paddingTop: theme.spacing(3),
		});

		return {
			container,
			stepTitle,
			stepSubTitle,
		};
	}, [theme]);
};
