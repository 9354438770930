import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const useSideNavigationMenu = () => {
	const { pathname } = useLocation();

	return useCallback(
		(routePath: string) => {
			return pathname.includes(routePath);
		},
		[pathname]
	);
};

export default useSideNavigationMenu;
