import { ListSubheader, MenuItem, Typography } from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import { Controller } from 'react-hook-form';
import {
	getEnvironmentUserMenuItemLabel,
	transformEnvsSettingsToMenuItemsList,
} from './helpers';
import PersonIcon from '@mui/icons-material/Person';
import { useStoryEnvironmentUsersSelectionStyles } from './styles';

export const StoryEnvironmentUserSelection = ({
	control,
	register,
	errors,
	environmentsSettings,
	disabled,
}) => {
	const styles = useStoryEnvironmentUsersSelectionStyles();
	return (
		<Controller
			name="environmentUserId"
			control={control}
			render={({ field }) => (
				<FormTextField
					css={styles.formTextField}
					select
					name="environmentUserId"
					label="Environment and User"
					disabled={disabled}
					errors={errors}
					value={field.value}
					inputProps={register?.('environmentUserId')}
				>
					{transformEnvsSettingsToMenuItemsList(environmentsSettings).map(
						(option) => {
							return (
								<Choose>
									<When condition={option.name}>
										<ListSubheader key={option.id}>{option.name}</ListSubheader>
									</When>

									<Otherwise>
										<MenuItem
											key={option.id}
											value={option.id}
											css={styles.menuItem}
										>
											<PersonIcon fontSize="small" className="user-env-icon" />
											<Typography component="span" className="user-env-name">
												{option.envName},{' '}
											</Typography>
											{getEnvironmentUserMenuItemLabel(option)}
										</MenuItem>
									</Otherwise>
								</Choose>
							);
						}
					)}
				</FormTextField>
			)}
		/>
	);
};
