import React from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import GitHubIcon from '@mui/icons-material/GitHub';
import { SerializedStyles } from '@emotion/react';
import { useInitialScreenStyles } from '@src/modules/settings/components/GitSettings/components/InitialGithubAppScreen/styles';

export const InitialScreen = ({
	isLoadingInfo,
	styles: propsStyles,
	installationURL,
	handleRedirect,
}: {
	isLoadingInfo: boolean;
	styles: SerializedStyles;
	installationURL: string;
	handleRedirect: () => void;
}) => {
	const styles = useInitialScreenStyles(propsStyles);

	return (
		<>
			<Typography>
				To enable Pull Requests for generated and auto-healed tests, please
				install Checksum's Github Application
			</Typography>

			<Box css={styles.buttonContainer}>
				<Button
					startIcon={<GitHubIcon />}
					variant="contained"
					size="large"
					disabled={isLoadingInfo}
					href={installationURL}
					target="_blank"
					onClick={handleRedirect}
				>
					Install Github Application
				</Button>
			</Box>
		</>
	);
};
