import Config from '@src/config';

export function transformReportHTML(htmlContent, testRunId) {
	// Step 1: Remove <!DOCTYPE html>
	let modifiedHtml = htmlContent.replace(/<!DOCTYPE html>/i, '');

	// Step 2: Remove <html> and </html> tags
	modifiedHtml = modifiedHtml.replace(/<\/?html.*?>/gi, '');

	// Step 3: Remove all <meta> tags
	modifiedHtml = modifiedHtml.replace(/<meta[^>]*>/gi, '');

	// Step 4: Remove <title> tag
	modifiedHtml = modifiedHtml.replace(/<title>.*?<\/title>/gi, '');

	// Step 5: Remove <body> and </body> tags but keep nested content
	modifiedHtml = modifiedHtml.replace(/<\/?body.*?>/gi, '');

	// Step 6: Remove <head> and </head> tags but keep nested content
	modifiedHtml = modifiedHtml.replace(/<\/?head.*?>/gi, '');

	// Step 7: Change div with id "root" to id "report-root"
	modifiedHtml = modifiedHtml.replace(
		/<div id=['"]root['"]>/i,
		'<div id="report-root">'
	);

	// Step 8: Update react code to target "report-root" instead of "root"
	modifiedHtml = modifiedHtml.replace(
		`document.querySelector("#root")`,
		`document.querySelector("#report-root")`
	);

	// Step 9: When url is constructed from hash part consider the query string
	modifiedHtml = modifiedHtml.replace(
		/new URLSearchParams\(window\.location\.hash\.slice\(1\)\)/g,
		"new URLSearchParams(window.location.hash.slice(window.location.hash.indexOf('?') + 1))"
	);

	// Step 10: Update all relative href starting with hash to include or /test-runs/:id path
	modifiedHtml = modifiedHtml.replace(/#\?/g, `#/test-runs/${testRunId}?`);

	// Step 11:
	// Since the report is processed to add product assets base path,
	// in order to make the report work in local environment, we need to replace it
	if (Config.env === 'local') {
		modifiedHtml = modifiedHtml.replace(
			/https:\/\/api.checksum.ai/g,
			Config.api.baseUrl
		);
	}

	// Step 12: Replace window.onload with window.onreportload to be called after the report fragment is rendered
	modifiedHtml = modifiedHtml.replace(`window.onload`, `window.onreportload`);

	// Step 13: Remove the script that sets window.playwrightReportBase64
	modifiedHtml = modifiedHtml.replace(
		/window.playwrightReportBase64 = "(.*)";/g,
		''
	);

	// Step 14: Wrap everything within a div with id "report-root-wrapper"
	modifiedHtml = `<div id="report-root-wrapper">\n${modifiedHtml.trim()}\n</div>`;

	return modifiedHtml;
}
