import {
	Button,
	Divider,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from 'react';
import { useStyles } from './styles';
import { TextContentHeader } from './components/TextContent';
import { LoadingButton } from '@mui/lab';
import { LoadingBlockOverlay } from '@src/common/components/LoadingBlockOverlay/LoadingBlockOverlay';
import { useApplicationEnvironmentSettings } from './hooks/useApplicationEnvironmentSettings';
import { FormTextField } from '@src/common/components/FormTextField';
import { DynamicEnvironmentUserRow } from '@src/modules/settings/components/ApplicationEnvironmentSettings/components/DynamicEnvironmentUserRow/DynamicEnvironmentUserRow';
import * as React from 'react';
import { Add } from '@mui/icons-material';
import { IsDefaultEnvironmentCheckbox } from '@src/modules/settings/components/ApplicationEnvironmentSettings/components/IsDefaultEnvironmentCheckbox/IsDefaultEnvironmentCheckbox';
import { ConfirmationDialog } from '@src/common/components/ConfirmationDialog/ConfirmationDialog';

// will take as param env name and change values accordingly
export const ApplicationEnvironmentSettings = forwardRef<
	{
		// envName: string
		submitForm: () => Promise<void>;
		getIsError: () => boolean;
	},
	{
		setIsFormSubmitting?: Dispatch<SetStateAction<boolean>>;
		externalControl?: boolean;
		disableMultipleEnvironments?: boolean;
	}
>(
	(
		{
			setIsFormSubmitting,
			externalControl = false,
			disableMultipleEnvironments = false,
		},
		submitFormRef: ForwardedRef<unknown>
	) => {
		const {
			register,
			errors,
			isSaving,
			submitForm,
			isLoadingSettingsProfile,
			isDirty,
			fields,
			handleAddNewEnvUser,
			handleChangeIsEnvUserDefault,
			control,
			isSingleUser,
			handleDeleteEnvironmentUser,
			getIsUserDefault,
			environments,
			activeEnvironmentId,
			onChangeEnvironment,
			activeEnvironmentSettings,
			isCreatingNewEnvSettings,
			onCreateNewEnvironment,
			onDeleteEnvironment,
			shouldDisplayDeleteButton,
			cancelCreateNewEnvironment,
			confirmationModalContent,
			isConfirmationModalOpened,
		} = useApplicationEnvironmentSettings({
			submitFormRef,
			setIsFormSubmitting,
		});

		const { styles, getDynamicFieldRowStyles } = useStyles({ isSingleUser });

		return (
			<>
				<TextContentHeader
					disableMultipleEnvironments={disableMultipleEnvironments}
				/>

				{/* do not show add new env or envs list if on disableMultipleEnvironments */}
				<If condition={!disableMultipleEnvironments}>
					<Box css={styles.row}>
						<FormTextField
							css={styles.envsSelect}
							select
							label="Environments"
							placeholder="Select environment"
							name="environmentUserId"
							value={isCreatingNewEnvSettings ? 'create' : activeEnvironmentId}
							onChange={onChangeEnvironment}
						>
							{environments.map(({ id, name }) => (
								<MenuItem key={id} value={id}>
									{name}
								</MenuItem>
							))}

							<If condition={isCreatingNewEnvSettings}>
								<MenuItem key={'create'} value={'create'}>
									NEW ENVIRONMENT
								</MenuItem>
							</If>
						</FormTextField>

						<Box css={styles.buttonsContainerEnd}>
							<If condition={shouldDisplayDeleteButton}>
								<Button
									variant="outlined"
									size="large"
									onClick={onDeleteEnvironment}
								>
									Delete
								</Button>
							</If>

							<If condition={isCreatingNewEnvSettings}>
								<Button
									variant="contained"
									size="large"
									onClick={cancelCreateNewEnvironment}
								>
									Cancel
								</Button>
							</If>

							<If condition={!isCreatingNewEnvSettings}>
								<Button
									variant="contained"
									size="large"
									onClick={onCreateNewEnvironment}
								>
									Create new environment
								</Button>
							</If>
						</Box>
					</Box>

					<Divider />
				</If>

				<form>
					<fieldset css={styles.fieldset} disabled={isLoadingSettingsProfile}>
						<LoadingBlockOverlay
							condition={isLoadingSettingsProfile}
							offsetY={-90}
							size={20}
						/>

						<Box css={styles.container}>
							<Box css={styles.row}>
								<FormTextField
									label="Environment name"
									placeholder='e.g. "Production", "Staging", "QA"'
									required
									variant="outlined"
									register={register}
									errors={errors}
									name="name"
									hasErrorMessage
								/>

								<IsDefaultEnvironmentCheckbox
									isCreatingNewEnvSettings={isCreatingNewEnvSettings}
									styles={styles.checkbox}
									control={control}
									activeEnvironmentSettings={activeEnvironmentSettings}
									environments={environments}
								/>
							</Box>

							<Box css={styles.row}>
								<FormTextField
									label="Environment URL"
									info="A base URL that our agent will navigate to access your application"
									required
									variant="outlined"
									register={register}
									errors={errors}
									name="environmentBaseURL"
									hasErrorMessage
								/>

								<FormTextField
									label="Login URL"
									info="The URL or path to use for logging in to the application. Values starting with '/' will be relative to the environment URL"
									required
									variant="outlined"
									register={register}
									errors={errors}
									name="loginURL"
									hasErrorMessage
								/>
							</Box>

							<Typography variant="h3" gutterBottom css={styles.testUsersTitle}>
								Test Users
								<Button onClick={handleAddNewEnvUser} variant="contained">
									<Add fontSize="small" />
									Add a new user
								</Button>
							</Typography>

							{fields.map((envUser, index) => {
								return (
									<DynamicEnvironmentUserRow
										key={envUser.id}
										index={index}
										styles={styles}
										errors={errors}
										envUser={envUser}
										control={control}
										register={register}
										isSingleUser={isSingleUser}
										isEnvUserDefault={getIsUserDefault(index)}
										getDynamicFieldRowStyles={getDynamicFieldRowStyles}
										onChangeEnvironmentUserIsDefault={
											handleChangeIsEnvUserDefault
										}
										onDeleteEnvironmentUser={handleDeleteEnvironmentUser}
									/>
								);
							})}

							{/* TODO: switch to FormTextField */}
							<If condition={false}>
								<TextField
									InputLabelProps={{ shrink: true }}
									label="Pre-test Hook"
									variant="outlined"
									fullWidth
									error={!!errors?.preTestHook}
									{...register('preTestHook')}
									helperText={
										<>
											A webhook our agent will call before every test run
											<Tooltip
												title={
													<>
														The purpose of this webhook is to set up a
														consistent seed to the provided user, so tests will
														always run on the same context. <br />
														<br />
														You can return a json with "username" and "password"
														keys to be used, otherwise we'll use the username
														and password defined above
													</>
												}
											>
												<InfoOutlinedIcon
													fontSize="small"
													css={styles.tooltipIcon}
												/>
											</Tooltip>
										</>
									}
								/>
							</If>

							<Box css={styles.buttonsContainer}>
								<If condition={!externalControl}>
									<LoadingButton
										loading={isSaving}
										variant="outlined"
										size="large"
										disabled={!isDirty}
										onClick={submitForm}
									>
										Save
									</LoadingButton>
								</If>
							</Box>
						</Box>
					</fieldset>
				</form>

				<If condition={!!confirmationModalContent}>
					<ConfirmationDialog
						open={isConfirmationModalOpened}
						{...confirmationModalContent}
					/>
				</If>
			</>
		);
	}
);
