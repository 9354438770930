import { Navigate } from 'react-router-dom';
import React from 'react';
import { RouteRestrictions } from './types';
import {
	ApplicationsInfo,
	AuthenticationInfo,
} from '@src/common/state/volatile/types';

export const checkIfRouteConditionIsSatisfied = (condition) => {
	if (condition === undefined) {
		return true;
	}

	switch (typeof condition) {
		case 'function':
			return condition();
		default:
			return condition;
	}
};

export const checkIfRouteRestrictionsAreSatisfied = (
	routeRestrictions: RouteRestrictions,
	authenticationInfo: AuthenticationInfo,
	applicationsInfo: ApplicationsInfo
) => {
	const isNoApplicationsWhenRequired =
		routeRestrictions.requireApplications &&
		authenticationInfo.authenticated &&
		!applicationsInfo.activeApplication;

	const isUserNotInternalWhenRequired =
		routeRestrictions.internal && !authenticationInfo.user?.internal;

	if (isNoApplicationsWhenRequired && isUserNotInternalWhenRequired) {
		return false;
	}

	// when the user is authenticated but has no active application and the route is not internal, restriction is not satisfied
	if (
		routeRestrictions.authenticated &&
		authenticationInfo.authenticated &&
		!routeRestrictions.internal &&
		!applicationsInfo.activeApplication
	) {
		return false;
	}

	/*// check role
	if (routeRestrictions.role) {
		// in case of array, check if role is included
		if (Array.isArray(routeRestrictions.role)) {
			if (!routeRestrictions.role.includes(authenticationInfo.role)) {
				return false;
			}
		}
		// in case of single value, make sure restricting role is not greater than user role
		else {
			if (isRoleGreaterThan(routeRestrictions.role, authenticationInfo.role)) {
				return false;
			}
		}
	}*/

	// check permissions
	const userPermissions = authenticationInfo?.user?.internalPermissions || [];

	if (routeRestrictions.permissions) {
		if (!userPermissions.includes(routeRestrictions.permissions)) {
			return false;
		}
	}
	if (routeRestrictions.permissionsAtLeastOneOf) {
		if (
			!routeRestrictions.permissionsAtLeastOneOf.some((permission) =>
				userPermissions.includes(permission)
			)
		) {
			return false;
		}
	}
	if (routeRestrictions.permissionsAll) {
		if (
			!routeRestrictions.permissionsAll.every((permission) =>
				userPermissions.includes(permission)
			)
		) {
			return false;
		}
	}

	return true;
};

export const DefaultAppRouteAuthenticationRedirect = (
	<Navigate replace to="/authenticate" />
);
export const DefaultAppRouteUnavailableRedirect = <Navigate replace to="/" />;

export const getComponentOrChildrenComponent = (component, children) => {
	if (component) {
		return component;
	}
	return () => <>{children}</>;
};
