export const getReactElementSize = (node: HTMLElement) => {
	if (!node) {
		return {
			width: 600,
			height: 350,
		};
	}

	const containerStyles = window.getComputedStyle(node);

	//process only the padding that is the same on each side that replayer has
	const paddingLeft = containerStyles
		.getPropertyValue('padding-left')
		.replace('px', '');
	const paddingRight = containerStyles
		.getPropertyValue('padding-right')
		.replace('px', '');

	const paddingLeftNumberValue = !!paddingLeft.length ? Number(paddingLeft) : 0;
	const paddingRightNumberValue = !!paddingRight.length
		? Number(paddingRight)
		: 0;

	//width without paddings
	const actualWidth =
		node.clientWidth - paddingLeftNumberValue - paddingRightNumberValue;

	return {
		width: actualWidth,
		height: getProperAspectRatioHeight(actualWidth),
	};
};

const getProperAspectRatioHeight = (width: number) => {
	return (width * 9) / 16;
};
