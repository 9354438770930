import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { useTraceViewer } from './hooks';
import { useTraceViewerStyles } from './styles';
import { Link, Typography } from '@mui/material';

export const TraceViewer = ({ traceFileURL }: { traceFileURL: string }) => {
	const { done, src, isLongWait, downloadFile } = useTraceViewer(traceFileURL);
	const styles = useTraceViewerStyles(isLongWait);
	return (
		<div css={styles.container}>
			<If condition={!done}>
				<LoadingCircularProgress
					styles={styles.loader}
					circularProgressSize={30}
				>
					<Typography variant="h2">Downloading...</Typography>
					<Typography variant="subtitle2" css={styles.longWaitNote}>
						Trace files tend to be large, so this might take a minute.
						<Typography variant="caption" component="p">
							For future access, you can{' '}
							<Link onClick={downloadFile}>download the trace file</Link>
							<br /> and use{' '}
							<Link href="https://trace.playwright.dev" target="_blank">
								https://trace.playwright.dev
							</Link>{' '}
							to view it.
						</Typography>
					</Typography>
				</LoadingCircularProgress>
			</If>
			<If condition={!!src}>
				<iframe
					title="trace-viewer"
					src={src}
					height="100%"
					width="100%"
					sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
				></iframe>
			</If>
		</div>
	);
};
