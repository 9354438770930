import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { useIsDefaultEnvironmentCheckbox } from '@src/modules/settings/components/ApplicationEnvironmentSettings/components/IsDefaultEnvironmentCheckbox/hooks';
import { ConditionalWrapper } from '@src/common/components/ConditionalWrapper';

export const IsDefaultEnvironmentCheckbox = ({
	control,
	isCreatingNewEnvSettings,
	activeEnvironmentSettings,
	environments,
	styles,
}) => {
	const { shouldDisplay, isEnvironmentDefault } =
		useIsDefaultEnvironmentCheckbox({
			isCreatingNewEnvSettings,
			activeEnvironmentSettings,
			environments,
		});

	return (
		<If condition={shouldDisplay}>
			<ConditionalWrapper
				condition={isEnvironmentDefault}
				wrapper={(children) => (
					<Tooltip
						title="One of your environments must be set as the default environment. To switch, select another environment from the dropdown and mark it as default"
						placement="top"
					>
						{children}
					</Tooltip>
				)}
			>
				<span css={styles}>
					<FormControlLabel
						label={
							activeEnvironmentSettings?.isDefault
								? 'Default environment'
								: 'Set as default environment'
						}
						control={
							<Controller
								control={control}
								name="isDefault"
								render={({ field: props }) => {
									return (
										<Checkbox
											{...props}
											disabled={activeEnvironmentSettings?.isDefault}
											value={props.value}
											checked={props.value}
											color="primary"
										/>
									);
								}}
							/>
						}
					/>
				</span>
			</ConditionalWrapper>
		</If>
	);
};
