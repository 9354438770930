import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useTestRunsPageStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		return {
			container: css({
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: '65%',
				gap: theme.spacing(1),
			}),
			generateTestsLink: css({ textDecoration: 'underline' }),
		};
	}, [theme]);
};
