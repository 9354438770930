import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAddNewUserForm } from '@src/modules/settings/components/ProjectSettingsForm/components/AddNewUserForm/hooks';
import { useAddNewUserFormStyles } from '@src/modules/settings/components/ProjectSettingsForm/components/AddNewUserForm/styles';
import { ApplicationUserRoles } from '@src/modules/application/service';

export const AddNewUserForm = () => {
	const { register, errors, isAddNewUserLoading, handleAddUserToApp } =
		useAddNewUserForm();

	const styles = useAddNewUserFormStyles();

	return (
		<form>
			<Box css={styles.formContent}>
				<FormTextField
					name="email"
					label="Email"
					placeholder="email@example.com"
					fullWidth
					register={register}
					errors={errors}
				/>

				<FormTextField
					name="role"
					select
					label="Role"
					fullWidth
					register={register}
					defaultValue={ApplicationUserRoles.Member}
				>
					<MenuItem value={ApplicationUserRoles.Member}>Member</MenuItem>
					<MenuItem value={ApplicationUserRoles.Admin}>Admin</MenuItem>
				</FormTextField>

				<LoadingButton
					onClick={handleAddUserToApp}
					size="large"
					variant="outlined"
					loading={isAddNewUserLoading}
				>
					Add User
				</LoadingButton>
			</Box>
		</form>
	);
};
