import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useCollectionListItemStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const pageItem = css({
			h6: {
				wordBreak: 'break-all',
				maxWidth: 'calc(100% - 30px)',
			},
		});

		const link = css({
			color: 'inherit',
			width: '100%',
		});

		const inboxBadgeIcon = css({
			marginRight: '20px',
		});

		const collectionItemButton = css({
			minHeight: '60px',
		});

		const collectionTitleContainer = css({
			display: 'flex',
			gap: theme.spacing(0.5),
			alignItems: 'center',
		});

		return {
			inboxBadgeIcon,
			pageItem,
			link,
			collectionTitleContainer,
			collectionItemButton,
		};
	}, [theme]);
};

export const useCollectionListItemSkeletonStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const primarySkeleton = css({
			width: '65%',
			height: '20px',
		});

		const primaryText = css({
			marginBottom: theme.spacing(1),
		});

		const secondarySkeleton = css({
			width: '35%',
			height: '10px',
		});

		return {
			primaryText,
			secondarySkeleton,
			primarySkeleton,
		};
	}, [theme]);
};

export const useCollectionPrimaryTextStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const count = css({
			color: theme.colors.text.secondary,
		});

		return {
			count,
		};
	}, [theme]);
};
