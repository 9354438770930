import { Box, MenuItem, TableCell, TableRow } from '@mui/material';
import { FormTextField } from '@src/common/components/FormTextField';
import { useMemberTableRow } from '@src/modules/settings/components/ProjectSettingsForm/components/MemberTableRow/hooks';
import { LoadingButton } from '@mui/lab';
import { useMemberTableRowStyles } from '@src/modules/settings/components/ProjectSettingsForm/components/MemberTableRow/styles';
import { ApplicationUserRoles } from '@src/modules/application/service';
import { InvitationStatus } from '@src/modules/settings/api/types';

export const MemberTableRow = ({
	role,
	isAuthenticatedUserAllowedToUpdate,
	email,
	invitationStatus,
	id,
}: {
	role: ApplicationUserRoles;
	isAuthenticatedUserAllowedToUpdate: boolean;
	email: string;
	invitationStatus: InvitationStatus;
	id: string;
}) => {
	const {
		handleDeleteUserFromApp,
		handleEditMembersRole,
		isDeletingLoading,
		isSelectDisabled,
		invitationStatusFormatted,
		roleFormatted,
		isUserEditable,
	} = useMemberTableRow({
		id,
		isAuthenticatedUserAllowedToUpdate,
		invitationStatus,
		role,
	});

	const styles = useMemberTableRowStyles();

	return (
		<TableRow css={styles.tableRow}>
			<TableCell component="th" scope="row" css={styles.tableCell}>
				{email}
			</TableCell>

			<TableCell align="center" css={styles.tableCell}>
				<Choose>
					<When condition={isUserEditable}>
						<FormTextField
							name="role"
							select
							value={role}
							onChange={handleEditMembersRole}
							disabled={isSelectDisabled}
							styles={styles.dropdown}
						>
							<MenuItem value={ApplicationUserRoles.Member}>Member</MenuItem>
							<MenuItem value={ApplicationUserRoles.Admin}>Admin</MenuItem>
						</FormTextField>
					</When>

					<Otherwise>
						<Box css={styles.roleViewOnlyBox}>{roleFormatted}</Box>
					</Otherwise>
				</Choose>
			</TableCell>

			<TableCell align="center" css={styles.tableCell}>
				{invitationStatusFormatted}
			</TableCell>

			<If condition={isUserEditable}>
				<TableCell align="center" css={styles.tableCell}>
					<LoadingButton
						loading={isDeletingLoading}
						variant="text"
						onClick={handleDeleteUserFromApp}
					>
						Remove
					</LoadingButton>
				</TableCell>
			</If>
		</TableRow>
	);
};

//[QUESTIONS]

//if current user did not accept the project then this project will not be in the projects list?
//can Admin change another admins role to member?
//what about owner is that role has the same abilities as admin?
//remove the code from volatile state

//should redirect on select application?
//remove the active application from the menu list?

//[UPDATES]

//Add empty state and loading skeleton state for table
