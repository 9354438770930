import { Box, Dialog, DialogContent } from '@mui/material';
import { useCollectionModalStyles } from '@src/modules/collections/components/CollectionFormModal/styles';
import { useCollectionFormModal } from '../../hooks/useCollectionFormModal';
import { ConfirmationDialog } from '@src/common/components/ConfirmationDialog/ConfirmationDialog';
import { EditOrCreateCollectionModalContent } from '@src/modules/collections/components/CollectionFormModal/components/EditOrCreateCollectionModalContent/EditOrCreateCollectionModalContent';
import { CollectionModalMode } from '@src/modules/user-stories-exploration/service';
import { DeleteCollectionModal } from '@src/modules/collections/components/CollectionFormModal/components/DeleteCollectionModal/DeleteCollectionModal';

export const CollectionFormModal = ({
	open,
	onClose,
	collectionIdForUpdate,
	collections,
	openedCollectionModalMode,
	afterAddNewCollectionModalCloseCallback,
}) => {
	const {
		errors,
		confirmationAlertContent,
		handleCloseModal,
		isSubmitLoading,
		handleSubmitCollectionForm,
		registerEditCollectionField,
		isNew,
		collectionToUpdate,
	} = useCollectionFormModal({
		onClose,
		collectionIdForUpdate,
		openedCollectionModalMode,
		collections,
		afterAddNewCollectionModalCloseCallback,
	});

	const styles = useCollectionModalStyles();

	return (
		<>
			<Dialog fullWidth open={open} onClose={handleCloseModal} maxWidth="md">
				<DialogContent>
					<Box css={styles.section}>
						<Choose>
							<When
								condition={
									openedCollectionModalMode !== CollectionModalMode.Delete
								}
							>
								<EditOrCreateCollectionModalContent
									handleCloseModal={handleCloseModal}
									handleSubmitCollectionForm={handleSubmitCollectionForm}
									registerEditCollectionField={registerEditCollectionField}
									isSubmitLoading={isSubmitLoading}
									errors={errors}
									isNew={isNew}
								/>
							</When>

							<Otherwise>
								<DeleteCollectionModal
									onClose={onClose}
									collections={collections}
									collectionToDelete={collectionToUpdate}
								/>
							</Otherwise>
						</Choose>
					</Box>
				</DialogContent>
			</Dialog>

			<If condition={!!confirmationAlertContent}>
				<ConfirmationDialog {...confirmationAlertContent} />
			</If>
		</>
	);
};
