import { MostUsedMailProviders } from '@src/modules/application/components/ApplicationForm/constants';

export const getDefaultValuesWithPrefilledDomain = (user: {
	email: string;
}) => {
	if (!user) {
		return {
			appName: '',
		};
	}

	const domain = user.email.split('@')[1].toLowerCase();

	return {
		appName: MostUsedMailProviders.includes(domain)
			? ''
			: `${domain.replace(/[^a-z0-9]/g, '-')}-app`, // replace all non-alphanumeric characters with a dash
	};
};
