import {
	LogComponents,
	LoggerOptions,
	LoggerParams,
	LoggerSessionData,
	LogVerbosity,
} from '@src/services/logger/types';
import {
	ActiveLocalLogProvider,
	ActiveRemoteLogProvider,
} from '@src/services/logger/constants';
import LogProviderInterface from '@src/services/logger/providers/LogProviderInterface';
import { merge } from 'lodash';

class Logger {
	private readonly component;
	private readonly subComponent?;
	private readonly localLogger?: LogProviderInterface;
	private readonly remoteLogger?: LogProviderInterface;
	private localVerbosity;
	private remoteVerbosity;

	private static sessionData: LoggerSessionData;

	constructor(
		component: LogComponents,
		{
			subComponent = undefined,
			remoteVerbosity = LogVerbosity.Debug,
			localVerbosity = LogVerbosity.Debug,
			mute = false,
		}: LoggerOptions = {}
	) {
		this.component = component;
		this.subComponent = subComponent;
		this.remoteVerbosity = mute ? LogVerbosity.Off : remoteVerbosity;
		this.localVerbosity = mute ? LogVerbosity.Off : localVerbosity;
		if (ActiveLocalLogProvider) {
			this.localLogger = new ActiveLocalLogProvider(component, {
				subComponent,
			});
		}
		if (ActiveRemoteLogProvider) {
			this.remoteLogger = new ActiveRemoteLogProvider(component, {
				subComponent,
			});
		}
	}

	debug(message: string, data?: object, params?: LoggerParams) {
		this.log(LogVerbosity.Debug, message, data, params);
	}

	info(message: string, data?: object, params?: LoggerParams) {
		this.log(LogVerbosity.Info, message, data, params);
	}

	trace(message: string, data?: object, params?: LoggerParams) {
		this.log(LogVerbosity.Trace, message, data, params);
	}

	warn(message: string, data?: object, params?: LoggerParams) {
		this.log(LogVerbosity.Warn, message, data, params);
	}

	error(message: string, data?: object, params?: LoggerParams) {
		this.log(LogVerbosity.Error, message, data, params);
	}

	critical(message: string, data?: object, params?: LoggerParams) {
		this.log(LogVerbosity.Critical, message, data, params);
	}

	static initSessionData({ appName }) {
		this.sessionData = { appName };
	}

	static mergeSessionData(data: Partial<LoggerSessionData>) {
		this.sessionData = merge({}, this.sessionData, data);
	}

	setRemoteVerbosity(verbosity: LogVerbosity) {
		this.remoteVerbosity = verbosity;
	}
	setLocalVerbosity(verbosity: LogVerbosity) {
		this.localVerbosity = verbosity;
	}

	private log(
		verbosity: LogVerbosity,
		message: string,
		data: object = {},
		params: LoggerParams = {}
	) {
		const payload = { sessionData: Logger.sessionData, ...data };

		// log local
		if (this.localLogger && verbosity >= this.localVerbosity) {
			this.localLogger.log(verbosity, message, payload, params);
		}
		// log remote
		if (this.remoteLogger && verbosity >= this.remoteVerbosity) {
			this.remoteLogger.log(verbosity, message, payload, params);
		}
	}
}

export default Logger;
